import { Datacontext } from "../../data/datacontext";
import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("admin.agencies", {
      abstract: true,
      url: "/agencies",
      template:  require("../../views/common/sub_content.html"),
      data: { pageTitle: "Agencies", requiresLogin: true }
    })
    .state("admin.agencies.list", {
      url: "/lists",
      template:  require("./views/lists.html"),
      controller: "AgencyList",
      controllerAs: "vm",
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "treeGrid",
                files: [
                  rootUrl +
                    "assets/plugins/angular-bootstrap-grid-tree/tree-grid-directive.js",
                  rootUrl +
                    "assets/plugins/angular-bootstrap-grid-tree/treeGrid.css"
                ]
              }
            ])
        ]
      }
    })
    .state("admin.agencies.edit", {
      url: "/createEdit/{id:int}",
      template:  require("./views/create_edit.html"),
      controller: "AgencyCreatEdit",
      controllerAs: "vm",
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("admin.agencies.create", {
      url: "/createEdit",
      template:  require("./views/create_edit.html"),
      controller: "AgencyCreatEdit",
      controllerAs: "vm",
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    });
}
