import * as angular from "angular";
import { routeConfig } from "./config.route";
import {ConfigurationListCtrl, ConfigurationViewCtrl, ConfigurationCreateCtrl, PopularProductListCtrl, PopularProductViewCtrl, PopularProductCreateCtrl, PreOrderListCtrl, PreOrderViewCtrl, PreOrderCreateCtrl, Game1ActivitiesListCtrl, Game1ActivityViewCtrl, Game1ActivityCreateCtrl, FourthAreaListCtrl, FourthAreaViewCtrl, FourthAreaCreateCtrl, FourthAreaConfigListCtrl, FourthAreaConfigViewCtrl, FourthAreaConfigCreateCtrl, 
  HumancountboardConfigListCtrl,HumancountboardConfigViewCtrl,HumancountboardConfigCreateCtrl,
  StoreDevicesListCtrl, DocSignListCtrl } from "./store";

export default angular
  .module("app.store", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .controller("ConfigurationListController", ConfigurationListCtrl)
  .controller("ConfigurationViewController", ConfigurationViewCtrl)
  .controller("ConfigurationCreateController", ConfigurationCreateCtrl)
  .controller("PopularProductListController", PopularProductListCtrl)
  .controller("PopularProductViewController", PopularProductViewCtrl)
  .controller("PopularProductCreateController", PopularProductCreateCtrl)
  .controller("PreOrderListController", PreOrderListCtrl)
  .controller("PreOrderViewController", PreOrderViewCtrl)
  .controller("PreOrderCreateController", PreOrderCreateCtrl)
  .controller("StudentAreaListController", Game1ActivitiesListCtrl)
  .controller("StudentAreaViewController", Game1ActivityViewCtrl)
  .controller("StudentAreaCreateController", Game1ActivityCreateCtrl)
  .controller("FourthAreaListController", FourthAreaListCtrl)
  .controller("FourthAreaViewController", FourthAreaViewCtrl)
  .controller("FourthAreaCreateController", FourthAreaCreateCtrl)
  .controller("FourthAreaConfigListController", FourthAreaConfigListCtrl)
  .controller("FourthAreaConfigViewController", FourthAreaConfigViewCtrl)
  .controller("FourthAreaConfigCreateController", FourthAreaConfigCreateCtrl)
  .controller("HumancountboardConfigListController", HumancountboardConfigListCtrl)
  .controller("HumancountboardConfigViewController", HumancountboardConfigViewCtrl)
  .controller("HumancountboardConfigCreateController", HumancountboardConfigCreateCtrl)
  .controller("StoreDeviceListController", StoreDevicesListCtrl)
  .controller("DocSignListController", DocSignListCtrl);
