import * as angular from "angular";
import Swal from "sweetalert2";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { ICustomer, IDevice, IAgency } from "../models/models";
import { FunctionalModule } from "../models/enums";

class BaseCrtl {
  public getGameTypeList(): { [key: number]: string } {
    return {
      0: "拉霸",
      1: "刮刮樂",
      2: "省錢快拍",
      3: "招財進寶",
      4: "夾娃娃機"
    };
  }

  public checkImage(
    imageIndex: number,
    imagesRelease: any,
    imagesUpdate: any
  ): boolean {
    var sucess: boolean = false;
    for (var i = 0; i < imagesRelease.length; i++) {
      if (imagesRelease[i].no == imageIndex) {
        sucess = true;
      }
    }
    for (var i = 0; i < imagesUpdate.length; i++) {
      if (imagesUpdate[i].no == imageIndex) {
        sucess = true;
      }
    }
    return sucess;
  }

  public getDate(fromDateTime: Date): Date {
    var theDate = new Date();
    if (fromDateTime != undefined && fromDateTime != null) {
      theDate = fromDateTime;
    }
    theDate.setHours(0);
    theDate.setMinutes(0);
    theDate.setSeconds(0);
    return theDate;
  }
}

// 維護
export class ActivityListCtrl {
  private activities: Array<any>;
  private activityService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common", "$filter", "$state"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state
  ) {
    this.activities = datacontext.martConfigurations().query();
    this.activityService = datacontext.martConfigurations();
    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number, isEdit: boolean): void {
    this.activityService.delete(
      { id: id, isEdit: isEdit },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
      },
      httpResponse => {
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }
}

export class ActivityViewCtrl {
  private activity: any;
  private logger: ILogger;
  static $inject = ["datacontext", "common", "$stateParams", "$window"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window
  ) {
    this.logger = common.logger;
    datacontext.martConfigurations().get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        var imagesRelease = [];
        for (var i = 0; i < value.martImages.length; i++) {
          if (value.martImages[i].itemStatus === 1) {
            imagesRelease.push(value.martImages[i]);
          }
        }
        this.activity.martImages = imagesRelease;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }
  goBack(): void {
    this.$window.history.back();
  }
}

export class ActivityCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;

  private titleIndex: string[];
  private valueIndex: number[];
  private running: boolean;
  private uploader: any;
  private isEdit: boolean;
  private selectAllValue: string;
  private imagesRelease: any;
  private imagesUpdate: any;
  private activity: any;
  private imageCategory: string;
  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "localStorageService",
    "$window",
    "$rootScope"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private localStorageService,
    private $window,
    private $rootScope
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.martConfigurations();

    // 初始化
    this.titleIndex = [
      "背景圖(Background)",
      "按鈕(第一功能)",
      "按鈕(第二功能)",
      "按鈕(第三功能)",
      "MP3(第一功能)",
      "MP3(第二功能)",
      "MP3(第三功能)"
    ];
    this.valueIndex = [0, 3, 1, 2, 6, 4, 5];
    this.running = false;

    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.selectAllValue = "Select All";
    this.imagesRelease = [];
    this.imagesUpdate = [];
    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      customerId: null,
      agencyId: null,
      isNormalAction: true,
      devices: [],
      martImages: [],
      marquee: "#ffffff",
      marqueeBackground: "#51d95a"
    };
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }
    this.customers = this.customerService.query();

    // Devices
	  this.activityService.getDeviceList((value, responseHeaders) => {
		  this.devices = value.filter(
			  device => device.functionalModule & FunctionalModule.MartApp
		  );
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/MartConfiguration", // webapi url
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: (item, options) => {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType === "mp4") {
          return true;
        } else {
          if ("|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1) {
            var fileSize = Math.ceil(item.size / 1024 / 1024);
            if (fileSize > 2) {
              Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }
      }
    });

    // CALLBACKS
    this.uploader.onWhenAddingFileFailed = (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) => {
      this.logger.log("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      this.logger.log("onAfterAddingFile", fileItem.file.name);
    };
    this.uploader.onAfterAddingAll = addedFileItems => {
      this.logger.log("onAfterAddingAll", addedFileItems);
    };
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      var formData = [
        {
          activityId: this.activity.id,
          imageCategory: this.imageCategory
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
      this.logger.log("onBeforeUploadItem", item);
    };
    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (response != null) {
        this.activity.id = response.id;
        this.activity.martImages = response.martImages;
        this.imagesRelease = [];
        this.imagesUpdate = [];
        if (this.isEdit) {
          for (var i = 0; i < response.martImages.length; i++) {
            if (response.martImages[i].itemStatus === 1) {
              this.imagesRelease.push(response.martImages[i]);
            } else {
              this.imagesUpdate.push(response.martImages[i]);
            }
          }
        } else {
          this.imagesRelease = response.martImages;
        }
      }
      this.logger.log("onSuccessItem", response);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        for (var i = 0; i < this.activity.martImages.length; i++) {
          if (this.activity.martImages[i].itemStatus === 1) {
            this.imagesRelease.push(this.activity.martImages[i]);
          } else {
            this.imagesUpdate.push(this.activity.martImages[i]);
          }
        }

        // 設定已經勾選的 Device
        var devicesSelected = this.activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }
        this.logger.log(this.activity);
      }
    );
  }

  uploaderChange(imageCategory: string) {
    this.imageCategory = imageCategory;
  }

  submitCheck(): void {
    this.save();
  }
  save(): void {
    this.running = true;
    this.activityService.createConfiguration(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.running = false;
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("marts.list");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
      }
    );
  }

  cancel(): void {
    this.$window.history.back();
  }

  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }
  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }
  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.martImages, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.activity.martImages = value.martImages;
        this.imagesRelease = [];
        this.imagesUpdate = [];
        for (var i = 0; i < value.martImages.length; i++) {
          if (value.martImages[i].itemStatus === 1) {
            this.imagesRelease.push(value.martImages[i]);
          } else {
            this.imagesUpdate.push(value.martImages[i]);
          }
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

// 第一功能
export class GameActivitiesListCtrl {
  private activities: Array<any>;
  private activityService: any;
  private logger: ILogger;
  private _baseCrtl = new BaseCrtl();
  private gameTypeList: { [key: number]: string };

  running: boolean;

  static $inject = ["datacontext", "common", "$filter", "$state"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state
  ) {
    this.gameTypeList = this._baseCrtl.getGameTypeList();
    this.activityService = datacontext.martGames();
    this.logger = common.logger;
    this.running = false;
    this.showActivityList();
  }

  showActivityList(): void {
    this.running = true;
    this.activityService.query(
      null,
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("系統錯誤訊息", httpResponse, "Error");
      }
    );
  }

  deleteActivity(id: number, isEdit: boolean): void {
    this.running = true;
    this.activityService.delete(
      { id: id, isEdit: isEdit },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
        this.running = false;
      },
      httpResponse => {
        this.running = false;
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }
}

export class GameActivityViewCtrl {
  private activity: any;
  private logger: ILogger;
  private _baseCrtl = new BaseCrtl();
  private gameTypeList: { [key: number]: string };

  private rewardTotalCount = 0;
  private reward1 = [0, 0];
  private reward2 = [0, 0];
  private reward3 = [0, 0];
  private reward4 = [0, 0];
  private reward5 = [0, 0];
  private rewardOther = [0, 0];
  private service: any;

  itemTitle: string[];
  itemCountTop: number;
  running: boolean;

  static $inject = ["datacontext", "common", "$stateParams", "$window"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window
  ) {
    this.logger = common.logger;
    this.service = datacontext.martGames();
    this.gameTypeList = this._baseCrtl.getGameTypeList();
    this.itemTitle = ["背景圖", "轉輪圖"];
    this.itemCountTop = this.itemTitle.length;
    this.running = true;
    this.service.get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        this.countReward(this.activity.prizeCountList);
        this.logger.log(this.activity);
        this.running = false;
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }

  goBack(): void {
    this.$window.history.back();
  }

  countReward(prizeCountList: any): void {
    this.reward1 = [0, 0];
    this.reward2 = [0, 0];
    this.reward3 = [0, 0];
    this.reward4 = [0, 0];
    this.reward5 = [0, 0];
    this.rewardOther = [0, 0];
    this.rewardTotalCount = 0;
    for (var i = 0; i < prizeCountList.length; i++) {
      this.rewardTotalCount =
        this.rewardTotalCount + prizeCountList[i].totalCount;
      switch (prizeCountList[i].no) {
        case 1:
          this.reward1[0] = prizeCountList[i].enableCount;
          this.reward1[1] = prizeCountList[i].disableCount;
          break;
        case 2:
          this.reward2[0] = prizeCountList[i].enableCount;
          this.reward2[1] = prizeCountList[i].disableCount;
          break;
        case 3:
          this.reward3[0] = prizeCountList[i].enableCount;
          this.reward3[1] = prizeCountList[i].disableCount;
          break;
        case 4:
          this.reward4[0] = prizeCountList[i].enableCount;
          this.reward4[1] = prizeCountList[i].disableCount;
          break;
        case 5:
          this.reward5[0] = prizeCountList[i].enableCount;
          this.reward5[1] = prizeCountList[i].disableCount;
          break;
        default:
          this.rewardOther[0] = prizeCountList[i].enableCount;
          this.rewardOther[1] = prizeCountList[i].disableCount;
          break;
      }
    }
  }
}

export class GameActivityCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;

  private logger: ILogger;
  private _baseCrtl = new BaseCrtl();

  private customerService;
  private agencyService;
  private activityService;
  private imageStartDate;

  gameTypeList: { [key: number]: string };

  running: boolean;
  uploader: any;
  images: any;
  activity: any;
  selectAllValue: string;
  isEdit: boolean;
  imagesUpdate: any;
  imageNo: number;
  imageCategory: string;
  webApiUrl: string;
  rewardTotalCount: number;
  reward1 = [0, 0];
  reward2 = [0, 0];
  reward3 = [0, 0];
  reward4 = [0, 0];
  reward5 = [0, 0];
  rewardOther = [0, 0];
  itemTitle: string[];
  itemCountTop: number;
  private role: any;
  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "$window",
    "localStorageService"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private $window,
    private localStorageService
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.martGames();

    // 初始化
    this.running = false;
    this.gameTypeList = this._baseCrtl.getGameTypeList();
    this.itemTitle = [
      "背景圖",
      "轉輪圖",
      "一獎",
      "二獎",
      "三獎",
      "四獎",
      "五獎"
    ];
    this.itemCountTop = this.itemTitle.length;
    this.images = [];
    for (var i = 0; i < this.itemCountTop; i++) {
      var image = {
        no: i,
        reward: "",
        marketPrice: null,
        price: null,
        gotoUrl: "",
        fileUrl: ""
      };
      this.images.push(image);
    }

    var nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      isNormalAction: true,
      notifyMail: "",
      notifyMinimumQty: 10,
      agencyId: null,
      devices: [],
      customerId: null,
      startDate: this.getDate(new Date()),
      endDate: this.getDate(nextMonth),
      gotoUrl: "",
      images: this.images,
      prizeCountList: [],
      gameType: 0
    };

    this.selectAllValue = "Select All";
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.imagesUpdate = [];
    this.imageNo = 0;
    this.imageCategory = "";

    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }
    this.customers = this.customerService.query();

    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.MartApp
		);
      if (this.isEdit) {
        this.running = true;
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: this.webApiUrl,
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // Another user-defined filter
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: function(item) {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType === "mp4") {
          return true;
        } else if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    this.uploader.filters.push({
      name: "csvFilter",
      fn: function(item) {
        var fileName = item.name.toLowerCase();
        var charIndex = fileName.lastIndexOf(".");
        var key = fileName.substring(charIndex, fileName.length);
        return "|.csv|".indexOf(key) !== -1;
      }
    });

    // CALLBACKS
    this.uploader.onWhenAddingFileFailed = (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) => {
      this.logger.log("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      this.logger.log("onAfterAddingFile", fileItem.file.name);
    };
    this.uploader.onAfterAddingAll = addedFileItems => {
      this.logger.log("onAfterAddingAll", addedFileItems);
    };
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      item.url = this.webApiUrl;
      if (this.imageCategory == "PrizeNumbers") {
        this.activity.prizeCountList = [];
        this.countReward(this.activity.prizeCountList);
      }
      var formData = [
        {
          activityId: this.activity.id,
          imageCategory: this.imageCategory,
          imageNo: this.imageNo,
          martCategory: "3"
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
      this.logger.log("onBeforeUploadItem", item);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      this.logger.log("onSuccessItem", response);
      this.activity.id = response.id;
      if (this.imageCategory == "PrizeNumbers") {
        this.activity.prizeCountList = response.prizeCountList;
        this.countReward(this.activity.prizeCountList);
      } else {
        this.imagesUpdate = [];
        if (this.isEdit) {
          for (var i = 0; i < response.martImages.length; i++) {
            var no = response.martImages[i].no;
            if (response.martImages[i].itemStatus === 1) {
              this.activity.images[no].id = response.martImages[i].id;
              this.activity.images[no].dispalyName =
                response.martImages[i].dispalyName;
              this.activity.images[no].fileUrl = response.martImages[i].fileUrl;
            } else {
              this.imagesUpdate.push(response.martImages[i]);
            }
          }
        } else {
          for (var i = 0; i < response.martImages.length; i++) {
            var no = response.martImages[i].no;
            this.activity.images[no].id = response.martImages[i].id;
            this.activity.images[no].dispalyName =
              response.martImages[i].dispalyName;
            this.activity.images[no].fileUrl = response.martImages[i].fileUrl;
          }
        }
      }
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCancelItem = (fileItem, response, status, headers) => {
      this.logger.log("onCancelItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        this.activity.startDate = this.getDate(
          this.$filter("localDateTime")(this.activity.startDate)
        );
        this.activity.endDate = this.getDate(
          this.$filter("localDateTime")(this.activity.endDate)
        );

        this.countReward(this.activity.prizeCountList);

        this.imagesUpdate = [];
        for (var i = 0; i < this.activity.martImages.length; i++) {
          var no = this.activity.martImages[i].no;
          if (this.activity.martImages[i].itemStatus === 1) {
            this.images[no] = this.activity.martImages[i];
          } else {
            this.imagesUpdate.push(this.activity.martImages[i]);
          }
        }

        this.activity.images = this.images;

        // 設定已經勾選的 Device
        var devicesSelected = this.activity.devices;

        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }
        this.logger.log(this.activity);
        this.running = false;
      }
    );
  }

  submitCheck(): void {
    this.running = true;
    // 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
    var errMsg = new Array();
    if (this.activity.notifyMail == "") {
      errMsg.push("。序號結束的通知對象");
    }
    if (this.activity.gotoUrl == "") {
      errMsg.push("。QR Code URL");
    }
    if (this.activity.customerId == null || this.activity.customerId == "") {
      errMsg.push("。廣告 客戶");
    }
    if (this.activity.startDate == "") {
      errMsg.push("。廣告 開始日期");
    }
    if (this.activity.endDate == "") {
      errMsg.push("。廣告 結束日期");
    }
    var startDate = Date.parse(this.activity.startDate);
    var endDate = Date.parse(this.activity.endDate);
    if (startDate > endDate) {
      {
        errMsg.push("。廣告 開始日期 > 結束日期");
      }
    }
    if (this.activity.images[0].fileUrl == "") {
      errMsg.push("。" + this.itemTitle[0] + " 檔案上傳");
    }

    if (this.activity.gameType == 0 || this.activity.gameType == null) {
      if (this.activity.images[1].fileUrl == "") {
        // 切為拉霸, 檢查是否有補上傳檔案
        var image1Upload = false;
        for (var i = 0; i < this.imagesUpdate.length; i++) {
          if (this.imagesUpdate[i].no == 1) {
            image1Upload = true;
            break;
          }
        }
        if (image1Upload == false) {
          errMsg.push("。" + this.itemTitle[1] + " 檔案上傳");
        }
      }
    }

    for (var i = 2; i < this.itemTitle.length; i++) {
      if (this.activity.images[i].reward == "") {
        errMsg.push("。" + this.itemTitle[i] + " 獎項名稱");
      }
    }

    if (this.rewardTotalCount == 0) {
      errMsg.push("。序號(csv檔案)");
    }

    if (errMsg.length > 0) {
      this.running = false;
      Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
    } else {
      this.save();
    }
  }

  save(): void {
    this.running = true;
    this.activityService.createActivity(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$window.history.back();
      },
      httpResponse => {
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
      }
    );
  }

  cancel(): void {
    this.running = true;
    this.$window.history.back();
  }

  // Device 的 CRUD
  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }

  // Device Select All
  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }

  uploaderChange(imageCategory: string) {
    if (imageCategory == "PrizeNumbers") {
      this.imageCategory = imageCategory;
      this.webApiUrl = this.apiUrl + "api/PrizeNumbers";
    } else {
      this.imageCategory =
        imageCategory == "0"
          ? "Background"
          : imageCategory == "1"
          ? "Reward"
          : "Product";
      this.imageNo = Number(imageCategory);
      this.webApiUrl = this.apiUrl + "api/MartGame";
    }
  }

  countReward(prizeCountList: any): void {
    this.reward1 = [0, 0];
    this.reward2 = [0, 0];
    this.reward3 = [0, 0];
    this.reward4 = [0, 0];
    this.reward5 = [0, 0];
    this.rewardOther = [0, 0];
    this.rewardTotalCount = 0;
    for (var i = 0; i < prizeCountList.length; i++) {
      this.rewardTotalCount =
        this.rewardTotalCount + prizeCountList[i].totalCount;
      switch (prizeCountList[i].no) {
        case 1:
          this.reward1[0] = prizeCountList[i].enableCount;
          this.reward1[1] = prizeCountList[i].disableCount;
          break;
        case 2:
          this.reward2[0] = prizeCountList[i].enableCount;
          this.reward2[1] = prizeCountList[i].disableCount;
          break;
        case 3:
          this.reward3[0] = prizeCountList[i].enableCount;
          this.reward3[1] = prizeCountList[i].disableCount;
          break;
        case 4:
          this.reward4[0] = prizeCountList[i].enableCount;
          this.reward4[1] = prizeCountList[i].disableCount;
          break;
        case 5:
          this.reward5[0] = prizeCountList[i].enableCount;
          this.reward5[1] = prizeCountList[i].disableCount;
          break;
        default:
          this.rewardOther[0] = prizeCountList[i].enableCount;
          this.rewardOther[1] = prizeCountList[i].disableCount;
          break;
      }
    }
  }

  getDate(fromDateTime: Date): Date {
    var theDate;
    if (fromDateTime == undefined || fromDateTime == null) {
      theDate = new Date();
    } else {
      theDate = fromDateTime;
    }
    theDate.setHours(0);
    theDate.setMinutes(0);
    theDate.setSeconds(0);
    return theDate;
  }

  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.martImages, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesUpdate = [];
        for (var i = 0; i < value.martImages.length; i++) {
          if (value.martImages[i].itemStatus === 1) {
            var no = value.martImages[i].no;
            this.activity.images[no].id = value.martImages[i].id;
            this.activity.images[no].dispalyName =
              value.martImages[i].dispalyName;
            this.activity.images[no].fileUrl = value.martImages[i].fileUrl;
          } else {
            this.imagesUpdate.push(value.martImages[i]);
          }
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

// 第二功能
export class PurchasingActivitiesListCtrl {
  public activities: Array<any>;
  private activityService: any;
  private logger: ILogger;
  private running: boolean;
  private searchText: string;
  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$filter",
    "$state"
  ];
  constructor(
    private authenticator: any,
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state
  ) {
    this.role = authenticator.authentication.role;
    if (this.role === "Manager") {
      this.searchText = "審核";
    }
    this.activities = datacontext.martPurchasings().query();
    this.activityService = datacontext.martPurchasings();
    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number, isEdit: boolean): void {
    this.activityService.delete(
      { id: id, isEdit: isEdit },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
      },
      httpResponse => {
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }

  docCopy(id: number): void {
    this.running = true;
    this.activityService.docCopy(
      { id: id },
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Copy Activity Error");
      }
    );
  }
}

export class PurchasingActivityViewCtrl {
  private activity: any;
  private logger: ILogger;
  private activityService;

  private docSign: any;
  private running: boolean;

  static $inject = [
    "datacontext",
    "common",
    "$stateParams",
    "$window",
    "$state"
  ];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window,
    private $state
  ) {
    this.logger = common.logger;
    this.activityService = datacontext.martPurchasings();
    this.activityService.get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
    // 初始值
    this.docSign = {
      id: 0,
      comment: "",
      decision: ""
    };
  }

  goBack(): void {
    this.$window.history.back();
  }

  signAction(decision: string): void {
    this.running = true;
    this.docSign.id = this.activity.id;
    this.docSign.decision = decision;
    this.activityService.signDoc(
      null,
      this.docSign,
      (value, responseHeaders) => {
        this.running = false;
        this.$state.go("marts.purchasingList");
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Submit failed.", httpResponse, "簽核失敗");
      }
    );
  }
}

export class PurchasingActivityCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;
  private _baseCrtl: BaseCrtl = new BaseCrtl();

  private customerService;
  private agencyService;
  private activityService;
  private imageStartDate;
  private images = [];
  private uploader: any;
  private activity: any;
  private isEdit: boolean;
  private imagesRelease: any;
  private selectAllValue: string;
  private running: boolean;
  private martImages: any;
  private imageNo: string;
  private role: any;
  private imageCountTop: number;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "$window",
    "localStorageService"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private $window,
    private localStorageService
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.martPurchasings();

    // 初始化
    this.imageCountTop = 10;
    this.running = false;
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.imagesRelease = [];
    var nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      agencyId: null,
      isNormalAction: true,
      devices: [],
      images: []
    };

    for (var i = 0; i < this.imageCountTop; i++) {
      var image = {
        no: i + 1,
        name: "",
        customerId: null,
        startDate: this._baseCrtl.getDate(new Date()),
        endDate: this._baseCrtl.getDate(nextMonth),
        gotoUrl: ""
      };
      this.images.push(image);
    }
    this.activity.images = this.images;
    this.selectAllValue = "Select All";
    this.martImages = [];
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }
    this.customers = this.customerService.query();
    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.MartApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/MartPurchasing", // webapi url
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: function(item /*{File|FileLikeObject}*/, options) {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType === "mp4") {
          return true;
        } else if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onWhenAddingFileFailed = (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) => {};

    this.uploader.onAfterAddingFile = fileItem => {};

    this.uploader.onAfterAddingAll = addedFileItems => {};

    this.uploader.onBeforeUploadItem = item => {
      this.running = true;

      var formData = [
        {
          activityId: this.activity.id,
          imageNo: this.imageNo
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
    };
    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (response != null) {
        this.activity.id = response.id;
        this.activity.martImages = response.martImages;
        this.imagesRelease = [];
        for (var i = 0; i < response.martImages.length; i++) {
          this.imagesRelease.push(response.martImages[i]);
        }
      }
      this.logger.log("onSuccessItem", response);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCancelItem = (fileItem, response, status, headers) => {
      this.logger.log("onCancelItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.running = true;
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        this.activity.images = this.images;
        var itemNo = 0;
        for (var i = 0; i < this.activity.martImages.length; i++) {
          itemNo = this.activity.martImages[i].no - 1;
          if (itemNo >= 0) {
            this.imagesRelease.push(this.activity.martImages[i]);
            this.activity.images[itemNo] = this.activity.martImages[i];
            this.activity.images[itemNo].startDate = this.getDate(
              this.$filter("localDateTime")(this.activity.martImages[i].startDate)
            );
            this.activity.images[itemNo].endDate = this.getDate(
              this.$filter("localDateTime")(this.activity.martImages[i].endDate)
            );
          }
        }

        // 設定已經勾選的 Device
        var devicesSelected = this.activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }

        this.logger.log(this.activity);
        this.running = false;
      }
    );
  }
  uploaderChange(imageNo: string) {
    this.imageNo = imageNo;
  }
  submitCheck(): void {
    // 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
    var errMsg = new Array();

    if (this.imagesRelease.length == 0) {
      errMsg.push("。廣告(1) 檔案上傳");
    }
    for (var i = 0; i < this.imagesRelease.length; i++) {
      if (
        this.activity.images[i].customerId == null ||
        this.activity.images[i].customerId == ""
      ) {
        errMsg.push("。廣告(" + (i + 1) + ") 客戶");
      }
      if (this.activity.images[i].startDate == "") {
        errMsg.push("。廣告(" + (i + 1) + ") 開始日期");
      }
      if (this.activity.images[i].endDate == "") {
        errMsg.push("。廣告(" + (i + 1) + ") 結束日期");
      }
      if (this.activity.images[i].gotoUrl == "") {
        errMsg.push("。廣告(" + (i + 1) + ") QR Code URL");
      }
      var startDate = Date.parse(this.activity.images[i].startDate);
      var endDate = Date.parse(this.activity.images[i].endDate);
      if (startDate > endDate) {
        {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期 > 結束日期");
        }
      }
      var nowDate = Date.now();
      if (nowDate > endDate) {
        {
          errMsg.push("。廣告(" + (i + 1) + ") 結束日期 < 今日");
        }
      }
      this.activity.images[i].no = i + 1;
    }

    if (errMsg.length > 0) {
      Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
    } else {
      this.docSubmit();
    }
  }

  docSubmit(): void {
    this.running = true;
    // Save
    this.activityService.createActivity(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$window.history.back();
      },
      httpResponse => {
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
        this.$window.history.back();
      }
    );
  }

  save(): void {
    this.running = true;
    // Save
    this.activityService.save(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$window.history.back();
      },
      httpResponse => {
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
        this.$window.history.back();
      }
    );
  }

  cancel(): void {
    this.running = true;
    this.$window.history.back();
  }
  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }
  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }
  getDate(fromDateTime: Date): Date {
    var theDate;
    if (fromDateTime == undefined || fromDateTime == null) {
      theDate = new Date();
    } else {
      theDate = fromDateTime;
    }
    theDate.setHours(0);
    theDate.setMinutes(0);
    theDate.setSeconds(0);
    return theDate;
  }
  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.martImages, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesRelease = [];
        for (var i = 0; i < value.martImages.length; i++) {
          this.imagesRelease.push(value.martImages[i]);
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

// 第三功能
export class Purchasing2ActivitiesListCtrl {
  public activities: Array<any>;
  private activityService: any;
  private logger: ILogger;
  private running: boolean;
  private searchText: string;
  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$filter",
    "$state"
  ];
  constructor(
    private authenticator: any,
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state
  ) {
    this.role = authenticator.authentication.role;
    if (this.role === "Manager") {
      this.searchText = "審核";
    }
    this.activities = datacontext.martTickets().query();
    this.activityService = datacontext.martTickets();
    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number): void {
    this.activityService.delete(
      { id: id },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
      },
      httpResponse => {
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }
  docCopy(id: number): void {
    this.running = true;
    this.activityService.docCopy(
      { id: id },
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Copy Activity Error");
      }
    );
  }
}

export class Purchasing2ActivityViewCtrl {
  private activity: any;
  private logger: ILogger;
  private activityService;
  private docSign: any;
  private running: boolean;

  static $inject = [
    "datacontext",
    "common",
    "$stateParams",
    "$window",
    "$state"
  ];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window,
    private $state
  ) {
    this.logger = common.logger;
    this.activityService = datacontext.martTickets();
    this.activityService.get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
    // 初始值
    this.docSign = {
      id: 0,
      comment: "",
      decision: ""
    };
  }

  goBack(): void {
    this.$window.history.back();
  }

  signAction(decision: string): void {
    this.running = true;
    this.docSign.id = this.activity.id;
    this.docSign.decision = decision;
    this.activityService.signDoc(
      null,
      this.docSign,
      (value, responseHeaders) => {
        this.running = false;
        this.$window.history.back();
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Submit failed.", httpResponse, "簽核失敗");
      }
    );
  }
}

export class Purchasing2ActivityCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;
  private images = [];

  private _baseCrtl = new BaseCrtl();

  running: boolean;
  isEdit: boolean;
  imagesRelease: any;
  activity: any;
  selectAllValue: string;
  uploader: any;
  imageNo: number;
  private role: any;
  private imageCountTop: number;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "$window",
    "localStorageService"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private $window,
    private localStorageService
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.martTickets();

    // 初始化
    this.running = false;
    this.imageCountTop = 10;
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.imagesRelease = [];
    var nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      agencyId: null,
      isNormalAction: true,
      devices: [],
      images: []
    };

    for (var i = 0; i < this.imageCountTop; i++) {
      var image = {
        no: i,
        name: "",
        customerId: null,
        startDate: this._baseCrtl.getDate(new Date()),
        endDate: this._baseCrtl.getDate(nextMonth),
        gotoUrl: ""
      };
      this.images.push(image);
    }
    this.activity.images = this.images;
    this.selectAllValue = "Select All";
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }
    this.customers = this.customerService.query();
    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.MartApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/MartTicket", // webapi url
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: function(item /*{File|FileLikeObject}*/, options) {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType === "mp4") {
          return true;
        } else if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onWhenAddingFileFailed = (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) => {
      this.logger.log("onWhenAddingFileFailed", item, filter, options);
    };

    this.uploader.onAfterAddingFile = fileItem => {};

    this.uploader.onAfterAddingAll = addedFileItems => {};

    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      this.logger.log("onBeforeUploadItem", item);
      var formData = [
        {
          activityId: this.activity.id,
          imageNo: this.imageNo
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      this.logger.log("onSuccessItem", response);
      if (response != null) {
        this.activity.id = response.id;
        this.activity.martImages = response.martImages;
        this.imagesRelease = [];
        if (this.isEdit) {
          for (var i = 0; i < response.martImages.length; i++) {
            this.imagesRelease.push(response.martImages[i]);
          }
        } else {
          this.imagesRelease = response.martImages;
        }
      }
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCancelItem = (fileItem, response, status, headers) => {
      this.logger.log("onCancelItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.running = true;
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        this.activity.images = this.images;
        var itemNo = 0;
        for (var i = 0; i < this.activity.martImages.length; i++) {
          itemNo = this.activity.martImages[i].no;
          this.imagesRelease.push(this.activity.martImages[i]);
          this.activity.images[itemNo] = this.activity.martImages[i];
          this.activity.images[itemNo].startDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(this.activity.martImages[i].startDate)
          );
          this.activity.images[itemNo].endDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(this.activity.martImages[i].endDate)
          );
        }

        // 設定已經勾選的 Device
        var devicesSelected = this.activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }

        this.logger.log(this.activity);
        this.running = false;
      }
    );
  }
  uploaderChange(imageNo: number) {
    this.imageNo = imageNo;
  }
  submitCheck(): void {
    // 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
    var errMsg = new Array();

    if (this.imagesRelease.length == 0) {
      errMsg.push("。廣告(1) 檔案上傳");
    }
    for (var i = 0; i < this.imagesRelease.length; i++) {
      if (
        this.activity.images[i].customerId == null ||
        this.activity.images[i].customerId == ""
      ) {
        errMsg.push("。廣告(" + (i + 1) + ") 客戶");
      }
      if (this.activity.images[i].startDate == "") {
        errMsg.push("。廣告(" + (i + 1) + ") 開始日期");
      }
      if (this.activity.images[i].endDate == "") {
        errMsg.push("。廣告(" + (i + 1) + ") 結束日期");
      }
      if (this.activity.images[i].gotoUrl == "") {
        errMsg.push("。廣告(" + (i + 1) + ") QR Code URL");
      }
      var startDate = Date.parse(this.activity.images[i].startDate);
      var endDate = Date.parse(this.activity.images[i].endDate);
      if (startDate > endDate) {
        {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期 > 結束日期");
        }
      }
      var nowDate = Date.now();
      if (nowDate > endDate) {
        {
          errMsg.push("。廣告(" + (i + 1) + ") 結束日期 < 今日");
        }
      }
      this.activity.images[i].no = i;
    }

    if (errMsg.length > 0) {
      Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
    } else {
      this.release();
    }
  }

  release(): void {
    this.running = true;
    this.activityService.releaseActivity(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.running = false;
        this.logger.success("Activity has Release!", value, "Good job!");
        this.$window.history.back();
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Release`,
          httpResponse,
          "Release Activity Error"
        );
      }
    );
  }

  save(): void {
    this.running = true;
    // Save
    this.activityService.save(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$window.history.back();
      },
      httpResponse => {
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
      }
    );
  }

  cancel(): void {
    this.$window.history.back();
  }

  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }

  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }
  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.martImages, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesRelease = [];
        for (var i = 0; i < value.martImages.length; i++) {
          this.imagesRelease.push(value.martImages[i]);
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

// Device Info
export class DevicesListCtrl {
  private devices: any[];
  private activityService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common"];
  constructor(datacontext: Datacontext, common: any) {
    this.logger = common.logger;
    this.activityService = datacontext.martConfigurations();
    this.activityService.getDeviceInfoList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.MartApp
		);
      this.logger.log("devices :", this.devices);
    });
  }
}
