import { StateService } from "@uirouter/angularjs";
import { ILogger } from "../../blocks/logger/logger";
import { Datacontext, IProjectorResource } from "../../data/datacontext";
import { IProjector } from "../../models/models";

interface IProjectorListViewModel {
  projectors: Array<any>;

  del(id: number): void;
}

export class ProjectorListsCtrl implements IProjectorListViewModel {
  projectors: angular.resource.IResourceArray<IProjectorResource>;

  private projectorService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common", "projectors", "$filter"];
  constructor(
    datacontext: Datacontext,
    common: any,
    projectors: angular.resource.IResourceArray<IProjectorResource>,
    private $filter
  ) {
    this.projectors = projectors;

    this.projectorService = datacontext.projectors();
    this.logger = common.logger;
    this.logger.log("projectors :", projectors);
  }

  del(id: number): void {
    this.projectorService.delete(
      { id: id },
      (value, responseHeaders) => {
        var indx = this.$filter("findIndexByKeyValue")(
          this.projectors,
          "id",
          id
        );
        this.projectors.splice(indx, 1);
        this.logger.success(
          "Projector has be deleted",
          value,
          "Delete Projector Success"
        );
      },
      httpResponse => {
        this.logger.error(
          "Projector can't delete, device ID is " + id,
          httpResponse,
          "Delete Projector Error"
        );
      }
    );
  }
}

interface IProjectorViewModel {
  projector: IProjector;
}

export class ProjectorViewCtrl implements IProjectorViewModel {
  static $inject = ["common", "projector"];
  constructor(common: any, public projector: IProjector) {
    var logger: ILogger = common.logger;
    logger.log(projector);
  }
}

interface IProjectorSaveViewModel {
  projector: IProjector;
  save(): void;
}

export class ProjectorSaveCtrl implements IProjectorSaveViewModel {
  private logger: ILogger;
  private projectorService: any;
  private isEdit: boolean;
  projector: IProjector;

  static $inject = [
    "datacontext",
    "common",
    "devices",
    "$state",
    "$stateParams"
  ];
  constructor(
    datacontext: Datacontext,
    common,
    public devices: angular.resource.IResourceArray<
      angular.resource.IResource<any>
    >,
    private $state: StateService,
    $stateParams: any
  ) {
    this.logger = common.logger;
    this.projectorService = datacontext.projectors();
    this.isEdit = $stateParams.id !== undefined;
    if (this.isEdit) {
      this.projector = this.projectorService.get({ id: $stateParams.id });
    }
  }

  save(): void {
    if (this.isEdit) {
      this.projectorService.update(
        { id: this.projector.id },
        this.projector,
        (value, responseHeaders) => {
          this.logger.success("Projectors has Update!", value, "Good job!");
          this.$state.go("developer.projectors.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    } else {
      this.projectorService.save(
        null,
        this.projector,
        (value, responseHeaders) => {
          this.isEdit = true;
          this.logger.success("Projectors has Create!", value, "Good job!");
          this.$state.go("developer.projectors.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    }
  }
}
