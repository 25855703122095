import { StateProvider } from "@uirouter/angularjs";
import { Datacontext, ILicenseResource } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(
  rootUrl: string,
  $stateProvider: StateProvider
): void {
  $stateProvider
    .state("developer.configuration", {
      abstract: true,
      url: "/configuration",
      template:  require("../../views/common/sub_content.html"),
      data: { pageTitle: "configuration", requiresLogin: true }
    })
    .state("developer.configuration.list", {
      url: "/lists",
      template:  require("./views/lists.html"),
      controller: "ConfigurationList",
      controllerAs: "vm",
      resolve: {
        license: [
          "datacontext",
          (datacontext: Datacontext): ILicenseResource =>
            datacontext.serverLicense().get()
        ],
        configurations: [
          "datacontext",
          (
            datacontext: Datacontext
          ): angular.resource.IResourceArray<angular.resource.IResource<any>> =>
            datacontext.configurations().query()
        ],
        devices: [
          "datacontext",
          (
            datacontext: Datacontext
          ): angular.resource.IResourceArray<angular.resource.IResource<any>> =>
            datacontext.devices().query()
        ],
        ota: ["datacontext", (datacontext: Datacontext): angular.resource.IResource<angular.resource.IResource<any>> => datacontext.ota().get({ id: 1 })],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad): any =>
            $ocLazyLoad.load([
              {
                name: "xeditable",
                files: [rootUrl + "assets/plugins/angular-xeditable/css/xeditable.min.css", rootUrl + "assets/plugins/angular-xeditable/js/xeditable.min.js"]
              }
            ])
        ]
      }
    });
}
