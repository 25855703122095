import { StateService } from "@uirouter/angularjs";
import { ILogger } from "../../blocks/logger/logger";
import { Datacontext } from "../../data/datacontext";


    interface IApplicationListViewModel {
        apps: Array<any>;
        active(id: number): void;
    }

    export class ApplicationListsCtrl implements IApplicationListViewModel {
        apps: any[];

        private appService: any;
        private logger: ILogger;

        static $inject = ["datacontext", "common", "apps"];

        constructor(datacontext: Datacontext, common: any, apps: any) {
            this.apps = apps;

            this.appService = datacontext.apps();
            this.logger = common.logger;
            this.logger.log("apps :", apps);
        }

        active(id: number): void {
            this.appService.setActive({ id: id },
                null,
                (value) => {
                    this.logger.success("This Virsion has be active", value, "Active App Version Success");
                    this.apps = this.appService.query();
                });
        }
    }

    interface IApplicationSaveViewModel {
        app: any;
        save(): void;
    }

    export class ApplicationSaveCtrl implements IApplicationSaveViewModel {
        app: any;

        private logger: ILogger;
        private appService: any;
        private isEdit: boolean;

        static $inject = ["datacontext", "common", "$state", "$stateParams", "$filter"];

        constructor(datacontext: Datacontext, common, private $state: StateService,
            $stateParams: any, private $filter) {

            this.logger = common.logger;
            this.appService = datacontext.apps();
            this.isEdit = $stateParams.id !== undefined;

            if (this.isEdit) {
                this.app = this.appService.get({ id: $stateParams.id });
            } else {
                this.app = {
                    version: "",
                    packageAddress: "",
                    md5: "",
                    functionalModule: 1,
                    operatingSystem: 0,
                    active: false
                }
            }
        }

        save(): void {
            if (this.isEdit) {
                this.appService.update({ id: this.app.id },
                    this.app,
                    (value, responseHeaders) => {
                        this.logger.success(this.$filter("translate")("CAMERA.TOASTR.MESSAGE.UPDATE.SUCCESS"),
                            value,
                            this.$filter("translate")("CAMERA.TOASTR.TITLE.UPDATE.SUCCESS"));
                        this.$state.go("developer.applications.list");
                    },
                    httpResponse => {
                        this.logger.log(httpResponse);
                    });
            } else {
                this.appService.save(null,
                    this.app,
                    (value, responseHeaders) => {
                        this.isEdit = true;
                        this.logger.success(this.$filter("translate")("CAMERA.TOASTR.MESSAGE.CREATE.SUCCESS"),
                            value,
                            this.$filter("translate")("CAMERA.TOASTR.TITLE.CREATE.SUCCESS"));
                        this.$state.go("developer.applications.list");
                    },
                    httpResponse => {
                        this.logger.log(httpResponse);
                    });
            }
        }
    }


