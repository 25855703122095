import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IAgency } from "../models/models";

interface IMartPack2ViewModel {
  selectAgency: IAgency;
  monthKey: string;

  loading: boolean;

  getMartPack2();
}

export class MartPack2Ctrl implements IMartPack2ViewModel {
  agencyService: any;
  agencies: any[];
  devicesByFaceCount: any[];
  devicesBySmileScore: any[];
  devicesByStaySec: any[];
  devicesByButtonCount: any[];
  devicesByButtonCountRate: any[];
  chaptersByFaceCount: any[];
  chaptersBySmileScore: any[];
  authorized: boolean;

  selectAgency: IAgency;
  monthKey: string;

  loading: boolean;

  static $inject = [
    "Authenticator",
    "agencyService",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    authenticator,
    agencyService,
    public $http,
    public apiUrl,
    public environment: number
  ) {
    this.loading = false;
    this.monthKey = moment()
      .subtract(8, "days")
      .format("YYYYMM");
    this.authorized = authenticator.authentication.role.includes("SystemAdmin");
    if (this.authorized) {
      agencyService.query().$promise.then(value => {
        this.agencies = value;
        if (this.agencies.length > 0) {
          this.selectAgency = this.agencies[0];
        }
      });
    }
    //devices.$promise.then(value => {
    //    this.devices = value.filter(device => device.functionalModule & Models.FunctionalModule.FaceDetection);
    //});
  }

  //function declare
  getMartPack2() {
    this.loading = true;

    this.$http
      .post(this.apiUrl + "api/Analysis/MartPack2", {
        monthKey: this.monthKey,
        system: this.environment,
        agencyId: this.selectAgency == null ? 0 : this.selectAgency.id
        //agencyId : 6 //用 Neo 測試
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          this.devicesByFaceCount = obj.rankByFaceCount;
          this.devicesBySmileScore = obj.rankBySmileScore;
          this.devicesByStaySec = obj.rankByStaySec;
          this.devicesByButtonCount = obj.rankByButtonCount;
          this.devicesByButtonCountRate = obj.rankByButtonCountRate;
          this.chaptersByFaceCount = obj.rankChapterByFaceCount;
          this.chaptersBySmileScore = obj.rankChapterBySmileScore;
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }
}
