import * as CryptoJS from "crypto-js";

autoLoginController.$inject = ["$stateParams", "$location", "Authenticator", "ngAuthSettings"];

export function autoLoginController($stateParams, $location, authenticator, ngAuthSettings) {

    if (!$stateParams.u || !$stateParams.p)
        $location.path("/");
    
   var pw = decryptByDESModeCBC($stateParams.u, $stateParams.p);

    var loginData = {
        userName: aesEncrypt($stateParams.u),
        password: aesEncrypt(pw),
        useRefreshTokens: true
    };
    
    authenticator.login(loginData)
        .then(function (response) {
            $location.path("/");
        },
        function (reason) {
            console.log("Login fail", reason);
            $location.path("/");
        });

    function aesEncrypt(encryptData) {
        var secretPassphrase = CryptoJS.enc.Utf8.parse(ngAuthSettings.password);
        var salt = CryptoJS.lib.WordArray.random(128 / 8);
        var key128Bits500Iterations = CryptoJS.PBKDF2(secretPassphrase, salt, { keySize: 128 / 32, iterations: 500 });
        var iv = CryptoJS.lib.WordArray.random(128 / 8);
        var options = { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };

        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(encryptData), key128Bits500Iterations, options);

        var binaryData = CryptoJS.enc.Hex.stringify(salt);
        binaryData += ("," + CryptoJS.enc.Hex.stringify(iv));
        binaryData += ("," + encrypted);

        return binaryData;
    }

    //CBC�Ҧ��ѱK
    function decryptByDESModeCBC(userName, password) {
        var key = desKey();
        var iv = userName;
        var keyHex = CryptoJS.enc.Utf8.parse(key);
        var ivHex = CryptoJS.enc.Utf8.parse(iv);
        var decrypted = CryptoJS.DES.decrypt({
            ciphertext: CryptoJS.enc.Hex.parse(password)
        }, keyHex, {
            iv: ivHex,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    function desKey() {
        var dateTime = new Date();

        var key = dateTime.getUTCFullYear().toString().substr(2, 2);
        key = key + dateTime.getUTCMonth().toString();
        key = key + dateTime.getUTCDate().toString();
        key = key + dateTime.getUTCHours().toString();

        while (key.length < 8) key = "0" + key;
        return key;
    }
}

loginController.$inject = ["$scope", "$location", "Authenticator", "ngAuthSettings", "region"];

export function loginController($scope, $location, authenticator, ngAuthSettings, region) {

    $scope.enableRecaptcha = region !== "CN";

    $scope.loginData = {
        userName: "",
        password: "",
        useRefreshTokens: true,
        recaptchaResponse: ""
    };

    $scope.message = "";

    $scope.login = function () {

        var loginData = {
            userName: aesEncrypt($scope.loginData.userName),
            password: aesEncrypt($scope.loginData.password),
            useRefreshTokens: $scope.loginData.useRefreshTokens
        };

        console.log(loginData);

        authenticator.login(loginData)
            .then(function (response) {

                var isVerifyOtp = authenticator.isVerifyOtp();
                if (isVerifyOtp) $location.path("/");
                else $location.path("/send_code");
            },
                function (reason) {
                    if (reason.data.error_description)
                        $scope.message = reason.data.error_description;
                    else $scope.message = reason.data;

                    console.log("Login fail", reason);
                });
    };

    // $scope.authExternalProvider = function (provider) {

    //   var redirectUri = location.protocol + "//" + location.host + "/authcomplete.html";

    //   var externalProviderUrl = "/" +
    //     "api/Account/ExternalLogin?provider=" +
    //     provider +
    //     "&response_type=token" +
    //     "&redirect_uri=" +
    //     redirectUri;
    //   window.$windowScope = $scope;

    //   var oauthWindow = window.open(externalProviderUrl,
    //     "Authenticate Account",
    //     "location=0,status=0,width=600,height=750");
    // };

    $scope.authCompletedCB = function (fragment) {

        $scope.$apply(function () {

            if (fragment.haslocalaccount === false) {

                authenticator.logOut();

                authenticator.externalAuthData = {
                    provider: fragment.provider,
                    userName: fragment.external_user_name,
                    externalAccessToken: fragment.external_access_token
                };

                $location.path("/associate");

            } else {
                //Obtain access token and redirect to orders
                var externalData = {
                    provider: fragment.provider,
                    externalAccessToken: fragment.external_access_token
                };
                authenticator.obtainAccessToken(externalData)
                    .then(function (response) {

                        $location.path("/orders");

                    },
                        function (err) {
                            $scope.message = err.error_description;
                        });
            }

        });
    };

    function aesEncrypt(encryptData) {
        var secretPassphrase = CryptoJS.enc.Utf8.parse(ngAuthSettings.password);
        var salt = CryptoJS.lib.WordArray.random(128 / 8);
        var key128Bits500Iterations = CryptoJS.PBKDF2(secretPassphrase, salt, { keySize: 128 / 32, iterations: 500 });
        var iv = CryptoJS.lib.WordArray.random(128 / 8);
        var options = { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };

        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(encryptData), key128Bits500Iterations, options);

        var binaryData = CryptoJS.enc.Hex.stringify(salt);
        binaryData += ("," + CryptoJS.enc.Hex.stringify(iv));
        binaryData += ("," + encrypted);

        return binaryData;
    }
}

signUpController.$inject = ["$scope", "$location", "$timeout", "Authenticator"];

export function signUpController($scope, $location, $timeout, authenticator) {

    $scope.savedSuccessfully = false;
    $scope.message = "";

    $scope.registration = {
        userName: "",
        email: "",
        password: "",
        confirmPassword: ""
    };

    $scope.signUp = function () {

        authenticator.saveRegistration($scope.registration)
            .then(function (response) {

                $scope.savedSuccessfully = true;
                $scope
                    .message =
                    "User has been registered successfully, you will be redicted to login page in 2 seconds.";
                startTimer();

            },
                function (response) {
                    var errors = [];
                    for (var key in response.data.modelState) {
                        if (response.data.modelState.hasOwnProperty(key)) {
                            for (var i = 0; i < response.data.modelState[key].length; i++) {
                                errors.push(response.data.modelState[key][i]);
                            }
                        }
                    }
                    $scope.message = "Failed to register user due to:" + errors.join(" ");
                });
    };

    function startTimer() {
        var timer = $timeout(function () {
            $timeout.cancel(timer);
            $location.path("/login");
        },
            2000);
    }
}

forgotPasswordController.$inject = ["$scope", "Authenticator"];

export function forgotPasswordController($scope, authenticator) {

    $scope.sendSuccessfully = false;
    $scope.message = "";

    $scope.forgotPassword = {
        email: ""
    };

    $scope.send = function () {

        authenticator.sendForgotPassword($scope.forgotPassword)
            .then(function (response) {

                $scope.sendSuccessfully = true;
                $scope.message = "Send forgot password successfully, check your email to reset password.";
            },
                function (response) {
                    var errors = [];
                    for (var key in response.data.modelState) {
                        if (response.data.modelState.hasOwnProperty(key)) {
                            for (var i = 0; i < response.data.modelState[key].length; i++) {
                                errors.push(response.data.modelState[key][i]);
                            }
                        }
                    }
                    $scope.message = "Failed to send forgot password due to:" + errors.join(" ");
                });
    };

}

resetPasswordController.$inject = ["$scope", "$location", "$timeout", "Authenticator", "userId", "code"];

export function resetPasswordController($scope, $location, $timeout, authenticator, userId, code) {
    $scope.sendSuccessfully = false;
    $scope.message = "";

    $scope.resetPassword = {
        userId: userId,
        code: code,
        newPassword: "",
        confirmPassword: ""
    };

    $scope.reset = function () {

        authenticator.sendResetPassword($scope.resetPassword)
            .then(function (response) {

                $scope.sendSuccessfully = true;
                $scope
                    .message =
                    "User has been registered successfully, you will be redicted to login page in 2 seconds.";
                startTimer();

            },
                function (response) {
                    var errors = [];
                    for (var key in response.data.modelState) {
                        if (response.data.modelState.hasOwnProperty(key)) {
                            for (var i = 0; i < response.data.modelState[key].length; i++) {
                                errors.push(response.data.modelState[key][i]);
                            }
                        }
                    }
                    $scope.message = "Failed to reset password due to:" + errors.join(" ");
                });
    };

    function startTimer() {
        var timer = $timeout(function () {
            $timeout.cancel(timer);
            $location.path("/login");
        },
            2000);
    }
}

sendCodeController.$inject = ["$scope", "$state", "Authenticator"];

export function sendCodeController($scope, $state, authenticator) {
    $scope.message = "";
    authenticator.getValidTwoFactorProviders()
        .then(function (response) {
            $scope.validTwoFactorProviders = response.data;
        });

    $scope.sendCode = function (provider) {
        authenticator.sendCode(provider)
            .then(function () {
                $state.go("verifyOTP", { "provider": provider });
            });
    };
}

verifyOTPController.$inject = ["$scope", "$state", "$stateParams", "$location", "Authenticator", "localStorageService"];

export function verifyOTPController($scope, $state, $stateParams, $location, authenticator, localStorageService) {
    $scope.message = "";
    var provider = $stateParams.provider;

    $scope.verify = function (code) {
        authenticator.verifyOTPCode(provider, code)
            .then(function () {
                localStorageService.set("verifyOTP", true);
                $location.path("/");
            },
                function (reason) {
                    if (reason.data.error_description)
                        $scope.message = reason.data.error_description;
                    else $scope.message = reason.data;

                    console.log("verify OTP Code fail", reason);
                });
    };
}
