import { Datacontext } from "../../data/datacontext";
import { IIdentityService } from "./identity.factory";
import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("admin.users", {
      abstract: true,
      url: "/users",
      template:  require("../../views/common/sub_content.html"),
      data: { pageTitle: "Users", requiresLogin: true }
    })
    .state("admin.users.list", {
      url: "/lists",
      template: require("./views/user/lists.html"),
      controller: "UserList",
      controllerAs: "vm",
      resolve: { users: ["IdentityService", (identityService: IIdentityService) => identityService.users().query()] }
    })
    .state("admin.users.edit", {
      url: "/edit/{id:string}",
      template: require("./views/user/edit.html"),
      controller: "UserEdit",
      controllerAs: "vm",
      resolve: {
        user: ["IdentityService", "$stateParams", (identityService: IIdentityService, $stateParams) => identityService.users().get({ id: $stateParams.id })],
        roles: ["IdentityService", (identityService: IIdentityService) => identityService.roles().query()],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        timeZones: ["datacontext", (datacontext: Datacontext) => datacontext.configurations().getSystemTimeZones()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              name: "checklist-model",
              files: [rootUrl + "assets/plugins/checklist-model/checklist-model.js"]
            },
            {
              name: "moment-picker",
              files: [
                rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
              ]
            }
          ])
        ]
      }
    })
    .state("admin.users.create", {
      url: "/create",
      template: require("./views/user/create.html"),
      controller: "UserCreate",
      controllerAs: "vm"
    })
    .state("admin.roles", {
      abstract: true,
      url: "/roles",
      template:  require("../../views/common/sub_content.html"),
      data: { pageTitle: "Roles", requiresLogin: true }
    })
    .state("admin.roles.list", {
      url: "/lists",
      template: require("./views/role/lists.html"),
      controller: "RoleList",
      controllerAs: "vm",
      resolve: {
        roles: ["IdentityService", (identityService: IIdentityService) => identityService.roles().query()]
      }
    });
}
