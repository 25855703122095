declare var $: any;

import { Datacontext } from "../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl, $stateProvider): void {
  $stateProvider
    .state("analysis", {
      abstract: true,
      url: "/analysis",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Analysis", requiresLogin: true }
    })
    .state("analysis.martPack1", {
      url: "/martPack1",
      template:  require("./views/martPack1.html"),
      controller: "martPack1Ctrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/highcharts.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.martPack2", {
      url: "/martPack2",
      template:  require("./views/martPack2.html"),
      controller: "martPack2Ctrl",
      controllerAs: "vm",
      resolve: {
        agencyService: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/highcharts.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.martRaw1", {
      url: "/martRaw1",
      template:  require("./views/martRaw1.html"),
      controller: "martRaw1Ctrl",
      controllerAs: "vm",
      resolve: {
        agencyService: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.martRaw2", {
      url: "/martRaw2",
      template:  require("./views/martRaw2.html"),
      controller: "martRaw2Ctrl",
      controllerAs: "vm",
      resolve: {
        agencyService: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.martRaw3", {
      url: "/martRaw3",
      template:  require("./views/martRaw3.html"),
      controller: "martRaw3Ctrl",
      controllerAs: "vm",
      resolve: {
        agencyService: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.martRaw4", {
      url: "/martRaw4",
      template:  require("./views/martRaw4.html"),
      controller: "martRaw4Ctrl",
      controllerAs: "vm",
      resolve: {
        agencyService: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.siteDNA", {
      url: "/siteDNA",
      template:  require("./views/siteDNA.html"),
      controller: "siteDNACtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/highcharts.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.siteDNAByAge", {
      url: "/siteDNAByAge",
      template:  require("./views/siteDNAByAge.html"),
      controller: "siteDNAByAgeCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/highcharts.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.peopleCount", {
      url: "/peopleCount",
      template:  require("./views/peopleCount.html"),
      controller: "peopleCountCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.peopleCountAll", {
      url: "/peopleCountAll",
      template:  require("./views/peopleCountAll.html"),
      controller: "peopleCountAllCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.peopleAttract", {
      url: "/peopleAttract",
      template:  require("./views/peopleAttract.html"),
      controller: "peopleAttractCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.peopleAttractAll", {
      url: "/peopleAttractAll",
      template:  require("./views/peopleAttractAll.html"),
      controller: "peopleAttractAllCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.cameraEvents", {
      url: "/cameraEvents",
      template:  require("./views/cameraEvents.html"),
      controller: "cameraEventsCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.playLogs", {
      url: "/playLogs",
      template:  require("./views/playLogs.html"),
      controller: "playLogsCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.playLogsAll", {
      url: "/playLogsAll",
      template:  require("./views/playLogsAll.html"),
      controller: "playLogsAllCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.qrCodeLogs", {
      url: "/qrCodeLogs",
      template:  require("./views/qrCodeLogs.html"),
      controller: "qrCodeLogsCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    })
    .state("analysis.buttonLogs", {
      url: "/buttonLogs",
      template:  require("./views/buttonLogs.html"),
      controller: "buttonLogsCtrl",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css"
                ]
              },
              {
                name: "highcharts",
                serie: true,
                files: [
                  "https://code.highcharts.com/stock/highstock.js",
                  "https://code.highcharts.com/modules/data.js"
                ]
              }
            ])
        ]
      }
    });
}

export function exportToCsv() {
  return {
    restrict: "A",
    link: function(scope, element, attrs) {
      var el = element[0];
      element.bind("click", function(e) {
        //var table = e.target.nextElementSibling;
        var table: HTMLTableElement = <HTMLTableElement>(
          $("#" + attrs["tableElement"])[0]
        );
        var csvString = "";
        for (var i = 0; i < table.rows.length; i++) {
          var rowData = table.rows[i].cells;
          for (var j = 0; j < rowData.length; j++) {
            csvString = csvString + rowData[j].innerHTML + ",";
          }
          csvString = csvString.substring(0, csvString.length - 1);
          csvString = csvString + "\n";
        }
        csvString = csvString.substring(0, csvString.length - 1);
        var a = $("<a/>", {
          style: "display:none",
          //href: 'data:application/octet-stream;base64,' + btoa(csvString),
          href:
            "data:text/txt;charset:utf-8,\ufeff" +
            encodeURIComponent(csvString),
          download: "pcloud.csv"
        }).appendTo("body");
        a[0].click();
        a.remove();
      });
    }
  };
}
