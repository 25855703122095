import * as R from "ramda";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";

interface IEmergencyAlertViewModel {
  boards: any[];
  devices: Array<any>;
  boardId: number;
  emergencyLogs: any[];

  canAlert: boolean;

  boardChange(): void;
  alert(): void;
  disable(id: number): void;
}

export class EmergencyAlertCtrl implements IEmergencyAlertViewModel {
  boards: any[];
  devices: any[];
  boardId: number;
  emergencyLogs: any[];

  canAlert: boolean;

  private emergencyLogsService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common", "boards", "devices"];

  constructor(
    datacontext: Datacontext,
    common: any,
    boards: any,
    devices: angular.resource.IResourceArray<angular.resource.IResource<any>>
  ) {
    this.boardId = -1;
    this.boards = boards;
    this.devices = devices;

    this.canAlert = false;

    this.emergencyLogsService = datacontext.emergencyLogs();
    this.logger = common.logger;
  }

  boardChange(): void {
    if (this.boardId && this.boardId !== -1) {
      this.emergencyLogsService
        .getEmergencyLogsBy({ boardId: this.boardId })
        .$promise.then(value => {
          this.emergencyLogs = value;
          const found = R.find(R.propEq("active", true))(value);
          this.canAlert = found ? false : true;
        });
    } else {
      this.emergencyLogs = [];
    }
  }

  alert(): void {
    if (this.boardId !== -1) {
      const isSelected = device =>
        device.boardId === this.boardId && device.selected;
      const selectedDevices = R.project(
        ["id", "name", "messageQueue"],
        R.filter(isSelected, this.devices)
      );

      const emergencyAlert = {
        boardId: this.boardId,
        emergencyDevices: selectedDevices
      };

      this.emergencyLogsService.save(
        null,
        emergencyAlert,
        (value, responseHeaders) => {
          this.logger.log("value", value);

          this.emergencyLogs = this.emergencyLogsService.getEmergencyLogsBy({
            boardId: this.boardId
          });
          this.canAlert = false;

          this.logger.warning("緊急插播已經發佈", value, "請注意!");
        }
      );

      this.logger.log("emergencyAlert", emergencyAlert);
    }
  }

  disable(id: number): void {
    this.emergencyLogsService.disable(
      { id: id },
      null,
      () => {
        this.emergencyLogsService
          .getEmergencyLogsBy({ boardId: this.boardId })
          .$promise.then(value => {
            this.emergencyLogs = value;
            const found = R.find(R.propEq("active", true))(value);
            this.canAlert = found ? false : true;
          });

        this.logger.success("已經解除緊急管制");
      },
      httpResponse => {
        this.logger.error(
          `無法解除緊急管制 ${id}`,
          httpResponse,
          "Disable Error"
        );
      }
    );
  }
}
