

    ngTranslateLanguageSelect.$inject = ["LocaleService"];

    export function ngTranslateLanguageSelect(localeService) {
        // Usage:
        //     <div ng-translate-language-select></div>
        // Creates:
        //
        var directive = {
            restrict: "A",
            replace: true,
            template: "" +
                '<li class="dropdown" ng-if="visible">' +
                '<a class="dropdown-toggle count-info" data-toggle="dropdown"><i class="fa fa-language" aria-hidden="true"></i> {{ "NAVBAR.LANGUAGE" | translate }}</a>' +
                '<ul class="dropdown-menu animated fadeInRight m-t-xs">' +
                '<li ng-repeat="localesDisplayName in localesDisplayNames"><a ng-click="changeLanguage(localesDisplayName)">{{localesDisplayName}}</a></li>' +
                "</ul>" +
                "</li>" +
                "",
            controller: controller
        };

        controller.$inject = ["$scope"];
        return directive;

        /* @ngInject */
        function controller($scope) {
            $scope.currentLocaleDisplayName = localeService.getLocaleDisplayName();
            $scope.localesDisplayNames = localeService.getLocalesDisplayNames();
            $scope.visible = $scope.localesDisplayNames && $scope.localesDisplayNames.length > 1;

            $scope.changeLanguage = function(locale) {
                localeService.setLocaleByDisplayName(locale);
            };
        }
    }
