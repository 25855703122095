import * as angular from "angular";
import { routeConfig } from "./config.route";

import NaturalBeautyProduct from "./product/product.module";

export default angular
  .module("app.smartshelves.naturalbeauty", [
    NaturalBeautyProduct.name
  ])
  .config(routeConfig);
