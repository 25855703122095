import * as angular from "angular";
import { routeConfig } from "./config.route";
import { SeriesListsCtrl, SeriesEditContentCtrl, SeriesEditCtrl } from "./series";

export default angular
  .module("app.smartshelves.series", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("seriesList", {
    template: require("./views/lists.html"),
    controller: SeriesListsCtrl,
    bindings: {
      series: "<",
      authentication: "<"
    }
  })
  .component("seriesEditContent", {
    template: require("./views/edit-content.html"),
    controller: SeriesEditContentCtrl,
    bindings: {
      mainseries:"<",
      series: "=",
      culture: "<",
      ismainculture: "<",
      authentication: "<"
    }
  })
  .component("seriesEdit", {
    template: require("./views/edit.html"),
    controller: SeriesEditCtrl,
    bindings: {
      series: "<",
      agencies: "<",
      authentication: "<"
    }
  });
