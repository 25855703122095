import * as angular from "angular";
import { routeConfig } from "./config.route";
import { PlaylogList } from "./playlog";
import { PlaylogTrend } from "./logchart";

export default angular
  .module("app.logs.playlog", [])
  .config(routeConfig)
  .controller("PlaylogList", PlaylogList)
  .controller("PlaylogTrend", PlaylogTrend);
