import { IStatusCounts } from "./dashboard.model";
import { IHeartbeatStatusService } from "./dashboard.factory";

  interface IDeviceHeartbeatDashboardModel {
    devices: Array<any>;
    counts: any;
    level2s: Array<any>;
  }

  export class DeviceHeartbeatDashboardCtrl implements IDeviceHeartbeatDashboardModel {
    devices: Array<any>;
    counts: IStatusCounts;
    level2s: Array<any>;

    static $inject = ["heartbeatStatusService"];

    constructor(heartbeatStatusService: IHeartbeatStatusService) {
      this.devices = heartbeatStatusService.devices;
      this.counts = heartbeatStatusService.counts;
      this.level2s = heartbeatStatusService.level2s;
    }
  }


