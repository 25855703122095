import { StateService } from "@uirouter/angularjs";
import Swal from "sweetalert2";
import * as moment from "moment-timezone";

import { ILogger } from "../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";
import { CultureService } from "../../services/culture.service";

interface ITreasurehuntListViewModel {
  treasurehunts: Array<any>;

  dtOptions: any;
  dtColumnDefs: any;

  del(id: number): void;
}

export class TreasurehuntListsCtrl implements ITreasurehuntListViewModel, angular.IComponentController {
  treasurehunts: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly treasurehuntService: any;
  private readonly logger: ILogger;

  static $inject = [ "smartShelvesDatacontext", "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder" ];
  constructor(datacontext: SmartShelvesDatacontext, common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder) {
    this.treasurehuntService = datacontext.treasurehunts();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "TreasureHuntsFile" },
        { extend: "pdf", title: "TreasureHuntsFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  $onInit() {
    this.logger.log(this.treasurehunts);
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.treasurehuntService.delete(
          { id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(
              this.treasurehunts,
              "id",
              id
            );
            this.treasurehunts.splice(indx, 1);
            Swal.fire(
              "Deleted!",
              "Your Treasurehunt has been deleted.",
              "success"
            );
          },
          httpResponse => {
            this.logger.error(
              `Treasurehunt can't delete, treasurehunt ID is ${id}`,
              httpResponse,
              "Delete Treasurehunt Error"
            );
            Swal.fire("Cancelled", "Treasurehunt can't delete", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Treasurehunt file is safe :)", "success");
      }
    });
  }
}

interface ITreasurehuntEditViewModel {
  treasurehunt: any;
  products: any;

  uploader: any;

  save(): void;
}

export class TreasurehuntEditContentCtrl implements angular.IComponentController {
  treasurehunt: any;
  product: any;
  ismainculture: boolean;
  culture: string;
  maintreasurehunt: any;
  authentication: any;
  private agencies: any;
  private role: any;
  uploader: any;

  static $inject = ["apiUrl", "FileUploader", "localStorageService", "datacontext", "Authenticator", "$sce"];
  constructor(private apiUrl, private FileUploader, private localStorageService, private datacontext: Datacontext, private authenticator: any, private $sce) {
    this.role = authenticator.authentication.role;
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = datacontext.agencies().query();
    }
    this.uploaderSetting();
  }

  uploaderSetting(): void {
    // File Upload 相關
    this.uploader = new this.FileUploader({
      url: `${this.apiUrl}api/TreasureHunts/UploadTreasureHuntPicture`, // webapi url
      headers: { Authorization: `Bearer ${this.localStorageService.get("jwt")}` }
    });
    //this.uploader.queueLimit = 1;
    this.uploader.removeAfterUpload = true;

    // FILTERS
    this.uploader.filters.push({
      name: "imageFilter",
      fn: (item /*{File|FileLikeObject}*/, options) => {
        var type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
      }
    });

    // CALLBACKS

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      console.info("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      console.info("onAfterAddingFile", fileItem);
    };
    this.uploader.onAfterAddingAll = addedFileItems => {
      if (this.uploader.getNotUploadedItems().length > 1) {
        this.uploader.removeFromQueue(0);
      }
      console.info("onAfterAddingAll", addedFileItems);
    };
    this.uploader.onBeforeUploadItem = item => {
      var formData = [
        {
          id: this.treasurehunt.id,
          treasurehunt: JSON.stringify(this.treasurehunt)
        }
      ];

      Array.prototype.push.apply(item.formData, formData);

      console.info("onBeforeUploadItem", item);
    };
    this.uploader.onProgressItem = (fileItem, progress) => {
      console.info("onProgressItem", fileItem, progress);
    };
    this.uploader.onProgressAll = progress => {
      console.info("onProgressAll", progress);
    };
    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (status == 200) {
        this.treasurehunt.pictureUrl = response.mediaUrl;
      }
      //this.isEdit = this.treasurehunt.id !== 0;

      console.info("onSuccessItem", fileItem, response, status, headers);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      console.info("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCancelItem = (fileItem, response, status, headers) => {
      console.info("onCancelItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteItem = (fileItem, response, status, headers) => {
      console.info("onCompleteItem", fileItem, response, status, headers);
      //this.$state.go("smartshelves.treasurehunts.list");
    };
    this.uploader.onCompleteAll = () => {
      console.info("onCompleteAll");
      //this.$state.go("smartshelves.treasurehunts.list");
    };

    console.info("uploader", this.uploader);
  }


}

export class TreasurehuntEditCtrl implements ITreasurehuntEditViewModel, angular.IComponentController {
  isEdit: boolean;

  treasurehunt: any;
  products: any;

  uploader: any;

  mainCulture: { [key: string]: string };
  otherCultures: { [key: string]: string };

  private logger: ILogger;
  private treasurehuntService: any;

  static $inject = ["CultureService", "smartShelvesDatacontext", "common", "$stateParams", "$state", "apiUrl", "FileUploader", "localStorageService" ];
  constructor(private cultureService: CultureService, datacontext: SmartShelvesDatacontext, private common: any, private $stateParams, private $state: StateService, private apiUrl, private FileUploader, private localStorageService) {
    this.logger = common.logger;
    this.treasurehuntService = datacontext.treasurehunts();
  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;

    if (this.isEdit) {
      this.treasurehunt.$promise.then(result => {
        result.putOnShelvesDateTime = moment
          .utc(result.putOnShelvesDateTime)
          .local();
        result.pullOffShelvesDateTime = moment
          .utc(result.pullOffShelvesDateTime)
          .local();
        this.treasurehunt = result;
        this.logger.log(this.treasurehunt);
        this.initCulture();
      });
    } else {
      this.treasurehunt = {
        id: 0,
        title: null,
        huntingProductId: null,
        prompt: null,
        pictureUrl: null,
        putOnShelvesDateTime: moment.utc().local(),
        pullOffShelvesDateTime: moment.utc().local(),
        treasureHuntTranslations: []
      };
      this.initCulture();
    }
  }


  initCulture() {
    this.mainCulture = this.cultureService.mainCulture;
    this.otherCultures = this.cultureService.otherCultures;
  }

  // 檢查是否有語系資料
  checkHasTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    if (this.treasurehunt.treasureHuntTranslations.length == 0) {
      this.pushNewTranslation(culture);
    }
    else {
      var found = this.getTranslation(culture);
      if (!found) {
        this.pushNewTranslation(culture);
      }
    }
  }

  // 新增語系資料
  pushNewTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    let newTranslation = {
      id: 0,
      title: null,
      prompt: null,
      pictureUrl: null,
      culture: culture,
      treasurehuntId: this.treasurehunt.id
    };
    this.treasurehunt.treasureHuntTranslations.push(newTranslation);
  }

  // 取得已存在的語系資料
  getTranslation(culture: string): any {
    culture = culture.replace(/_/g, '-');
    return this.cultureService.getTranslation(culture, this.treasurehunt.treasureHuntTranslations);
  }

  save(): void {
    this.treasurehunt.putOnShelvesDateTime = moment(this.treasurehunt.putOnShelvesDateTime, "MM/DD/YYYY hh:mm:ss A").utc();
    this.treasurehunt.pullOffShelvesDateTime = moment(this.treasurehunt.pullOffShelvesDateTime, "MM/DD/YYYY hh:mm:ss A").utc();

    if (this.isEdit) {
      this.treasurehuntService.update({ id: this.treasurehunt.id }, this.treasurehunt,
        () => { this.$state.go("smartshelves.treasurehunts.list"); },
        httpResponse => { this.logger.log(httpResponse); }
      );
    } else {
      this.treasurehuntService.save(null, this.treasurehunt,
        () => { this.$state.go("smartshelves.treasurehunts.list"); },
        httpResponse => { this.logger.log(httpResponse); }
      );
    }
  }
}
