import { StateService } from "@uirouter/angularjs";
import Swal from "sweetalert2";

import { ILogger } from "../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../data/datacontext";

interface ISurveyresultdefinitionListViewModel {
  surveyresultdefinitions: Array<any>;

  dtOptions: any;
  dtColumnDefs: any;

  del(id: number): void;
}

export class SurveyresultdefinitionListsCtrl
  implements ISurveyresultdefinitionListViewModel, angular.IController {
  surveyresultdefinitions: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly surveyresultdefinitionService: any;
  private readonly logger: ILogger;

  static $inject = [
    "smartShelvesDatacontext",
    "common",
    "$filter",
    "DTOptionsBuilder",
    "DTColumnDefBuilder"
  ];

  $onInit() {
    this.logger.log(this.surveyresultdefinitions);
  }

  constructor(
    datacontext: SmartShelvesDatacontext,
    common: any,
    private readonly $filter,

    DTOptionsBuilder,
    DTColumnDefBuilder
  ) {
    this.surveyresultdefinitionService = datacontext.surveyResultDefinitions();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "SurveyresultdefinitionsFile" },
        { extend: "pdf", title: "SurveyresultdefinitionsFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.surveyresultdefinitionService.delete(
          { id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(
              this.surveyresultdefinitions,
              "id",
              id
            );
            this.surveyresultdefinitions.splice(indx, 1);
            Swal.fire(
              "Deleted!",
              "Your Surveyresultdefinition has been deleted.",
              "success"
            );
          },
          httpResponse => {
            this.logger.error(
              `Surveyresultdefinition can't delete, surveyresultdefinition ID is ${id}`,
              httpResponse,
              "Delete Surveyresultdefinition Error"
            );
            Swal.fire(
              "Cancelled",
              "Surveyresultdefinition can't delete",
              "error"
            );
          }
        );
      } else {
        Swal.fire(
          "Cancelled!",
          "Your Surveyresultdefinition file is safe :)",
          "success"
        );
      }
    });
  }
}

interface ISurveyresultdefinitionEditViewModel {
  surveyresultdefinition: any;
  surveys: any;
  products: any;
  skintypes: any;

  skinTypeRecommendProducts: any;
  iconBg: string[];
  uploaders: any[];

  save(): void;
  updateRecommendProduct(parentIndex, index, data): void;
}

export class SurveyresultdefinitionEditCtrl implements ISurveyresultdefinitionEditViewModel, angular.IController {
  isEdit: boolean;

  surveyresultdefinition: any;
  surveys: any;
  products: any;
  skintypes: any;

  skinTypeRecommendProducts: any;
  iconBg: string[];
  uploaders: any[];

  private logger: ILogger;
  private surveyresultdefinitionService: any;
  private recommendProductService: any;

  static $inject = ["smartShelvesDatacontext", "common", "$stateParams", "$state", "apiUrl", "FileUploader", "localStorageService", "$filter"];
  constructor(datacontext: SmartShelvesDatacontext, common: any, private $stateParams, private $state: StateService, private apiUrl, private FileUploader, private localStorageService, private $filter) {
    this.logger = common.logger;
    this.surveyresultdefinitionService = datacontext.surveyResultDefinitions();
    this.recommendProductService = datacontext.recommendProducts();

    this.iconBg = ["blue-bg", "navy-bg", "lazur-bg", "yellow-bg"];
  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;

    if (this.isEdit) {
      this.surveyresultdefinition.$promise.then(value => {
        this.surveyresultdefinition = value;
        this.skinTypeRecommendProducts = this.surveyresultdefinition.skinTypeRecommendProducts;

        this.logger.log(this.skinTypeRecommendProducts);
      });
    } else {
      this.surveyresultdefinition = {
        id: 0,
        name: null,
        skinTypeRecommendProducts: [],
        surveyId: 0
      };
      this.skinTypeRecommendProducts = [];
    }

    this.uploaderSetting();
  }

  uploaderSetting(): void {
    this.skintypes.$promise.then(skintypes => {
      this.skintypes = skintypes;
      this.uploaders = new Array(skintypes.length * 3);

      for (let i = 0; i < this.uploaders.length; i++) {
        // File Upload 相關
        this.uploaders[i] = new this.FileUploader({
          url: `${this.apiUrl}api/RecommendProducts/UploadProductPicture`, // webapi url
          headers: {
            Authorization: `Bearer ${this.localStorageService.get("jwt")}`
          },
          alias: `file${i}`
        });
        let uploader = this.uploaders[i];
        //this.uploader.queueLimit = 1;
        uploader.removeAfterUpload = true;

        // FILTERS
        uploader.filters.push({
          name: "imageFilter",
          fn: (item /*{File|FileLikeObject}*/, options) => {
            var type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
            return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
          }
        });

        // CALLBACKS

        uploader.onWhenAddingFileFailed = (item, filter, options) => {
          console.info("onWhenAddingFileFailed", item, filter, options);
        };
        uploader.onAfterAddingFile = fileItem => {
          console.info("onAfterAddingFile", fileItem);
        };
        uploader.onAfterAddingAll = addedFileItems => {
          if (uploader.getNotUploadedItems().length > 1) {
            uploader.removeFromQueue(0);
          }
          console.info("onAfterAddingAll", addedFileItems);
        };
        uploader.onBeforeUploadItem = item => {
          const number = +uploader.alias.replace("file", "");

          const skinTypeRecommendProductIndex = Math.floor(number / 3);
          const recommendProductIndex = number % 3;

          const skinTypeRecommendProduct = this.skinTypeRecommendProducts[
            skinTypeRecommendProductIndex
          ];
          const recommendProduct =
            skinTypeRecommendProduct.recommendProducts[recommendProductIndex];

          this.logger.log("number", number);
          this.logger.log("skinTypeRecommendProduct", skinTypeRecommendProduct);
          this.logger.log("recommendProduct", recommendProduct);

          const formData = [
            {
              id: recommendProduct.id
            }
          ];

          Array.prototype.push.apply(item.formData, formData);

          console.info("onBeforeUploadItem", item);
        };
        uploader.onProgressItem = (fileItem, progress) => {
          console.info("onProgressItem", fileItem, progress);
        };
        uploader.onProgressAll = progress => {
          console.info("onProgressAll", progress);
		};

		uploader.onSuccessItem = (fileItem, response, status, headers) => {			
          const number = +uploader.alias.replace("file", "");
          const skinTypeRecommendProductIndex = Math.floor(number / 3);
	      const recommendProductIndex = number % 3;

		  const dateTime = Date.now();
		  const timestamp = Math.floor(dateTime / 1000).toString();
		  response.productPictureUrl = response.productPictureUrl + "?timestamp=" + timestamp;

          this.skinTypeRecommendProducts[
            skinTypeRecommendProductIndex
          ].recommendProducts[recommendProductIndex] = response;

          console.info("onSuccessItem", fileItem, response, status, headers);
        };
        uploader.onErrorItem = (fileItem, response, status, headers) => {
          console.info("onErrorItem", fileItem, response, status, headers);
        };
        uploader.onCancelItem = (fileItem, response, status, headers) => {
          console.info("onCancelItem", fileItem, response, status, headers);
        };
        uploader.onCompleteItem = (fileItem, response, status, headers) => {
          console.info("onCompleteItem", fileItem, response, status, headers);
        };
        uploader.onCompleteAll = () => {
          console.info("onCompleteAll");
        };
      }

      console.info("uploaders", this.uploaders);
    });
  }

  save(): void {
    if (this.isEdit) {
      this.surveyresultdefinitionService.update(
        { id: this.surveyresultdefinition.id }, this.surveyresultdefinition,
        () => { this.$state.go("smartshelves.surveyresultdefinitions.list"); },
        httpResponse => { this.logger.log(httpResponse); }
      );
    } else {
      this.surveyresultdefinitionService.save(null, this.surveyresultdefinition,
        () => { this.$state.go("smartshelves.surveyresultdefinitions.list"); },
        httpResponse => { this.logger.log(httpResponse); }
      );
    }
  }

  updateRecommendProduct(parentIndex, index, data): void {
    let recommendProduct = this.skinTypeRecommendProducts[parentIndex].recommendProducts[index];
    recommendProduct.productId = data;

    this.recommendProductService.update({ id: recommendProduct.id }, recommendProduct,
      value => { this.skinTypeRecommendProducts[parentIndex].recommendProducts[index] = value; },
      httpResponse => { this.logger.log(httpResponse); }
    );
  }
}
