import * as angular from "angular";

export default angular.module("app.template", []).run([
  "$templateCache",
  function($templateCache: angular.ITemplateCacheService) {
    $templateCache.put("app/views/common/footer.html", require("./common/footer.html"));
    $templateCache.put("app/views/common/navigation.html", require("./common/navigation.html"));
    $templateCache.put("app/views/common/notify.html", require("./common/notify.html"));
    $templateCache.put("app/views/common/topnavbar.html", require("./common/topnavbar.html"));
  }
]);
