import * as angular from "angular";
import Geocoder from "../services/geocoder-service";
import { routeConfig } from "./config.route";
import { BoardListsCtrl, BoardViewCtrl, BoardCreateEditCtrl } from "./board";

export default angular.module("app.board", [
  // Angular modules

  // Custom modules

  // 3rd Party Modules
  Geocoder.name
])
  .config(routeConfig)
  .component("boardList", {
    template: require('./views/lists.html'),
    controller: BoardListsCtrl,
    bindings: { authentication: "<" }
  })
  .controller("BoardView", BoardViewCtrl)
  .controller("BoardCreateEdit", BoardCreateEditCtrl);
