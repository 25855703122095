

import { authenticator } from "./authorization/authenticator";
import { StateProvider, StateService, UrlRouterProvider, Transition } from "@uirouter/angularjs";

interface IAppRootScope extends angular.IRootScopeService {
  $state: StateService;
  $on(statechangestart: string, listener: (event: any, toState: any, toParams: any, fromState: any, fromParams: any, options: any) => void);
}

export class RouteConfig {
  static $inject = ["rootUrl", "$stateProvider", "$urlRouterProvider"];

  constructor(rootUrl: string, $stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider) {
    $stateProvider
      .state("accessdenied", {
        url: "/accessdenied",
        template: require("./views/accessdenied.html"),
        data: { pageTitle: "Access Denied", specialClass: "gray-bg" }
      })
      .state("integration", {
        url: "/integration/senao/ec-integration",
        template: require("./views/ec-integration.html"),
        data: { pageTitle: "Integration", specialClass: "gray-bg", requiresLogin: true, roles: ["Sales"] }
      })
      .state("opening", {
        url: "/opening",
        template: '<opening></opening>',
        data: { pageTitle: "Opening", specialClass: "gray-bg", requiresLogin: true },
        resolve: {
          loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              name: "text-mask",
              files: [rootUrl + "assets/plugins/angular1-text-mask/angular1TextMask.js"]
            }
          ])
        ]
        }
      });

    $urlRouterProvider.otherwise("/dashboards/dashboard_1");
  }
}

export class IdleConfig {
  static $inject = ["IdleProvider", "KeepaliveProvider"];

  constructor(idleProvider, keepaliveProvider) {
    // configure Idle settings
    idleProvider.idle(5); // in seconds
    idleProvider.timeout(120); // in seconds
    keepaliveProvider.interval(2); // in seconds
  }
}

// 1.6 之後會出現錯誤
export class HandledRejectionsConfig {
  static $inject = ["$qProvider"];

  constructor($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
  }
}

export class DebugConfig {
  static $inject = ["$compileProvider", "$ocLazyLoadProvider", "debugMode"];

  constructor(
    $compileProvider: ng.ICompileProvider,
    $ocLazyLoadProvider: oc.ILazyLoadProvider,
    debugMode: boolean
  ) {
    // disables AngularJS debug info
    $compileProvider.debugInfoEnabled(debugMode);

    // set to true if you want to see what and when is dynamically loaded
    $ocLazyLoadProvider.config({ debug: debugMode });
  }
}

export function appRun($rootScope: IAppRootScope, $state: StateService, authenticator, $transitions: Transition): void {
  //authManager.redirectWhenUnauthenticated();

  // 會影響背影顏色
  $rootScope.$state = $state;

  authenticator.fillAuthData();

  //authManager.checkAuthOnRefresh();

  //$rootScope.$on("tokenHasExpired:", () => {
  //    alert("Your session has expired!");
  //});

  // 確定此葉面是否需要登入，如果是不需要登入的就不做任何事情
  const requiresLogin = { to: state => state.data != null && state.data.requiresLogin === true };

  $transitions.onStart(requiresLogin, trans => {
    //const authManager = trans.injector().get("authManager");
    // 先取得登入的 token 是否都沒問題
    const isAuthenticated: boolean = authenticator.isAuthenticated();
    const isVerifyOtp: boolean = authenticator.isVerifyOtp();

    // 沒有權限，改到登入畫面
    if (!isAuthenticated) return trans.router.stateService.target("login");
    // 兩階段驗證尚未驗證，改到驗證畫面
    if (!isVerifyOtp) return trans.router.stateService.target("send_code");
    //if (!isVerifyOtp) console.log("isVerifyOtp", isVerifyOtp);

    // 判斷權限是不是對的
    const roles: Array<string> = trans.$to().data.roles;
    if (roles && roles.length > 0 && !authenticator.isInAnyRole(roles)) {
      // user is signed in but not
      // authorized for desired state
      return trans.router.stateService.target("accessdenied");
    }
    return true;
  });
}

appRun.$inject = ["$rootScope", "$state", "Authenticator", "$transitions"];
