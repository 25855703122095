import { StateProvider } from "@uirouter/angularjs";
import { Datacontext, IBoardResource } from "../data/datacontext";

  routeConfig.$inject = ["rootUrl", "$stateProvider"];

  export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
    $stateProvider
      .state("boards",
        {
          abstract: true,
          url: "/boards",
          template:  require("../views/common/content.html"),
          data: { pageTitle: "Boards", requiresLogin: true }
        })
      .state("boards.list",
        {
          url: "/lists",
          template: '<board-list authentication="authentication"></board-list>',
          resolve: {
            loadPlugin: [
              "$ocLazyLoad", $ocLazyLoad => $ocLazyLoad.load([
                {
                  files: [
                    rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                    rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                  ]
                }
              ])
            ]
          }
        })
      .state("boards.view",
        {
          url: "/{id:int}",
          template:  require("./views/view.html"),
          controller: "BoardView",
          controllerAs: "vm",
          resolve: {
            board: [
              "datacontext", "$stateParams", (datacontext: Datacontext, $stateParams): IBoardResource => datacontext.boards().get({ id: $stateParams.id })
            ]
          }
        })
      .state("boards.edit",
        {
          url: "/{id:int}/edit",
          template:  require("./views/create_edit.html"),
          controller: "BoardCreateEdit",
          controllerAs: "vm",
          resolve: {
            agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()]
          }
        })
      .state("boards.create",
        {
          url: "/create",
          template:  require("./views/create_edit.html"),
          controller: "BoardCreateEdit",
          controllerAs: "vm",
          resolve: {
            agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()]
          }
        });
  }
