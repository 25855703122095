import { StateProvider } from "@uirouter/angularjs";
import { Datacontext, ICustomerResource } from "../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("customers", {
      abstract: true,
      url: "/customers",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Customers", requiresLogin: true }
    })
    .state("customers.list", {
      url: "/lists",
      template:  require("./views/lists.html"),
      controller: "CustomerList",
      controllerAs: "vm",
      resolve: {
        customers: [
          "datacontext",
          (
            datacontext: Datacontext
          ): angular.resource.IResourceArray<ICustomerResource> =>
            datacontext.customers().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("customers.view", {
      url: "/{id:int}",
      template:  require("./views/view.html"),
      controller: "CustomerView",
      controllerAs: "vm",
      resolve: {
        customer: [
          "datacontext",
          "$stateParams",
          (datacontext: Datacontext, $stateParams): ICustomerResource =>
            datacontext.customers().get({ id: $stateParams.id })
        ]
      }
    })
    .state("customers.edit", {
      url: "/{id:int}/edit",
      template:  require("./views/create_edit.html"),
      controller: "CustomerSave",
      controllerAs: "vm",
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ]
      }
    })
    .state("customers.create", {
      url: "/create",
      template: require("./views/create_edit.html"),
      controller: "CustomerSave",
      controllerAs: "vm",
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ]
      }
    });
}
