import { Datacontext } from "../../data/datacontext";
import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("admin.layouts", {
      abstract: true,
      url: "/layouts",
      template:  require("../../views/common/sub_content.html"),
      data: { pageTitle: "Layouts", requiresLogin: true }
    })
    .state("admin.layouts.list", {
      url: "/lists",
      template:  require("./views/lists.html"),
      controller: "LayoutList",
      controllerAs: "vm",
      resolve: {
        layouts: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.layouts().query()
        ]
      }
    })
    .state("admin.layouts.view", {
      url: "/{id:int}",
      template:  require("./views/view.html"),
      controller: "LayoutView",
      controllerAs: "vm",
      resolve: {
        layout: [
          "datacontext",
          "$stateParams",
          (datacontext: Datacontext, $stateParams) =>
            datacontext.layouts().get({ id: $stateParams.id })
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "gridster",
                files: [
                  rootUrl +
                    "assets/plugins/javascript-detect-element-resize/jquery.resize.js",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.css",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("admin.layouts.edit", {
      url: "/{id:int}/edit",
      template:  require("./views/edit.html"),
      controller: "LayoutCreateEdit",
      controllerAs: "vm",
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "gridster",
                files: [
                  rootUrl +
                    "assets/plugins/javascript-detect-element-resize/jquery.resize.js",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.css",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("admin.layouts.create", {
      url: "/create/{orientation:int}",
      template:  require("./views/edit.html"),
      controller: "LayoutCreateEdit",
      controllerAs: "vm",
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          $ocLazyLoad =>
            $ocLazyLoad.load([
              {
                name: "gridster",
                files: [
                  rootUrl +
                    "assets/plugins/javascript-detect-element-resize/jquery.resize.js",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.css",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.js"
                ]
              }
            ])
        ]
      }
    });
}
