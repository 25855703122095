import { StateProvider } from "@uirouter/angularjs";
routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("bulletins", {
      abstract: true,
      url: "/bulletins",
      template: require("../views/common/content.html"),
      data: { pageTitle: "佈告欄", requiresLogin: true }
    })
    .state("bulletins.configurationList", {
      url: "/configurationList",
      template: require('./views/configuration/list.html'),
      controller: "BulletinConfigurationListController",
      controllerAs: "vm"
    })
    .state("bulletins.configurationCreate", {
      url: "/configurationCreate",
      template: require('./views/configuration/create_edit.html'),
      controller: "BulletinConfigurationCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("bulletins.configurationEdit", {
      url: "/configurationEdit/{id:int}",
      template: require('./views/configuration/create_edit.html'),
      controller: "BulletinConfigurationCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("bulletins.bulletin1List", {
      url: "/bulletin1",
      template: require('./views/bulletin/list.html'),
      controller: "Bulletin1ListController",
      controllerAs: "vm"
    })
    .state("bulletins.bulletin1Create", {
      url: "/bulletin1Create",
      template: require('./views/bulletin/create_edit.html'),
      controller: "Bulletin1CreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("bulletins.bulletin1Edit", {
      url: "/bulletin1Edit/{id:int}",
      template: require('./views/bulletin/create_edit.html'),
      controller: "Bulletin1CreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("bulletins.bulletin2List", {
      url: "/bulletin2",
      template: require('./views/bulletin/list.html'),
      controller: "Bulletin2ListController",
      controllerAs: "vm"
    })
    .state("bulletins.bulletin2Create", {
      url: "/bulletin2Create",
      template: require('./views/bulletin/create_edit.html'),
      controller: "Bulletin2CreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("bulletins.bulletin2Edit", {
      url: "/bulletin2Edit/{id:int}",
      template: require('./views/bulletin/create_edit.html'),
      controller: "Bulletin2CreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("bulletins.bulletin3List", {
      url: "/bulletin3",
      template: require('./views/bulletin/list.html'),
      controller: "Bulletin3ListController",
      controllerAs: "vm"
    })
    .state("bulletins.bulletin3Create", {
      url: "/bulletin3Create",
      template: require('./views/bulletin/create_edit.html'),
      controller: "Bulletin3CreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("bulletins.bulletin3Edit", {
      url: "/bulletin3Edit/{id:int}",
      template: require('./views/bulletin/create_edit.html'),
      controller: "Bulletin3CreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    });
}
