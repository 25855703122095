import * as angular from "angular";
import Swal from "sweetalert2";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { ICustomer, IDevice, IAgency } from "../models/models";
import { forEach } from "ramda";

export class BulletinConfigurationListCtrl {
	private activities: Array<any>;
	private activityService: any;
	private logger: ILogger;

	static $inject = ["datacontext", "common", "$filter", "$state"];
	constructor(
		datacontext: Datacontext,
		common: any,
		private $filter,
		private $state,
		private swal
	) {
		this.activities = datacontext.storeConfigurations().query();
		this.activityService = datacontext.storeConfigurations();
		this.logger = common.logger;
		this.logger.log("activities : ", this.activities);
	}

	deleteActivity(id: number, isEdit: boolean): void {
		this.activityService.deleteConfiguration(
			{ id: id, isEdit: isEdit },
			(value, responseHeaders) => {
				Swal.fire("Deleted!", "Your activity has been deleted.", "success");
				this.$state.reload();
			},
			httpResponse => {
				this.logger.error(
					`Activity can't delete, device ID is ${id}`,
					httpResponse,
					"Delete Activity Error"
				);
			}
		);
	}
}

export class BulletinConfigurationCreateCtrl {
	private customers: Array<ICustomer>;
	private devices: Array<IDevice>;
	private agencies: Array<IAgency>;
	private logger: ILogger;

	private customerService;
	private agencyService;
	private activityService;

	private selectAllValue: string;

	activity: any;
	imageCategory: string;
	uploader: any;
	isEdit: boolean;
	isSaving: boolean;
	imagesRelease: any;
	imagesUpdate: any;
	titleIndex: string[];
	valueIndex: number[];
	running: boolean;
	private role: any;

	static $inject = [
		"Authenticator",
		"datacontext",
		"common",
		"$stateParams",
		"$state",
		"apiUrl",
		"FileUploader",
		"$filter",
		"localStorageService",
		"$window",
		"$rootScope"
	];
	constructor(
		private authenticator: any,
		private datacontext: Datacontext,
		common: any,
		private $stateParams,
		private $state,
		private apiUrl,
		private FileUploader,
		private $filter,
		private localStorageService,
		private $window,
		private $rootScope
	) {
		this.logger = common.logger;
		this.role = authenticator.authentication.role;
		this.customerService = datacontext.customers();
		this.agencyService = datacontext.agencies();
		this.activityService = datacontext.storeConfigurations();

		// 初始化
		this.running = false;
		this.titleIndex = [
			"背景圖(Background)1080*600px",
			"按鈕(最新消息)210*226px",
			"按鈕(榮譽會員)210*226px",
			"按鈕(產品介紹)210*226px"
		];
		this.valueIndex = [0, 1, 3, 2];

		this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
		this.selectAllValue = "Select All";
		this.activity = {
			id: 0,
			name: "",
			subject: "",
			description: "",
			customerId: 0,
			agencyId: 0,
			isNormalAction: true,
			devices: [],
			storeMultimedia: []
		};
		this.isSaving = false;
		this.imageCategory = "";
		this.imagesRelease = [];
		this.imagesUpdate = [];

		if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
			this.agencies = this.agencyService.query();
		}

		this.customers = this.customerService.query();

		// Devices
		this.activityService.getDeviceList((value, responseHeaders) => {
			this.devices = value;
			if (this.isEdit) {
				this.getEditValue(value);
			}
		});

		// File Upload 相關
		this.uploader = new FileUploader({
			url: apiUrl + "api/StoreConfiguration",
			headers: {
				Authorization: "Bearer " + localStorageService.get("jwt")
			},
			autoUpload: true,
			removeAfterUpload: true
		});

		// FILTERS
		this.uploader.filters.push({
			name: "mediaFilter",
			fn: (item, options) => {
				const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
				const mediaType = item.name
					.slice(item.name.lastIndexOf(".") + 1)
					.toLowerCase();
				if (mediaType == "mp4") {
					return true;
				} else {
					return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
				}
			}
		});

		// CALLBACKS
		this.uploader.onWhenAddingFileFailed = (
			item /*{File|FileLikeObject}*/,
			filter,
			options
		) => {
			this.logger.log("onWhenAddingFileFailed", item, filter, options);
		};
		this.uploader.onAfterAddingFile = fileItem => {
			this.logger.log("onAfterAddingFile", fileItem.file.name);
		};

		this.uploader.onAfterAddingAll = addedFileItems => {
			this.logger.log("onAfterAddingAll", addedFileItems);
		};

		this.uploader.onBeforeUploadItem = item => {
			this.running = true;
			this.isSaving = true;
			var formData = [
				{
					activityId: this.activity.id,
					imageCategory: this.imageCategory
				}
			];
			Array.prototype.push.apply(item.formData, formData);
		};

		this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
			if (response != null) {
				this.activity.id = response.id;
				this.activity.storeMultimedia = response.storeMultimedia;
				this.imagesRelease = [];
				this.imagesUpdate = [];
				if (this.isEdit) {
					for (var i = 0; i < response.storeMultimedia.length; i++) {
						if (response.storeMultimedia[i].itemStatus == 1) {
							this.imagesRelease.push(response.storeMultimedia[i]);
						} else {
							this.imagesUpdate.push(response.storeMultimedia[i]);
						}
					}
				} else {
					this.imagesRelease = response.storeMultimedia;
				}
			}
			this.logger.log("onSuccessItem", response);
		};

		this.uploader.onErrorItem = (fileItem, response, status, headers) => {
			this.logger.log("onErrorItem", fileItem, response, status, headers);
		};

		this.uploader.onCompleteAll = () => {
			this.running = false;
			this.isSaving = false;
			this.logger.log("onCompleteAll");
		};
	}

	getEditValue(devices: any): void {
		this.activityService.get(
			{ id: this.$stateParams.id },
			(value, responseHeaders) => {
				var activity = value;
				for (var i = 0; i < activity.storeMultimedia.length; i++) {
					if (activity.storeMultimedia[i].itemStatus == 1) {
						this.imagesRelease.push(activity.storeMultimedia[i]);
					} else {
						this.imagesUpdate.push(activity.storeMultimedia[i]);
					}
				}

				// 設定已經勾選的 Device
				var devicesSelected = activity.devices;
				for (var i = 0; i < devicesSelected.length; i++) {
					const found = this.$filter("getById")(devices, devicesSelected[i].id);
					if (found !== undefined && found !== null) {
						found.selected = true;
					}
				}
				this.activity = activity;

				// 重新計算 Select All
				if (devicesSelected.length == devices.length) {
					this.selectAllValue = "Unselect All";
				}
				this.logger.log(this.activity);
			}
		);
	}

	uploaderChange(imageCategory: string) {
		this.imageCategory = imageCategory;
	}

	submitCheck(): void {
		this.save();
	}

	save(): void {
		this.running = true;
		this.isSaving = true;
		this.activityService.createConfiguration(
			null,
			this.activity,
			(value, responseHeaders) => {
				this.running = false;
				this.logger.success("Activity has Create!", value, "Good job!");
				this.$state.go("bulletins.configurationList");
			},
			httpResponse => {
				this.running = false;
				this.logger.log(httpResponse);
				this.logger.error(
					`Activity can't Create`,
					httpResponse,
					"Create Activity Error"
				);
			}
		);
	}

	cancel(): void {
		this.isSaving = true;
		var id = this.activity.id;
		if (id == 0) {
			this.$window.history.back();
		} else {
			this.activityService.deleteConfiguration(
				{ id: id, isEdit: this.isEdit },
				(value, responseHeaders) => {
					this.$window.history.back();
				},
				httpResponse => {
					this.logger.error(
						`Activity can't delete, device ID is ${id}`,
						httpResponse,
						"Delete Activity Error"
					);
				}
			);
		}
	}
	deviceChange(device: any): void {
		var devices = this.activity.devices;
		if (device.selected) {
			const addDevice = this.$filter("getById")(devices, device.id);
			if (addDevice == null) {
				devices.push(device);
			}
		} else {
			const delDevice = this.$filter("getById")(devices, device.id);
			if (delDevice !== null) {
				const indx = devices.indexOf(delDevice);
				devices.splice(indx, 1);
			}
		}
		this.activity.devices = devices;
	}
	devicesSelectAll(): void {
		var selected = false;
		if (this.selectAllValue == "Select All") {
			selected = true;
			this.selectAllValue = "Unselect All";
		} else {
			selected = false;
			this.selectAllValue = "Select All";
		}
		for (var i = 0; i < this.devices.length; i++) {
			this.devices[i].selected = selected;
			this.deviceChange(this.devices[i]);
		}
	}
	deleteImage(id: number): void {
		var image = this.$filter("getById")(this.activity.storeMultimedia, id);
		this.activityService.deleteImage(
			{ id: id },
			null,
			(value, responseHeaders) => {
				this.imagesRelease = [];
				this.imagesUpdate = [];
				for (var i = 0; i < value.storeMultimedia.length; i++) {
					if (value.storeMultimedia[i].itemStatus === 1) {
						this.imagesRelease.push(value.storeMultimedia[i]);
					} else {
						this.imagesUpdate.push(value.storeMultimedia[i]);
					}
				}
				this.logger.success("Action is successful.", value, "Deleted!");
			},
			httpResponse => {
				this.logger.error("Action failed.", httpResponse, "Deleted!");
			}
		);
	}
}

export class Bulletin1ListCtrl {
	private title: string;
	public activities: Array<any>;
	private activityService: any;
	private logger: ILogger;
	private running: boolean;

	private searchText: string;
	private role: string;

	static $inject = [
		"Authenticator",
		"datacontext",
		"common",
		"$state"
	];
	constructor(
		private authenticator: any,
		datacontext: Datacontext,
		common: any,
		private $state
	) {
		this.title = "最新消息";
		this.running = true;
		this.role = authenticator.authentication.role;
		this.activityService = datacontext.storePopularProducts();
		this.activityService.query(
			{},
			(value, responseHeaders) => {
				this.activities = value;
				this.running = false;
				this.logger.log("activities : ", this.activities);
			},
			httpResponse => {
				this.running = false;
				this.logger.error("Error", httpResponse, "Get Activity Error");
			}
		);

		this.logger = common.logger;
	}

	deleteActivity(id: number, isEdit: boolean): void {
		Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this activity!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, cancel!"
		}).then(isConfirm => {
			if (isConfirm.value) {
				this.activityService.deletePopularProduct(
					{ id: id },
					(value, responseHeaders) => {
						Swal.fire("Deleted!", "Your activity has been deleted.", "success");
						this.$state.reload();
					},
					httpResponse => {
						this.logger.error(
							`Activity can't delete, device ID is ${id}`,
							httpResponse,
							"Delete Activity Error"
						);
					}
				);
			}
		});
	}
}

export class Bulletin1CreateCtrl {
	private devices: Array<IDevice>;
	private agencies: Array<IAgency>;
	private logger: ILogger;

	private agencyService;
	private activityService;
	private selectAllValue: string;
	private title: string = "最新消息";

	activity: any;
	uploader: any;
	isEdit: boolean;
	running: boolean;
	imageNo: number;
	private imageArray: { [key: number]: object };

	private role: any;

	static $inject = [
		"Authenticator",
		"datacontext",
		"common",
		"$stateParams",
		"$state",
		"apiUrl",
		"FileUploader",
		"$filter",
		"$window",
		"localStorageService"
	];
	constructor(
		private authenticator: any,
		private datacontext: Datacontext,
		common: any,
		private $stateParams,
		private $state,
		private apiUrl,
		private FileUploader,
		private $filter,

		private $window,
		private localStorageService
	) {
		this.logger = common.logger;
		this.role = authenticator.authentication.role;
		this.agencyService = datacontext.agencies();
		this.activityService = datacontext.storePopularProducts();

		// 初始化
		this.running = false;
		this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單

		this.activity = {
			id: 0,
			name: "",
			description: "",
			agencyId: null,
			devices: [],
			storeMultimedia: []
		};

		this.selectAllValue = "Select All";
		if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
			this.agencies = this.agencyService.query();
		}

		// Devices
		this.activityService.getDeviceList((value, responseHeaders) => {
			this.devices = value;
			if (this.isEdit) {
				this.getEditValue(value);
			}
		});

		// File Upload 相關
		this.uploader = new FileUploader({
			url: apiUrl + "api/StorePopularProduct", // webapi url
			headers: {
				Authorization: "Bearer " + localStorageService.get("jwt")
			},
			autoUpload: true,
			removeAfterUpload: true
		});

		// FILTERS
		this.uploader.filters.push({
			name: "mediaFilter",
			fn: function (item /*{File|FileLikeObject}*/, options) {
				const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
				const mediaType = item.name
					.slice(item.name.lastIndexOf(".") + 1)
					.toLowerCase();
				let fileSize = Math.ceil(item.size / 1024 / 1024);
				if (mediaType === "mp4") {
					return true;
				} else if (fileSize > 2) {
					Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
					return false;
				} else {
					return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
				}
			}
		});

		// CALLBACKS
		this.uploader.onBeforeUploadItem = item => {
			this.running = true;
			const formData = [
				{
					activityId: this.activity.id,
					imageNo: this.imageNo,
					reward: this.activity.storeMultimedia[this.imageNo].reward
				}
			];
			Array.prototype.push.apply(item.formData, formData);
			this.logger.log("onBeforeUploadItem", item);
		};

		this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
			if (response !== null) {
				this.activity.id = response.id;
				this.activity.storeMultimedia = response.storeMultimedia.sort((a, b) => a.no - b.no);
			}
			this.logger.log("onSuccessItem", response);
		};
		this.uploader.onErrorItem = (fileItem, response, status, headers) => {
			this.logger.log("onErrorItem", fileItem, response, status, headers);
		};

		this.uploader.onCompleteAll = () => {
			this.running = false;
			this.logger.log("onCompleteAll");
		};
	}

	getEditValue(devices: any): void {
		this.activityService.get(
			{ id: this.$stateParams.id },
			(value, responseHeaders) => {
				this.logger.log(value);
				this.activity = value;

				// 檔案排序
				this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);

				// 設定已經勾選的 Device
				const devicesSelected = this.activity.devices;
				for (let i = 0; i < devicesSelected.length; i++) {
					const found = this.$filter("getById")(devices, devicesSelected[i].id);
					if (found !== undefined && found !== null) {
						found.selected = true;
					}
				}
				this.activity.devices = devicesSelected;


				// 重新計算 Select All
				if (devicesSelected.length === devices.length) {
					this.selectAllValue = "Unselect All";
				}

				this.logger.log(this.activity);
			}
		);
	}

	uploaderChange(imageNo: number) {
		this.imageNo = imageNo;
	}

	submitCheck(): void {
		// 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
		this.running = true;
		const errMsg = [];

		if (this.activity.storeMultimedia.length === 0) {
			errMsg.push("。內容 檔案上傳");
		}
		for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
			if (this.activity.storeMultimedia[i].id === 0 || this.activity.storeMultimedia[i].fileUrl === "") {
				errMsg.push("。內容 " + (i + 1).toString() + " 檔案上傳");
			}
		}

		if (errMsg.length > 0) {
			this.running = false;
			Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
		} else {
			this.docSubmit();
		}
	}

	// Submit function
	docSubmit(): void {
		this.activityService.bulletin(
			null,
			this.activity,
			(value, responseHeaders) => {
				this.logger.success("Activity has Create!", value, "Good job!");
				this.$state.go("bulletins.bulletin1List");
			},
			httpResponse => {
				this.running = false;
				this.logger.log(httpResponse);
				this.logger.error(
					`Activity can't Submit`,
					httpResponse,
					"Submit Activity Error"
				);
			}
		);
	}

	cancel(): void {
		this.$window.history.back();
	}

	deviceChange(device: any): void {
		let devices = this.activity.devices;
		if (device.selected) {
			const addDevice = this.$filter("getById")(devices, device.id);
			if (addDevice === null) {
				devices.push(device);
			}
		} else {
			const delDevice = this.$filter("getById")(devices, device.id);
			if (delDevice !== null) {
				const indx = devices.indexOf(delDevice);
				devices.splice(indx, 1);
			}
		}
		this.activity.devices = devices;
	}

	devicesSelectAll(): void {
		let selected = false;
		if (this.selectAllValue === "Select All") {
			selected = true;
			this.selectAllValue = "Unselect All";
		} else {
			selected = false;
			this.selectAllValue = "Select All";
		}
		for (let i = 0; i < this.devices.length; i++) {
			this.devices[i].selected = selected;
			this.deviceChange(this.devices[i]);
		}
	}

	deleteImage(id: number): void {
		if (id === 0) {
			for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
				if (this.activity.storeMultimedia[i].id === 0) {
					this.activity.storeMultimedia.splice(i, 1);;
					break;
				}
			};
			this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);
			this.updateImages();
		} else {
			this.activityService.deleteImage(
				{ id: id },
				null,
				(value, responseHeaders) => {
					console.log(value);
					this.activity.storeMultimedia = value.storeMultimedia.sort((a, b) => a.no - b.no);
					this.updateImages();
					//this.logger.success("Action is successful.", value, "Deleted!");
				},
				httpResponse => {
					this.logger.error("Action failed.", httpResponse, "Deleted!");
				}
			);
		}
	}

	updateImages(): void {
		this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);
		for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
			this.activity.storeMultimedia[i].no = i;
		};
		this.activityService.bulletinUpdateImages(
			null,
			this.activity.storeMultimedia,
			(value, responseHeaders) => {
				console.log("Images data has update!");
				console.log(value);
				console.log(responseHeaders);
			},
			httpResponse => {
				this.running = false;
				this.logger.log(httpResponse);
				this.logger.error(
					`Images can't update.`,
					httpResponse,
					"Submit Images Error."
				);
			}
		);
	}

	addImage(index: number): void {
		let image = {
			id: 0,
			no: index,
			dispalyName: "",
			fileUrl: "",
			Reward: ""
		};
		this.activity.storeMultimedia.push(image);
		this.updateImages();
	}
}

export class Bulletin2ListCtrl {
	private title: string;
	public activities: Array<any>;
	private activityService: any;
	private logger: ILogger;
	private running: boolean;

	private searchText: string;
	private role: string;

	static $inject = [
		"Authenticator",
		"datacontext",
		"common",
		"$state"
	];
	constructor(
		private authenticator: any,
		datacontext: Datacontext,
		common: any,
		private $state
	) {
		this.title = "榮譽會員";
		this.running = true;
		this.role = authenticator.authentication.role;
		this.activityService = datacontext.storeStudentArea();
		this.activityService.query(
			{},
			(value, responseHeaders) => {
				this.activities = value;
				this.running = false;
				this.logger.log("activities : ", this.activities);
			},
			httpResponse => {
				this.running = false;
				this.logger.error("Error", httpResponse, "Get Activity Error");
			}
		);

		this.logger = common.logger;
	}

	deleteActivity(id: number): void {
		Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this activity!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, cancel!"
		}).then(isConfirm => {
			if (isConfirm.value) {
				this.activityService.deleteActivity(
					{ id: id, isEdit: false },
					(value, responseHeaders) => {
						Swal.fire("Deleted!", "Your activity has been deleted.", "success");
						this.$state.reload();
					},
					httpResponse => {
						this.logger.error(
							`Activity can't delete, device ID is ${id}`,
							httpResponse,
							"Delete Activity Error"
						);
					}
				);
			}
		});
	}
}

export class Bulletin2CreateCtrl {
	private devices: Array<IDevice>;
	private agencies: Array<IAgency>;
	private logger: ILogger;

	private agencyService;
	private activityService;
	private selectAllValue: string;
	private title: string = "榮譽會員";

	activity: any;
	uploader: any;
	isEdit: boolean;
	running: boolean;
	imageNo: number;
	private imageNoIndex: number;

	private role: any;

	static $inject = [
		"Authenticator",
		"datacontext",
		"common",
		"$stateParams",
		"$state",
		"apiUrl",
		"FileUploader",
		"$filter",
		"$window",
		"localStorageService"
	];
	constructor(
		private authenticator: any,
		private datacontext: Datacontext,
		common: any,
		private $stateParams,
		private $state,
		private apiUrl,
		private FileUploader,
		private $filter,

		private $window,
		private localStorageService
	) {
		this.logger = common.logger;
		this.role = authenticator.authentication.role;
		this.agencyService = datacontext.agencies();
		this.activityService = datacontext.storeStudentArea();

		// 初始化
		this.running = false;
		this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
		this.imageNoIndex = 0;

		this.activity = {
			id: 0,
			name: "",
			description: "",
			agencyId: null,
			devices: [],
			storeMultimedia: []
		};

		this.selectAllValue = "Select All";
		if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
			this.agencies = this.agencyService.query();
		}

		// Devices
		this.activityService.getDeviceList((value, responseHeaders) => {
			this.devices = value;
			if (this.isEdit) {
				this.getEditValue(value);
			//} else {
			//	this.addImage(0);
			}
		});

		// File Upload 相關
		this.uploader = new FileUploader({
			url: apiUrl + "api/StoreGame1", // webapi url
			headers: {
				Authorization: "Bearer " + localStorageService.get("jwt")
			},
			autoUpload: true,
			removeAfterUpload: true
		});

		// FILTERS
		this.uploader.filters.push({
			name: "mediaFilter",
			fn: function (item /*{File|FileLikeObject}*/, options) {
				const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
				const mediaType = item.name
					.slice(item.name.lastIndexOf(".") + 1)
					.toLowerCase();
				const fileSize = Math.ceil(item.size / 1024 / 1024);
				if (mediaType === "mp4") {
					return true;
				} else if (fileSize > 2) {
					Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
					return false;
				} else {
					return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
				}
			}
		});

		// CALLBACKS
		this.uploader.onBeforeUploadItem = item => {
			this.running = true;
			const formData = [
				{
					activityId: this.activity.id,
					imageNo: this.imageNo,
					reward: this.activity.storeMultimedia[this.imageNo].reward
				}
			];
			Array.prototype.push.apply(item.formData, formData);
			this.logger.log("onBeforeUploadItem", item);
		};

		this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
			if (response !== null) {
				this.activity.id = response.id;
				this.activity.storeMultimedia = response.storeMultimedia.sort((a, b) => a.no - b.no);
			}
			this.logger.log("onSuccessItem", response);
		};
		this.uploader.onErrorItem = (fileItem, response, status, headers) => {
			this.logger.log("onErrorItem", fileItem, response, status, headers);
		};

		this.uploader.onCompleteAll = () => {
			this.running = false;
			this.logger.log("onCompleteAll");
		};
	}

	getEditValue(devices: any): void {
		this.activityService.get(
			{ id: this.$stateParams.id },
			(value, responseHeaders) => {
				this.logger.log(value);
				this.activity = value;

				// 檔案排序
				this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);

				// 設定已經勾選的 Device
				const devicesSelected = this.activity.devices;
				for (let i = 0; i < devicesSelected.length; i++) {
					const found = this.$filter("getById")(devices, devicesSelected[i].id);
					if (found !== undefined && found !== null) {
						found.selected = true;
					}
				}
				this.activity.devices = devicesSelected;


				// 重新計算 Select All
				if (devicesSelected.length === devices.length) {
					this.selectAllValue = "Unselect All";
				}
			}
		);
	}

	uploaderChange(imageNo: number) {
		this.imageNo = imageNo;
	}

	updateImages(): void {
		this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);
		for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
			this.activity.storeMultimedia[i].no = i;
		};
		this.activityService.bulletinUpdateImages(
			null,
			this.activity.storeMultimedia,
			(value, responseHeaders) => {
				console.log("Images data has update!");
				console.log(value);
				console.log(responseHeaders);
			},
			httpResponse => {
				this.running = false;
				this.logger.log(httpResponse);
				this.logger.error(
					`Images can't update.`,
					httpResponse,
					"Submit Images Error."
				);
			}
		);
	}

	submitCheck(): void {
		// 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
		this.running = true;
		const errMsg = [];

		if (this.activity.storeMultimedia.length === 0) {
			errMsg.push("。內容 檔案上傳");
		}
		for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
			if (this.activity.storeMultimedia[i].id === 0 || this.activity.storeMultimedia[i].fileUrl === "") {
				errMsg.push("。內容 " + (i + 1).toString() + " 檔案上傳");
			}
		}

		if (errMsg.length > 0) {
			this.running = false;
			Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
		} else {
			this.docSubmit();
		}
	}

	// Submit function
	docSubmit(): void {
		this.activityService.bulletin(
			null,
			this.activity,
			(value, responseHeaders) => {
				this.logger.success("Activity has Create!", value, "Good job!");
				this.$state.go("bulletins.bulletin2List");
			},
			httpResponse => {
				this.running = false;
				this.logger.log(httpResponse);
				this.logger.error(
					`Activity can't Submit`,
					httpResponse,
					"Submit Activity Error"
				);
			}
		);
	}

	cancel(): void {
		this.$window.history.back();
	}

	deviceChange(device: any): void {
		const devices = this.activity.devices;
		if (device.selected) {
			const addDevice = this.$filter("getById")(devices, device.id);
			if (addDevice === null) {
				devices.push(device);
			}
		} else {
			const delDevice = this.$filter("getById")(devices, device.id);
			if (delDevice !== null) {
				const indx = devices.indexOf(delDevice);
				devices.splice(indx, 1);
			}
		}
		this.activity.devices = devices;
	}

	devicesSelectAll(): void {
		let selected = false;
		if (this.selectAllValue === "Select All") {
			selected = true;
			this.selectAllValue = "Unselect All";
		} else {
			selected = false;
			this.selectAllValue = "Select All";
		}
		for (let i = 0; i < this.devices.length; i++) {
			this.devices[i].selected = selected;
			this.deviceChange(this.devices[i]);
		}
	}

	deleteImage(id: number): void {
		if (id === 0) {
			for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
				if (this.activity.storeMultimedia[i].id === 0) {
					this.activity.storeMultimedia.splice(i, 1);;
					break;
				}
			};
			this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);
			this.updateImages();
		} else {
			this.activityService.deleteImage(
				{ id: id },
				null,
				(value, responseHeaders) => {
					this.activity.storeMultimedia = value.storeMultimedia.sort((a, b) => a.no - b.no);
					this.updateImages();
					this.logger.success("Delete Image is successful.", value, "Deleted!");
				},
				httpResponse => {
					this.logger.error("Action failed.", httpResponse, "Deleted!");
				}
			);
		}
	}

	addImage(index: number): void {
		const image = {
			id: 0,
			no: index,
			dispalyName: "",
			fileUrl: "",
			Reward: ""
		};
		this.activity.storeMultimedia.push(image);
		this.updateImages();
	}
}

export class Bulletin3ListCtrl {
	private title: string;
	public activities: Array<any>;
	private activityService: any;
	private logger: ILogger;
	private running: boolean;

	private searchText: string;
	private role: string;

	static $inject = [
		"Authenticator",
		"datacontext",
		"common",
		"$state"
	];
	constructor(
		private authenticator: any,
		datacontext: Datacontext,
		common: any,
		private $state
	) {
		this.title = "產品介紹";
		this.running = true;
		this.role = authenticator.authentication.role;
		this.activityService = datacontext.storePreOrder();
		this.activityService.query(
			{},
			(value, responseHeaders) => {
				this.activities = value;
				this.running = false;
				this.logger.log("activities : ", this.activities);
			},
			httpResponse => {
				this.running = false;
				this.logger.error("Error", httpResponse, "Get Activity Error");
			}
		);

		this.logger = common.logger;
	}

	deleteActivity(id: number, isEdit: boolean): void {
		Swal.fire({
			title: "Are you sure?",
			text: "You will not be able to recover this activity!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
			cancelButtonText: "No, cancel!"
		}).then(isConfirm => {
			if (isConfirm.value) {
				this.activityService.deletePreOrder(
					{ id: id },
					(value, responseHeaders) => {
						Swal.fire("Deleted!", "Your activity has been deleted.", "success");
						this.$state.reload();
					},
					httpResponse => {
						this.logger.error(
							`Activity can't delete, device ID is ${id}`,
							httpResponse,
							"Delete Activity Error"
						);
					}
				);
			}
		});
	}
}

export class Bulletin3CreateCtrl {
	private devices: Array<IDevice>;
	private agencies: Array<IAgency>;
	private logger: ILogger;

	private agencyService;
	private activityService;
	private selectAllValue: string;
	private title: string = "產品介紹";

	activity: any;
	uploader: any;
	isEdit: boolean;
	running: boolean;
	imageNo: number;

	private role: any;

	static $inject = [
		"Authenticator",
		"datacontext",
		"common",
		"$stateParams",
		"$state",
		"apiUrl",
		"FileUploader",
		"$filter",
		"$window",
		"localStorageService"
	];
	constructor(
		private authenticator: any,
		private datacontext: Datacontext,
		common: any,
		private $stateParams,
		private $state,
		private apiUrl,
		private FileUploader,
		private $filter,

		private $window,
		private localStorageService
	) {
		this.logger = common.logger;
		this.role = authenticator.authentication.role;
		this.agencyService = datacontext.agencies();
		this.activityService = datacontext.storePreOrder();

		// 初始化
		this.running = false;
		this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單

		this.activity = {
			id: 0,
			name: "",
			description: "",
			agencyId: null,
			devices: [],
			storeMultimedia: []
		};

		this.selectAllValue = "Select All";
		if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
			this.agencies = this.agencyService.query();
		}

		// Devices
		this.activityService.getDeviceList((value, responseHeaders) => {
			this.devices = value;
			if (this.isEdit) {
				this.getEditValue(value);
			}
		});

		// File Upload 相關
		this.uploader = new FileUploader({
			url: apiUrl + "api/StorePreOrder", // webapi url
			headers: {
				Authorization: "Bearer " + localStorageService.get("jwt")
			},
			autoUpload: true,
			removeAfterUpload: true
		});

		// FILTERS
		this.uploader.filters.push({
			name: "mediaFilter",
			fn: function (item /*{File|FileLikeObject}*/, options) {
				const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
				const mediaType = item.name
					.slice(item.name.lastIndexOf(".") + 1)
					.toLowerCase();
				const fileSize = Math.ceil(item.size / 1024 / 1024);
				if (mediaType === "mp4") {
					return true;
				} else if (fileSize > 2) {
					Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
					return false;
				} else {
					return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
				}
			}
		});

		// CALLBACKS
		this.uploader.onBeforeUploadItem = item => {
			this.running = true;
			const formData = [
				{
					activityId: this.activity.id,
					imageNo: this.imageNo,
					reward: this.activity.storeMultimedia[this.imageNo].reward
				}
			];
			Array.prototype.push.apply(item.formData, formData);
			this.logger.log("onBeforeUploadItem", item);
		};

		this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
			if (response != null) {
				this.activity.id = response.id;
				this.activity.storeMultimedia = response.storeMultimedia.sort((a, b) => a.no - b.no);
			}
			this.logger.log("onSuccessItem", response);
		};
		this.uploader.onErrorItem = (fileItem, response, status, headers) => {
			this.logger.log("onErrorItem", fileItem, response, status, headers);
		};

		this.uploader.onCompleteAll = () => {
			this.running = false;
			this.logger.log("onCompleteAll");
		};
	}

	getEditValue(devices: any): void {
		this.activityService.get(
			{ id: this.$stateParams.id },
			(value, responseHeaders) => {
				this.logger.log(value);
				this.activity = value;

				// 檔案排序
				this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);

				// 設定已經勾選的 Device
				const devicesSelected = this.activity.devices;
				for (let i = 0; i < devicesSelected.length; i++) {
					const found = this.$filter("getById")(devices, devicesSelected[i].id);
					if (found !== undefined && found !== null) {
						found.selected = true;
					}
				}
				this.activity.devices = devicesSelected;


				// 重新計算 Select All
				if (devicesSelected.length == devices.length) {
					this.selectAllValue = "Unselect All";
				}

				//this.logger.log(this.activity);
			}
		);
	}

	uploaderChange(imageNo: number) {
		this.imageNo = imageNo;
	}

	submitCheck(): void {
		// 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
		this.running = true;
		const errMsg = [];

		if (this.activity.storeMultimedia.length === 0) {
			errMsg.push("。內容 檔案上傳");
		}
		for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
			if (this.activity.storeMultimedia[i].id === 0 || this.activity.storeMultimedia[i].fileUrl === "") {
				errMsg.push("。內容 " + (i + 1).toString() + " 檔案上傳");
			}
		}

		if (errMsg.length > 0) {
			this.running = false;
			Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
		} else {
			this.docSubmit();
		}
	}

	// Submit function
	docSubmit(): void {
		this.activityService.bulletin(
			null,
			this.activity,
			(value, responseHeaders) => {
				this.logger.success("Activity has Create!", value, "Good job!");
				this.$state.go("bulletins.bulletin3List");
			},
			httpResponse => {
				this.running = false;
				this.logger.log(httpResponse);
				this.logger.error(
					`Activity can't Submit`,
					httpResponse,
					"Submit Activity Error"
				);
			}
		);
	}

	cancel(): void {
		this.$window.history.back();
	}

	deviceChange(device: any): void {
		const devices = this.activity.devices;
		if (device.selected) {
			const addDevice = this.$filter("getById")(devices, device.id);
			if (addDevice === null) {
				devices.push(device);
			}
		} else {
			const delDevice = this.$filter("getById")(devices, device.id);
			if (delDevice !== null) {
				const indx = devices.indexOf(delDevice);
				devices.splice(indx, 1);
			}
		}
		this.activity.devices = devices;
	}

	devicesSelectAll(): void {
		let selected = false;
		if (this.selectAllValue === "Select All") {
			selected = true;
			this.selectAllValue = "Unselect All";
		} else {
			selected = false;
			this.selectAllValue = "Select All";
		}
		for (let i = 0; i < this.devices.length; i++) {
			this.devices[i].selected = selected;
			this.deviceChange(this.devices[i]);
		}
	}

	deleteImage(id: number): void {
		if (id === 0) {
			for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
				if (this.activity.storeMultimedia[i].id === 0) {
					this.activity.storeMultimedia.splice(i, 1);;
					break;
				}
			};
			this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);
			this.updateImages();
		} else {
			this.activityService.deleteImage(
				{ id: id },
				null,
				(value, responseHeaders) => {
					this.activity.storeMultimedia = value.storeMultimedia.sort((a, b) => a.no - b.no);
					this.updateImages();
					this.logger.success("Delete Image is successful.", value, "Deleted!");
				},
				httpResponse => {
					this.logger.error("Action failed.", httpResponse, "Deleted!");
				}
			);
		}
	}

	updateImages(): void {
		this.activity.storeMultimedia = this.activity.storeMultimedia.sort((a, b) => a.no - b.no);
		for (let i = 0; i < this.activity.storeMultimedia.length; i++) {
			this.activity.storeMultimedia[i].no = i;
		};
		this.activityService.bulletinUpdateImages(
			null,
			this.activity.storeMultimedia,
			(value, responseHeaders) => {
				console.log("Images data has update!");
				console.log(value);
				console.log(responseHeaders);
			},
			httpResponse => {
				this.running = false;
				this.logger.log(httpResponse);
				this.logger.error(
					`Images can't update.`,
					httpResponse,
					"Submit Images Error."
				);
			}
		);
	}

	addImage(index: number): void {
		const image = {
			id: 0,
			no: index,
			dispalyName: "",
			fileUrl: "",
			Reward: ""
		};
		this.activity.storeMultimedia.push(image);
		this.updateImages();
	}
}
