interface ISmartShelvesDatacontext {
  series(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  products(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  drWuProducts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  naturalBeautyProducts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  skintypes(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  treasurehunts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  weightsDefinitions(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  surveyResultDefinitions(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  recommendProducts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  aiSkinDetectResultDefinitions(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  aiSkinDetectWeightsDefinitions(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  devicescenarios(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
}

export class SmartShelvesDatacontext implements ISmartShelvesDatacontext {
  static $inject = ["$resource", "apiUrl"];

  constructor(
    private $resource: angular.resource.IResourceService,
    private apiUrl: string
  ) {}

  series(): any {
    const url = this.apiUrl + "api/Series/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  products(): any {
    const url = this.apiUrl + "api/Products/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getProductsExceptCustom: {
        method: "GET",
        url: url + "GetProductsExceptCustom",
        isArray: true
      }
    });
  }

  drWuProducts(): any {
    const url = this.apiUrl + "api/DrWuProducts/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  naturalBeautyProducts(): any {
    const url = this.apiUrl + "api/NaturalBeautyProducts/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  skintypes(): any {
    const url = this.apiUrl + "api/SkinTypes/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  treasurehunts(): any {
    const url = this.apiUrl + "api/TreasureHunts/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  weightsDefinitions(): any {
    const url = this.apiUrl + "api/WeightsDefinitions/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getWeightsDefinitionsBy: {
        method: "GET",
        url: url + "GetWeightsDefinitionsBy/:answerResultDefinitionId",
        isArray: true
      }
    });
  }

  surveyResultDefinitions(): any {
    const url = this.apiUrl + "api/SurveyResultDefinitions/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  recommendProducts(): any {
    const url = this.apiUrl + "api/RecommendProducts/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  aiSkinDetectResultDefinitions(): any {
    const url = this.apiUrl + "api/AiSkinDetectResultDefinitions/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  aiSkinDetectWeightsDefinitions(): any {
    const url = this.apiUrl + "api/AiSkinDetectWeightsDefinitions/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getWeightsDefinitionsBy: {
        method: "GET",
        url: url + "GetWeightsDefinitionsBy/:aiSkinDetectResultDefinitionId",
        isArray: true
      }
    });
  }

  devicescenarios(): any {
    const url = this.apiUrl + "api/DeviceScenarios/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }
}
