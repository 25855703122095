import * as angular from "angular";
import { routeConfig } from "./config.route";

import DrwuProduct from "./product/product.module";

export default angular
  .module("app.smartshelves.drwu", [
    DrwuProduct.name
  ])
  .config(routeConfig);
