import * as angular from "angular";
import { routeConfig } from "./config.route";
import {CustomerListsCtrl, CustomerViewCtrl, CustomerSaveCtrl} from "./customer";

export default angular
  .module("app.customer", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .controller("CustomerList", CustomerListsCtrl)
  .controller("CustomerView", CustomerViewCtrl)
  .controller("CustomerSave", CustomerSaveCtrl);
