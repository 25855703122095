import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IAgency } from "../models/models";

interface IMartRaw4ViewModel {
  selectAgency: IAgency;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  loading: boolean;

  getMartRaw4();
}

export class MartRaw4Ctrl implements IMartRaw4ViewModel {
  agencyService: any;
  agencies: any[];
  facelogs: any[];
  authorized: boolean;

  selectAgency: IAgency;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dayKeyFrom: string;
  dayKeyTo: string;

  loading: boolean;
  emailAddress: string;

  static $inject = [
    "Authenticator",
    "agencyService",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    authenticator,
    agencyService,
    public $http,
    public apiUrl,
    public environment: number
  ) {
    this.loading = false;
    this.dateFrom = moment().subtract(2, "days");
    this.dateTo = moment().subtract(1, "days");

    this.authorized =
      authenticator.authentication.role.includes("SystemAdmin") ||
      authenticator.authentication.role.includes("Developer");
    if (this.authorized) {
      agencyService.query().$promise.then(value => {
        this.agencies = value;
        if (this.agencies.length > 0) {
          this.selectAgency = this.agencies[0];
        }
      });
    }
    //devices.$promise.then(value => {
    //    this.devices = value.filter(device => device.functionalModule & Models.FunctionalModule.FaceDetection);
    //});
  }

  //function declare
  getMartRaw4() {
    this.loading = true;
    this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
    this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

    this.$http
      .post(this.apiUrl + "api/Analysis/MartRaw4", {
        dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
        dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
        system: this.environment,
        agencyId: this.selectAgency == null ? 0 : this.selectAgency.id,
        emailAddress: this.emailAddress
        //agencyId : 6 //用 Neo 測試
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          this.facelogs = obj.facelogs;
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }

  getMartRaw4ByEmail() {
    Swal.fire<string>({
      title: "Please input email address:",
      input: "text",
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.emailAddress = result.value;
        this.getMartRaw4();
        Swal.fire("任務已執行，結果將送達：<br>" + this.emailAddress);
        this.emailAddress = null;
      } else {
        this.emailAddress = null;
      }
    });
  }
}

