import * as R from "ramda";

export class CultureService {
  mainCulture: { [key: string]: string };
  otherCultures: { [key: string]: string };

  static $inject = ['Authenticator', "LOCALES"];
  constructor(authenticator, locales) {
    // 轉型 
    var firstCulture = authenticator.authentication.agencyFirstCulture.replace(/-/g, '_');
    var secondCulture = authenticator.authentication.agencySecondCulture.replace(/-/g, '_');

    this.mainCulture = R.pick([firstCulture], locales.locales);
    //this.otherCultures = R.omit([authenticator.authentication.agencySecondCulture], locales.locales);
    this.otherCultures = R.pick([secondCulture], locales.locales);
  }

  // 取得已存在的語系資料
  getTranslation(culture: string, objects: any[]): any {
    return R.find(R.propEq("culture", culture))(objects);
  }
}
