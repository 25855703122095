import * as angular from "angular";

/**
 * INSPINIA - Responsive Admin Theme
 *
 * Main directives.js file
 * Define directives for used plugin
 *
 *
 * Functions (directives)
 *  - sideNavigation
 *  - iboxTools
 *  - minimalizaSidebar
 *  - vectorMap
 *  - sparkline
 *  - ionRangeSlider
 *  - dropZone
 *  - responsiveVideo
 *  - chatSlimScroll
 *  - customValid
 *  - fullScroll
 *  - closeOffCanvas
 *  - clockPicker
 *  - landingScrollspy
 *  - fitHeight
 *  - iboxToolsFullScreen
 *  - slimScroll
 *  - truncate
 *  - touchSpin
 *  - markdownEditor
 *  - resizeable
 *  - bootstrapTagsinput
 *
 */

declare var $: any;
declare var Dropzone: any;

/**
 * pageTitle - Directive for set Page title - mata title
 */
pageTitle.$inject = ["$transitions", "$timeout"];
sideNavigation.$inject = ["$timeout"];
iboxTools.$inject = ["$timeout"];
minimalizaSidebar.$inject = ["$timeout"];
chatSlimScroll.$inject = ["$timeout"];
fullScroll.$inject = ["$timeout"];
iboxToolsFullScreen.$inject = ["$timeout"];
slimScroll.$inject = ["$timeout"];
truncate.$inject = ["$timeout"];
ngThumb.$inject = ["$window"];

export function pageTitle($transitions, $timeout) {
  return {
    link: function (scope, element) {
      $transitions.onStart({}, function (trans) {
        // Default title - load on Dashboard 1
        var title = "P-CLOUD | Demand Multimedia System";
        // Create your own title pattern
        if (trans.$to().data && trans.$to().data.pageTitle) title = "P-CLOUD | " + trans.$to().data.pageTitle;
        $timeout(function () {
          element.text(title);
        });
      });
    }
  };
};

/**
 * sideNavigation - Directive for run metsiMenu on sidebar navigation
 */
export function sideNavigation($timeout) {
  return {
    restrict: "A",
    link: function (scope, element) {
      // Call the metsiMenu plugin and plug it to sidebar navigation
      $timeout(function () {
        element.metisMenu();

      });

      // Enable initial fixed sidebar
      //var sidebar = element.parent();
      //sidebar.slimScroll({
      //    height: '100%',
      //    railOpacity: 0.9,
      //});
    }
  };
};

/**
 * responsibleVideo - Directive for responsive video
 */
export function responsiveVideo() {
  return {
    restrict: "A",
    link: function (scope, element) {
      var figure = element;
      var video = element.children();
      video
        .attr("data-aspectRatio", video.height() / video.width())
        .removeAttr("height")
        .removeAttr("width");

      //We can use $watch on $window.innerWidth also.
      $(window).resize(function () {
        var newWidth = figure.width();
        video
          .width(newWidth)
          .height(newWidth * video.attr("data-aspectRatio"));
      }).resize();
    }
  };
}

/**
 * iboxTools - Directive for iBox tools elements in right corner of ibox
 */
export function iboxTools($timeout) {
  return {
    restrict: "A",
    scope: true,
    template: require("./views/common/ibox_tools.html"),
    controller: [
      "$scope", "$element", function ($scope, $element) {
        // Function for collapse ibox
        $scope.showhide = function () {
          var ibox = $element.closest("div.ibox");
          var icon = $element.find("i:first");
          var content = ibox.find("div.ibox-content");
          content.slideToggle(200);
          // Toggle icon from up to down
          icon.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
          ibox.toggleClass("").toggleClass("border-bottom");
          $timeout(function () {
            ibox.resize();
            ibox.find("[id^=map-]").resize();
          },
            50);
        };
        // Function for close ibox
        $scope.closebox = function () {
          var ibox = $element.closest("div.ibox");
          ibox.remove();
        };
      }
    ]
  };
}

/**
 * iboxTools with full screen - Directive for iBox tools elements in right corner of ibox with full screen option
 */
export function iboxToolsFullScreen($timeout) {
  return {
    restrict: "A",
    scope: true,
    template: require("./views/common/ibox_tools_full_screen.html"),
    controller: [
      "$scope", "$element", function ($scope, $element) {
        // Function for collapse ibox
        $scope.showhide = function () {
          var ibox = $element.closest("div.ibox");
          var icon = $element.find("i:first");
          var content = ibox.find("div.ibox-content");
          content.slideToggle(200);
          // Toggle icon from up to down
          icon.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
          ibox.toggleClass("").toggleClass("border-bottom");
          $timeout(function () {
            ibox.resize();
            ibox.find("[id^=map-]").resize();
          },
            50);
        };
        // Function for close ibox
        $scope.closebox = function () {
          var ibox = $element.closest("div.ibox");
          ibox.remove();
        };
        // Function for full screen
        $scope.fullscreen = function () {
          var ibox = $element.closest("div.ibox");
          var button = $element.find("i.fa-expand");
          $("body").toggleClass("fullscreen-ibox-mode");
          button.toggleClass("fa-expand").toggleClass("fa-compress");
          ibox.toggleClass("fullscreen");
          setTimeout(function () {
            $(window).trigger("resize");
          },
            100);
        };
      }
    ]
  };
}

/**
 * minimalizaSidebar - Directive for minimalize sidebar
*/
export function minimalizaSidebar($timeout) {
  return {
    restrict: "A",
    template:
      '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()"><i class="fa fa-bars"></i></a>',
    controller: [
      "$scope", "$element", function ($scope, $element) {
        $scope.minimalize = function () {
          $("body").toggleClass("mini-navbar");
          if (!$("body").hasClass("mini-navbar") || $("body").hasClass("body-small")) {
            // Hide menu in order to smoothly turn on when maximize menu
            $("#side-menu").hide();
            // For smoothly turn on menu
            setTimeout(
              function () {
                $("#side-menu").fadeIn(400);
              },
              200);
          } else if ($("body").hasClass("fixed-sidebar")) {
            $("#side-menu").hide();
            setTimeout(
              function () {
                $("#side-menu").fadeIn(400);
              },
              100);
          } else {
            // Remove all inline style from jquery fadeIn function to reset menu state
            $("#side-menu").removeAttr("style");
          }
        };
      }
    ]
  };
};


export function closeOffCanvas() {
  return {
    restrict: "A",
    template: '<a class="close-canvas-menu" ng-click="closeOffCanvas()"><i class="fa fa-times"></i></a>',
    controller: [
      "$scope", "$element", function ($scope, $element) {
        $scope.closeOffCanvas = function () {
          $("body").toggleClass("mini-navbar");
        };
      }
    ]
  };
}

/**
 * vectorMap - Directive for Vector map plugin
 */
export function vectorMap() {
  return {
    restrict: "A",
    scope: {
      myMapData: "=",
    },
    link: function (scope, element, attrs) {
      var map = element.vectorMap({
        map: "world_mill_en",
        backgroundColor: "transparent",
        regionStyle: {
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0
          }
        },
        series: {
          regions: [
            {
              values: scope.myMapData,
              scale: ["#1ab394", "#22d6b1"],
              normalizeFunction: "polynomial"
            }
          ]
        },
      });
      var destroyMap = function () {
        element.remove();
      };
      scope.$on("$destroy",
        function () {
          destroyMap();
        });
    }
  };
}


/**
 * sparkline - Directive for Sparkline chart
 */
export function sparkline() {
  return {
    restrict: "A",
    scope: {
      sparkData: "=",
      sparkOptions: "=",
    },
    link: function (scope, element, attrs) {
      scope.$watch(scope.sparkData,
        function () {
          render();
        });
      scope.$watch(scope.sparkOptions,
        function () {
          render();
        });
      var render = function () {
        $(element).sparkline(scope.sparkData, scope.sparkOptions);
      };
    }
  };
};

/**
 * ionRangeSlider - Directive for Ion Range Slider
 */
export function ionRangeSlider() {
  return {
    restrict: "A",
    scope: {
      rangeOptions: "="
    },
    link: function (scope, elem, attrs) {
      elem.ionRangeSlider(scope.rangeOptions);
    }
  };
}

/**
 * dropZone - Directive for Drag and drop zone file upload plugin
 */
export function dropZone() {
  return {
    restrict: "C",
    link: function (scope, element, attrs) {

      var config = {
        url: "http://localhost:8080/upload",
        maxFilesize: 100,
        paramName: "uploadfile",
        maxThumbnailFilesize: 10,
        parallelUploads: 1,
        autoProcessQueue: false
      };

      var eventHandlers = {
        'addedfile': function (file) {
          scope.file = file;
          if (this.files[1] != null) {
            this.removeFile(this.files[0]);
          }
          scope.$apply(function () {
            scope.fileAdded = true;
          });
        },

        'success': function (file, response) {
        }

      };

      var dropzone = new Dropzone(element[0], config);

      angular.forEach(eventHandlers,
        (handler, event) => {
          dropzone.on(event, handler);
        });

      scope.processDropzone = function () {
        dropzone.processQueue();
      };

      scope.resetDropzone = function () {
        dropzone.removeAllFiles();
      };
    }
  };
}

/**
 * chatSlimScroll - Directive for slim scroll for small chat
 */
export function chatSlimScroll($timeout) {
  return {
    restrict: "A",
    link: function (scope, element) {
      $timeout(function () {
        element.slimscroll({
          height: "234px",
          railOpacity: 0.4
        });

      });
    }
  };
}

/**
 * customValid - Directive for custom validation example
 */
export function customValid() {
  return {
    require: "ngModel",
    link: function (scope, ele, attrs, c) {
      scope.$watch(attrs.ngModel,
        function () {

          // You can call a $http method here
          // Or create custom validation

          var validText = "P-CLOUD";

          if (scope.extras == validText) {
            c.$setValidity("cvalid", true);
          } else {
            c.$setValidity("cvalid", false);
          }

        });
    }
  };
}


/**
 * fullScroll - Directive for slimScroll with 100%
 */
export function fullScroll($timeout) {
  return {
    restrict: "A",
    link: function (scope, element) {
      $timeout(function () {
        element.slimscroll({
          height: "100%",
          railOpacity: 0.9
        });

      });
    }
  };
}

/**
 * slimScroll - Directive for slimScroll with custom height
 */
export function slimScroll($timeout) {
  return {
    restrict: "A",
    scope: {
      boxHeight: "@"
    },
    link: function (scope, element) {
      $timeout(function () {
        element.slimscroll({
          height: scope.boxHeight,
          railOpacity: 0.9
        });

      });
    }
  };
}

/**
 * clockPicker - Directive for clock picker plugin
 */
export function clockPicker() {
  return {
    restrict: "A",
    link: function (scope, element) {
      element.clockpicker();
    }
  };
};


/**
 * landingScrollspy - Directive for scrollspy in landing page
 */
export function landingScrollspy() {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      element.scrollspy({
        target: ".navbar-fixed-top",
        offset: 80
      });
    }
  };
}

/**
 * fitHeight - Directive for set height fit to window height
 */
export function fitHeight() {
  return {
    restrict: "A",
    link: function (scope, element) {
      element.css("height", $(window).height() + "px");
      element.css("min-height", $(window).height() + "px");
    }
  };
}

/**
 * truncate - Directive for truncate string
 */
export function truncate($timeout) {
  return {
    restrict: "A",
    scope: {
      truncateOptions: "="
    },
    link: function (scope, element) {
      $timeout(function () {
        element.dotdotdot(scope.truncateOptions);

      });
    }
  };
}


/**
 * touchSpin - Directive for Bootstrap TouchSpin
 */
export function touchSpin() {
  return {
    restrict: "A",
    scope: {
      spinOptions: "="
    },
    link: function (scope, element, attrs) {
      scope.$watch(scope.spinOptions,
        function () {
          render();
        });
      var render = function () {
        $(element).TouchSpin(scope.spinOptions);
      };
    }
  };
};

/**
 * markdownEditor - Directive for Bootstrap Markdown
 */
export function markdownEditor() {
  return {
    restrict: "A",
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      $(element).markdown({
        savable: false,
        onChange: function (e) {
          ngModel.$setViewValue(e.getContent());
        }
      });
    }
  };
};

export function backImg() {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      var url = attrs.backImg;
      element.css({
        'background-image': "url(" + url + ")",
        'background-size': "cover"
      });
    }
  };
}

export function ngThumb($window) {
  var helper = {
    support: !!($window.FileReader && $window.CanvasRenderingContext2D),
    isFile: function (item) {
      return angular.isObject(item) && item instanceof $window.File;
    },
    isImage: function (file) {
      var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
      return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
    }
  };

  return {
    restrict: 'A',
    template: '<canvas/>',
    link: function (scope, element, attributes) {
      if (!helper.support) return;

      var params = scope.$eval(attributes.ngThumb);

      if (!helper.isFile(params.file)) return;
      if (!helper.isImage(params.file)) return;

      var canvas = element.find('canvas');
      var reader = new FileReader();

      reader.onload = onLoadFile;
      reader.readAsDataURL(params.file);

      function onLoadFile(event) {
        var img = new Image();
        img.onload = onLoadImage;
        img.src = event.target.result;
      }

      function onLoadImage() {
        var width = params.width || this.width / this.height * params.height;
        var height = params.height || this.height / this.width * params.width;
        canvas.attr({ width: width, height: height });
        canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
      }
    }
  };
}
