import { StateProvider } from "@uirouter/angularjs";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("smartshelves.devicescenarios", {
      abstract: true,
      url: "/devicescenarios",
      template: require("../../views/common/sub_content.html"),
      data: { pageTitle: "DeviceScenario", requiresLogin: true }
    })
    .state("smartshelves.devicescenarios.list", {
      url: "/lists",
      template: '<devicescenario-list devicescenarios="$resolve.devicescenarios" devices="$resolve.devices" treasurehunts="$resolve.treasurehunts" surveyresultdefinitions="$resolve.surveyresultdefinitions" aiskindetectresultdefinitions="$resolve.aiskindetectresultdefinitions"></devicescenario-list>',
      resolve: {
        devicescenarios: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.devicescenarios().query()],
        devices: ["datacontext", (datacontext: Datacontext) => datacontext.devices().query()],
        treasurehunts: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.treasurehunts().query()],
        surveyresultdefinitions: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.surveyResultDefinitions().query()],
        aiskindetectresultdefinitions: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.aiSkinDetectResultDefinitions().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          {
            serie: true, files: [rootUrl + "Scripts/plugins/dataTables/datatables.min.js", rootUrl + "Content/plugins/dataTables/datatables.min.css"]
          },
          {
            serie: true, name: "datatables", files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"]
          },
          {
            serie: true, name: "datatables.buttons", files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"]
          },
          {
            name: "xeditable", files: [rootUrl + "assets/plugins/angular-xeditable/css/xeditable.min.css", rootUrl + "assets/plugins/angular-xeditable/js/xeditable.min.js"]
          }
        ])
        ]
      }
    });
}
