    export interface IUser extends angular.resource.IResource<any> {
    }
    export interface IRole extends angular.resource.IResource<any> {
    }

    export interface IUserResource extends angular.resource.IResourceClass<IUser> {
        createUser: angular.resource.IResourceMethod<IUser>;
        update(user): IUser;
        addUserProfile: angular.resource.IResourceMethod<IUser>;
        assignRolesToUser: angular.resource.IResourceMethod<IUser>;
    }
    export interface IRoleResource extends angular.resource.IResourceClass<IRole> {
    }

    export interface IIdentityService {
        users(): IUserResource;
        roles(): IRoleResource;
    }

    export class IdentityService implements IIdentityService {
        static $inject = ["$resource", "rootUrl"];

        constructor(private $resource: angular.resource.IResourceService, private rootUrl: string) {
        }

        users(): IUserResource {
            const url = this.rootUrl + "api/Users/";

            const createAction: angular.resource.IActionDescriptor = {
                method: "POST",
                url: url + "create/",
                isArray: false
            };
            const updateAction: angular.resource.IActionDescriptor = {
                method: "PUT",
                isArray: false
            };
            const addUserProfileAction: angular.resource.IActionDescriptor = {
                method: "POST",
                url: url + "AddUserProfile/"
            };
            const assignRolesToUserAction: angular.resource.IActionDescriptor = {
                method: "PUT",
                url: url + ":id/roles/"
            };

            return this.$resource(url + ":id", { id: "@id" },
            {
                createUser: createAction,
                update: updateAction,
                addUserProfile: addUserProfileAction,
                assignRolesToUser: assignRolesToUserAction
            }) as IUserResource;
        }

        roles(): IRoleResource {
            const url = this.rootUrl + "api/Roles/:id";
            return this.$resource(url) as IRoleResource;;
        }
    }
