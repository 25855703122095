import * as angular from "angular";
import { routeConfig } from "./config.route";
import { AccountService } from "./account.factory";
import { AccountManageCtrl, AccountChangepasswordCtrl } from "./account";

export default angular
  .module("app.account", [])
  .config(routeConfig)
  .service("AccountService", AccountService)
  .component("accountManage", {
    template: require("./views/manage.html"),
    controller: AccountManageCtrl,
    bindings: { authentication: "<" }
  })
  .component("accountChangepassword", {
    template: require("./views/changepassword.html"),
    controller: AccountChangepasswordCtrl,
    bindings: { authentication: "<" }
  });
