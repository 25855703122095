import { StateService } from "@uirouter/angularjs";
import Swal from "sweetalert2";

import { ILogger } from "../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../data/datacontext";

interface IProductListViewModel {
  products: Array<any>;

  dtOptions: any;
  dtColumnDefs: any;

  del(id: number): void;
}

export class ProductListsCtrl implements IProductListViewModel, angular.IComponentController {
  products: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly productService: any;
  private readonly logger: ILogger;

  static $inject = [ "smartShelvesDatacontext", "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder" ];
  constructor(datacontext: SmartShelvesDatacontext, common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder ) {
    this.productService = datacontext.products();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "ProductsFile" },
        { extend: "pdf", title: "ProductsFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  $onInit() {
    this.logger.log(this.products);
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.productService.delete(
          { id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(
              this.products,
              "id",
              id
            );
            this.products.splice(indx, 1);
            Swal.fire("Deleted!", "Your Product has been deleted.", "success");
          },
          httpResponse => {
            this.logger.error(
              `Product can't delete, product ID is ${id}`,
              httpResponse,
              "Delete Product Error"
            );
            Swal.fire("Cancelled", "Product can't delete", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Product file is safe :)", "success");
      }
    });
  }
}

interface IProductEditViewModel {
  product: any;
  series: any;

  uploader: any;

  save(): void;
}

export class ProductEditCtrl implements IProductEditViewModel, angular.IComponentController {
  isEdit: boolean;

  product: any;
  series: any;

  uploader: any;

  private logger: ILogger;
  private productService: any;

  static $inject = [ "smartShelvesDatacontext", "common", "$stateParams", "$state", "apiUrl", "FileUploader", "localStorageService"];
  constructor(datacontext: SmartShelvesDatacontext, common: any, private $stateParams, private $state: StateService, private apiUrl, private FileUploader, private localStorageService) {
    this.logger = common.logger;
    this.productService = datacontext.products();

    this.uploaderSetting();
  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;

    if (this.isEdit) {
      this.product.$promise.then(value => {
        value.stars = value.stars.toString();
        this.product = value;
        this.logger.log(this.product);
      });
    } else {
      this.product = {
        id: 0,
        gtin: null,
        name: null,
        secondaryName: null,
        stars: "0",
        description: null,
        ingredients: null,
        skin: null,
        seriesId: null,
        pictureUrl: null,
        activity: {
          event: 0,
          type: null,
          reward: 0,
          imageUrl: null,
          isLoginRequired: true
        },
        treasureHuntActivity: {
          event: 0,
          type: null,
          reward: 0,
          imageUrl: null,
          isLoginRequired: true
        }
      };
    }
  }

  uploaderSetting(): void {
    // File Upload 相關
    this.uploader = new this.FileUploader({
      url: `${this.apiUrl}api/Products/UploadProductPicture`, // webapi url
      headers: {
        Authorization: `Bearer ${this.localStorageService.get("jwt")}`
      }
    });
    //this.uploader.queueLimit = 1;
    this.uploader.removeAfterUpload = true;

    // FILTERS
    this.uploader.filters.push({
      name: "imageFilter",
      fn: (item /*{File|FileLikeObject}*/, options) => {
        var type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
      }
    });

    // CALLBACKS

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      console.info("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      console.info("onAfterAddingFile", fileItem);
    };
    this.uploader.onAfterAddingAll = addedFileItems => {
      if (this.uploader.getNotUploadedItems().length > 1) {
        this.uploader.removeFromQueue(0);
      }
      console.info("onAfterAddingAll", addedFileItems);
    };
    this.uploader.onBeforeUploadItem = item => {
      var formData = [
        {
          id: this.product.id,
          product: JSON.stringify(this.product)
        }
      ];

      Array.prototype.push.apply(item.formData, formData);

      console.info("onBeforeUploadItem", item);
    };
    this.uploader.onProgressItem = (fileItem, progress) => {
      console.info("onProgressItem", fileItem, progress);
    };
    this.uploader.onProgressAll = progress => {
      console.info("onProgressAll", progress);
    };
    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      response.stars = response.stars.toString();
      this.product = response;
      this.isEdit = this.product.id !== 0;

      console.info("onSuccessItem", fileItem, response, status, headers);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      console.info("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCancelItem = (fileItem, response, status, headers) => {
      console.info("onCancelItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteItem = (fileItem, response, status, headers) => {
      console.info("onCompleteItem", fileItem, response, status, headers);
      this.$state.go("smartshelves.products.list");
    };
    this.uploader.onCompleteAll = () => {
      console.info("onCompleteAll");
      this.$state.go("smartshelves.products.list");
    };

    console.info("uploader", this.uploader);
  }

  save(): void {
    this.logger.log(this.product);
    //this.product.seriesId = +this.product.seriesId;
    if (this.isEdit) {
      this.productService.update(
        { id: this.product.id },
        this.product,
        () => {
          this.$state.go("smartshelves.products.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    } else {
      this.productService.save(
        null,
        this.product,
        () => {
          this.$state.go("smartshelves.products.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    }
  }
}
