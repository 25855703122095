localeService.$inject = ["$translate", "LOCALES", "$rootScope", "tmhDynamicLocale"];

export function localeService($translate: angular.translate.ITranslateService, LOCALES, $rootScope: angular.IRootScopeService, tmhDynamicLocale) {
  // PREPARING LOCALES INFO
  var localesObj = LOCALES.locales;

  // locales and locales display names
  var _LOCALES = Object.keys(localesObj);
  if (!_LOCALES || _LOCALES.length === 0) {
    console.error('There are no _LOCALES provided');
  }
  var _LOCALES_DISPLAY_NAMES = [];
  _LOCALES.forEach(locale => {
    _LOCALES_DISPLAY_NAMES.push(localesObj[locale]);
  });

  // STORING CURRENT LOCALE
  var currentLocale = $translate.proposedLanguage(); // because of async loading

  // METHODS
  var checkLocaleIsValid = locale => _LOCALES.indexOf(locale) !== -1;

  var setLocale = locale => {
    if (!checkLocaleIsValid(locale)) {
      console.error(`Locale name ${locale} is invalid`);
      return;
    }
    currentLocale = locale; // updating current locale

    // asking angular-translate to load and apply proper translations
    $translate.use(locale);
  };

  // EVENTS
  // on successful applying translations by angular-translate
  $rootScope.$on('$translateChangeSuccess', (event, data) => {
    document.documentElement.setAttribute('lang', data.language); // sets "lang" attribute to html

    // asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
    tmhDynamicLocale.set(data.language.toLowerCase().replace(/_/g, '-'));
    //moment.locale(data.language.toLowerCase());
  });

  return {
    getLocaleDisplayName: () => localesObj[currentLocale],
    setLocaleByDisplayName: localeDisplayName => {
      // get locale index
      setLocale(_LOCALES[_LOCALES_DISPLAY_NAMES.indexOf(localeDisplayName)]);
    },
    getLocalesDisplayNames: () => _LOCALES_DISPLAY_NAMES
  };
}
