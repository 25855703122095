import * as angular from "angular";
import { routeConfig } from "./config.route";
import { DeviceScenarioListsCtrl } from "./devicescenario";

export default angular
  .module("app.smartshelves.devicescenario", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("devicescenarioList", {
    template: require("./views/lists.html"),
    controller: DeviceScenarioListsCtrl,
    bindings: {
      devicescenarios: "<",
      devices: "<",
      treasurehunts: "<",
      surveyresultdefinitions: "<",
      aiskindetectresultdefinitions: "<"
    }
  });
