import { StateService } from "@uirouter/angularjs";
import Swal from "sweetalert2";
import { ILogger } from "../../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../../data/datacontext";
import { IAgency } from "../../../models/models";
import { Datacontext } from "../../../data/datacontext";
import { CultureService } from "../../../services/culture.service";


export class DrwuProductListsCtrl implements  angular.IComponentController {
  products: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly productService: any;
  private readonly logger: ILogger;

  static $inject = [ "smartShelvesDatacontext", "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder" ];
  constructor(datacontext: SmartShelvesDatacontext, common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder ) {
    this.productService = datacontext.drWuProducts();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "ProductsFile" },
        { extend: "pdf", title: "ProductsFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  $onInit() {
    this.logger.log(this.products);
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.productService.delete(
          { id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(
              this.products,
              "id",
              id
            );
            this.products.splice(indx, 1);
            Swal.fire("Deleted!", "Your Product has been deleted.", "success");
          },
          httpResponse => {
            console.log(httpResponse.status);
            if (httpResponse.status == 500) {
              Swal.fire("Cancelled", "Product can't delete.<br> Product used in the treasure hunt.", "error");
            } else if (httpResponse.status == 410) {
              Swal.fire("Cancelled", "Product can't delete.<br> Product used in the survey result definition.", "error");
            } else {
              Swal.fire("Cancelled", "Product can't delete.", "error");
            };
            this.logger.error(
              `Product can't delete, product ID is ${id}`,
              httpResponse,
              "Delete Product Error"
            );
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Product file is safe :)", "success");
      }
    });
  }
}

export class DrwuProductEditContentCtrl implements angular.IComponentController {
  mainproduct: any;
  product: any;
  series: any;
  ismainculture: boolean;
  culture: string;
  authentication: any;
  uploader: any;
  //agencies: any;

  private role: any;

  static $inject = ["apiUrl", "FileUploader", "localStorageService", "Authenticator"];
  constructor(private apiUrl, private FileUploader, private localStorageService, private authenticator: any) {
    this.uploaderSetting();
    //this.role = authenticator.authentication.role;
    //if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
    //  this.agencies = datacontext.agencies().query();
    //}
  }

  uploaderSetting(): void {
    // File Upload 相關
    this.uploader = new this.FileUploader({
      url: `${this.apiUrl}api/DrWuProducts/UploadProductPicture`, // webapi url
      headers: {
        Authorization: `Bearer ${this.localStorageService.get("jwt")}`
      }
    });
    //this.uploader.queueLimit = 1;
    this.uploader.removeAfterUpload = true;

    // FILTERS
    this.uploader.filters.push({
      name: "imageFilter",
      fn: (item /*{File|FileLikeObject}*/, options) => {
        var type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
      }
    });

    // CALLBACKS

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      console.info("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      console.info("onAfterAddingFile", fileItem);
    };
    this.uploader.onAfterAddingAll = addedFileItems => {
      if (this.uploader.getNotUploadedItems().length > 1) {
        this.uploader.removeFromQueue(0);
      }
      console.info("onAfterAddingAll", addedFileItems);
    };
    this.uploader.onBeforeUploadItem = item => {
      var formData = [
        {
          id: this.product.id,
          product: JSON.stringify(this.product)
        }
      ];

      Array.prototype.push.apply(item.formData, formData);

      console.info("onBeforeUploadItem", item);
    };
    this.uploader.onProgressItem = (fileItem, progress) => {
      console.info("onProgressItem", fileItem, progress);
    };
    this.uploader.onProgressAll = progress => {
      console.info("onProgressAll", progress);
    };
    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
	  if (status == 200) {
	      this.product.pictureUrl = response.mediaUrl;
      }
      console.info("onSuccessItem", fileItem, response, status, headers);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      console.info("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCancelItem = (fileItem, response, status, headers) => {
      console.info("onCancelItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteItem = (fileItem, response, status, headers) => {
      console.info("onCompleteItem", fileItem, response, status, headers);
      //this.$state.go("smartshelves.drwu.products.list");
    };
    this.uploader.onCompleteAll = () => {
      console.info("onCompleteAll");
      //this.$state.go("smartshelves.drwu.products.list");
    };

    console.info("uploader", this.uploader);
  }
}

export class DrwuProductEditCtrl implements  angular.IComponentController {
  isEdit: boolean;

  product: any;
  series: any;
  agencies: any;
  uploader: any;

  mainCulture: { [key: string]: string };
  otherCultures: { [key: string]: string };

  private logger: ILogger;
  private productService: any;

  static $inject = ["CultureService", "smartShelvesDatacontext", "common", "$stateParams", "$state", "apiUrl", "FileUploader", "localStorageService"];
  constructor(private cultureService: CultureService, datacontext: SmartShelvesDatacontext, common: any, private $stateParams, private $state: StateService, private apiUrl, private FileUploader, private localStorageService) {
    this.logger = common.logger;
    this.productService = datacontext.drWuProducts();

  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;

    if (this.isEdit) {
      this.product.$promise.then(value => {
        value.stars = value.stars.toString();
        this.product = value;
        this.logger.log(this.product);
        this.initCulture();
      });
    } else {
      this.product = {
        id: 0,
        gtin: null,
        name: null,
        secondaryName: null,
        stars: "0",
        description: null,
        ingredients: null,
        skin: null,
        seriesId: null,
        pictureUrl: null,
        productQrCodeUrl: null,
        treasureHuntQrCodeUrl:null,
        activity: {
          event: 0,
          type: null,
          reward: 0,
          imageUrl: null,
          isLoginRequired: true,
          qrCodeUrl: null
        },
        treasureHuntActivity: {
          event: 0,
          type: null,
          reward: 0,
          imageUrl: null,
          isLoginRequired: true,
          qrCodeUrl: null
        },
        drWuProductTranslations: []
      };
      this.initCulture();
    }
  }

  initCulture() {
    this.mainCulture = this.cultureService.mainCulture;
    this.otherCultures = this.cultureService.otherCultures;
  }

  // 檢查是否有語系資料
  checkHasTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    if (this.product.drWuProductTranslations.length == 0) {
      this.pushNewTranslation(culture);
    }
    else {
      var found = this.getTranslation(culture);
      if (!found) {
        this.pushNewTranslation(culture);
      }
    }
  }

  // 新增語系資料
  pushNewTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    let newTranslation = {
      id: 0,
      name: null,
      abbreviation: null,
      secondaryName:null,
      description: null,
      ingredients:null,
      pictureUrl: null,
      skin:null,
      culture: culture,
      drWuProductId: this.product.id
    };
    this.product.drWuProductTranslations.push(newTranslation);
  }

  // 取得已存在的語系資料
  getTranslation(culture: string): any {
    culture = culture.replace(/_/g, '-');
    return this.cultureService.getTranslation(culture, this.product.drWuProductTranslations);
  }

  save(): void {
    //this.product.seriesId = +this.product.seriesId;
    if (this.isEdit) {
      this.productService.update(
        { id: this.product.id },
        this.product,
        () => {
          this.$state.go("smartshelves.drwu.products.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    } else {
      this.productService.save(
        null,
        this.product,
        () => {
          this.$state.go("smartshelves.drwu.products.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    }
  }
}
