import { StateService } from "@uirouter/angularjs";

import Swal from "sweetalert2";

import { ILogger } from "../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";
import { CultureService } from "../../services/culture.service";
import { IAgency } from "../../models/models";
import { IAuthentication, authenticator } from "../../authorization/authenticator";



export class SkintypeListsCtrl implements angular.IComponentController {
  skintypes: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly skintypeService: any;
  private readonly logger: ILogger;

  static $inject = [
    "smartShelvesDatacontext",
    "common",
    "$filter",
    "DTOptionsBuilder",
    "DTColumnDefBuilder"
  ];

  $onInit() {
    this.logger.log(this.skintypes);
  }

  constructor(
    datacontext: SmartShelvesDatacontext,
    common: any,
    private readonly $filter,

    DTOptionsBuilder,
    DTColumnDefBuilder
  ) {
    this.skintypeService = datacontext.skintypes();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "SkintypesFile" },
        { extend: "pdf", title: "SkintypesFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.skintypeService.delete(
          { id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(
              this.skintypes,
              "id",
              id
            );
            this.skintypes.splice(indx, 1);
            Swal.fire("Deleted!", "Your Skintype has been deleted.", "success");
          },
          httpResponse => {
            this.logger.error(
              `Skintype can't delete, skintype ID is ${id}`,
              httpResponse,
              "Delete Skintype Error"
            );
            Swal.fire("Cancelled", "Skintype can't delete", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Skintype file is safe :)", "success");
      }
    });
  }
}

export class SkintypeEditContentCtrl implements angular.IComponentController {
  skintype: any;
  ismainculture: boolean;
  culture: string;

  authentication: any;
  uploader: any;
  mainskintype: any;
  private agencies: Array<IAgency>;
  private role: any;

  static $inject = ["apiUrl", "FileUploader", "localStorageService", "datacontext", "Authenticator","$sce"];
  constructor(private apiUrl, private FileUploader, private localStorageService, private datacontext: Datacontext, private authenticator: any, private $sce) {
    this.uploaderSetting();
    this.role = authenticator.authentication.role;
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = datacontext.agencies().query();
    }
  }

  uploaderSetting(): void {
    // File Upload 相關
    this.uploader = new this.FileUploader({
      url: `${this.apiUrl}api/SkinTypes/UploadMedia`, // webapi url
      headers: { Authorization: `Bearer ${this.localStorageService.get("jwt")}` }
    });
    //this.uploader.queueLimit = 1;
    this.uploader.removeAfterUpload = true;

    // FILTERS
    this.uploader.filters.push({
      name: "videoFilter",
      fn: (item /*{File|FileLikeObject}*/, options) => {
        var type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        return "|mp4|".indexOf(type) !== -1;
      }
    });

    // CALLBACKS
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      console.info("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      console.info("onAfterAddingFile", fileItem);
    };
    this.uploader.onAfterAddingAll = addedFileItems => {
      if (this.uploader.getNotUploadedItems().length > 1) {
        this.uploader.removeFromQueue(0);
      }
      console.info("onAfterAddingAll", addedFileItems);
    };
    this.uploader.onBeforeUploadItem = item => {
      var formData = [{
        id: this.skintype.id,
        culture: this.culture,
        isMainCulture: this.ismainculture,
        skinType: JSON.stringify(this.skintype)
      }];

      Array.prototype.push.apply(item.formData, formData);

      console.info("onBeforeUploadItem", item);
    };
    this.uploader.onProgressItem = (fileItem, progress) => {
      console.info("onProgressItem", fileItem, progress);
    };
    this.uploader.onProgressAll = progress => {
      console.info("onProgressAll", progress);
    };
    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      //this.skintype = response;
      if (status == 200) {
        this.skintype.mediaUrl = response.mediaUrl;
        this.skintype.sources = [
          {
            src: this.$sce.trustAsResourceUrl(response.mediaUrl),
            type: "video/mp4"
          }
        ];
      }
      console.info("onSuccessItem", fileItem, response, status, headers);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      console.info("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCancelItem = (fileItem, response, status, headers) => {
      console.info("onCancelItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteItem = (fileItem, response, status, headers) => {
      console.info("onCompleteItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      console.info("onCompleteAll");
    };

    console.info("uploader", this.uploader);
  }
}

export class SkintypeEditCtrl implements angular.IComponentController {
  isEdit: boolean;

  authentication: any;
  skintype: any;

  mainCulture: { [key: string]: string };
  otherCultures: { [key: string]: string };

  uploader: any;

  private logger: ILogger;
  private skintypeService: any;

  static $inject = ["CultureService", "smartShelvesDatacontext", "common", "$stateParams", "$state", "$sce", "localStorageService"];
  constructor(private cultureService: CultureService, datacontext: SmartShelvesDatacontext, common: any, private $stateParams, private $state: StateService, private $sce, private localStorageService) {
    this.logger = common.logger;
    this.skintypeService = datacontext.skintypes();
  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;
    if (this.isEdit) {
      this.skintype.$promise.then(value => {
        value.sources = [
          {
            src: this.$sce.trustAsResourceUrl(value.mediaUrl),
            type: "video/mp4"
          }
        ];

        if (value.skinTypeTranslations != null) {
          value.skinTypeTranslations.forEach(element => {
            element.sources = [
              {
                src: this.$sce.trustAsResourceUrl(element.mediaUrl),
                type: "video/mp4"
              }
            ];
          });

        }

        this.skintype = value;

        this.initCulture();
      });
    } else {
      this.skintype = {
        id: 0,
        name: null,
        description: null,
        recommend: null,
        priority: 0,
        mediaUrl: null,
        skinTypeTranslations: []
      };
      this.initCulture();
    }
  }

  initCulture() {
    this.mainCulture = this.cultureService.mainCulture;
    this.otherCultures = this.cultureService.otherCultures;
  }

  // 檢查是否有語系資料
  checkHasTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    if (this.skintype.skinTypeTranslations.length == 0) {
      this.pushNewTranslation(culture);
    }
    else {
      var found = this.getTranslation(culture);
      if (!found) {
        this.pushNewTranslation(culture);
      }
    }
  }

  // 新增語系資料
  pushNewTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    let newTranslation = {
      id: 0,
      name: null,
      description: null,
      recommend: null,
      priority: this.skintype.priority,
      mediaUrl: null,
      culture: culture,
      skinTypeId: this.skintype.id
    };
    this.skintype.skinTypeTranslations.push(newTranslation);
  }

  // 取得已存在的語系資料
  getTranslation(culture: string): any {
    culture = culture.replace(/_/g, '-');
    return this.cultureService.getTranslation(culture, this.skintype.skinTypeTranslations);
  }

  save(): void {
    if (this.isEdit) {
      this.skintypeService.update({ id: this.skintype.id }, this.skintype,
        () => { this.$state.go("smartshelves.skintypes.list"); },
        httpResponse => { this.logger.log(httpResponse); }
      );
    } else {
      this.skintypeService.save(null, this.skintype,
        () => { this.$state.go("smartshelves.skintypes.list"); },
        httpResponse => { this.logger.log(httpResponse); }
      );
    }
  }

}
