import { StateService } from "@uirouter/angularjs";
import { ILogger } from "../blocks/logger/logger";
import { Datacontext, ICustomerResource } from "../data/datacontext";
import { ICustomer } from "../models/models";

interface ICustomerListViewModel {
  customers: Array<any>;
  authentication: any;

  del(id: number): void;
}

export class CustomerListsCtrl implements ICustomerListViewModel {
  customers: any[];
  authentication: any;

  private customerService: any;
  private logger: ILogger;

  static $inject = [
    "datacontext",
    "common",
    "Authenticator",
    "customers",
    "$filter"
  ];

  constructor(
    datacontext: Datacontext,
    common: any,
    authenticator,
    customers: angular.resource.IResourceArray<ICustomerResource>,
    private $filter
  ) {
    this.customers = customers;
    this.authentication = authenticator.authentication;

    this.customerService = datacontext.customers();
    this.logger = common.logger;
    this.logger.log("customers :", customers);
  }

  del(id: number): void {
    this.customerService.delete(
      { id: id },
      (value, responseHeaders) => {
        var indx = this.$filter("findIndexByKeyValue")(
          this.customers,
          "id",
          id
        );
        this.customers.splice(indx, 1);
        this.logger.success(
          "Customer has be deleted",
          value,
          "Delete Customer Success"
        );
      },
      httpResponse => {
        this.logger.error(
          `Customer can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Customer Error"
        );
      }
    );
  }
}

interface ICustomerViewModel {
  customer: ICustomer;
}

export class CustomerViewCtrl implements ICustomerViewModel {
  static $inject = ["common", "customer"];

  constructor(common: any, public customer: ICustomer) {
    const logger: ILogger = common.logger;
    logger.log(customer);
  }
}

interface ICustomerSaveViewModel {
  customer: ICustomer;
  authentication: any;
  save(): void;
}

export class CustomerSaveCtrl implements ICustomerSaveViewModel {
  private logger: ILogger;
  private customerService: any;
  private isEdit: boolean;
  customer: ICustomer;
  authentication: any;

  static $inject = [
    "datacontext",
    "common",
    "Authenticator",
    "agencies",
    "$state",
    "$stateParams"
  ];

  constructor(
    datacontext: Datacontext,
    common,
    authenticator,
    public agencies: angular.resource.IResourceArray<
      angular.resource.IResource<any>
    >,
    private $state: StateService,
    $stateParams: any
  ) {
    this.logger = common.logger;
    this.authentication = authenticator.authentication;
    this.customerService = datacontext.customers();
    this.isEdit = $stateParams.id !== undefined;
    if (this.isEdit) {
      this.customer = this.customerService.get({ id: $stateParams.id });
    }
  }

  save(): void {
    if (this.isEdit) {
      this.customerService.update(
        { id: this.customer.id },
        this.customer,
        (value, responseHeaders) => {
          this.logger.success("Customers has Update!", value, "Good job!");
          this.$state.go("customers.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    } else {
      this.customerService.save(
        null,
        this.customer,
        (value, responseHeaders) => {
          this.isEdit = true;
          this.logger.success("Customers has Create!", value, "Good job!");
          this.$state.go("customers.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    }
  }
}
