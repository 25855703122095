import { StateProvider } from "@uirouter/angularjs";
import { SmartShelvesDatacontext } from "../../data/datacontext";
import { Datacontext } from "../../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("smartshelves.drwu.products", {
      abstract: true,
      url: "/products",
      template: require("../../../views/common/sub_content.html"),
      data: { pageTitle: "Products", requiresLogin: true }
    })
    .state("smartshelves.drwu.products.list", {
      url: "/lists",
      template: '<drwu-product-list products="$resolve.products" authentication="authentication"></drwu-product-list>',
      resolve: {
        products: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.drWuProducts().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              serie: true,
              files: [
                rootUrl + "Scripts/plugins/dataTables/datatables.min.js",
                rootUrl + "Content/plugins/dataTables/datatables.min.css"
              ]
            },
            {
              serie: true,
              name: "datatables",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"]
            },
            {
              serie: true,
              name: "datatables.buttons",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"]
            }
          ])
        ]
      }
    })
    .state("smartshelves.drwu.products.create", {
      url: "/create",
      template:
        '<drwu-product-edit product="" series="$resolve.series" agencies="$resolve.agencies" authentication="authentication"></drwu-product-edit>',
      resolve: {
        series: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.series().query()],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { name: "angularFileUpload", files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"] },
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    })
    .state("smartshelves.drwu.products.edit", {
      url: "/{id:int}/edit",
      template:
        '<drwu-product-edit product="$resolve.product" series="$resolve.series" agencies="$resolve.agencies" authentication="authentication"></drwu-product-edit>',
      resolve: {
        product: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext, $stateParams) =>
          datacontext.drWuProducts().get({ id: $stateParams.id })],
        series: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext) => datacontext.series().query()],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              { name: "angularFileUpload", files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"]},
              { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
            ])
        ]
      }
    });
}
