import * as angular from "angular";
import { IAuthSettings } from "./constants";
import { routeConfig } from "./config.route";
import { authenticator, authconfig } from "./authenticator";
import { autoLoginController, loginController, signUpController, forgotPasswordController, resetPasswordController, sendCodeController, verifyOTPController } from "./authorization";

import 'angular-local-storage';
import 'angular-jwt';

export default angular
  .module("app.authorization", [
    // Angular modules

    // Custom modules
    "LocalStorageModule", // angular-local-storage

    // 3rd Party Modules
    "angular-jwt"
  ])

  .constant<IAuthSettings>("ngAuthSettings", {
    apiServiceBaseUri: $("#linkRoot").attr("href"),
    clientId: "angularJSApp",
    password: "pcloud"
  })
  .config(routeConfig)

  .factory("Authenticator", authenticator)
  .config(authconfig)

  .controller("AutoLoginController", autoLoginController)
  .controller("LoginController", loginController)
  .controller("SignUpController", signUpController)
  .controller("ForgotPasswordController", forgotPasswordController)
  .controller("ResetPasswordController", resetPasswordController)
  .controller("SendCodeController", sendCodeController)
  .controller("VerifyOTPController", verifyOTPController);
