import * as angular from "angular";
import { routeConfig } from "./config.route";

import AppAdminAgency from "./agency/agency.module";
import AppAdminIdentity from "./identity/identity.module";
import AppAdminLayout from "./layout/layout.module";
import AppAdminLicense from "./license/license.module";

export default angular
  .module("app.admin", [
    AppAdminAgency.name,
    AppAdminIdentity.name,
    AppAdminLayout.name,
    AppAdminLicense.name
  ])
  .config(routeConfig);
