import { StateProvider } from "@uirouter/angularjs";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig( rootUrl: string, $stateProvider: StateProvider ): void {
  $stateProvider
    .state("smartshelves.series", {
      abstract: true,
      url: "/series",
      template: require("../../views/common/sub_content.html"),
      data: { pageTitle: "Series", requiresLogin: true }
    })
    .state("smartshelves.series.list", {
      url: "/lists",
      template: '<series-list series="$resolve.series" authentication="authentication"></series-list>',
      resolve: {
        series: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.series().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              serie: true,
              files: [rootUrl + "Scripts/plugins/dataTables/datatables.min.js", rootUrl + "Content/plugins/dataTables/datatables.min.css"]
            },
            {
              serie: true,
              name: "datatables",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"]
            },
            {
              serie: true,
              name: "datatables.buttons",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"]
            }
          ])
        ]
      }
    })
    .state("smartshelves.series.create", {
      url: "/create",
      template: '<series-edit series="" agencies="$resolve.agencies" authentication="authentication"></series-edit>',
      resolve: {
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    })
    .state("smartshelves.series.edit", {
      url: "/{id:int}/edit",
      template: '<series-edit series="$resolve.series" agencies="$resolve.agencies" authentication="authentication"></series-edit>',
      resolve: {
        series: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext, $stateParams) => datacontext.series().get({ id: $stateParams.id })],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    });
}
