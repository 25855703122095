import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IDevice } from "../models/models";
import { FunctionalModule } from "../models/enums";
import * as angular from "angular";

declare var Highcharts: any;

interface IPeopleCountViewModel {
  devices: Array<any>;
  selectDevice: IDevice;

  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  loading: boolean;

  getPeopleCount();
}

export class PeopleCountCtrl implements IPeopleCountViewModel {
  devices: any[];
  selectDevice: IDevice;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dayKeyFrom: string;
  dayKeyTo: string;

  loading: boolean;

  static $inject = [
    "devices",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    devices,
    private $http,
    private apiUrl,
    private environment: number
  ) {
    this.loading = false;
    this.dateFrom = moment().subtract(8, "days");
    this.dateTo = moment().subtract(1, "days");

    devices.$promise.then(value => {
      this.devices = value.filter(
        device => device.functionalModule & FunctionalModule.FaceDetection
      );
    });
    //this.devices = [{ name: '辦公室測試', guid: '80039b87-e206-4e15-aae4-000000000003' }];
    //console.log(this.devices);
  }

  //function declare
  getPeopleCount() {
    this.loading = true;
    this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
    this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

    this.$http
      .post(this.apiUrl + "api/Analysis/PeopleCount", {
        dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
        dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
        deviceGuid: this.selectDevice.guid,
        system: this.environment
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          //console.log(obj);
          this.drawChart1(obj.countByDay);
          this.drawChart2(obj.countByTime);
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }
  drawChart1(objs: any) {
    var i = 0;
    var xArray = [],
      ohlcArray = [],
      vArray = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.day);
      ohlcArray.push([obj.time, (obj.face / obj.count) * 100]);
      vArray.push([obj.time, obj.count]);
    });
    const chartOptions = {
      title: { text: `版位：${this.selectDevice.name}` },
      chart: {
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      rangeSelector: {
        selected: 1
      },
      xAxis: {
        categories: xArray,
        labels: {
          rotation: -45
        }
      },
      yAxis: [
        {
          labels: {
            align: "right",
            x: -3
          },
          title: {
            text: "回頭率(%)"
          },
          height: "60%",
          lineWidth: 2
        },
        {
          labels: {
            align: "right",
            x: -3
          },
          title: {
            text: "人流"
          },
          top: "65%",
          height: "35%",
          offset: 0,
          lineWidth: 2
        }
      ],
      series: [
        {
          type: "line",
          name: "回頭率(%)",
          yAxis: 0,
          data: ohlcArray
        },
        {
          type: "column",
          name: "人流",
          yAxis: 1,
          data: vArray
        }
      ]
    };
    Highcharts.chart("containerChart1", chartOptions);
  }
  drawChart2(objs: any) {
    var i = 0;
    var xArray = [],
      ohlcArray = [],
      vArray = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.time);
      ohlcArray.push([obj.time, (obj.face / obj.count) * 100]);
      vArray.push([obj.time, obj.count]);
    });
    const chartOptions = {
      title: { text: `版位：${this.selectDevice.name}` },
      chart: {
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      rangeSelector: {
        selected: 1
      },
      xAxis: {
        categories: xArray,
        labels: {
          rotation: -45
        }
      },
      yAxis: [
        {
          labels: {
            align: "right",
            x: -3
          },
          title: {
            text: "回頭率(%)"
          },
          height: "60%",
          lineWidth: 2
        },
        {
          labels: {
            align: "right",
            x: -3
          },
          title: {
            text: "人流"
          },
          top: "65%",
          height: "35%",
          offset: 0,
          lineWidth: 2
        }
      ],
      series: [
        {
          type: "line",
          name: "回頭率(%)",
          yAxis: 0,
          data: ohlcArray
        },
        {
          type: "column",
          name: "人流",
          yAxis: 1,
          data: vArray
        }
      ]
    };
    Highcharts.chart("containerChart2", chartOptions);
  }
}
