import { StateService } from "@uirouter/angularjs";
import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { IDevice, IAgency } from "../models/models";
import * as moment from "moment-timezone";

interface IApplicationCredentialListViewModel {
  applicationcredentials: Array<any>;
  authentication: any;
}

export class ApplicationCredentialListsCtrl
  implements IApplicationCredentialListViewModel, angular.IController {
  applicationcredentials: any[];
  authentication: any;

  private readonly logger: ILogger;

  static $inject = ["datacontext", "common"];

  $onInit() {
    this.logger.log(this.applicationcredentials);
  }

  constructor(datacontext: Datacontext, common: any) {
    this.logger = common.logger;
  }
}

interface IApplicationCredentialCreateEditViewModel {
  applicationcredential: IDevice;
  agencies: IAgency;

  authentication: any;

  save(): void;
}

export class ApplicationCredentialCreateEditCtrl
  implements IApplicationCredentialCreateEditViewModel, angular.IController {
  isEdit: boolean;

  applicationcredential: any;
  agencies: IAgency;

  authentication: any;

  private logger: ILogger;
  private applicationCredentialService: any;

  static $inject = [
    "datacontext",
    "common",
    "$stateParams",
    "$state"
  ];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state: StateService
  ) {
    this.logger = common.logger;
    this.applicationCredentialService = datacontext.applicationCredentials();
  }

  $onInit() {
    // 判斷為編輯還是新增播放清單
    this.isEdit = this.$stateParams.id !== undefined;

    if (this.isEdit) {
      this.applicationcredential.$promise.then(value => {
        //value.volume = value.volume.toString();
        //value.commodity.type = value.commodity.type.toString();
        this.applicationcredential = value;
      });
    } else {
      this.applicationcredential = {
        id: 0,
        name: null,
        appId: null,
        appSecret: null,
        issuedUtc:  moment(),
        expiresUtc: moment().add(1, "year"),
        active: true,
        agencyId: null,
        isExpired: false
      };
    }
  }

  save(): void {
    if (this.isEdit) {
      this.applicationCredentialService.update(
        { id: this.applicationcredential.id },
        this.applicationcredential,
        (value, responseHeaders) => {
          this.logger.success(
            "Application Credential has Update!",
            value,
            "Good job!"
          );
          this.$state.go("applicationCredentials.list");
        }
      );
    } else {
      this.applicationCredentialService.save(
        null,
        this.applicationcredential,
        (value, responseHeaders) => {
          this.applicationcredential = value;
          this.isEdit = true;
          this.logger.success(
            "Application Credential has Create!",
            value,
            "Good job!"
          );
          this.$state.go("applicationCredentials.list");
        }
      );
    }
  }
}
