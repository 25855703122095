import * as angular from "angular";

export interface IStatusCounts {
  totalCount: number;
  enableCount: number;
  disableCount: number;
  expiredCount: number;

  calculate(devices: Array<any>, $filter: ng.IFilterService): void;
}

export class StatusCounts implements IStatusCounts {
  totalCount: number;
  enableCount: number;
  disableCount: number;
  expiredCount: number;

  constructor() {
    this.totalCount = 0;
    this.enableCount = 0;
    this.disableCount = 0;
    this.expiredCount = 0;
  }

  calculate(devices: Array<any>, $filter: ng.IFilterService): void {
    this.totalCount = devices.length;
    if (this.totalCount === 0) {
      this.enableCount = 0;
      this.disableCount = 0;
      this.expiredCount = 0;
    } else {
      const enableDevices = $filter("filter")(
        devices,
        { heartbeat: true },
        true
      );
      if (angular.isArray(enableDevices)) {
        this.enableCount = enableDevices.length;
        this.disableCount = this.totalCount - this.enableCount;
      }

      const expiredDevices = $filter("filter")(
        devices,
        { isExpired: true },
        true
      );
      if (angular.isArray(expiredDevices)) {
        this.expiredCount = expiredDevices.length;
      }
    }
  }
}
