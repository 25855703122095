import { StateProvider } from "@uirouter/angularjs";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("smartshelves.skintypes", {
      abstract: true,
      url: "/skintypes",
      template: require("../../views/common/sub_content.html"),
      data: { pageTitle: "SkinTypes", requiresLogin: true }
    })
    .state("smartshelves.skintypes.list", {
      url: "/lists",
      template: '<skintype-list skintypes="$resolve.skintypes" authentication="authentication"></skintype-list>',
      resolve: {
        skintypes: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.skintypes().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          {
            serie: true,
            files: [rootUrl + "Scripts/plugins/dataTables/datatables.min.js", rootUrl + "Content/plugins/dataTables/datatables.min.css"]
          },
          {
            serie: true,
            name: "datatables",
            files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"]
          },
          {
            serie: true,
            name: "datatables.buttons",
            files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"]
          }
        ])
        ]
      }
    })
    .state("smartshelves.skintypes.create", {
      url: "/create",
      template: '<skintype-edit skintype="" agencies="$resolve.agencies" authentication="authentication"></skintype-edit>',
      resolve: {
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { name: "angularFileUpload", files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"] },
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    })
    .state("smartshelves.skintypes.edit", {
      url: "/{id:int}/edit",
      template: '<skintype-edit skintype="$resolve.skintype" agencies="$resolve.agencies" authentication="authentication"></skintype-edit>',
      resolve: {
        skintype: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext, $stateParams) => datacontext.skintypes().get({ id: $stateParams.id })],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { name: "angularFileUpload", files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"] },
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    });
}
