import * as angular from "angular";
import { routeConfig } from "./config.route";
import { DeviceListsCtrl, DeviceEditCtrl, DeviceListTable } from "./device";

export default angular
  .module("app.device", [
    // Angular modules

    // Custom modules

    // 3rd Party Modules
    "geocoder"
  ])
  .config(routeConfig)
  .component("deviceListTable", {
    template: require("./views/list.table.html"),
    controller: DeviceListTable,
    bindings: {
      devices: "<",
      authentication: "<"
    }
  })
  .component("deviceList", {
    template: require("./views/lists.html"),
    controller: DeviceListsCtrl,
    bindings: {
      devices: "<",
      nolicensedevices: "<",
      authentication: "<"
    }
  })
  .component("deviceEdit", {
    template: require("./views/edit.html"),
    controller: DeviceEditCtrl,
    bindings: {
      device: "<",
      projectors: "<",
      agencies: "<",
      boards: "<",
      licenseusedlogs:"<",
      authentication: "<"
    }
  });
