import { StateProvider } from "@uirouter/angularjs";
routeConfig.$inject = ["$stateProvider"];

export function routeConfig($stateProvider: StateProvider): void {
  $stateProvider.state("smartshelves.naturalbeauty", {
    abstract: true,
    url: "/naturalbeauty",
    template: require("../../views/common/sub_content.html"),
    data: { pageTitle: "NaturalBeauty", requiresLogin: true }
  });
}
