import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["$stateProvider"];

export function routeConfig($stateProvider: StateProvider): void {
  $stateProvider
    .state("account", {
      abstract: true,
      url: "/account",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Account", requiresLogin: true }
    })
    .state("account.manage", {
      url: "/manage?accessToken",
      template:
        '<account-manage authentication="authentication"></account-manage>'
    })
    .state("account.changepassword", {
      url: "/changepassword",
      template:
        '<account-changepassword authentication="authentication"></account-changepassword>'
    });
}
