
  export interface IUser extends angular.resource.IResource<any> {
  }

  export interface IUserResource extends angular.resource.IResourceClass<IUser> {
    createUser: angular.resource.IResourceMethod<IUser>;
    update(user): IUser;
    addUserProfile: angular.resource.IResourceMethod<IUser>;
    assignRolesToUser: angular.resource.IResourceMethod<IUser>;
  }

  export interface IAccountService {
    users(): IUserResource;
  }

  export class AccountService implements IAccountService {
    static $inject = ["$resource", "rootUrl"];

    constructor(private readonly $resource: angular.resource.IResourceService, private readonly rootUrl: string) {
    }
    users(): IUserResource {
      const url = this.rootUrl + "api/Users/";

      const changePasswordAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "ChangePassword/"
      };
      const addPhoneNumberAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "AddPhoneNumber/"
      };
      const removePhoneNumberAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "RemovePhoneNumber/"
      };
      const verifyPhoneNumberAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "VerifyPhoneNumber/"
      };

      const sendVerifyEmailCodeAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "SendVerifyEmailCode/"
      };

      const verifyEmailAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "VerifyEmail/"
      };

      const enableTwoFactorAuthenticationAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "EnableTwoFactorAuthentication/"
      };
      const disableTwoFactorAuthenticationAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "DisableTwoFactorAuthentication/"
      };
      const enableGoogleAuthenticatorAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "EnableGoogleAuthenticator/"
      };
      const disableGoogleAuthenticatorAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "DisableGoogleAuthenticator/"
      };
      const verifyGoogleAuthenticatorAction: angular.resource.IActionDescriptor = {
        method: "POST",
        url: url + "VerifyGoogleAuthenticator/"
      };

      return this.$resource(url + ":id", { id: "@id" },
        {
          changePassword: changePasswordAction,
          addPhoneNumber: addPhoneNumberAction,
          removePhoneNumber: removePhoneNumberAction,
          verifyPhoneNumber: verifyPhoneNumberAction,
          sendVerifyEmailCode: sendVerifyEmailCodeAction,
          verifyEmail: verifyEmailAction,
          enableTwoFactorAuthentication: enableTwoFactorAuthenticationAction,
          disableTwoFactorAuthentication: disableTwoFactorAuthenticationAction,
          enableGoogleAuthenticator: enableGoogleAuthenticatorAction,
          disableGoogleAuthenticator: disableGoogleAuthenticatorAction,
          verifyGoogleAuthenticator: verifyGoogleAuthenticatorAction
        }) as IUserResource;
    }
  }
