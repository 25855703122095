import * as angular from "angular";
import { routeConfig } from "./config.route";
import { MediaList, MediaCreatEdit, MediaView } from "./media";

export default angular
  .module("app.media", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .controller("MediaList", MediaList)
  .controller("MediaCreatEdit", MediaCreatEdit)
  .controller("MediaView", MediaView);
  ;
