import * as angular from "angular";
import { routeConfig } from "./config.route";
import { gridsterOpts, LayoutListsCtrl, LayoutviewCtrl, LayoutCreateEditCtrl } from "./layout";

export default angular.module("app.admin.layout", [])
  .config(routeConfig)
  .constant("gridsterOpts", gridsterOpts)
  .controller("LayoutList", LayoutListsCtrl)
  .controller("LayoutView", LayoutviewCtrl)
  .controller("LayoutCreateEdit", LayoutCreateEditCtrl);
