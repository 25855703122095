import { ILicenseInformation, IConfiguration, IAgency, IBoard, IDevice, IProjector, ICustomer, IOrderSetting } from "../models/models";

export interface IDatacontext {
  serverLicense(): angular.resource.IResourceClass<ILicenseResource>;
  configurations(): angular.resource.IResourceClass<IConfigurationResource>;
  notifyAccounts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  agencies(): angular.resource.IResourceClass<IAgencyResource>;
  contracts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  licenses(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  boards(): angular.resource.IResourceClass<IBoardResource>;
  devices(): angular.resource.IResourceClass<IDeviceResource>;

  projectors(): angular.resource.IResourceClass<IProjectorResource>;
  apps(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  ota(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  applicationCredentials(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  orderSettings(): angular.resource.IResourceClass<IOrderSettingResource>;
  customers(): angular.resource.IResourceClass<ICustomerResource>;

  medias(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  tags(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  playlists(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  chapters(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  layouts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  containers(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  rules(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  schedulers(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  events(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  playlogs(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  emergencyLogs(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  surveys(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  subjects(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  options(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  answerResultDefinitions(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  martConfigurations(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  martPurchasings(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  martTickets(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  martGames(): angular.resource.IResourceClass<angular.resource.IResource<any>>;

  storeConfigurations(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  storePopularProducts(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  storePreOrder(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  storeStudentArea(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  storeFourthArea(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  storeFourthAreaConfig(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
  storeSign(): angular.resource.IResourceClass<angular.resource.IResource<any>>;
}

// #region IResource 定義

export interface ILicenseResource extends angular.resource.IResourceClass<ILicenseInformation> {}

export interface IConfigurationResource extends angular.resource.IResourceClass<IConfiguration> {}

export interface IAgencyResource extends angular.resource.IResourceClass<IAgency> {
  update(agency): IAgency;
}

export interface IBoardResource extends angular.resource.IResourceClass<IBoard> {}

export interface IDeviceResource extends angular.resource.IResourceClass<IDevice> {}

export interface IProjectorResource extends angular.resource.IResourceClass<IProjector> {}

export interface ICustomerResource extends angular.resource.IResourceClass<ICustomer> {}

export interface IOrderSettingResource extends angular.resource.IResourceClass<IOrderSetting> {}

// #endregion

export class Datacontext implements IDatacontext {
  static $inject = ["$resource", "apiUrl"];

  constructor(private $resource: angular.resource.IResourceService, private apiUrl: string) {}

  serverLicense(): any {
    const url = this.apiUrl + "api/ServerLicense";
    return this.$resource(url);
  }

  configurations(): any {
    const url = this.apiUrl + "api/Configurations/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getSystemTimeZones: {
        method: "GET",
        url: url + "GetSystemTimeZones",
        isArray: true
      }
    });
  }

  notifyAccounts(): any {
    const url = this.apiUrl + "api/NotifyAccounts/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getNotifyAccountByName: {
        method: "GET",
        url: url + "GetNotifyAccountByName/:name",
        isArray: false
      },
      getNotifyAccountByAccessToken: {
        method: "GET",
        url: url + "GetNotifyAccountByAccessToken/:accessToken",
        isArray: false
      },
      setupUserAccount: {
        method: "POST",
        url: url + "SetupUserAccount/:accessToken"
      }
    });
  }

  apps(): any {
    const url = this.apiUrl + "api/DeviceApplications/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getLatestAppVersion: {
        method: "GET",
        url: url + "LatestAppVersion",
        isArray: true
      },
      setActive: { method: "POST", url: url + "SetActive/:id" },
      notifyUpdateAppVersion: {
        method: "POST",
        url: url + "NotifyUpdateAppVersion/:id/:deviceType/:operatingSystem"
      },
      sendOtaInformation: {
        method: "POST",
        url: url + "SendOtaInformation/:id"
      }
    });
  }

  ota(): any {
    const url = this.apiUrl + "api/OTA/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  applicationCredentials(): any {
    const url = this.apiUrl + "api/ApplicationCredentials/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  licenses(): any {
    const url = this.apiUrl + "api/Licenses/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      opening: { method: "POST", url: url + "Opening" },
      removeLicense: { method: "POST", url: url + "RemoveLicense" },
      getAvailableLicenses: { method: "Get", url: url + "GetAvailableLicenses", isArray: true },
      getAvailableLicensesByAgency: { method: "Get", url: url + "GetAvailableLicensesByAgency/:agencyId", isArray: true },
      getLicenseUsedLogByDevice: { method: "Get", url: url + "GetLicenseUsedLogByDevice/:deviceId", isArray: true }
    });
  }

  contracts(): any {
    const url = this.apiUrl + "api/Contracts/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getBy: { method: "GET", url: url + "GetBy/:agencyId", isArray: true }
    });
  }

  agencies(): any {
    const url = this.apiUrl + "api/Agencies/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  boards(): any {
    const url = this.apiUrl + "api/Boards/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  devices(): any {
    const url = this.apiUrl + "api/Devices/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getNoLicenseDevices: { method: "GET", url: url + "GetNoLicenseDevices", isArray: true },
      sendDevicePowerCommand: {
        method: "POST",
        url: url + "SendDevicePowerCommand/:id/:command"
      },
      sendScreenPowerCommand: {
        method: "POST",
        url: url + "SendScreenPowerCommand/:id/:command"
      },
      sendProjectorCommand: {
        method: "POST",
        url: url + "SendProjectorCommand/:id/:command"
      },
      sendScheduleProjectorCommand: {
        method: "POST",
        url: url + "SendScheduleProjectorCommand/:id"
      },
      sendDanMuCommand: {
        method: "POST",
        url: url + "SendDanMuCommand/:id/:command"
      },
      sendArduinoRelayCommand: {
        method: "POST",
        url: url + "SendArduinoRelayCommand/:id/:command"
      },
      sendVolumeCommand: {
        method: "POST",
        url: url + "SendVolumeCommand/:id/:volume"
      },
      sendDeviceRegister: {
        method: "POST",
        url: url + "SendDeviceRegister/:id"
      }
    });
  }

  projectors(): any {
    const url = this.apiUrl + "api/Projectors/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  orderSettings(): any {
    const url = this.apiUrl + "api/OrderSettings/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      getMyOrderSettings: {
        method: "GET",
        url: url + "GetMyOrderSettings",
        isArray: true
      },
      getOrderSettingStatuses: {
        method: "GET",
        url: url + "GetOrderSettingStatuses",
        isArray: true
      },
      getOrderSettingNoCount: {
        method: "GET",
        url: url + "GetOrderSettingNoCount",
        isArray: false
      },
      approval: { method: "POST", url: url + "Approval/:id" }
    });
  }

  customers(): any {
    const url = this.apiUrl + "api/Customers/";
    console.log(url);
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  medias(): any {
    const url = this.apiUrl + "api/Medias/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  tags(): any {
    const url = this.apiUrl + "api/Tags/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  playlists(): any {
    const url = this.apiUrl + "api/PlayLists/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      publish: { method: "POST", url: url + "Publish/:id" },
      publishMySchedulers: {
        method: "POST",
        url: url + "PublishMySchedulers/:id"
      },
      copy: { method: "POST", url: url + "Copy/:id" }
    });
  }

  chapters(): any {
    const url = this.apiUrl + "api/Chapters/:id";
    return this.$resource(url, null, {
      update: { method: "PUT" }
    });
  }

  layouts(): any {
    const url = this.apiUrl + "api/Layouts/:id";
    return this.$resource(url, null, {
      update: { method: "PUT" }
    });
  }

  containers(): any {
    const url = this.apiUrl + "api/Containers/:id";
    return this.$resource(url, null, {
      update: { method: "PUT" }
    });
  }

  rules(): any {
    const url = this.apiUrl + "api/Rules/:id";
    return this.$resource(url);
  }

  schedulers(): any {
    const url = this.apiUrl + "api/Schedulers/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" },
      publish: { method: "POST", url: url + "Publish/:id/false" },
      overrideAndPublish: { method: "POST", url: url + "Publish/:id/true" }
    });
  }

  events(): any {
    const url = this.apiUrl + "api/Events/";
    return this.$resource(url + ":id", null, {
      getEventsBy: { method: "GET", url: url + "GetEventsBy", isArray: true }
    });
  }

  playlogs(): any {
    const url = this.apiUrl + "api/Playlogs";
    return this.$resource(
      url, // + '?deviceUid=:deviceUid&timeFrom=:timeFrom&timeTo=:timeTo',
      {
        deviceUid: "@deviceUid", //'50d19929-e584-44f2-af8e-89984e523f85',
        timeFrom: "@timeFrom", //'2016-02-04',
        timeTo: "@timeTo" //'2016-12-01'
      },
      {}
    );
  }

  emergencyLogs(): any {
    const url = this.apiUrl + "api/EmergencyLogs/";
    return this.$resource(url + ":id", null, {
      getEmergencyLogsBy: {
        method: "GET",
        url: url + "GetEmergencyLogsBy/:boardId",
        isArray: true
      },
      disable: { method: "POST", url: url + "Disable/:id" }
    });
  }

  surveys(): any {
    const url = this.apiUrl + "api/Surveys/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  subjects(): any {
    const url = this.apiUrl + "api/Subjects/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  options(): any {
    const url = this.apiUrl + "api/Options/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  answerResultDefinitions(): any {
    const url = this.apiUrl + "api/AnswerResultDefinitions/";
    return this.$resource(url + ":id", null, {
      update: { method: "PUT" }
    });
  }

  martConfigurations(): any {
    const url = this.apiUrl + "api/MartConfiguration/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: url + "GetDeviceList",
        isArray: true
      },
      getDeviceInfoList: {
        method: "GET",
        url: url + "GetDeviceInfoList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteConfiguration: { method: "DELETE", url: url + "DeleteConfiguration" },
      createConfiguration: { method: "POST", url: url + "CreateConfiguration" },
      checkDevices: { method: "POST", url: url + "CheckDevices", isArray: true }
    });
  }
  martPurchasings(): any {
    const url = this.apiUrl + "api/MartPurchasing/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: this.apiUrl + "api/MartConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteActivity: { method: "DELETE", url: url + "DeleteActivity" },
      createActivity: { method: "POST", url: url + "CreateActivity" },
      checkDevices: {
        method: "POST",
        url: url + "CheckDevices",
        isArray: true
      },
      signDoc: { method: "POST", url: url + "SignDoc" },
      save: { method: "POST", url: url + "Save" },
      docCopy: { method: "GET", url: url + "DocCopy", isArray: true }
    });
  }
  martTickets(): any {
    const url = this.apiUrl + "api/MartTicket/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: this.apiUrl + "api/MartConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      createVirtualImage: { method: "POST", url: url + "CreateVirtualImage" },
      getImage: { method: "POST", url: url + "GetImage" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteActivity: { method: "DELETE", url: url + "DeleteActivity" },
      createActivity: { method: "POST", url: url + "CreateActivity" },
      releaseActivity: { method: "POST", url: url + "ReleaseActivity" },
      checkDevices: {
        method: "POST",
        url: url + "CheckDevices",
        isArray: true
      },
      signDoc: { method: "POST", url: url + "SignDoc" },
      save: { method: "POST", url: url + "Save" },
      docCopy: { method: "GET", url: url + "DocCopy", isArray: true }
    });
  }
  martGames(): any {
    const url = this.apiUrl + "api/MartGame/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: this.apiUrl + "api/MartConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteActivity: { method: "DELETE", url: url + "DeleteActivity" },
      createActivity: { method: "POST", url: url + "CreateActivity" },
      checkDevices: { method: "POST", url: url + "CheckDevices", isArray: true }
    });
  }

  storeConfigurations(): any {
    const url = this.apiUrl + "api/StoreConfiguration/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: url + "GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteConfiguration: {method: "DELETE", url: url + "DeleteConfiguration"},
      createConfiguration: { method: "POST", url: url + "CreateConfiguration" },
      checkDevices: {
        method: "POST",
        url: url + "CheckDevices",
        isArray: true
      },
      getDeviceInfoList: {
        method: "GET",
        url: url + "GetDeviceInfoList",
        isArray: true
      }
    });
  }
  storePopularProducts(): any {
    const url = this.apiUrl + "api/StorePopularProduct/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: this.apiUrl + "api/StoreConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deletePopularProduct: {
        method: "DELETE",
        url: url + "DeletePopularProduct"
      },
      createPopularProduct: {
        method: "POST",
        url: url + "CreatePopularProduct"
      },
      signDoc: { method: "POST", url: url + "SignDoc" },
      save: { method: "POST", url: url + "Save" },
      docCopy: { method: "GET", url: url + "DocCopy", isArray: true },
        bulletin: { method: "POST", url: url + "Bulletin1" },
        bulletinUpdateImages: { method: "POST", url: url + "Bulletin1UpdateImages" }
    });
  }
  storePreOrder(): any {
    const url = this.apiUrl + "api/StorePreOrder/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: this.apiUrl + "api/StoreConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deletePreOrder: { method: "DELETE", url: url + "DeletePreOrder" },
      createPreOrder: { method: "POST", url: url + "CreatePreOrder" },
      signDoc: { method: "POST", url: url + "SignDoc" },
      save: { method: "POST", url: url + "Save" },
      docCopy: { method: "GET", url: url + "DocCopy", isArray: true },
        bulletin: { method: "POST", url: url + "Bulletin3" },
        bulletinUpdateImages: { method: "POST", url: url + "Bulletin3UpdateImages" }
    });
  }

  storeFourthArea(): any {
    const url = this.apiUrl + "api/StoreFourthArea/";
    return this.$resource(url + ":id", null, {
      getActivityList: {
        method: "GET",
        url: url + "GetActivityList",
        isArray: true
      },
      getActivity: { method: "GET", url: url + "GetActivity" },
      getDeviceList: {
        method: "GET",
		url: this.apiUrl + "api/StoreConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteActivity: { method: "DELETE", url: url + "DeleteActivity" },
      createActivity: { method: "POST", url: url + "CreateActivity" },
      signDoc: { method: "POST", url: url + "SignDoc" },
      save: { method: "POST", url: url + "Save" },
      docCopy: { method: "GET", url: url + "DocCopy", isArray: true }
    });
  }

  storeFourthAreaConfig(): any {
    const url = this.apiUrl + "api/StoreFourthAreaConfig/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
		url: this.apiUrl + "api/StoreConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteConfiguration: {
        method: "DELETE",
        url: url + "DeleteConfiguration"
      },
      createConfiguration: { method: "POST", url: url + "CreateConfiguration" },
      getDeviceInfoList: {
        method: "GET",
        url: url + "GetDeviceInfoList",
        isArray: true
      }
    });
  }

  storeStudentArea(): any {
    const url = this.apiUrl + "api/StoreGame1/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
        url: this.apiUrl + "api/StoreConfiguration/GetDeviceList",
        isArray: true
      },
      update: { method: "PUT" },
      deleteImage: { method: "DELETE", url: url + "DeleteImage" },
      deleteActivity: { method: "DELETE", url: url + "DeleteActivity" },
      createActivity: { method: "POST", url: url + "CreateActivity" },
      bulletin: { method: "POST", url: url + "Bulletin2" },
      bulletinUpdateImages: { method: "POST", url: url + "Bulletin2UpdateImages" }
    });
  }

  storeSign(): any {
    const url = this.apiUrl + "api/StoreSign/";
    return this.$resource(url + ":id", null, {
      getWaitSignDocList: {
        method: "GET",
        url: url + "GetWaitSignDocList",
        isArray: true
      }
    });
  }

  humancountboardConfig(): any {
    const url = this.apiUrl + "api/HumancountboardConfig/";
    return this.$resource(url + ":id", null, {
      getDeviceList: {
        method: "GET",
        url: url + "GetDeviceList",
        isArray: true
      },
      deleteConfiguration: {
        method: "DELETE",
        url: url + "DeleteConfiguration"
      },
      createConfiguration: { 
        method: "POST", 
        url: url + "CreateConfiguration" 
      }
    });
  }
}
