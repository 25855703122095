import { StateProvider } from "@uirouter/angularjs";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(
  rootUrl: string,
  $stateProvider: StateProvider
): void {
  $stateProvider
    .state("smartshelves.surveyresultdefinitions", {
      abstract: true,
      url: "/surveyresultdefinitions",
      template: require("../../views/common/sub_content.html"),
      data: { pageTitle: "Survey Result Definitions", requiresLogin: true }
    })
    .state("smartshelves.surveyresultdefinitions.list", {
      url: "/lists",
      template:
        '<surveyresultdefinition-list surveyresultdefinitions="$resolve.surveyresultdefinitions"></surveyresultdefinition-list>',
      resolve: {
        surveyresultdefinitions: [
          "smartShelvesDatacontext",
          (datacontext: SmartShelvesDatacontext) =>
            datacontext.surveyResultDefinitions().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                serie: true,
                files: [
                  rootUrl + "Scripts/plugins/dataTables/datatables.min.js",
                  rootUrl + "Content/plugins/dataTables/datatables.min.css"
                ]
              },
              {
                serie: true,
                name: "datatables",
                files: [
                  rootUrl +
                  "Scripts/plugins/dataTables/angular-datatables.min.js"
                ]
              },
              {
                serie: true,
                name: "datatables.buttons",
                files: [
                  rootUrl +
                  "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("smartshelves.surveyresultdefinitions.create", {
      url: "/create",
      template:
        '<surveyresultdefinition-edit surveyresultdefinition="" surveys="$resolve.surveys" products="$resolve.products" skintypes="$resolve.skintypes"></surveyresultdefinition-edit>',
      resolve: {
        surveys: ["datacontext", "$stateParams", (datacontext: Datacontext) => datacontext.surveys().query()],
        products: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.products().query()],
        skintypes: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext) => datacontext.skintypes().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          {
            name: "angularFileUpload",
            files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"]
          },
          {
            name: "xeditable",
            files: [
              rootUrl + "assets/plugins/angular-xeditable/css/xeditable.min.css",
              rootUrl + "assets/plugins/angular-xeditable/js/xeditable.min.js"
            ]
          },
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    })
    .state("smartshelves.surveyresultdefinitions.edit", {
      url: "/{id:int}/edit",
      template:
        '<surveyresultdefinition-edit surveyresultdefinition="$resolve.surveyresultdefinition" surveys="$resolve.surveys" products="$resolve.products" skintypes="$resolve.skintypes"></surveyresultdefinition-edit>',
      resolve: {
        surveyresultdefinition: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext, $stateParams) => datacontext.surveyResultDefinitions().get({ id: $stateParams.id })],
        surveys: ["datacontext", "$stateParams", (datacontext: Datacontext) => datacontext.surveys().query()],
        products: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.products().query()],
        skintypes: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext) => datacontext.skintypes().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          {
            name: "angularFileUpload",
            files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"]
          },
          {
            name: "xeditable",
            files: [
              rootUrl + "assets/plugins/angular-xeditable/css/xeditable.min.css",
              rootUrl + "assets/plugins/angular-xeditable/js/xeditable.min.js"
            ]
          },
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    });
}
