import { ILogger } from "../../blocks/logger/logger";
import { IAgency } from "../../models/models";

export var gridsterOpts = {
  columns: 192, // the width of the grid, in columns
  pushing: false, // whether to push other items out of the way on move or resize
  floating: false, // whether to automatically float items up so they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
  swapping: false, // whether or not to have items of the same size switch places instead of pushing down if they are the same size
  width: "auto", // can be an integer or 'auto'. 'auto' scales gridster to be the full width of its containing element
  colWidth: "8", // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
  rowHeight: "match", // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
  margins: [5, 5], // the pixel distance between each widget
  outerMargin: true, // whether margins apply to outer edges of the grid
  sparse: false, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
  isMobile: false, // stacks the grid items if true
  mobileBreakPoint: 600, // if the screen is not wider that this, remove the grid layout and stack the items
  mobileModeEnabled: true, // whether or not to toggle mobile mode when screen width is less than mobileBreakPoint
  minColumns: 192, // the minimum columns the grid must have
  minRows: 108, // the minimum height of the grid, in rows
  maxRows: 108,
  defaultSizeX: 10, // the default width of a gridster item, if not specifed
  defaultSizeY: 10, // the default height of a gridster item, if not specified
  minSizeX: 1, // minimum column width of an item
  maxSizeX: null, // maximum column width of an item
  minSizeY: 1, // minumum row height of an item
  maxSizeY: null, // maximum row height of an item
  resizable: {
    enabled: false
  },
  draggable: {
    enabled: false
  }
};

export interface ILayoutListViewModel {
  layouts: Array<any>;
  del(id: number): void;
}

export class LayoutListsCtrl implements ILayoutListViewModel {
  layouts: any[];

  private logger: ILogger;
  private layoutService: any;

  static $inject = ["layouts", "common", "datacontext", "$filter"];
  constructor(layouts, common, datacontext, private $filter) {
    this.layouts = layouts;
    this.logger = common.logger;
    this.layoutService = datacontext.layouts();
  }

  del(id: number): void {
    this.layoutService.delete(
      { id: id },
      value => {
        var indx = this.$filter("findIndexByKeyValue")(this.layouts, "id", id);
        this.layouts.splice(indx, 1);
        this.logger.success(
          "Layout has be deleted",
          value,
          "Delete Layout Success"
        );
      },
      httpResponse => {
        this.logger.error(
          `Layout can't delete, layout ID is ${id}`,
          httpResponse,
          "Delete Layout Error"
        );
      }
    );
  }
}

interface ILayoutViewModel {
  layout: any;
  gridsterOpts: any;
}

export class LayoutviewCtrl implements ILayoutViewModel {
  layout: any;
  gridsterOpts: any;

  static $inject = ["common", "layout", "gridsterOpts"];
  constructor(common, layout, gridsterOpts) {
    const logger = common.logger;

    this.gridsterOpts = gridsterOpts;

    layout.$promise.then(data => {
      this.layout = data;

      if (this.layout.orientation === 1) {
        this.gridsterOpts.columns = 108;
        this.gridsterOpts.minRows = 192;
        this.gridsterOpts.maxRows = 192;
      }
    });

    logger.log(layout);
  }
}

interface ILayoutCreateEditViewModel {
  layout: any;
  gridsterOpts: any;

  agencies: IAgency;

  addContainer(): void;
  removeContainer(indx: number): void;
  save(): void;
}

export class LayoutCreateEditCtrl implements ILayoutCreateEditViewModel {
  layout: any;
  gridsterOpts: any;

  agencies: IAgency;

  private isEdit: boolean;
  private logger: ILogger;
  private layoutService: any;

  private delContainers: Array<any>;

  static $inject = [
    "gridsterOpts",
    "datacontext",
    "common",
    "agencies",
    "$state",
    "$stateParams"
  ];

  constructor(
    gridsterOpts,
    datacontext,
    common,
    agencies,
    private $state,
    $stateParams
  ) {
    // 判斷為編輯還是新增播放清單
    this.isEdit = $stateParams.id !== undefined;

    this.logger = common.logger;
    this.layoutService = datacontext.layouts();

    this.agencies = agencies;

    this.gridsterOpts = gridsterOpts;
    this.gridsterOpts.resizable.enabled = true;
    this.gridsterOpts.draggable.enabled = true;

    if (this.isEdit) {
      datacontext
        .layouts()
        .get({ id: $stateParams.id })
        .$promise.then(data => {
          this.layout = data;

          if (this.layout.orientation === 1) {
            this.gridsterOpts.columns = 108;
            this.gridsterOpts.minRows = 192;
            this.gridsterOpts.maxRows = 192;
          }
        });
    } else {
      this.layout = {
        containerSettings: [
          {
            id: 0,
            no: 1,
            sizeX: 192,
            sizeY: 108,
            row: 0,
            col: 0,
            type: "Media"
          }
        ],
        orientation: $stateParams.orientation,
        containerQuantity: 1,
        category: "Customization"
      };

      if (this.layout.orientation === 1) {
        this.gridsterOpts.columns = 108;
        this.gridsterOpts.minRows = 192;
        this.gridsterOpts.maxRows = 192;

        this.layout.containerSettings[0].sizeX = 108;
        this.layout.containerSettings[0].sizeY = 192;
      }
    }

    this.delContainers = [];
  }

  addContainer(): void {
    if (this.layout.containerQuantity < 8) {
      const container = {
        id: 0,
        no: this.layout.containerSettings.length + 1,
        sizeX: 10,
        sizeY: 10,
        type: "Media"
      };

      this.layout.containerSettings.push(container);
      this.layout.containerQuantity = this.layout.containerSettings.length;
    } else {
      this.logger.warning("最多只能設定 8 個區塊!", null, "新增區塊錯誤");
    }
  }

  removeContainer(indx: number): void {
    const delContainer = this.layout.containerSettings.splice(indx, 1);

    if (delContainer[0].id !== 0) this.delContainers.push(delContainer);

    for (let i = 0; i < this.layout.containerSettings.length; i++) {
      this.layout.containerSettings[i].no = i + 1;
    }

    this.layout.containerQuantity = this.layout.containerSettings.length;
  }

  save(): void {
    if (this.layout.containerQuantity > 0) {
      if (this.isEdit) {
        this.layoutService.update(
          { id: this.layout.id },
          this.layout,
          (value, responseHeaders) => {
            this.$state.go("admin.layouts.list");
          },
          httpResponse => {
            this.logger.log(httpResponse);
          }
        );
      } else {
        this.layoutService.save(
          null,
          this.layout,
          (value, responseHeaders) => {
            this.$state.go("admin.layouts.list");
          },
          httpResponse => {
            this.logger.log(httpResponse);
          }
        );
      }
    } else {
      this.logger.warning("最少要設定 1 個區塊!", null, "儲存版型");
    }
  }
}
