import { StateProvider } from "@uirouter/angularjs";
routeConfig.$inject = ["rootUrl", "$stateProvider"];

  export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
    $stateProvider
      .state("autoLogin",
        {
          url: "/autoLogin?u&p",
          params: {
            u: null,
            p: null
          },
          template: require("./views/auto_login.html"),
          controller: "AutoLoginController",
          data: { pageTitle: "Login", specialClass: "gray-bg" },
          resolve: {
            loadPlugin: [
              "$ocLazyLoad", $ocLazyLoad => $ocLazyLoad.load([
                {
                  files: [rootUrl + "assets/plugins/crypto-js/crypto-js.js"]
                }
              ])
            ]
          }
        })
      .state("login",
        {
          url: "/login",
          template: require("./views/login.html"),
          controller: "LoginController",
          data: { pageTitle: "Login", specialClass: "gray-bg" },
          resolve: {
            loadPlugin: [
              "$ocLazyLoad", $ocLazyLoad => $ocLazyLoad.load([
                {
                  files: [rootUrl + "assets/plugins/crypto-js/crypto-js.js"]
                },
                {
                  name: "vcRecaptcha",
                  files: [rootUrl + "assets/plugins/angular-recaptcha/angular-recaptcha.min.js"]
                }
              ])
            ]
          }
        })
      .state("register",
        {
          url: "/register_under",
          template: require("./views/register.html"),
          controller: "SignUpController",
          data: { pageTitle: "Register", specialClass: "gray-bg" }
        })
      .state("lockscreen",
        {
          url: "/lockscreen",
          template: require("./views/lockscreen.html"),
          data: { pageTitle: "Lockscreen", specialClass: "gray-bg" }
        })
      .state("forgot_password",
        {
          url: "/forgot_password",
          template: require("./views/forgot_password.html"),
          controller: "ForgotPasswordController",
          data: { pageTitle: "Forgot password", specialClass: "gray-bg" }
        })
      .state("reset_password",
        {
          url: "/reset_password?userId&code",
          template: require("./views/reset_password.html"),
          controller: "ResetPasswordController",
          resolve: {
            userId: ["$stateParams", $stateParams => $stateParams.userId],
            code: ["$stateParams", $stateParams => $stateParams.code]
          },
          data: { pageTitle: "Reset password", specialClass: "gray-bg" }
        })

      .state("send_code",
        {
          url: "/sendcode",
          template: require("./views/send_code.html"),
          controller: "SendCodeController",
          data: { pageTitle: "SendCode", specialClass: "gray-bg" }
        })
      .state("verifyOTP",
        {
          url: "/verifyOTP",
          template: require("./views/verify_OTP.html"),
          controller: "VerifyOTPController",
          data: { pageTitle: "verifyOTP", specialClass: "gray-bg" },
          params: {
            provider: null
          }
        });
  }

