import { StateService } from "@uirouter/angularjs";
import Swal from "sweetalert2";
import * as moment from "moment-timezone";
import * as R from "ramda";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { IDevice, IProjector, IAgency, IBoard } from "../models/models";
import { FunctionalModule } from "../models/enums";

export class DeviceListTable implements angular.IComponentController {
  devices: any[];
  authentication: any;

  dtOptions: any;
  dtColumnDefs: any;

  private readonly deviceService: any;
  private readonly logger: ILogger;

  static $inject = [ "datacontext", "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder" ];

  $onInit() {

  }

  constructor(datacontext: Datacontext, common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder) {
    this.deviceService = datacontext.devices();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "DevicesFile" },
        { extend: "pdf", title: "DevicesFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];


  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.deviceService.delete({ id: id }, () => {
            var indx = this.$filter("findIndexByKeyValue")(this.devices, "id", id);
            this.devices.splice(indx, 1);
            Swal.fire("Deleted!", "Your Device has been deleted.", "success");
          },
          httpResponse => {
            this.logger.error(`Device can't delete, device ID is ${id}`, httpResponse, "Delete Device Error");
            Swal.fire("Cancelled", "Device can't delete", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Device file is safe :)", "success");
      }
    });
  }

}

export class DeviceListsCtrl implements angular.IComponentController {
  devices: any[];
  nolicensedevices: any[];

  availableDevices: any[];
  disableDevices: any[];
  expiredDevices: any[];

  authentication: any;

  $onInit() {
    this.devices.$promise.then(values => {
      this.disableDevices = R.filter((device: IDevice) => device.disabled, values);
      
      this.expiredDevices = R.filter((device: IDevice) => device.isExpired, values);

      this.availableDevices = R.filter((device: IDevice) => !device.disabled, values);
    });
  }
}

interface IDeviceEditViewModel {
  device: IDevice;
  projectors: IProjector;
  agencies: IAgency;
  boards: IBoard;
  licenseusedlogs: any;
  availableLicenses: any;

  functionalModule: any;

  authentication: any;

  save(): void;

  sendDeviceRegister(): void;

  notifyUpdateAppVersion(deviceType): void;
  sendOTAInformation(): void;

  sendDevicePowerCommand(command: string): void;
  sendScreenPowerCommand(command: string): void;

  sendDanMuCommand(command: string): void;
  sendArduinoRelayCommand(command: string): void;

  sendVolumeCommand(volume: number): void;

  sendProjectorCommand(command: string): void;

  setProjectorSchedule(): void;

  hasFunctionalModule(devicefunctionalModule: FunctionalModule, functionalModule: FunctionalModule): boolean;

  functionalModuleChange(): void;

  removeLicense(): void;
}

export class DeviceEditCtrl implements IDeviceEditViewModel, angular.IComponentController {
  device: any;
  projectors: IProjector;
  agencies: IAgency;
  boards: IBoard;
  licenseusedlogs: any;
  availableLicenses: any;

  license: any;

  authentication: any;

  functionalModule: any;
  private logger: ILogger;
  private deviceService: any;
  private appService: any;
  private licenseService: any;

  static $inject = ["datacontext", "common", "$state"];
  constructor(datacontext: Datacontext, common: any, private $state: StateService) {
    this.logger = common.logger;
    this.deviceService = datacontext.devices();
    this.appService = datacontext.apps();
    this.licenseService = datacontext.licenses();
  }

  $onInit() {
    this.device.$promise.then(value => {
      if (value.licenseId) {
        this.licenseService.get({ id: value.licenseId }).$promise.then(l => {
          l.openingUtc = moment.utc(l.openingUtc).local();
          this.license = l;
        });

        if (value.agencyId) {
          this.availableLicenses = this.licenseService.getAvailableLicensesByAgency({ agencyId: value.agencyId });
        }
      }

      value.volume = value.volume.toString();

      //value.commodity.type = value.commodity.type.toString();

      this.functionalModule = {
        None: this.hasFunctionalModule(value.functionalModule, FunctionalModule.None),
        MediaPlayer: this.hasFunctionalModule(value.functionalModule, FunctionalModule.MediaPlayer),
		  FaceDetection: this.hasFunctionalModule(value.functionalModule, FunctionalModule.FaceDetection),
		  MartApp: this.hasFunctionalModule(value.functionalModule, FunctionalModule.MartApp),
		  StoreApp: this.hasFunctionalModule(value.functionalModule, FunctionalModule.StoreApp),
      };

      this.device = value;
    });
  }

  functionalModuleChange(): void {
    if (this.functionalModule.None)
      this.device.functionalModule |= FunctionalModule.None;
    else this.device.functionalModule &= ~FunctionalModule.None;

    if (this.functionalModule.MediaPlayer)
      this.device.functionalModule |= FunctionalModule.MediaPlayer;
    else this.device.functionalModule &= ~FunctionalModule.MediaPlayer;

    if (this.functionalModule.FaceDetection)
      this.device.functionalModule |= FunctionalModule.FaceDetection;
	  else this.device.functionalModule &= ~FunctionalModule.FaceDetection;

	  if (this.functionalModule.MartApp)
		  this.device.functionalModule |= FunctionalModule.MartApp;
	  else this.device.functionalModule &= ~FunctionalModule.MartApp;

	  if (this.functionalModule.StoreApp)
		  this.device.functionalModule |= FunctionalModule.StoreApp;
	  else this.device.functionalModule &= ~FunctionalModule.StoreApp;

    this.logger.log(this.device.functionalModule);
  }

  hasFunctionalModule(deviceFunctionalModule: FunctionalModule, functionalModule: FunctionalModule): boolean {
    return (deviceFunctionalModule & functionalModule) === functionalModule;
  }

  save(): void {
    this.deviceService.update({ id: this.device.id }, this.device,
      () => {
        this.$state.go("devices.list");
      },
      httpResponse => {
        this.logger.log(httpResponse);
      }
    );
  }

  sendDeviceRegister(): void {
    this.device.$sendDeviceRegister({ id: this.device.id },
      () => {
        this.logger.success(`Send Register Data to Device!`);
      },
      httpResponse => {
        this.logger.error(
          "Can not send register data to Device",
          httpResponse,
          "Send Register Data Error"
        );
      }
    );
  }

  notifyUpdateAppVersion(deviceType): void {
    this.appService.notifyUpdateAppVersion(
      {
        id: this.device.id,
        deviceType: deviceType,
        operatingSystem: this.device.operatingSystem
      },
      null,
      value => {
        this.device.hasUpdate = true;
        this.logger.success(
          "Device has notified",
          value,
          "Update App Version Success"
        );
      },
      httpResponse => {
        this.device.hasUpdate = false;
        this.logger.error(
          "Can not notify this Device",
          httpResponse,
          "Update App Version Error"
        );
      }
    );
  }

  sendOTAInformation(): void {
    this.appService.sendOtaInformation(
      { id: this.device.id },
      null,
      value => {
        this.logger.success("Device has notified", value, "Update OTA Success");
      },
      httpResponse => {
        this.logger.error(
          "Can not notify this Device",
          httpResponse,
          "Update OTA Error"
        );
      }
    );
  }

  sendDevicePowerCommand(command: string): void {
    // 顯示對話框
      var commandLine = command;
      if (command == "Off"){
        commandLine = "Shoutdown";
      } else if (command == "Restart") {
        commandLine = "Reboot";
      }
      Swal.fire({
        title: "Are you sure?",
        text: `Send Device Power Command : ${commandLine}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "YES",
        cancelButtonText: "NO"
      }).then(isConfirm => {
        if (isConfirm.value) {
          this.device.$sendDevicePowerCommand(
            { id: this.device.id, command: command },
            () => {
              this.logger.success(`Send Device Power Command : ${commandLine}`);
            }
          );
        } 
      });
  }

  sendScreenPowerCommand(command: string): void {
    this.device.$sendScreenPowerCommand(
      { id: this.device.id, command: command },
      () => {
        this.logger.success(`Send Screen Power Command ${command}`);
      }
    );
  }

  // 彈幕開關
  sendDanMuCommand(command: string): void {
    this.device.$sendDanMuCommand(
      { id: this.device.id, command: command },
      () => {
        this.logger.success(`Send DanMu Command ${command}`);
      }
    );
  }

  // 薄膜開關
  sendArduinoRelayCommand(command: string): void {
    this.device.$sendArduinoRelayCommand(
      { id: this.device.id, command: command },
      () => {
        this.logger.success(`Send Arduino Relay Command ${command}`);
      }
    );
  }

  // 音量控制
  sendVolumeCommand(volume: number): void {
    this.device.$sendVolumeCommand(
      { id: this.device.id, volume: volume },
      () => {
        this.logger.success(`Send Volume ${volume}`);
      }
    );
  }

  // 投影機指令
  sendProjectorCommand(command: string): void {
    this.device.$sendProjectorCommand(
      { id: this.device.id, command: command },
      () => {
        this.logger.success(`Send Projector Command ${command}`);
      }
    );
  }

  setProjectorSchedule(): void {
    if (this.device.projectorSchedule.type !== 2) {
      this.device.projectorSchedule.onTime = "";
      this.device.projectorSchedule.offTime = "";
    }

    const projectorSchedule = {
      type: this.device.projectorSchedule.type,
      startTime: this.device.projectorSchedule.onTime,
      endTime: this.device.projectorSchedule.offTime
    };

    this.deviceService.sendScheduleProjectorCommand(
      { id: this.device.id },
      projectorSchedule,
      () => {
        this.logger.success(
          "Send Projector Schedule Setting",
          projectorSchedule
        );
      }
    );
  }

  removeLicense(): void{
    this.licenseService.removeLicense({ deviceId: this.device.id, licenseId: this.license.id }, null,
      () => {
        this.device.licenseId = null;
        this.license = null;
        Swal.fire({ icon: 'success', title: "已解除此裝置的授權綁定，裝置立即失效", showConfirmButton: false, timer: 1500 })
      },
      httpResponse  => {
        this.logger.error("解除裝置授權錯誤", httpResponse, "解除裝置授權錯誤");
        Swal.fire({ icon: 'error', title: '解除裝置授權錯誤', text: httpResponse.data.message, footer: '請聯絡系統管理人員' })
      });
  }
}
