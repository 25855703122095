import * as angular from "angular";
import Swal from "sweetalert2";

import { IIdentityService } from "./identity.factory";

  interface IUserListViewModel {
    users: any;
    del(id: number): void;
  }

  export class UserList implements IUserListViewModel {
    users: any;
    static $inject = ["users", "IdentityService", "$filter"];

    constructor(users, private identityService: IIdentityService,  private $filter) {
      /* jshint validthis:true */
      this.users = users;
    }

    del(id: number): void {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!"
      })
        .then(isConfirm => {
        if (isConfirm.value) {
          this.identityService.users().delete({ id: id }, (value, responseHeaders) => {
            var indx = this.$filter("findIndexByKeyValue")(this.users, "id", id);
            this.users.splice(indx, 1);
            Swal.fire("Deleted!", "User has been deleted.", "success");
          }, httpResponse => {
            Swal.fire("Cancelled", "User file is safe :)", "error");
          });
        } else {
          Swal.fire("Cancelled!", "Your user file is safe :)", "success");
        }
      });
    }

  }

  UserEdit.$inject = ["IdentityService", "common", "user", "roles", "agencies", "timeZones", "$state"];

  export function UserEdit(identityService: IIdentityService, common, user, roles, agencies, timeZones, $state): void {
    var vm = this;
    var useService = identityService.users();
    var logger = common.logger;

    var beforeRoles = [];
    var afterRoles = [];

    vm.user = user;
    vm.roles = roles;
    vm.agencies = agencies;
    vm.timeZones = timeZones;
    vm.save = save;

    activate();

    ////////////
    function activate() {
      user.$promise.then(data => {
        angular.copy(data.roles, beforeRoles);
      });

      logger.log(user);
      logger.log(agencies);
      logger.log(timeZones);
    }

    function save() {
      angular.copy(vm.user.roles, afterRoles);
      useService.addUserProfile(null, vm.user,
        (saveUser) => {
          if (!angular.equals(beforeRoles, afterRoles)) {
            useService.assignRolesToUser({ id: saveUser.id }, afterRoles,
              () => { $state.go("admin.users.list"); });
          } else {
            $state.go("admin.users.list");
          }
        });
    }
  }

  UserCreate.$inject = ["IdentityService", "common", "$state"];

  export function UserCreate(identityService: IIdentityService, common, $state): void {
    var vm = this;
    var useService = identityService.users();
    var logger = common.logger;

    vm.save = save;

    activate();

    ////////////
    function activate() {
      vm.user = {};
      vm.savedSuccessfully = false;
      vm.message = null;
    }

    function save() {
      logger.log(vm.user);

      useService.createUser(vm.user,
        (saveUser, response) => {
          vm.savedSuccessfully = true;

          logger.log(saveUser);
          logger.log(response);

          $state.go("admin.users.list");
        },
        (response) => {
          vm.savedSuccessfully = false;
          var errors = [];
          for (var key in response.data.modelState) {
            if (response.data.modelState.hasOwnProperty(key)) {
              for (var i = 0; i < response.data.modelState[key].length; i++) {
                errors.push(response.data.modelState[key][i]);
              }
            }
          }
          vm.message = "Failed to create user due to:" + errors.join(" ");
        }
      );
    }
  }

  RoleList.$inject = ["IdentityService", "roles"];

  export function RoleList(identityService: IIdentityService, roles): void {
    /* jshint validthis:true */
    const vm = this;
    vm.roles = roles;

    activate();

    function activate() {
      console.log(roles);
    }
  }
