import { IAuthSettings } from "./constants";
import { IHttpResponse } from "angular";

export interface IAuthentication {
  expirationDate: string;
  useRefreshTokens: boolean;
  requireOTP: boolean;

  id: string;
  userName: string;
  userEmail: string;

  role: Array<string>;
  userAgency: string;
  agencyFirstCulture: string;
  agencySecondCulture: string;
  interCut: boolean;
  detectionRule: boolean;
  dashboard: boolean;
  mart: boolean;
  emergency: boolean;
  applicationCredential: boolean;
  smartShelves: boolean;
  report: boolean;
}

export class Authentication implements IAuthentication {
  expirationDate: string;
  useRefreshTokens: boolean;
  requireOTP: boolean;

  id: string;
  userName: string;
  userEmail: string;

  role: string[];
  userAgency: string;
  agencyFirstCulture: string;
  agencySecondCulture: string;

  interCut: boolean;
  detectionRule: boolean;
  dashboard: boolean;
  mart: boolean;
  emergency: boolean;
  applicationCredential: boolean;
  smartShelves: boolean;
  report: boolean;
}

class TokenPayload implements angular.jwt.JwtToken {
  nameid?: string;
  unique_name?: string;
  user_email?: string;
  role?: string[];
  user_agency?: string;
  agencyFirstCulture?: string;
  agencySecondCulture?: string;

  interCut?: string;
  detectionRule?: string;
  dashboard?: string;
  mart?: string;
  emergency?: string;
  applicationCredential?: string;
  smartShelves?: string;
  report?: string;
}

interface ILogin {
  userName: string;
  password: string;
  useRefreshTokens: boolean;
}


authenticator.$inject = ["$http", "$q", "localStorageService", "ngAuthSettings", "jwtHelper"];

export function authenticator($http, $q: angular.IQService, localStorageService, ngAuthSettings: IAuthSettings, jwtHelper: angular.jwt.IJwtHelper) {
  var access_token: string = "";

  // api 路徑
  var serviceBase = ngAuthSettings.apiServiceBaseUri;
  // 權限的資料
  var authentication: IAuthentication = {
    expirationDate: "",
    useRefreshTokens: false,
    requireOTP: false,
    id: "",
    userName: "",
    userEmail: "",
    role: [],
    userAgency: "",
    agencyFirstCulture:"",
    agencySecondCulture: "",
    interCut: false,
    detectionRule: false,
    dashboard: false,
    mart: false,
    emergency: false,
    applicationCredential: false,
    smartShelves: false,
    report: false
  };

  const service: {
    access_token: string;
    authentication: IAuthentication;
    fillAuthData: () => void;
    saveRegistration: (registration: any) => any;
    login: (loginData: ILogin) => angular.IPromise<any>;
    logOut: () => void;
    refreshToken: () => angular.IPromise<any>;
    sendForgotPassword: (forgotPassword: any) => any;
    sendResetPassword: (resetPassword: any) => any;
    getValidTwoFactorProviders: () => any;
    sendCode: (provider: string) => any;
    verifyOTPCode: (provider: string, code: string) => any;
    isTokenExpired: () => boolean;
    isInRole: (role: string) => boolean;
    isInAnyRole: (roles: string[]) => boolean;
    isAuthenticated: () => boolean;
    isVerifyOtp: () => boolean;
  } = {
    access_token: access_token,
    authentication: authentication,
    fillAuthData: fillAuthData,

    saveRegistration: saveRegistration,
    login: login,
    logOut: logOut,

    refreshToken: refreshToken,

    sendForgotPassword: sendForgotPassword,
    sendResetPassword: sendResetPassword,

    getValidTwoFactorProviders: getValidTwoFactorProviders,
    sendCode: sendCode,
    verifyOTPCode: verifyOTPCode,

    isTokenExpired: isTokenExpired,

    isInRole: isInRole,
    isInAnyRole: isInAnyRole,

    isAuthenticated: isAuthenticated,
    isVerifyOtp: isVerifyOtp
  };

  return service;

  //////////////////////
  function fillAuthData() {

    const authData = localStorageService.get("authorizationData");
    if (authData) {
      const token = localStorageService.get("jwt");

      authentication.expirationDate = authData.expirationDate;
      authentication.useRefreshTokens = authData.useRefreshTokens;
      authentication.requireOTP = authData.requireOTP;

      if (token) {
        access_token = token;
        const tokenPayload: TokenPayload = jwtHelper.decodeToken(token);

        authentication.id = tokenPayload.nameid;
        authentication.userName = tokenPayload.unique_name;
        authentication.userEmail = tokenPayload.user_email;

        authentication.role = tokenPayload.role;
        authentication.userAgency = tokenPayload.user_agency;
        authentication.agencyFirstCulture = tokenPayload.agencyFirstCulture;
        authentication.agencySecondCulture = tokenPayload.agencySecondCulture;
        authentication.interCut = (tokenPayload.interCut === "True");
        authentication.detectionRule = (tokenPayload.detectionRule === "True");
        authentication.dashboard = (tokenPayload.dashboard === "True");
        authentication.mart = (tokenPayload.mart === "True");
        authentication.emergency = (tokenPayload.emergency === "True");
        authentication.applicationCredential = (tokenPayload.applicationCredential === "True");
        authentication.smartShelves = (tokenPayload.smartShelves === "True");
        authentication.report = (tokenPayload.report === "True");
      }
    }
  }

  //////////////////////
  function saveRegistration(registration) {

    logOut();

    return $http.post(serviceBase + "api/account/register", registration).then(response => response);

  }

  function logOut() {

    //$http.post(serviceBase + 'api/Account/Logout', null);

    localStorageService.remove("authorizationData");
    localStorageService.remove("jwt");
    localStorageService.remove("verifyOTP");

    authentication = new Authentication();
  }

  function login(loginData: ILogin): angular.IPromise<any> {

    let data = `grant_type=password&username=${encodeURIComponent(loginData.userName)}&password=${encodeURIComponent(loginData.password)}`;

    if (loginData.useRefreshTokens) {
      data = data + "&client_id=" + ngAuthSettings.clientId;
    }

    var deferred = $q.defer();

    $http.post(serviceBase + "token", data, { headers: { 'Content-Type': "application/x-www-form-urlencoded" } })
      .then((response: IHttpResponse<any>) => {
        localStorageService.set("verifyOTP", false);

        localStorageService.set("jwt", response.data.access_token);

        localStorageService.set("authorizationData",
          {
            token: response.data.access_token,
            userName: response.data.userName,
            expirationDate: response.data.expirationDate,
            refreshToken: loginData.useRefreshTokens ? response.data.refresh_token : null,
            useRefreshTokens: true,
            requireOTP: (response.data.requireOTP === "True")
          });

        fillAuthData();

        deferred.resolve(response);
      },
        (reason) => {
          logOut();
          deferred.reject(reason);
        });

    return deferred.promise;

  }

  //////////////////////
  function refreshToken(): angular.IPromise<any> {
    var deferred = $q.defer();
    const authData = localStorageService.get("authorizationData");

    if (!authData) deferred.reject("No authorization data");

    if (authData.useRefreshTokens && ngAuthSettings.clientId) {

      const data = `grant_type=refresh_token&refresh_token=${authData.refreshToken}&client_id=${ngAuthSettings.clientId}`;

      $http.post(serviceBase + "token", data, { headers: { 'Content-Type': "application/x-www-form-urlencoded" }, skipAuthorization: true })
        .then((response: IHttpResponse<any>) => {

          localStorageService.set("jwt", response.data.access_token);
          localStorageService.set("authorizationData",
            {
              token: response.data.access_token,
              userName: response.data.userName,
              expirationDate: response.data.expirationDate,
              refreshToken: response.data.refresh_token,
              useRefreshTokens: true
            });

          fillAuthData();

          deferred.resolve(response);
        },
          (reason) => {
            logOut();
            deferred.reject(reason);
          });
    } else {
      deferred.reject("No refresh token or client id");
    }

    return deferred.promise;
  }

  //////////////////////
  function sendForgotPassword(forgotPassword) {
    logOut();
    return $http.post(serviceBase + "api/Account/ForgotPassword", forgotPassword).then(response => response);
  }

  function sendResetPassword(resetPassword) {
    logOut();
    return $http.post(serviceBase + "api/account/ResetPassword", resetPassword).then(response => response);
  }

  //////////////////////
  function getValidTwoFactorProviders() {
    return $http.get(serviceBase + "api/Account/ValidTwoFactorProviders",
      { headers: { 'Authorization': 'Bearer ' + access_token } })
      .then(response => response);
  }

  function sendCode(provider) {
    return $http.post(serviceBase + "api/Account/SendCode?twoFactorProvider=" + provider, null,
      { headers: { 'Authorization': 'Bearer ' + access_token } })
      .then(response => response);
  }

  function verifyOTPCode(provider, code) {
    return $http.post(serviceBase + "api/Account/VerifyOTPCode?twoFactorProvider=" + provider + "&code=" + code, null,
      { headers: { 'Authorization': 'Bearer ' + access_token } })
      .then(response => response);
  }

  //////////////////////
  function isTokenExpired(): boolean {
    const jwt = localStorageService.get("jwt");
    return !jwt || jwtHelper.isTokenExpired(jwt);
  }

  //////////////////////
  function isInRole(role: string): boolean {
    if (!authentication.role) return false;
    return authentication.role.indexOf(role) !== -1;
  }

  function isInAnyRole(roles: string[]): boolean {
    for (let i = 0; i < roles.length; i++) {
      if (this.isInRole(roles[i])) return true;
    }
    return false;
  }

  function isAuthenticated(): boolean {
    const jwt = localStorageService.get("jwt");
    return jwt && !jwtHelper.isTokenExpired(jwt);
  }

  function isVerifyOtp(): boolean {
    if (!authentication.requireOTP) return true;

    const verifyOtp = localStorageService.get("verifyOTP");
    return verifyOtp;
  }
}

authconfig.$inject = ["$httpProvider", "jwtOptionsProvider"];

export function authconfig($httpProvider: angular.IHttpProvider, jwtOptionsProvider): void {
  // Please note we're annotating the function so that the $injector works when the file is minified
  jwtOptionsProvider.config({
    tokenGetter: ["jwtHelper", "localStorageService", "$injector",
      (jwtHelper: angular.jwt.IJwtHelper, localStorageService, $injector) => {
        var idToken = localStorageService.get("jwt");
        return idToken;

        //var authData = localStorageService.get("authorizationData");

        //if (!idToken || !angular.isString(idToken)) return null;

        //if (jwtHelper.isTokenExpired(idToken) && authData && authData.useRefreshTokens) {
        //    const authService = $injector.get("Authenticator");
        //    // This is a promise of a JWT id_token
        //    return authService.refreshToken().then(
        //        response => {
        //            idToken = localStorageService.get("jwt");
        //            return !idToken || !angular.isString(idToken) ? null : idToken;
        //        },
        //        reason => {
        //            if (reason.data)
        //                console.log("console.log error : ", reason.data);
        //            else
        //                console.log("console.log error : ",reason);

        //            return null;
        //        });
        //} else {
        //    return idToken;
        //}
      }
    ],
    whiteListedDomains: [
      /(\-?\w*)*\.core-pcloud\.com/, /pcloud(\-?\w*)*\.azurewebsites\.net/,
      /p-cloud(\-?\w*)*\.azurewebsites\.net/, /pcloudapi(\-?\w*)*\.azurewebsites\.net/,
      /pcloud(\-?\w*)*\.chinacloudsites\.cn/, "localhost"
    ],
    unauthenticatedRedirector: ["$state", $state => {
      $state.go("login");
    }]
  });

  $httpProvider.interceptors.push("jwtInterceptor");
}
