import { StateService } from "@uirouter/angularjs";
import * as R from "ramda";
import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { SmartShelvesDatacontext } from "../smartshelves/data/datacontext";

import { IAgency } from "../models/models";
import { CultureService } from "../services/culture.service";

interface ISurveyListViewModel {
  surveys: Array<any>;
  authentication: any;

  dtOptions: any;
  dtColumnDefs: any;

  del(id: number): void;
}

export class SurveyListsCtrl implements ISurveyListViewModel, angular.IController {
  surveys: any[];
  authentication: any;

  dtOptions: any;
  dtColumnDefs: any;

  private readonly surveyService: any;
  private readonly logger: ILogger;

  static $inject = ["datacontext", "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder"];

  $onInit() { }

  constructor(datacontext: Datacontext, common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder) {
    this.surveyService = datacontext.surveys();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: 'copy' },
        { extend: 'csv' },
        { extend: 'excel', title: 'SurveysFile' },
        { extend: 'pdf', title: 'SurveysFile' },
        {
          extend: 'print',
          customize: win => {
            $(win.document.body).addClass('white-bg');
            $(win.document.body).css('font-size', '10px');

            $(win.document.body).find('table')
              .addClass('compact')
              .css('font-size', 'inherit');
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    })
      .then(isConfirm => {
        if (isConfirm.value) {
          this.surveyService.delete({ id: id },
            () => {
              var indx = this.$filter("findIndexByKeyValue")(this.surveys, "id", id);
              this.surveys.splice(indx, 1);
              Swal.fire("Deleted!", "Your Survey has been deleted.", "success");
            },
            httpResponse => {
              this.logger.error(`Survey can't delete, survey ID is ${id}`, httpResponse, "Delete Survey Error");
              Swal.fire("Cancelled", "Survey can't delete", "error");
            });
        } else {
          Swal.fire("Cancelled!", "Your Survey file is safe :)", "success");
        }
      });
  }
}

interface ISurveyEditViewModel {
  survey: any;
  // weightsDefinitions: any;

  agencies: IAgency;
  skintypes: any;

  authentication: any;

  //weightsDefinitionsGroupByOption: any;

  // sortableOptions: any;
  //subjects: any;

  save(): void;
  //updateQuestion(index, data): void;
  //updateOption(parentIndex, index, data): void;
  //updateWeights(optionId, id, data): void;
}

export class SurveyEditContentCtrl implements angular.IComponentController {
  isEdit: boolean;
  survey: any;
  ismainculture: boolean;
  culture: string;
  authentication: any;
  skintypes: any;
  parent: any;
  private agencies: Array<IAgency>;
  private role: any;

  weightsDefinitions: any;
  weightsDefinitionsGroupByOption: any;

  getTranslation: any;
  sortableOptions: any;
  subjects: any;
  processWeightsDefinitions: any;
  updateQuestion: any;
  updateOption: any;
  updateWeights: any;

  private logger: ILogger;

  private surveyService: any;
  private subjectService: any;
  private optionService: any;
  private answerResultDefinitionService: any;
  private weightsDefinitionService: any;

  static $inject = ["apiUrl", "localStorageService", "datacontext", "smartShelvesDatacontext", "common", "Authenticator", "$sce", "$filter"];

  constructor(private apiUrl, private localStorageService, private datacontext: Datacontext, smartShelvesDatacontext: SmartShelvesDatacontext, private common: any, private authenticator: any, private $sce, private $filter) {
    this.logger = common.logger;

    this.surveyService = datacontext.surveys();
    //this.subjectService = datacontext.subjects();
    this.optionService = datacontext.options();
    this.answerResultDefinitionService = datacontext.answerResultDefinitions();
    this.weightsDefinitionService = smartShelvesDatacontext.weightsDefinitions();
  }

  $onInit() {
    this.sortableOptions = this.parent.sortableOptions;
    this.subjects = this.parent.subjects;
    this.processWeightsDefinitions = this.parent.processWeightsDefinitions;
    this.updateQuestion = this.parent.updateQuestion;
    this.updateOption = this.parent.updateOption;
    this.updateWeights = this.parent.updateWeights;
    this.getTranslation = this.parent.getTranslation;
    this.subjectService = this.parent.subjectService;
  }
}

export class SurveyEditCtrl implements ISurveyEditViewModel, angular.IController {

  isEdit: boolean;

  surveys: any;
  survey: any;
  weightsDefinitions: any;
  weightsDefinitionsGroupByOption: any;

  agencies: IAgency;
  skintypes: any;
  authentication: any;

  mainCulture: { [key: string]: string };
  otherCultures: { [key: string]: string };

  private logger: ILogger;

  private surveyService: any;
  private subjectService: any;
  private optionService: any;
  private answerResultDefinitionService: any;
  private weightsDefinitionService: any;

  static $inject = ["CultureService", "datacontext", "smartShelvesDatacontext", "common", "$stateParams", "$state", "$filter"];
  constructor(private cultureService: CultureService, datacontext: Datacontext, smartShelvesDatacontext: SmartShelvesDatacontext, private common: any, private $stateParams, private $state: StateService, private $filter) {
    this.logger = common.logger;

    this.surveyService = datacontext.surveys();
    this.subjectService = datacontext.subjects();
    this.optionService = datacontext.options();
    this.answerResultDefinitionService = datacontext.answerResultDefinitions();
    this.weightsDefinitionService = smartShelvesDatacontext.weightsDefinitions();
  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;
    if (this.isEdit) {
      this.survey.$promise
        .then(result => {
          result.startDateTime = moment.utc(result.startDateTime).local();
          result.endDateTime = moment.utc(result.endDateTime).local();

          result.subjects = this.$filter("orderBy")(result.subjects, ["number"]);
          R.map(subject => { subject.options = this.$filter("orderBy")(subject.options, ["number"]); }, result.subjects);

          this.survey = result;
          this.logger.log("survey", this.survey);

          this.processWeightsDefinitions();
          this.initCulture();
        });
    } else {
      this.survey = {
        id: 0,
        name: null,
        description: null,
        startDateTime: moment.utc().local(),
        endDateTime: moment.utc().local(),
        agencyId: null, answerResultDefinition: { id: 0, name: null },
        subjects: []
      }
      this.initCulture();
    }
  }

  sortableOptions = {
    stop: (e, ui) => {
      for (let i = 0; i < this.survey.subjects.length; i++) {
        if (this.survey.subjects[i].number !== i + 1) {
          this.survey.subjects[i].number = i + 1;
          this.subjectService.update({ id: this.survey.subjects[i].id }, this.survey.subjects[i],
            (value) => {
              this.survey.subjects[i] = value;
            },
            httpResponse => {
              this.logger.error("Update Question Error", httpResponse, "Error");
            });
        }
      }
      this.processWeightsDefinitions();
    }
  }

  subjects = {
    new: () => {
      const subject = {
        id: 0, number: this.survey.subjects.length + 1, question: "New Question",
        options: [
          { id: 0, number: 1, text: "Option 1", subjectId: 0, optionTranslations: [] },
          { id: 0, number: 2, text: "Option 2", subjectId: 0, optionTranslations: [] },
          { id: 0, number: 3, text: "Option 3", subjectId: 0, optionTranslations: [] }],
        surveyId: this.survey.id,
        subjectTranslations: []
      };

      this.subjectService.save(null, subject,
        value => {
          this.survey.subjects.push(value);
          R.map(option => {
            R.map(skintype => {
              const weightsDefinition = {
                answerResultDefinitionId: this.survey.answerResultDefinition.id,
                optionId: option.id, skinTypeId: skintype.id, weights: 0
              };

              let weightsDefinitionsPromise = [];
              weightsDefinitionsPromise.push(this.weightsDefinitionService.save(weightsDefinition).$promise);
              this.common.$q.all(weightsDefinitionsPromise)
                .then(results => {
                  // R.map(result => { this.survey.answerResultDefinition.weightsDefinitions.push(result); }, results);
                  this.processWeightsDefinitions();
                });
            }, this.skintypes);
          }, value.options);
        },
        httpResponse => { this.logger.error("Create New Question Error", httpResponse, "Error"); });
    },
    edit: (indx) => {

    },
    del: (indx) => {
      let subject = this.survey.subjects[indx];
      this.subjectService.delete({ id: subject.id },
        () => {
          this.survey.subjects.splice(indx, 1);
          Swal.fire("Deleted!", "Your Subject has been deleted.", "success");
          this.processWeightsDefinitions();
        },
        httpResponse => {
          this.logger.error(`Subject can't delete, Subject ID is ${subject.id}`, httpResponse, "Delete Subject Error");
          Swal.fire("Cancelled", "Subject can't delete", "error");
        });
    }
  }

  processWeightsDefinitions() {
    this.weightsDefinitionService.getWeightsDefinitionsBy({ answerResultDefinitionId: this.survey.answerResultDefinitionId }, null,
      value => {
        this.weightsDefinitions = value;
        this.weightsDefinitionsGroupByOption = R.groupBy((weightsDefinition: any) => weightsDefinition.optionId)(this.weightsDefinitions);

        R.mapObjIndexed((weightsDefinitions: any, key) => {
          weightsDefinitions = R.assoc("weights", R.values(weightsDefinitions), {});

          R.map(subject => {
            const option = R.find(R.propEq("id", +key))(subject.options);
            if (option) {
              weightsDefinitions = R.assoc("subject", subject, weightsDefinitions);
              weightsDefinitions = R.assoc("option", option, weightsDefinitions);
            }
          }, this.survey.subjects);

          this.weightsDefinitionsGroupByOption[key] = weightsDefinitions;
        }, this.weightsDefinitionsGroupByOption);

        this.weightsDefinitionsGroupByOption = R.values(this.weightsDefinitionsGroupByOption);
      });
  }

  updateQuestion(index, ismainculture, culture, data): void {
    culture = culture.replace(/_/g, '-');
    const subject = this.survey.subjects[index];
    if (ismainculture) {
      subject.question = data;
    } else {
      for (var i = 0; i < subject.subjectTranslations.length; i++) {
        if (subject.subjectTranslations[i].culture == culture) {
          subject.subjectTranslations[i].question = data;
        }
      }
    }

    this.subjectService.update({ id: subject.id }, subject,
      value => { this.survey.subjects[index] = value; },
      httpResponse => { this.logger.error("Update Question Error", httpResponse, "Error"); });
  }

  updateOption(parentIndex, index, ismainculture, culture, data): void {
    culture = culture.replace(/_/g, '-');
    const option = this.survey.subjects[parentIndex].options[index];
    if (ismainculture) {
      option.text = data;
    } else {
      if (option.optionTranslations.length == 0) {
        let newTranslation = {
          id: 0,
          text: data,
          culture: culture,
          OptionId: option.id
        };
        option.optionTranslations.push(newTranslation);
      } else {
        for (var i = 0; i < option.optionTranslations.length; i++) {
          if (option.optionTranslations[i].culture == culture) {
            option.optionTranslations[i].text = data;
          }
        }
      }
    }

    this.optionService.update({ id: option.id }, option,
      value => {
        this.survey.subjects[parentIndex].options[index] = value;
      },
      httpResponse => { this.logger.error("Update Option Error", httpResponse, "Error"); });
  }

  updateWeights(optionId, id, data): void {
    const weightsDefinitions = this.$filter("filter")(this.weightsDefinitionsGroupByOption, { option: { id: optionId } }, true)[0];
    const weights = this.$filter("filter")(weightsDefinitions.weights, { id: id }, true)[0];

    weights.weights = data;

    this.weightsDefinitionService.update({ id: weights.id }, weights,
      value => { this.logger.log("save weights", value); },
      httpResponse => { this.logger.error("Update Weights Error", httpResponse, "Error"); });
  }

  save(): void {
    this.survey.startDateTime = moment(this.survey.startDateTime, "MM/DD/YYYY hh:mm:ss A").utc();
    this.survey.endDateTime = moment(this.survey.endDateTime, "MM/DD/YYYY hh:mm:ss A").utc();
    this.survey.answerResultDefinition.name = this.survey.name + " 矩陣對應表";

    if (this.isEdit) {
      this.surveyService.update({ id: this.survey.id }, this.survey,
        () => {
          this.answerResultDefinitionService.update({ id: this.survey.answerResultDefinition.id }, this.survey.answerResultDefinition,
            () => { this.$state.go("surveys.list"); },
            httpResponse => { this.logger.error("Survey Update Error", httpResponse, "Error"); });
        },
        httpResponse => { this.logger.error("Survey Update Error", httpResponse, "Error"); });
    } else {
      this.surveyService.save(null, this.survey,
        () => { this.$state.go("surveys.list"); },
        httpResponse => { this.logger.error("Survey Create Error", httpResponse, "Error"); });
    }
  }

  initCulture() {
    this.mainCulture = this.cultureService.mainCulture;
    this.otherCultures = this.cultureService.otherCultures;
  }

  // 檢查是否有語系資料
  checkHasTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    for (var i = 0; i < this.survey.subjects.length; i++) {
      var subject = this.survey.subjects[i];
      if (subject.subjectTranslations.length == 0) {
        this.pushNewTranslation(culture, subject);
      }
      else {
        var found = this.getTranslation(culture, subject.subjectTranslations);
        if (!found) {
          this.pushNewTranslation(culture, subject);
        }
        for (var j = 0; j < subject.options.length; j++) {
          found = this.getTranslation(culture, subject.options[j].optionTranslations);
          if (!found) {
            this.pushNewOptionTranslation(culture, subject.options[j]);
          }
        }
      }
    }
  }

  // 新增語系資料
  pushNewTranslation(culture: string, subject: any): void {
    culture = culture.replace(/_/g, '-');
    let newTranslation = {
      id: 0,
      question: null,
      options: [{
        id: 0,
        number: null,
        subjectId: subject.id,
        text: null,
        optionTranslations: []
      }],
      culture: culture,
      subjectId: subject.id
    };
    subject.subjectTranslations.push(newTranslation);
  }

  pushNewOptionTranslation(culture: string, option: any): void {
    culture = culture.replace(/_/g, '-');
    let newTranslation = {
      id: 0,
      text: null,
      culture: culture,
      OptionId: option.id
    };
    option.optionTranslations.push(newTranslation);
  }

  // 取得已存在的語系資料
  getTranslation(culture: string, objects: any): any {
    culture = culture.replace(/_/g, '-');
    return this.cultureService.getTranslation(culture, objects);
  }

}


