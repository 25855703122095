import * as angular from "angular";
import * as moment from "moment-timezone";
import * as R from "ramda";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { IScheduleDialogFactory } from "./schedule.factory";
import { FunctionalModule } from "../models/enums";

  Schedule.$inject = ["scheduleFactory", "uiCalendarConfig", "common", "devices", "datacontext", "$filter"];

  export function Schedule(scheduleFactory: IScheduleDialogFactory, uiCalendarConfig, common, devices, datacontext: Datacontext, $filter): void {
    var vm = this;
    var logger: ILogger = common.logger;
    var eventService = datacontext.events();

	var hasSave = false;

    vm.create = () => {
      scheduleFactory.create().then(newSchedule => {
        logger.success("Create new schedule success!", newSchedule, "Schedule");
        uiCalendarConfig.calendars["Scheduler"].fullCalendar("refetchEventSources", eventsF);
      },
        () => {
          uiCalendarConfig.calendars["Scheduler"].fullCalendar("refetchEventSources", eventsF);
          logger.log(`Modal dismissed at: ${new Date()}`);
        });
    };

    /* Event sources array */
    vm.eventSources = [];
    // Resources
    vm.resources = [];

    /* config object */
    vm.uiConfig = {
      calendar: {
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        aspectRatio: 1.8,

        nowIndicator: true,
        businessHours: { start: "8:00", end: "18:00", dow: [1, 2, 3, 4, 5] },

        editable: false,
        eventOverlap: false, // will cause the event to take up entire resource height

        header: {
          left: "today prev,next",
          center: "title",
          right: "timelineDay,listWeek,month"
        },
        customButtons: {
          myCustomButton: {
            text: "custom!",
            click: () => {
              logger.info("clicked the custom button!", null, "custom");
            }
          }
        },
        defaultView: "timelineDay",
        views: {
          timelineThreeDays: {
            type: "timeline",

            duration: { days: 3 }
          },
          agendaTwoDay: {
            type: "agenda",
            duration: { days: 2 },
            // views that are more than a day will NOT do this behavior by default
            // so, we need to explicitly enable it
            groupByResource: true

            //// uncomment this line to group by day FIRST with resources underneath
            //groupByDateAndResource: true
          }
        },
        //eventResourceField: 'deviceId',
        resourceAreaWidth: "15%",
        resourceLabelText: "Device",
        resourceGroupField: "country",
        resourceColumns: [
          {
            group: true,
            labelText: 'Country',
            field: 'country'
          },
          {
            group: true,
            labelText: 'City',
            field: 'city'
          }
          ,
          {
            labelText: 'Name',
            field: 'title'
          }
        ],
        resources: vm.resources,

        eventClick: onEventClick,

        timezone: moment.tz.guess()
      }
    };

    /* Change View */
    vm.changeView = changeView;

    activate();

    ////////////
    function activate() {

      // 由 devices 轉成 resources
      devices.$promise.then(value => {
        var mydevices = value.filter(device => device.functionalModule & FunctionalModule.MediaPlayer);

        const resources = $filter("orderBy")(mydevices, ["messageQueue.country", "name"]);

        R.map(device => {
          vm.resources.push({
            id: device.id,
            title: device.name,
            eventColor: device.scheduleColor,
            country: device.messageQueue.country,
            city: device.messageQueue.city
          });
        }, resources);
      });

      // 設定 events 的來源，可以有多組來源
      vm.eventSources = [eventsF];

    }

    /* event source that calls a function on every view switch */
    function eventsF(start, end, timezone, callback) {
      eventService.getEventsBy({ start: start.format(), end: end.format(), timezone: timezone },
        queryEvents => {

          R.map(queryEvent => {
            queryEvent.start = moment.utc(queryEvent.start).tz(timezone).local();
            queryEvent.end = moment.utc(queryEvent.end).tz(timezone).local();
          }, queryEvents);

          callback(queryEvents);
        });
    };

    /* Change View */
    function changeView(view, calendar) {
      uiCalendarConfig.calendars[calendar].fullCalendar("changeView", view);
    }

    /* message on eventClick */
    function onEventClick(event, jsEvent, view) {
      logger.log("Click event : ", event);

      scheduleFactory.edit(event.schedulerId)
        .then(editSchedule => {
          logger.log("edit Schedule", editSchedule);
          uiCalendarConfig.calendars["Scheduler"].fullCalendar("refetchEventSources", eventsF);
        },
          () => {
            uiCalendarConfig.calendars["Scheduler"].fullCalendar("refetchEventSources", eventsF);
            logger.log(`Modal dismissed at: ${new Date()}`);
          });
    }
  }


