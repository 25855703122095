import * as angular from "angular";
import { ExceptionHandlerProvider, config } from "./exception-handler";
import { Exception } from "./exception";

import BlocksLogger from "../logger/logger.module";

export default angular
  .module("blocks.exception", [BlocksLogger.name])
  .provider("exceptionHandler", ExceptionHandlerProvider)
  .config(config)
  .service("exception", Exception);
