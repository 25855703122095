import * as angular from "angular";
import { routeConfig } from "./config.route";
import { EmergencyAlertCtrl } from "./emergency";

export default angular
  .module("app.emergency", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .controller("EmergencyAlert", EmergencyAlertCtrl);
