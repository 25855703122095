import { Logger } from "../logger/logger";

// Include in index.html so that app level exceptions are handled.
// Exclude from testRunner.html which should run exactly what it wants to run

interface IExceptionHandlerConfig {
  appErrorPrefix: string;
}

export class ExceptionHandlerProvider {
  config: IExceptionHandlerConfig = {
    appErrorPrefix: undefined
  };

  configure(appErrorPrefix: string) {
    this.config.appErrorPrefix = appErrorPrefix;
  }

  $get: () => { config: IExceptionHandlerConfig } = () => {
    return { config: this.config };
  };
}

export function config($provide: ng.auto.IProvideService): void {
  $provide.decorator("$exceptionHandler", extendExceptionHandler);
}
config.$inject = ["$provide"];

function extendExceptionHandler(
  $delegate: ng.IExceptionHandlerService,
  exceptionHandler: ExceptionHandlerProvider,
  logger: Logger
) {
  return (exception: any, cause: any) => {
    //  var appErrorPrefix = '[Error] ';
    var appErrorPrefix = exceptionHandler.config.appErrorPrefix || "";
    var errorData = { exception: exception, cause: cause };
    exception.message = appErrorPrefix + exception.message;
    $delegate(exception, cause);
    /**
     * Could add the error to a service's collection,
     * add errors to $rootScope, log errors to remote web server,
     * or log locally. Or throw hard. It is entirely up to you.
     * throw exception;
     *
     * @example
     *     throw { message: 'error message we added' };
     */
    logger.error(exception.message, errorData);
  };
}

extendExceptionHandler.$inject = ["$delegate", "exceptionHandler", "logger"];
