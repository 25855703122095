import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../data/datacontext";

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("applicationCredentials", {
      abstract: true,
      url: "/applicationCredentials",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Application Credentials", requiresLogin: true }
    })
    .state("applicationCredentials.list", {
      url: "/lists",
      template:
        '<application-credential-list applicationcredentials="$resolve.applicationcredentials" authentication="authentication"></application-credential-list>',
      resolve: {
        applicationcredentials: [
          "datacontext",
          (datacontext: Datacontext) =>
            datacontext.applicationCredentials().query()
        ]
      }
    })
    .state("applicationCredentials.create", {
      url: "/create",
      template:
        '<application-credential-create-edit agencies="$resolve.agencies" authentication="authentication"></application-credential-create-edit>',
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("applicationCredentials.edit", {
      url: "/{id:int}/edit",
      template:
        '<application-credential-create-edit applicationcredential="$resolve.applicationcredential" agencies="$resolve.agencies" authentication="authentication"></application-credential-create-edit>',
      resolve: {
        applicationcredential: [
          "datacontext",
          "$stateParams",
          (datacontext: Datacontext, $stateParams) =>
            datacontext.applicationCredentials().get({ id: $stateParams.id })
        ],
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    });
}

routeConfig.$inject = ["rootUrl", "$stateProvider"];
