import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider) {
  $stateProvider
    .state("playlogs", {
      abstract: true,
      url: "/playlogs",
      template:  require("../../views/common/content.html"),
      data: { pageTitle: "PlayLogs", requiresLogin: true },
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          function($ocLazyLoad: oc.ILazyLoad) {
            return $ocLazyLoad.load([
              {
                name: "ngCsv",
                files: [rootUrl + "Scripts/plugins/ng-csv/ng-csv.min.js"]
              }
            ]);
          }
        ]
      }
    })
    .state("playlogs.list", {
      url: "/lists",
    template: require("./views/playlogs/lists.html"),
      controller: "PlaylogList",
      controllerAs: "vm",
      resolve: {
        //為了要傳UI端的參數，故不在此解讀
        //playlogs: [
        //    'datacontext', function(datacontext) {
        //        return datacontext.playlogs.query();
        //    }
        //] ,
        loadPlugin: [
          "$ocLazyLoad",
          function($ocLazyLoad: oc.ILazyLoad) {
            return $ocLazyLoad.load([
              {
                name: "datePicker",
                files: [
                  rootUrl + "Content/plugins/datepicker/angular-datepicker.css",
                  rootUrl + "Scripts/plugins/datepicker/angular-datepicker.js"
                ]
              }
            ]);
          }
        ]
      }
    })
    .state("playlogs.chart", {
      url: "/chart",
    template: require("./views/playlogs/logchart.html"),
      controller: "PlaylogTrend",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          function($ocLazyLoad: oc.ILazyLoad) {
            return $ocLazyLoad.load([
              {
                name: "datePicker",
                files: [
                  rootUrl + "Content/plugins/datepicker/angular-datepicker.css",
                  rootUrl + "Scripts/plugins/datepicker/angular-datepicker.js"
                ]
              }
            ]);
          }
        ]
      }
    });
}
