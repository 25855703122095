import * as angular from "angular";
import { routeConfig } from "./config.route";
import { AiSkinDetectResultDefinitionListsCtrl, AiSkinDetectResultDefinitionEditCtrl } from "./aiskindetect";

export default angular
  .module("app.smartshelves.aiskindetect", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("aiskindetectresultdefinitionList", {
    template: require("./views/lists.html"),
    controller: AiSkinDetectResultDefinitionListsCtrl,
    bindings: {
      aiskindetectresultdefinitions: "<",
      authentication: "<"
    }
  })
  .component("aiskindetectresultdefinitionEdit", {
    template: require("./views/edit.html"),
    controller: AiSkinDetectResultDefinitionEditCtrl,
    bindings: {
      aiskindetectresultdefinition: "<",
      skintypes: "<",
      agencies: "<",
      authentication: "<"
    }
  });
