import { StateService, StateParams } from "@uirouter/angularjs";
import * as R from "ramda";
import Swal from "sweetalert2";

import { ILogger } from "../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../data/datacontext";

interface IAiSkinDetectResultDefinitionListViewModel {
  aiskindetectresultdefinitions: Array<any>;

  dtOptions: any;
  dtColumnDefs: any;

  del(id: number): void;
}

export class AiSkinDetectResultDefinitionListsCtrl implements IAiSkinDetectResultDefinitionListViewModel, angular.IController {
  aiskindetectresultdefinitions: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly aiSkinDetectResultDefinitionService: any;
  private readonly logger: ILogger;

  static $inject = ["smartShelvesDatacontext", "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder"];

  $onInit() {
    this.logger.log(this.aiskindetectresultdefinitions);
  }

  constructor(datacontext: SmartShelvesDatacontext, common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder) {
    this.aiSkinDetectResultDefinitionService = datacontext.aiSkinDetectResultDefinitions();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "SurveyresultdefinitionsFile" },
        { extend: "pdf", title: "SurveyresultdefinitionsFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.aiSkinDetectResultDefinitionService.delete({ id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(this.aiskindetectresultdefinitions, "id", id);
            this.aiskindetectresultdefinitions.splice(indx, 1);
            Swal.fire("Deleted!", "Your Surveyresultdefinition has been deleted.", "success");
          },
          httpResponse => {
            this.logger.error(`Surveyresultdefinition can't delete, surveyresultdefinition ID is ${id}`, httpResponse, "Delete Surveyresultdefinition Error");
            Swal.fire("Cancelled", "Surveyresultdefinition can't delete", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Surveyresultdefinition file is safe :)", "success");
      }
    });
  }
}

interface IAiSkinDetectResultDefinitionEditViewModel {
  aiskindetectresultdefinition: any;
  aiSkinDetectWeightsDefinitionsBySkinType: any[];
  skintypes: any;

  updateWeights(skintypeId, id, data): boolean;
  save(): void;
}

export class AiSkinDetectResultDefinitionEditCtrl implements IAiSkinDetectResultDefinitionEditViewModel, angular.IController {
  isEdit: boolean;

  aiskindetectresultdefinition: any;
  aiSkinDetectWeightsDefinitionsBySkinType: any;
  skintypes: any;

  private logger: ILogger;

  private aiSkinDetectResultDefinitionService: any;
  private aiSkinDetectWeightsDefinitionService: any;

  static $inject = ["smartShelvesDatacontext", "common", "$stateParams", "$state", "$filter"];
  constructor(datacontext: SmartShelvesDatacontext, private common: any, private $stateParams: StateParams, private $state: StateService, private $filter: angular.IFilterService) {
    this.logger = common.logger;

    this.aiSkinDetectResultDefinitionService = datacontext.aiSkinDetectResultDefinitions();
    this.aiSkinDetectWeightsDefinitionService = datacontext.aiSkinDetectWeightsDefinitions();
  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;

    if (this.isEdit) {
      this.common.$q.all([this.aiskindetectresultdefinition.$promise, this.skintypes.$promise])
        .then((result: any[]) => {
          this.aiskindetectresultdefinition = result[0];
          this.skintypes = result[1];

          this.processWeightsDefinitions();
        });
    } else {
      this.aiskindetectresultdefinition = { d: 0, name: null, aiSkinDetectWeightsDefinitions: [] };
      this.aiSkinDetectWeightsDefinitionsBySkinType = [];
    }
  }

  processWeightsDefinitions() {
    this.logger.log("aiskindetectresultdefinition", this.aiskindetectresultdefinition);

    this.aiSkinDetectWeightsDefinitionsBySkinType = R.groupBy((aiSkinDetectWeightsDefinition: any) => aiSkinDetectWeightsDefinition.skinTypeId)(this.aiskindetectresultdefinition.aiSkinDetectWeightsDefinitions);

    this.logger.log("aiSkinDetectWeightsDefinitionsBySkinType 1", this.aiSkinDetectWeightsDefinitionsBySkinType);

    R.mapObjIndexed((aiSkinDetectWeightsDefinitions: any, key) => {
      aiSkinDetectWeightsDefinitions = R.assoc("weights", R.values(aiSkinDetectWeightsDefinitions), {});

      const skintype = R.find(R.propEq("id", +key))(this.skintypes);
      if (skintype) aiSkinDetectWeightsDefinitions = R.assoc("skintype", skintype, aiSkinDetectWeightsDefinitions);

      this.aiSkinDetectWeightsDefinitionsBySkinType[key] = aiSkinDetectWeightsDefinitions;

    }, this.aiSkinDetectWeightsDefinitionsBySkinType);

    this.aiSkinDetectWeightsDefinitionsBySkinType = R.values(this.aiSkinDetectWeightsDefinitionsBySkinType);
    this.logger.log("aiSkinDetectWeightsDefinitionsBySkinType 2", this.aiSkinDetectWeightsDefinitionsBySkinType);
  }

  updateWeights(skintypeId: any, id: any, data: number): boolean {
    data = +data;
    if (isNaN(data) || data < 0 || data > 1) return false;

    this.logger.log("skintypeId", skintypeId);
    this.logger.log("id", id);

    const weightsDefinitions: any = this.$filter("filter")(this.aiSkinDetectWeightsDefinitionsBySkinType, { skintype: { id: skintypeId } }, true)[0];
    this.logger.log("weightsDefinitions", weightsDefinitions);

    const weights: any = this.$filter("filter")(weightsDefinitions.weights, { id: id }, true)[0];
    this.logger.log("weights", weights);

    weights.weights = data;

    this.aiSkinDetectWeightsDefinitionService.update({ id: weights.id },
      weights, value => {
        this.logger.log("save weights", value);
        return true;
      },
      httpResponse => {
        this.logger.error("Update Weights Error", httpResponse, "Error");
        return false;
      }
    );

    return false;
  }

  save(): void {
    if (this.isEdit) {
      this.aiSkinDetectResultDefinitionService.update(
        { id: this.aiskindetectresultdefinition.id },
        this.aiskindetectresultdefinition,
        () => {
          this.$state.go("smartshelves.aiskindetect.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    } else {
      this.aiSkinDetectResultDefinitionService.save(
        null,
        this.aiskindetectresultdefinition,
        () => {
          this.$state.go("smartshelves.aiskindetect.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    }
  }
}
