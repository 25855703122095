import { StateProvider } from "@uirouter/angularjs";
routeConfig.$inject = ["$stateProvider"];

export function routeConfig($stateProvider: StateProvider): void {
  $stateProvider.state("smartshelves", {
    abstract: true,
    url: "/smartshelves",
      template:  require("../views/common/content.html"),
    data: { pageTitle: "SmartShelves", requiresLogin: true }
  });
}
