import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(
  rootUrl: string,
  $stateProvider: StateProvider
): void {
  $stateProvider
    .state("medias", {
      abstract: true,
      url: "/medias",
      template: require("../views/common/content.html"),
      data: { pageTitle: "Media", requiresLogin: true }
    })
    .state("medias.list", {
      url: "/lists",
      template: require("./views/lists.html"),
      controller: "MediaList",
      controllerAs: "vm"
    })
    .state("medias.edit", {
      url: "/createEdit/{id:int}",
      template: require("./views/create_edit.html"),
      controller: "MediaCreatEdit",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "ngTagsInput",
                files: [
                  rootUrl +
                  "assets/plugins/ng-tags-input/ng-tags-input.min.css",
                  rootUrl +
                  "assets/plugins/ng-tags-input/ng-tags-input.bootstrap.min.css",
                  rootUrl + "assets/plugins/ng-tags-input/ng-tags-input.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("medias.view", {
      url: "/view/{mediaType:string}{dispalyName:string}{fileUrl:string}",
      template: require("./views/view.html"),
      controller: "MediaView",
      controllerAs: "vm"
    })
    .state("medias.create", {
      url: "/createEdit",
      template: require("./views/create_edit.html"),
      controller: "MediaCreatEdit",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "ngTagsInput",
                files: [
                  rootUrl +
                  "assets/plugins/ng-tags-input/ng-tags-input.min.css",
                  rootUrl +
                  "assets/plugins/ng-tags-input/ng-tags-input.bootstrap.min.css",
                  rootUrl + "assets/plugins/ng-tags-input/ng-tags-input.min.js"
                ]
              }
            ])
        ]
      }
    });
}
