export var apiUrl: string;
export var functionUrl: string;
export var functionCode: string;
export var environment: number; // for 影像辨識區分的變數
export var region: string;

switch (location.hostname) {
    case "www.core-pcloud.com":
        apiUrl = "https://pcloud-api.azurewebsites.net/";
        functionUrl = "https://pcloud-function.azurewebsites.net/";
        functionCode = "DppCl7ukXd0Gdxmz4YocUcOR6ghNSJ5SecB1h9o9eFescv7ZbaDYhg==";
        environment = 1;
        region = "TW";
        break;
    case "poc.core-pcloud.com":
        apiUrl = "https://pcloud-poc-api.azurewebsites.net/";
        functionUrl = "https://pcloud-function-poc.azurewebsites.net/";
        functionCode = "QcuDjgW68iHPRLFg7nw6I9afbmcdNuAJWh5RYOFjYXyPHFj/uBt8HQ==";
        environment = 2;
        region = "TW";
        break;
    case "dev.core-pcloud.com":
        apiUrl = "https://pcloud-dev-api.azurewebsites.net/";
        functionUrl = "https://pcloud-function-dev.azurewebsites.net/";
        functionCode = "WBPZaucSUYH39RlyaYxzra5r2yr6I775V4hLVsBVj59o9xWdyuQYpA==";
        environment = 3;
        region = "TW";
        break;

    // CN
    case "cn.core-pcloud.com":
        apiUrl = "https://pcloud-api-cn.azurewebsites.net/";
        functionUrl = "https://pcloud-function-dev.azurewebsites.net/";
        functionCode = "WBPZaucSUYH39RlyaYxzra5r2yr6I775V4hLVsBVj59o9xWdyuQYpA==";
        environment = 5;
        region = "CN";
        break;

    default:
        apiUrl = "https://pcloud-dev-api.azurewebsites.net/";
        functionUrl = "https://pcloud-function-dev.azurewebsites.net/";
        functionCode = "WBPZaucSUYH39RlyaYxzra5r2yr6I775V4hLVsBVj59o9xWdyuQYpA==";
        environment = 1;
        region = "TW";
}


