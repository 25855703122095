import * as angular from "angular";
import * as moment from "moment-timezone";
import * as R from "ramda";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { IPlayList, IDevice } from "../models/models";
import { FunctionalModule } from "../models/enums";

export interface IScheduleDialogFactory {
	create(): angular.IPromise<any>;
	edit(scheduleId: number): angular.IPromise<any>;
}

scheduleFactory.$inject = ["$uibModal", "datacontext"];

export function scheduleFactory($uibModal: angular.ui.bootstrap.IModalService, datacontext: Datacontext): IScheduleDialogFactory {
	const factory: IScheduleDialogFactory = { create: createData, edit: editData };

	return factory;

	function createData(): angular.IPromise<any> {
		const modalOptions: angular.ui.bootstrap.IModalSettings = {
			template: require("./views/editModal.html"),
			controller: modalInstance,
			controllerAs: "vm",
			windowClass: "animated flipInY",
			size: "lg",
			resolve: {
				data: () => ({
					isEdit: false,
					item: {
						name: "",
						duration: {
							startDate: moment().startOf("day"),
							endDate: moment().startOf("day"),
							startTime: moment().format("HH:mm"),
							endTime: moment().format("HH:mm"),
							timeZone: moment.tz.guess(),
							isRecurrence: false,
							recurrenceRule: ""
						},
						playlistId: null,
						devices: []
					},
					playlists: datacontext.playlists().query(),
					devices: datacontext.devices().query()
				})
			}
		};

		return $uibModal.open(modalOptions).result;
	}

	function editData(scheduleId: number): angular.IPromise<any> {
		const modalOptions: angular.ui.bootstrap.IModalSettings = {
			template: require("./views/editModal.html"),
			controller: modalInstance,
			controllerAs: "vm",
			windowClass: "animated flipInY",
			size: "lg",
			resolve: {
				data: () => ({
					isEdit: true,
					item: datacontext.schedulers().get({ id: scheduleId }),
					playlists: datacontext.playlists().query(),
					devices: datacontext.devices().query()
				})
			}
		};

		return $uibModal.open(modalOptions).result;
	}
}

modalInstance.$inject = ["$scope", "$uibModalInstance", "data", "datacontext", "common", "cronService", "$filter"];

function modalInstance($scope, $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, data, datacontext: Datacontext, common, cronService, $filter): void {
	var vm = this;
	var logger: ILogger = common.logger;
	var $q: ng.IQService = common.$q;
	const schedulerService = datacontext.schedulers();

	vm.isOverlaps = false;

	vm.recurrenceRule1 = "0 0 * * 0";
	vm.recurrenceRule2 = "0 0 * * *";

	vm.frequencyType = "3";
	vm.myLocalFrequency = null;
	vm.myLocalFrequency1 = null;
	vm.myLocalFrequency2 = null;
	vm.hasSave = false;

	activate();

	////////////
	function activate() {
		vm.isEdit = data.isEdit;

		// 下拉式選單使用
		data.devices.$promise.then(values => vm.setingDeives(values));

		vm.orientationFilter = 0;

		if (vm.isEdit) {
			common.$q.all([data.item.$promise, data.playlists.$promise, data.devices.$promise])
				.then(result => {
					vm.schedule = result[0] || {};
					vm.playlists = result[1] || [];
					vm.setingDeives(result[2]);

					vm.schedule.duration.startDate = moment.utc(vm.schedule.duration.startDate).local();
					vm.schedule.duration.endDate = moment.utc(vm.schedule.duration.endDate).local();

					if (vm.schedule.duration.isRecurrence) {
						vm.myLocalFrequency = cronService.fromCron(vm.schedule.duration.recurrenceRule, true, "default");
						vm.frequencyType = vm.myLocalFrequency.base.toString();

						if (vm.myLocalFrequency.base === 4) {
							vm.recurrenceRule1 = vm.schedule.duration.recurrenceRule;
						} else {
							vm.recurrenceRule2 = vm.schedule.duration.recurrenceRule;
						}
					}

					const playlist: IPlayList = R.find(R.propEq("id", vm.schedule.playlistId))(vm.playlists);
					if (playlist) vm.orientationFilter = playlist.orientation;

					R.map(item => {
						const device = R.find(R.propEq("id", item))(vm.availableDeviceLists);
						const idx = $filter("findIndexByKeyValue")(vm.availableDeviceLists, "id", item);
						if (idx !== -1) {
							vm.selectedDeviceLists.push(device);
							vm.availableDeviceLists.splice(idx, 1);
						}
					}, vm.schedule.devices);

					logger.log(vm.schedule);
				});
		} else {
			vm.schedule = data.item || {};
			vm.playlists = data.playlists;
		}
	}

	vm.setingDeives = values => {
		vm.devices = values.filter(
			device => device.functionalModule & FunctionalModule.MediaPlayer
		);

		vm.availableDeviceLists = [];
		vm.selectedDeviceLists = [];
		angular.copy(vm.devices, vm.availableDeviceLists);

		vm.availableDevices = [];
		vm.selectedDevices = [];
	};

	vm.cancel = () => {
		$uibModalInstance.dismiss("cancel");
	};
	vm.del = () => {
		datacontext.schedulers().remove({ id: vm.schedule.id }, null, () => {
			$uibModalInstance.dismiss("cancel");
		});
	};

	vm.save = () => {
		if (vm.hasSave) return;
		vm.hasSave = true;
		if (vm.isOverlaps) {
			schedulerService.overrideAndPublish(
				{ id: vm.schedule.id },
				null,
				value => {
					logger.log("Publish schedule : ", value);
					logger.success("Publish schedule success!", vm.schedule, "Schedule");
					$uibModalInstance.close(vm.schedule);
				},
				httpResponse => {
					logger.error("Publish schedule has error!", httpResponse, "Schedule");
					$uibModalInstance.close(vm.schedule);
				}
			);
		} else {
			schedulerService.publish(
				{ id: vm.schedule.id },
				null,
				value => {
					logger.log("Publish schedule : ", value);
					logger.success("Publish schedule success!", vm.schedule, "Schedule");
					$uibModalInstance.close(vm.schedule);
				},
				httpResponse => {
					logger.error("Publish schedule has error!", httpResponse, "Schedule");
					$uibModalInstance.close(vm.schedule);
				}
			);
		}
	};

	// cron 下拉式選單設定
	vm.cronConfig = {
		allowMultiple: false,
		options: {
			allowMinute: false,
			allowHour: false,
			allowDay: true,
			allowWeek: false,
			allowMonth: false,
			allowYear: false
		}
	};
	vm.multipleCronConfig = {
		allowMultiple: true,
		options: {
			allowMinute: false,
			allowHour: false,
			allowDay: false,
			allowWeek: true,
			allowMonth: false,
			allowYear: false
		}
	};

	$scope.selectDay = day => {
		if (vm.myLocalFrequency1.dayValues.indexOf(day) >= 0) {
			vm.myLocalFrequency1.dayValues.splice(vm.myLocalFrequency1.dayValues.indexOf(day), 1);
		} else {
			vm.myLocalFrequency1.dayValues.push(day);
		}
	};

	//#region validation
	vm.SettingOrientationFilter = () => {
		var playlist: IPlayList = R.find(R.propEq("id", vm.schedule.playlistId))(vm.playlists);
		if (playlist) {
			vm.orientationFilter = playlist.orientation;
			vm.schedule.devices = [];
		}
	};

	$scope.step1Validation = () => !$scope.scheduleForm.schedulename.$invalid && !$scope.scheduleForm.playlistSelect.$invalid;
	$scope.step2Validation = () => !$scope.scheduleForm.startDate.$invalid && !$scope.scheduleForm.endDate.$invalid && !$scope.scheduleForm.startTime.$invalid && !$scope.scheduleForm.endTime.$invalid;
	$scope.step3Validation = () => !$scope.scheduleForm.$invalid && vm.schedule.devices.length > 0;
	$scope.step4Validation = () => !$scope.scheduleForm.$invalid;

	$scope.exitValidation = () => {
		var d = $q.defer();

		vm.schedule.published = false;

		if (typeof vm.schedule.duration.startDate === "string") {
			vm.schedule.duration.startDate = moment.tz(vm.schedule.duration.startDate, vm.schedule.duration.timeZone).utc();
			logger.log("after startDate", vm.schedule.duration.startDate);
		}
		if (typeof vm.schedule.duration.endDate === "string") {
			vm.schedule.duration.endDate = moment.tz(vm.schedule.duration.endDate, vm.schedule.duration.timeZone).utc();
			logger.log("after endDate", vm.schedule.duration.endDate);
		}

		if (vm.schedule.duration.isRecurrence) {
			if (vm.frequencyType === "4") {
				vm.schedule.duration.recurrenceRule = vm.recurrenceRule1;
			} else {
				vm.schedule.duration.recurrenceRule = vm.recurrenceRule2;
			}
		}

		if (vm.isEdit) {
			schedulerService.update(
				{ id: vm.schedule.id },
				vm.schedule,
				value => {
					schedulerService.get({ id: value.id }).$promise.then(schedule => {
						schedule.duration.startDate = moment.utc(schedule.duration.startDate).local();
						schedule.duration.endDate = moment.utc(schedule.duration.endDate).local();
						vm.schedule = schedule;
					});

					logger.success("Update schedule success!", vm.schedule, "Schedule");
					logger.log("Update schedule : ", value);
					return d.resolve(true);
				},
				httpResponse => {
					logger.error("Update schedule has error!", httpResponse, "Schedule");
					return d.resolve(false);
				}
			);
		} else {
			schedulerService.save(
				null,
				vm.schedule,
				value => {
					schedulerService.get({ id: value.id }).$promise.then(schedule => {
						schedule.duration.startDate = moment.utc(schedule.duration.startDate).local();
						schedule.duration.endDate = moment.utc(schedule.duration.endDate).local();
						vm.schedule = schedule;
					});

					vm.isEdit = true;

					logger.success(
						"Create new schedule success!",
						vm.schedule,
						"schedule"
					);
					logger.log("Create schedule : ", value);
					return d.resolve(true);
				},
				httpResponse => {
					logger.error("Create schedule has error!", httpResponse, "Schedule");
					return d.resolve(false);
				}
			);
		}

		return d.promise;
	};

	//endregion

	vm.moveItem = (items: IDevice[], from: IDevice[], to: IDevice[]) => {
		items.map(item => {
			//Here from is returned as blank and to as undefined
			var idx = $filter("findIndexByKeyValue")(from, "id", item.id);
			//var idx = from.indexOf(item);
			if (idx !== -1) {
				from.splice(idx, 1);
				to.push(item);
			}
		});

		vm.schedule.devices = vm.selectedDeviceLists.map(d => d.id);

		logger.log("availableDeviceLists", vm.availableDeviceLists);
		logger.log("selectedDeviceLists", vm.selectedDeviceLists);
		logger.log("schedule.devices", vm.schedule.devices);
	};
}
