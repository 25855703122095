import Swal from 'sweetalert2';

TagList.$inject = ['datacontext', 'common', 'tags'];

TagCreatEdit.$inject = ['datacontext', 'common', '$scope', '$stateParams', '$state', 'rootUrl'];

export function TagList(datacontext, common, tags) {
  var vm = this;
  var tagService = datacontext.tags();
  vm.tags = tags;
  vm.del = del;

  function del(indx) {
    var tag = vm.tags[indx];

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!'
    }).then(isConfirm => {
      if (isConfirm.value) {
        tagService.delete(
          { id: tag.id },
          (value, responseHeaders) => {
            vm.tags.splice(indx, 1);
            Swal.fire('Deleted!', 'Your tag has been deleted.', 'success');
          },
          httpResponse => {
            Swal.fire('Cancelled', 'Your tag file is safe :)', 'error');
          }
        );
      } else {
        Swal.fire('Cancelled!', 'Your tag file is safe :)', 'success');
      }
    });
  }
}

export function TagCreatEdit(datacontext, common, $scope, $stateParams, $state, rootUrl) {
  var ta = this;
  var logger = common.logger;
  var tags = datacontext.tags;
  ta.rootUrl = rootUrl;
  ta.save = save;
  $scope.save = save;

  activate();

  function activate() {
    // 判斷為編輯還是新增Tag
    $scope.isEdit = $stateParams.id !== undefined;

    // Tag 的初始化
    ta.tag = {
      id: 0,
      name: ''
    };

    if ($scope.isEdit) {
      tags.get({ id: $stateParams.id },
        data => {
          ta.tag = data;
        });
    }
  }

  function save(tag) {
    if ($scope.isEdit) {
      tag.orders = undefined;
      tags.update(
        { id: tag.id },
        tag,
        (value, responseHeaders) => {
          logger.success('Tag has Update!', value, 'Good job!');
          $state.go('tags.list');
        },
        httpResponse => {
          logger.error(httpResponse.data.message, httpResponse, 'Tag');
        }
      );
    } else {
      tags.save(
        null,
        tag,
        (value, responseHeaders) => {
          ta.tag = value;
          $scope.isEdit = true;
          logger.success('Tag has Create!', value, 'Good job!');
          $state.go('tags.list');
        },
        httpResponse => {
          logger.error(httpResponse.data.message, httpResponse, 'Tag');
        }
      );
    }
  }
}
