interface Window {
  encodeURIComponent: any;
}

declare var window: Window;

export function getById() {
  return function(input: any[], id: string | number) {
    var i = 0,
      len = input.length;
    for (; i < len; i++) {
      if (+input[i].id === +id) {
        return input[i];
      }
    }
    return null;
  };
}

export function findIndexByKeyValue() {
  return function(obj: { [x: string]: any; }[], key: string | number, value: any) {
    for (var i = 0; i < obj.length; i++) {
      if (obj[i][key] === value) {
        return i;
      }
    }
    return null;
  };
}

export function findOrderSettingStatusesByBoard() {
  return function(obj: { board: { id: any; }; }[], id: any) {
    for (var i = 0; i < obj.length; i++) {
      if (obj[i].board.id === id) {
        return i;
      }
    }
    return null;
  };
}

export function tagsFilter() {
  return function(items, tags) {
    var filtered = [];
    (items || []).forEach(function(item) {
      var matches = tags.every(function(tag: any) :boolean {
        for (var i = 0; i < item.tags.length; i++) {
          if (item.tags[i]["name"] === tag) {
            return true;
          }
        }
        return false;
      });
      if (matches) {
        filtered.push(item);
      }
    });
    return filtered;
  };
}

export function urlencode() {
  return function(input: any) {
    return window.encodeURIComponent(input);
  };
}

export function localDateTime() {
  return function(fromDateTime: string | number | Date) {
    if (!fromDateTime) return null;
    var fDate = new Date(fromDateTime);
    var date = new Date(
      Date.UTC(
        fDate.getFullYear(),
        fDate.getMonth(),
        fDate.getDate(),
        fDate.getHours(),
        fDate.getMinutes(),
        fDate.getSeconds()
      )
    );
    return date;
  };
}

export function heartbeatFilter() {
  return function(items: any[], heartbeat: any) {
    if (heartbeat === undefined || heartbeat === null) return items;

    var filtered = [];
    for (var i = 0; i < items.length; i++) {
      var item = items[i];
      if (item.heartbeat === heartbeat) {
        filtered.push(item);
      }
    }
    return filtered;
  };
}
