import * as angular from "angular";
import Swal from "sweetalert2";
import * as moment from "moment-timezone";

declare var Highcharts: any;
// import * as Highcharts from 'highcharts';

import { IDevice } from "../models/models";
import { FunctionalModule } from "../models/enums";

interface IMartPack1ViewModel {
  devices: Array<any>;
  selectDevice: IDevice;

  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  loading: boolean;

  getMartPack1();
}

export class MartPack1Ctrl implements IMartPack1ViewModel {
  devices: any[];
  selectDevice: IDevice;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dayKeyFrom: string;
  dayKeyTo: string;

  loading: boolean;

  static $inject = [
    "devices",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    devices,
    public $http,
    public apiUrl,
    public environment: number
  ) {
    this.loading = false;
    this.dateFrom = moment().subtract(8, "days");
    this.dateTo = moment().subtract(1, "days");

    devices.$promise.then(value => {
      this.devices = value.filter(
        device => device.functionalModule & FunctionalModule.FaceDetection
      );
    });
    //this.devices = [{ name: '麗寶摩天輪TV01', guid: '7F906552-6890-4D0C-89A8-026792992FD9' }];
    //console.log(this.devices);
  }

  //function declare
  getMartPack1() {
    this.loading = true;
    this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
    this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

    this.$http
      .post(this.apiUrl + "api/Analysis/MartPack1", {
        dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
        dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
        deviceGuid: this.selectDevice.guid,
        system: this.environment
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          //console.log(obj);

          this.drawChart11(obj.chart11, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart12(obj.chart12, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart13(obj.chart13, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart14(obj.chart14, this.dayKeyFrom, this.dayKeyTo);

          this.drawChart21(obj.chart21, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart2101(obj.chart22, this.dayKeyFrom, this.dayKeyTo); //所有的 Button Logs

          this.drawChart31(obj.chart31, this.dayKeyFrom, this.dayKeyTo);

          this.drawChart41(obj.chart41, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart42(obj.chart41, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart43(obj.chart41, this.dayKeyFrom, this.dayKeyTo);

          this.drawChart501(obj.chart51, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart501_1(obj.chart51, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart502(obj.chart51, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart503(obj.chart51, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart511(obj.chart52, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart511_1(obj.chart52, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart512(obj.chart52, this.dayKeyFrom, this.dayKeyTo);
          this.drawChart513(obj.chart52, this.dayKeyFrom, this.dayKeyTo);
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }

  drawChart11(objs: any, dayKeyFrom, dayKeyTo) {
    var y0Array = [];
    var i = 0;
    var totalCount = 0;

    angular.forEach(objs, obj => {
      i++;
      totalCount += obj.count;
      y0Array.push({ name: obj.gender, y: obj.count });
    });

    const chartOptions = {
      title: {
        text: `${dayKeyFrom} ~ ${dayKeyTo} 累計 ${totalCount} 人數`
      },
      chart: {
        type: "pie",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format:
              "<b>{point.name}</b>: {point.percentage:.1f} % <br> {point.y} 人數"
          },
          showInLegend: true
        }
      },
      colors: ["#ff8080", "#4775d1", "#33ff33"],
      series: [
        {
          name: "Count",
          data: y0Array
        }
      ]
    };
    Highcharts.chart("chart11", chartOptions);
  }

  drawChart12(objs: any, dayKeyFrom, dayKeyTo) {
    var y0Array = [];
    var i = 0;
    var totalCount = 0;

    angular.forEach(objs, obj => {
      i++;
      totalCount += obj.count;
      y0Array.push({ name: obj.ageGradeName, y: obj.count });
    });

    const chartOptions = {
      title: {
        text: `${dayKeyFrom} ~ ${dayKeyTo} 累計 ${totalCount} 人數`
      },
      chart: {
        type: "pie",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format:
              "<b>{point.name}</b>: {point.percentage:.1f} % <br> {point.y} 人數"
          },
          showInLegend: true
        }
      },
      colors: ["#FFBE5F", "#32C887", "#D25F5F", "#2387C3", "#A0A0A0"],
      series: [
        {
          name: "Count",
          data: y0Array
        }
      ]
    };

    Highcharts.chart("chart12", chartOptions);
  }

  drawChart13(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.smile);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 微笑程度" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("chart13", chartOptions);
  }

  drawChart14(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.smile);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 微笑程度" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("chart14", chartOptions);
  }

  drawChart21(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var totalCount = 0;
    var xArray = [],
      y0Array = [];
    angular.forEach(objs, obj => {
      i++;
      totalCount += obj.count;
      xArray.push(obj.day);
      y0Array.push(obj.count);
    });
    const chartOptions = {
      title: {
        text: dayKeyFrom + "~" + dayKeyTo + " 共 " + totalCount + " 次"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "次" }
      },
      series: [
        {
          name: "次數",
          data: y0Array
        }
      ]
    };
    Highcharts.chart("chart21", chartOptions);
  }

  drawChart2101(objs: any, dayKeyFrom, dayKeyTo) {
    var sData = {};
    sData["day"] = [];
    angular.forEach(objs, obj => {
      if (obj.chapterName in sData) {
        //do nothing
      } else {
        sData[obj.chapterName] = [];
      }
    });
    var oldDay = 0;
    var colIndex = -1;
    var totalCount = 0;
    angular.forEach(objs, obj => {
      if (obj.day != oldDay) {
        sData["day"].push(obj.day);
        colIndex = colIndex + 1;
        oldDay = obj.day;
      }
      totalCount = totalCount + obj.count;
      sData[obj.chapterName][colIndex] = obj.count;
    });

    const chartOptions = {
      title: {
        text: dayKeyFrom + "~" + dayKeyTo + " 共 " + totalCount + " 次"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: sData["day"]
      },
      yAxis: {
        title: { text: "次" }
      },
      series: []
    };
    var chart = Highcharts.chart("chart2101", chartOptions);
    Object.keys(sData).forEach(function(key) {
      if (key != "day") {
        for (var i = 0; i < sData[key].length; i++) {
          if (sData[key][i] == null) {
            sData[key][i] = 0;
          }
        }
        chart.addSeries({
          name: key,
          data: sData[key]
        });
      }
    });
  }

  drawChart31(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.hour);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 周間每小時觀看人數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: "normal",
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("chart31", chartOptions);
  }

  drawChart41(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.dayOfWeek);
      y0Array.push(obj.maleStaySec / obj.maleCount);
      y1Array.push(obj.femaleStaySec / obj.femaleCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + "周間每日平均關注秒數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "平均秒數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        }
      ]
    };
    Highcharts.chart("chart41", chartOptions);
  }

  drawChart42(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.dayOfWeek);
      y0Array.push(obj.maleSmile / obj.maleCount);
      y1Array.push(obj.femaleSmile / obj.femaleCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + "周間每日平均微笑程度" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "微笑程度" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        }
      ]
    };
    Highcharts.chart("chart42", chartOptions);
  }

  drawChart43(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.dayOfWeek);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + "周間每日關注人數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("chart43", chartOptions);
  }

  drawChart501(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.maleStaySec / obj.maleCount);
      y1Array.push(obj.femaleStaySec / obj.femaleCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 平均關注秒數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "平均秒數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        }
      ]
    };
    Highcharts.chart("chart501", chartOptions);
  }

  drawChart501_1(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.maleStaySec);
      y1Array.push(obj.femaleStaySec);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 關注總秒數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "總秒數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        }
      ]
    };
    Highcharts.chart("chart501_1", chartOptions);
  }

  drawChart502(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.maleSmile / obj.maleCount);
      y1Array.push(obj.femaleSmile / obj.femaleCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 平均微笑程度" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "微笑程度" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        }
      ]
    };
    Highcharts.chart("chart502", chartOptions);
  }

  drawChart503(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 關注人數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("chart503", chartOptions);
  }

  drawChart511(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.tStaySec / obj.tCount);
      y1Array.push(obj.yStaySec / obj.yCount);
      y2Array.push(obj.sStaySec / obj.sCount);
      y3Array.push(obj.mStaySec / obj.mCount);
      y4Array.push(obj.oStaySec / obj.oCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 平均關注秒數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "平均秒數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("chart511", chartOptions);
  }

  drawChart511_1(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.tStaySec);
      y1Array.push(obj.yStaySec);
      y2Array.push(obj.sStaySec);
      y3Array.push(obj.mStaySec);
      y4Array.push(obj.oStaySec);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 總關注秒數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "總秒數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("chart511_1", chartOptions);
  }

  drawChart512(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.tSmile / obj.tCount);
      y1Array.push(obj.ySmile / obj.yCount);
      y2Array.push(obj.sSmile / obj.sCount);
      y3Array.push(obj.mSmile / obj.mCount);
      y4Array.push(obj.oSmile / obj.oCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 平均微笑程度" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "平均微笑程度" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("chart512", chartOptions);
  }

  drawChart513(objs: any, dayKeyFrom, dayKeyTo) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: { text: dayKeyFrom + "~" + dayKeyTo + " 關注人數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: null,
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("chart513", chartOptions);
  }
}
