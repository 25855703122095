import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../data/datacontext";
import { SmartShelvesDatacontext } from "../smartshelves/data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("surveys", {
      abstract: true,
      url: "/surveys",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Surveys", requiresLogin: true }
    })
    .state("surveys.list", {
      url: "/lists",
      template:
        '<survey-list surveys="$resolve.surveys" authentication="authentication"></survey-list>',
      resolve: {
        surveys: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.surveys().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                serie: true,
                files: [
                  rootUrl + "Scripts/plugins/dataTables/datatables.min.js",
                  rootUrl + "Content/plugins/dataTables/datatables.min.css"
                ]
              },
              {
                serie: true,
                name: "datatables",
                files: [
                  rootUrl +
                    "Scripts/plugins/dataTables/angular-datatables.min.js"
                ]
              },
              {
                serie: true,
                name: "datatables.buttons",
                files: [
                  rootUrl +
                    "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("surveys.create", {
      url: "/create",
      template:
        '<survey-edit survey="" agencies="$resolve.agencies" skintypes="$resolve.skintypes" authentication="authentication"></survey-edit>',
      resolve: {
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        skintypes: [
          "smartShelvesDatacontext",
          (datacontext: SmartShelvesDatacontext) =>
            datacontext.skintypes().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              },
              {
                name: "ui.sortable",
                files: [
                  rootUrl + "assets/plugins/angular-ui-sortable/sortable.min.js"
                ]
              },
              {
                name: "xeditable",
                files: [
                  rootUrl +
                    "assets/plugins/angular-xeditable/css/xeditable.min.css",
                  rootUrl +
                    "assets/plugins/angular-xeditable/js/xeditable.min.js"
                ]
              },
              { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
            ])
        ]
      }
    })
    .state("surveys.edit", {
      url: "/{id:int}/edit",
      template:
        '<survey-edit survey="$resolve.survey" agencies="$resolve.agencies" skintypes="$resolve.skintypes" authentication="authentication"></survey-edit>',
      resolve: {
        survey: [
          "datacontext",
          "$stateParams",
          (datacontext: Datacontext, $stateParams) =>
            datacontext.surveys().get({ id: $stateParams.id })
        ],
        agencies: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.agencies().query()
        ],
        skintypes: [
          "smartShelvesDatacontext",
          (datacontext: SmartShelvesDatacontext) =>
            datacontext.skintypes().query()
        ],
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              },
              {
                name: "ui.sortable",
                files: [
                  rootUrl + "assets/plugins/angular-ui-sortable/sortable.min.js"
                ]
              },
              {
                name: "xeditable",
                files: [
                  rootUrl +
                    "assets/plugins/angular-xeditable/css/xeditable.min.css",
                  rootUrl +
                    "assets/plugins/angular-xeditable/js/xeditable.min.js"
                ]
              },
              { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
            ])
        ]
      }
    });
}
