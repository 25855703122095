import { StateProvider } from "@uirouter/angularjs";
import { Datacontext, IProjectorResource } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(
  rootUrl: string,
  $stateProvider: StateProvider
): void {
  $stateProvider
    .state("developer.projectors", {
      abstract: true,
      url: "/projectors",
      template:  require("../../views/common/sub_content.html"),
      data: { pageTitle: "Projectors", requiresLogin: true }
    })
    .state("developer.projectors.list", {
      url: "/lists",
      template:  require("./views/lists.html"),
      controller: "ProjectorList",
      controllerAs: "vm",
      resolve: {
        projectors: [
          "datacontext",
          (
            datacontext: Datacontext
          ): angular.resource.IResourceArray<IProjectorResource> =>
            datacontext.projectors().query()
        ]
      }
    })
    .state("developer.projectors.view", {
      url: "/{id:int}",
      template:  require("./views/view.html"),
      controller: "ProjectorView",
      controllerAs: "vm",
      resolve: {
        projector: [
          "datacontext",
          "$stateParams",
          (datacontext: Datacontext, $stateParams): IProjectorResource =>
            datacontext.projectors().get({ id: $stateParams.id })
        ]
      }
    })
    .state("developer.projectors.edit", {
      url: "/{id:int}/edit",
      template:  require("./views/create_edit.html"),
      controller: "ProjectorSave",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ]
      }
    })
    .state("developer.projectors.create", {
      url: "/create",
      template:  require("./views/create_edit.html"),
      controller: "ProjectorSave",
      controllerAs: "vm",
      resolve: {
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ]
      }
    });
}
