import * as angular from "angular";
import * as R from "ramda";

import { ILogger } from "../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../data/datacontext";

interface IDeviceScenarioListViewModel {
  devicescenarios: Array<any>;
  devices: any[];
  treasurehunts: any[];
  surveyresultdefinitions: any[];
  aiskindetectresultdefinitions: any[];

  dtOptions: any;
  dtColumnDefs: any;

  show(id, type): string;
  update(id, type, data): void;
}

export class DeviceScenarioListsCtrl implements IDeviceScenarioListViewModel, angular.IController {
  devicescenarios: any[];
  devices: any[];
  treasurehunts: any[];
  surveyresultdefinitions: any[];
  aiskindetectresultdefinitions: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly devicescenarioService: any;
  private readonly logger: ILogger;

  static $inject = ["smartShelvesDatacontext", "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder"];

  constructor(datacontext: SmartShelvesDatacontext, private readonly common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder) {
    this.logger = common.logger;
    this.devicescenarioService = datacontext.devicescenarios();

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "DeviceScenariosFile" },
        { extend: "pdf", title: "DeviceScenariosFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");
            $(win.document.body).find("table").addClass("compact").css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  $onInit() {
    this.common.$q
      .all([this.devices.$promise, this.devicescenarios.$promise])
      .then(result => {
        const [devices, devicescenarios] = result;
        R.map(devicescenario => {
          const found = R.find(R.propEq("id", devicescenario.deviceId))(devices);
          if (found) {
            found.treasureHuntId = devicescenario.treasureHuntId;
            found.surveyResultDefinitionId = devicescenario.surveyResultDefinitionId;
            found.aiSkinDetectResultDefinitionId = devicescenario.aiSkinDetectResultDefinitionId;
          }
        }, devicescenarios);

        this.devices = devices;
        this.devicescenarios = devicescenarios;

        this.logger.log("devices", this.devices);
        this.logger.log("devicescenarios", this.devicescenarios);
      });
  }

  show(id, type): string {
    if (type === "treasurehunt") {
      const selected = this.$filter("filter")(this.treasurehunts, { id: id });
      return id && selected.length ? selected[0].title : "--- 請選擇 ---";
    }
    if (type === "surveyresultdefinition") {
      const selected = this.$filter("filter")(this.surveyresultdefinitions, { id: id });
      return id && selected.length ? selected[0].name : "--- 請選擇 ---";
    }
    if (type === "aiskindetectresultdefinition") {
      const selected = this.$filter("filter")(this.aiskindetectresultdefinitions, { id: id });
      return id && selected.length ? selected[0].name : "--- 請選擇 ---";
    }
    return "--- 請選擇 ---";
  }

  update(id, type, data): void {
    const devices = this.$filter("filter")(this.devices, { id: id });
    if (devices.length) {
      const device = devices[0];

      let devicescenario = {
        id: 0,
        deviceId: device.id,
        treasureHuntId: null,
        surveyResultDefinitionId: null,
        aiSkinDetectResultDefinitionId: null
      };

      const devicescenarios = this.$filter("filter")(this.devicescenarios, { deviceId: devices[0].id });
      if (devicescenarios.length) { devicescenario = devicescenarios[0]; }

      if (type === "treasurehunt") { devicescenario.treasureHuntId = data; }
      if (type === "surveyresultdefinition") { devicescenario.surveyResultDefinitionId = data; }
      if (type === "aiskindetectresultdefinition") { devicescenario.aiSkinDetectResultDefinitionId = data; }

      if (devicescenario.id === 0) {
        this.devicescenarioService.save(null, devicescenario,
          value => { this.devicescenarios = this.devicescenarioService.query(); },
          httpResponse => { this.logger.log(httpResponse); }
        );
      } else {
        this.devicescenarioService.update({ id: devicescenario.id }, devicescenario,
          value => { devicescenario = value; },
          httpResponse => { this.logger.log(httpResponse); }
        );
      }
    }
  }
}
