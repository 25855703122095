export enum FunctionalModule {
  None = 0,
  MediaPlayer = 1 << 0,
  FaceDetection = 1 << 1,
  MartApp = 1 << 2,
  StoreApp = 1 << 3
}

export enum Handshake {
  OFF = 0,
  XON_XOFF = 1,
  RTS_CTS = 2,
  DSR_DTR = 3
}
export enum OperatingSystem {
  Android = 0,
  iOS = 1,
  Windows = 2
}
export enum Orientation {
  Landscape = 0,
  Portrait = 1
}
export enum Parity {
  None = 0,
  Odd = 1,
  Even = 2,
  Mark = 3,
  Space = 4
}
export enum ProjectorScheduleType {
  None = 0,
  AllDay = 1,
  Schedule = 2
}
export enum StopBits {
  One = 1,
  Two = 2,
  OnePointFive = 5
}

export enum MartCategory {
  Configuration = 0,
  Purchasing = 1,
  Ticket = 2,
  Game = 3
}
export enum MartImageCategory {
  Background = 0,
  Btn1 = 1,
  Btn2 = 2,
  Btn3 = 3,
  Reward = 4,
  Product = 5
}
export enum MartItemStatus {
  Preparation = 0,
  Release = 1,
  Close = 2,
  Cancel = 3,
  Delete = 4
}
