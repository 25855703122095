import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import 'icheck-bootstrap'

import '../Scripts/inspinia.js';

import * as angular from "angular";

import { RouteConfig, IdleConfig, HandledRejectionsConfig, DebugConfig, appRun } from "./config";
import { getById, findIndexByKeyValue, findOrderSettingStatusesByBoard, tagsFilter, urlencode, localDateTime, heartbeatFilter } from "./filters/filters";
import { pageTitle, sideNavigation, iboxTools, minimalizaSidebar, vectorMap,
         sparkline, ionRangeSlider, dropZone, responsiveVideo, chatSlimScroll,
         customValid, fullScroll, closeOffCanvas, clockPicker, landingScrollspy, fitHeight,
         iboxToolsFullScreen, slimScroll, truncate, touchSpin, markdownEditor, backImg, ngThumb
} from "./directives";

import { MainController, OpeningComponent } from './controller';

import "angular-gravatar";

import Core from "./core/core.module";
import Template from "./views/template.module";
import Translations from "./translations/translations.module";
import Authorization from "./authorization/authorization.module";
import Data from "./data/data.module";
import Account from "./account/account.module";
import Admin from "./admin/admin.module";
import Analysis from "./analysis/analysis.module";
import ApplicationCredential from "./applicationCredential/applicationCredential.module";
import Board from "./board/board.module";
import Customer from "./customer/customer.module";
import Dashboard from "./dashboards/dashboard.module";
import Developer from "./developer/developer.module";
import Device from "./device/device.module";
import Logs from "./logs/logs.module";
import Media from "./media/media.module";
import Tag from "./tag/tag.module";
import Playlist from "./playlist/playlist.module";
import Schedule from "./schedule/schedule.module";

import AltobEmergency from "./emergency/emergency.module";
import NeoMart from "./mart/mart.module";
import NeoStore from "./store/store.module";
import LifeVantageBulletin from "./bulletin/bulletin.module";

import Smartshelves from "./smartshelves/smartshelves.module";
import Survey from "./survey/survey.module";

import { CultureService } from './services/culture.service';

// create the module and define its dependencies.
angular.module("app", [
  // angular modules

  // 3rd Party Modules
  "ui.gravatar",

  // custom modules
  Core.name, Template.name, Translations.name, Authorization.name, Data.name,
  Account.name, Admin.name, Developer.name,
  Dashboard.name, Analysis.name,
  ApplicationCredential.name,
  Board.name, Device.name, Customer.name, Media.name, Tag.name,
  Playlist.name, Schedule.name,
  Logs.name,
  AltobEmergency.name,
  NeoMart.name, NeoStore.name, LifeVantageBulletin.name,
  Smartshelves.name, Survey.name
]);

angular
  .module("app")
  .config(RouteConfig)
  .config(IdleConfig)
  .config(HandledRejectionsConfig)
  .config(DebugConfig)
  .run(appRun);

  angular
  .module("app")
  .filter("getById", getById)
  .filter("findIndexByKeyValue", findIndexByKeyValue)
  .filter("findOrderSettingStatusesByBoard", findOrderSettingStatusesByBoard)
  .filter("tagsFilter", tagsFilter)
  .filter("urlencode", urlencode)
  .filter("localDateTime", localDateTime)
  .filter("heartbeatFilter", heartbeatFilter);

angular
  .module("app")
  .directive("pageTitle", pageTitle)
  .directive("sideNavigation", sideNavigation)
  .directive("iboxTools", iboxTools)
  .directive("minimalizaSidebar", minimalizaSidebar)
  .directive("vectorMap", vectorMap)
  .directive("sparkline", sparkline)
  .directive("ionRangeSlider", ionRangeSlider)
  .directive("dropZone", dropZone)
  .directive("responsiveVideo", responsiveVideo)
  .directive("chatSlimScroll", chatSlimScroll)
  .directive("customValid", customValid)
  .directive("fullScroll", fullScroll)
  .directive("closeOffCanvas", closeOffCanvas)
  .directive("clockPicker", clockPicker)
  .directive("landingScrollspy", landingScrollspy)
  .directive("fitHeight", fitHeight)
  .directive("iboxToolsFullScreen", iboxToolsFullScreen)
  .directive("slimScroll", slimScroll)
  .directive("truncate", truncate)
  .directive("touchSpin", touchSpin)
  .directive("markdownEditor", markdownEditor)
  .directive("backImg", backImg)
  .directive("ngThumb", ngThumb);

  angular
  .module("app")
  .service('CultureService', CultureService);

angular.module("app").controller("MainCtrl", MainController)
  .component("opening", {
    template: require("./views/opening.html"),
    controller: OpeningComponent
  });
