import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["$stateProvider"];

export function routeConfig($stateProvider: StateProvider): void {
  $stateProvider.state("admin", {
    abstract: true,
    url: "/admin",
    template: require("../views/common/content.html"),
    data: { pageTitle: "Admin", requiresLogin: true, roles: ["SystemAdmin"] }
  });
}
