import * as angular from "angular";
import { ILogger } from "../blocks/logger/logger";
import { IAccountService } from "./account.factory";
import { Datacontext } from "../data/datacontext";

interface IAccountManageViewModel {
  user: any;
  authentication: any;

  showVerifyEmail: boolean;
  verifyCode: string;

  phoneNumber: string;
  showEditPhoneNumber: boolean;
  showVerifyPhoneNumber: boolean;
  showVerifyGoogleAuthenticator: boolean;
  googleAuthenticatorModel: IGoogleAuthenticatorModel;

  sendVerifyEmailCode(): void;
  verifyEmail(code: string): void;

  addPhoneNumber(number: string): void;
  removePhoneNumber(): void;
  verifyPhoneNumber(code: string): void;

  enableGoogleAuthenticator(): void;
  disableGoogleAuthenticator(): void;
  verifyGoogleAuthenticator(googleAuthenticatorModel: IGoogleAuthenticatorModel): void;

  enableTwoFactorAuthentication(): void;
  disableTwoFactorAuthentication(): void;

  lineNotifyAuthorize(): void;
}
export class AccountManageCtrl
  implements IAccountManageViewModel, angular.IController {
  user: any;
  authentication: any;

  showVerifyEmail: boolean;
  verifyCode: string;

  phoneNumber: string;
  showEditPhoneNumber: boolean;
  showVerifyPhoneNumber: boolean;
  showVerifyGoogleAuthenticator: boolean;
  showLineNotify: boolean;

  googleAuthenticatorModel: IGoogleAuthenticatorModel;

  private readonly useService: any;
  private readonly notifyAccountService: any;

  private readonly logger: ILogger;

  static $inject = ["AccountService", "common", "apiUrl", "$state", "datacontext"];
  constructor(accountService: IAccountService, common: any, private apiUrl: string, $state, datacontext: Datacontext) {
    this.showVerifyEmail = false;
    this.showEditPhoneNumber = false;
    this.showVerifyPhoneNumber = false;
    this.showVerifyGoogleAuthenticator = false;
    this.showLineNotify = false;

    this.useService = accountService.users();
    this.notifyAccountService = datacontext.notifyAccounts();
    this.logger = common.logger;

    const accessToken = $state.params.accessToken;
    if (accessToken) {
      this.logger.log("accessToken", accessToken);
      this.notifyAccountService.setupUserAccount({ accessToken: accessToken }, null,
        value => {
          this.logger.success("Setup Line Notify Success", value, "Success");
        },
        httpResponse => {
          this.logger.error("Setup Line Notify Error", httpResponse, "Error");
        }
      );
    }
  }

  $onInit() {
    this.user = this.useService.get({ id: this.authentication.id });
    this.phoneNumber = this.user.phoneNumber;
    console.log(this.user);
  }

  //-- E-Mail 相關 --
  sendVerifyEmailCode(): void {
    this.useService.sendVerifyEmailCode(
      null,
      null,
      () => {
        this.showVerifyEmail = true;
      },
      response => {
        var errors = [];
        for (let key in response.data.modelState) {
          if (response.data.modelState.hasOwnProperty(key)) {
            for (let i = 0; i < response.data.modelState[key].length; i++) {
              errors.push(response.data.modelState[key][i]);
            }
          }
        }
        this.logger.error(errors.join(" "), response);
      }
    );
  }

  verifyEmail(code: string): void {
    this.useService.verifyEmail(
      { code: code },
      null,
      () => {
        this.user = this.useService.get({ id: this.authentication.id });
        this.showVerifyEmail = false;
        this.verifyCode = "";
        this.logger.success("已完成驗證");
      },
      response => {
        var errors = [];
        for (let key in response.data.modelState) {
          if (response.data.modelState.hasOwnProperty(key)) {
            for (let i = 0; i < response.data.modelState[key].length; i++) {
              errors.push(response.data.modelState[key][i]);
            }
          }
        }
        this.logger.error(errors.join(" "), response);
      }
    );
  }

  //-- 電話號碼 相關 --
  addPhoneNumber(number: string): void {
    this.useService.addPhoneNumber(
      null,
      { number: number },
      data => {
        this.showEditPhoneNumber = false;
        this.showVerifyPhoneNumber = true;
      },
      response => {
        var errors = [];
        for (let key in response.data.modelState) {
          if (response.data.modelState.hasOwnProperty(key)) {
            for (let i = 0; i < response.data.modelState[key].length; i++) {
              errors.push(response.data.modelState[key][i]);
            }
          }
        }
        this.logger.error(errors.join(" "), response, "無法新增或變更電話號碼");
      }
    );
  }

  removePhoneNumber(): void {
    this.useService.removePhoneNumber(
      null,
      null,
      () => {
        this.user = this.useService.get({ id: this.authentication.id });
        this.phoneNumber = null;
        this.logger.success("已移除電話號碼");
      },
      response => {
        this.logger.error(response.data, response, "無法移除電話號碼");
      }
    );
  }

  verifyPhoneNumber(code: string): void {
    this.useService.verifyPhoneNumber(
      null,
      { code: code, phoneNumber: this.phoneNumber },
      data => {
        this.showVerifyPhoneNumber = false;
        this.user.phoneNumber = this.phoneNumber;
        this.verifyCode = "";
      },
      response => {
        var errors = [];
        for (let key in response.data.modelState) {
          if (response.data.modelState.hasOwnProperty(key)) {
            for (let i = 0; i < response.data.modelState[key].length; i++) {
              errors.push(response.data.modelState[key][i]);
            }
          }
        }
        this.logger.error(errors.join(" "), response);
      }
    );
  }

  //-- Google 驗證器 相關 --
  enableGoogleAuthenticator(): void {
    this.useService.enableGoogleAuthenticator(
      null,
      null,
      data => {
        this.showVerifyGoogleAuthenticator = true;
        this.googleAuthenticatorModel = data;
      },
      response => {
        this.logger.error(response.data, response, "啟用 Google 驗證器失敗");
      }
    );
  }

  disableGoogleAuthenticator(): void {
    this.useService.disableGoogleAuthenticator(
      null,
      null,
      () => {
        this.user = this.useService.get({ id: this.authentication.id });
      },
      response => {
        this.logger.error(response.data, response, "停用 Google 驗證器失敗");
      }
    );
  }

  verifyGoogleAuthenticator(googleAuthenticatorModel: IGoogleAuthenticatorModel): void {
    this.useService.verifyGoogleAuthenticator(null, googleAuthenticatorModel,
      () => {
        this.showVerifyGoogleAuthenticator = false;
        this.user = this.useService.get({ id: this.authentication.id });
      },
      response => {
        var errors = [];
        for (let key in response.data.modelState) {
          if (response.data.modelState.hasOwnProperty(key)) {
            for (let i = 0; i < response.data.modelState[key].length; i++) {
              errors.push(response.data.modelState[key][i]);
            }
          }
        }
        this.logger.error(errors.join(" "), response, "驗證錯誤");
      }
    );
  }

  //-- 兩階段驗證 --
  enableTwoFactorAuthentication(): void {
    this.useService.enableTwoFactorAuthentication(
      null,
      null,
      () => {
        this.user = this.useService.get({ id: this.authentication.id });
      },
      response => {
        this.logger.error(
          response.data.message,
          response,
          "無法啟用兩階段驗證"
        );
      }
    );
  }

  disableTwoFactorAuthentication(): void {
    this.useService.disableTwoFactorAuthentication(
      null,
      null,
      () => {
        this.user = this.useService.get({ id: this.authentication.id });
      },
      response => {
        this.logger.error(response.data, response, "停用兩階段驗證失敗");
      }
    );
  }

  //-- Line 通知 相關 --
  lineNotifyAuthorize(): void {
    var url = "https://notify-bot.line.me/oauth/authorize?";
    url += "response_type=code";
    url += "&client_id=nDohkQ7U0QTc0DtjbhTUiZ";
    url += `&redirect_uri=${this.apiUrl}api/LineBot/Notify`;
    url += "&scope=notify";
    url += "&state=pcloud";

    window.location.href = url;
  }
}

interface IGoogleAuthenticatorModel {
  secretKey: string;
  barcodeUrl: string;
  code: string;
}

export interface IChangePasswordModel {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export class AccountChangepasswordCtrl implements angular.IController {
  changePasswordModel: IChangePasswordModel;

  savedSuccessfully: boolean;
  message: string;

  private readonly useService: any;
  private readonly logger: ILogger;

  static $inject = ["AccountService", "common", "$state"];
  constructor(accountService: IAccountService, common: any, private readonly $state) {
    this.useService = accountService.users();
    this.logger = common.logger;
    this.savedSuccessfully = true;
  }

  $onInit() { }

  changePassword(changePasswordModel: IChangePasswordModel) {
    this.useService.changePassword(null, changePasswordModel,
      () => this.$state.go("account.manage"),
      response => {
        this.savedSuccessfully = false;
        var errors = [];
        for (let key in response.data.modelState) {
          if (response.data.modelState.hasOwnProperty(key)) {
            for (let i = 0; i < response.data.modelState[key].length; i++) {
              errors.push(response.data.modelState[key][i]);
            }
          }
        }
        this.message = "Failed to change password due to: " + errors.join(" ");
        this.logger.log(response);
      }
    );
  }
}
