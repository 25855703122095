import { StateProvider } from "@uirouter/angularjs";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(  rootUrl: string,  $stateProvider: StateProvider): void {
  $stateProvider
    .state("smartshelves.aiskindetect", {
      abstract: true,
      url: "/aiskindetect",
      template: require("../../views/common/sub_content.html"),
      data: {
        pageTitle: "AI Skin Detect Result Definitions",
        requiresLogin: true
      }
    })
    .state("smartshelves.aiskindetect.list", {
      url: "/lists",
      template:
        '<aiskindetectresultdefinition-list aiskindetectresultdefinitions="$resolve.aiskindetectresultdefinitions" authentication="authentication"></aiskindetectresultdefinition-list>',
      resolve: {
        aiskindetectresultdefinitions: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.aiSkinDetectResultDefinitions().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          {
            serie: true,
            files: [
              rootUrl + "Scripts/plugins/dataTables/datatables.min.js",
              rootUrl + "Content/plugins/dataTables/datatables.min.css"
            ]
          },
          {
            serie: true,
            name: "datatables",
            files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"]
          },
          {
            serie: true,
            name: "datatables.buttons",
            files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"]
          }
        ])
        ]
      }
    })
    .state("smartshelves.aiskindetect.create", {
      url: "/create",
      template: '<aiskindetectresultdefinition-edit aiskindetectresultdefinition="" skintypes="" agencies="$resolve.agencies" authentication="authentication"></aiskindetectresultdefinition-edit>',
      resolve: {
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          {
            name: "xeditable",
            files: [
              rootUrl + "assets/plugins/angular-xeditable/css/xeditable.min.css",
              rootUrl + "assets/plugins/angular-xeditable/js/xeditable.min.js"
            ]
          }
        ])
        ]
      }
    })
    .state("smartshelves.aiskindetect.edit", {
      url: "/{id:int}/edit",
      template: '<aiskindetectresultdefinition-edit aiskindetectresultdefinition="$resolve.aiskindetectresultdefinition" skintypes="$resolve.skintypes" agencies="$resolve.agencies" authentication="authentication"></aiskindetectresultdefinition-edit>',
      resolve: {
        aiskindetectresultdefinition: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext, $stateParams) => datacontext.aiSkinDetectResultDefinitions().get({ id: $stateParams.id })],
        skintypes: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext) => datacontext.skintypes().query()],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          {
            name: "xeditable",
            files: [
              rootUrl + "assets/plugins/angular-xeditable/css/xeditable.min.css",
              rootUrl + "assets/plugins/angular-xeditable/js/xeditable.min.js"
            ]
          }
        ])
        ]
      }
    });
}
