import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../../data/datacontext";

routeConfig.$inject = ["$stateProvider"];

export function routeConfig($stateProvider: StateProvider): void {
  $stateProvider
    .state("developer.applications", {
      abstract: true,
      url: "/applications",
      template:  require("../../views/common/sub_content.html"),
      data: { pageTitle: "Apps", requiresLogin: true }
    })
    .state("developer.applications.list", {
      url: "/lists",
      template:  require("./views/lists.html"),
      controller: "ApplicationListsCtrl",
      controllerAs: "vm",
      resolve: {
        apps: ["datacontext", (datacontext: Datacontext): any => datacontext.apps().query()]
      }
    })
    .state("developer.applications.edit", {
      url: "/{id:int}/edit",
      template:  require("./views/create_edit.html"),
      controller: "ApplicationSaveCtrl",
      controllerAs: "vm"
    })
    .state("developer.applications.create", {
      url: "/create",
      template:  require("./views/create_edit.html"),
      controller: "ApplicationSaveCtrl",
      controllerAs: "vm"
    });
}
