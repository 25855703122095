import * as angular from "angular";
import { routeConfig } from "./config.route";

import { LicenseListsCtrl, LicenseListTable, LicenseCreateEditCtrl } from "./license";

export default angular.module("app.admin.license", [])
  .config(routeConfig)
  .component("licenseListTable", {
    template: require("./views/list.table.html"),
    controller: LicenseListTable,
    bindings: {
      licenses: "<",
      authentication: "<",
      issued: "=",
      opening: "="
    }
  })
  .component("licenseList", {
    template: require("./views/lists.html"),
    controller: LicenseListsCtrl,
    bindings: {
      licenses: "<",
      authentication: "<"
    }
  })
  .component("licenseCreate", {
    template: require("./views/create_edit.html"),
    controller: LicenseCreateEditCtrl,
    bindings: {
      agencies: "<",
      authentication: "<"
    }
  })
  .component("licenseEdit", {
    template: require("./views/create_edit.html"),
    controller: LicenseCreateEditCtrl,
    bindings: {
      agencies: "<",
      authentication: "<"
    }
  });
