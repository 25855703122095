import { Datacontext } from "../../data/datacontext";

interface IConfigurationModel {
  license: any;
  configurations: any;
  totalCount: number;
  ota: any;
  usage(): number;

  updateConfiguration(id: number, data: string): any;
  updateOTA(): any;
}

export class ConfigurationListCtrl implements IConfigurationModel {
  totalCount: number;
  private configurationService: any;
  private otaService: any;

  static $inject = [
    "datacontext",
    "license",
    "configurations",
    "devices",
    "ota",
    "$filter"
  ];

  constructor(
    datacontext: Datacontext,
    public license: any,
    public configurations: any,
    devices: angular.resource.IResourceArray<angular.resource.IResource<any>>,
    public ota: any,
    private $filter
  ) {
    this.configurationService = datacontext.configurations();
    this.otaService = datacontext.ota();

    this.license.quantity = this.license.quantity || 100;
    this.totalCount = 0;

    devices.$promise.then(data => {
      this.totalCount = data.length;
    });
  }

  usage(): number {
    return this.totalCount === 0
      ? 0
      : (this.totalCount / this.license.quantity) * 100;
  }

  updateConfiguration(id: number, data: string): any {
    var filtered = this.$filter("filter")(this.configurations, { id: id });
    if (filtered.length) {
      filtered[0].value = data;
      this.configurationService.update({ id: id }, filtered[0]);
    }
  }

  updateOTA(): any {
    this.otaService.update({ id: this.ota.id }, this.ota);
  }
}
