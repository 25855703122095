import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(
  rootUrl: string,
  $stateProvider: StateProvider
): void {
  $stateProvider
    .state("playlists", {
      abstract: true,
      url: "/playlists",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Playlists", requiresLogin: true }
    })
    .state("playlists.list", {
      url: "/lists/{intercut}",
      template:  require("./views/lists.html"),
      controller: "List",
      controllerAs: "vm",
      resolve: {
        playlists: ["datacontext", (datacontext: Datacontext) => datacontext.playlists().query()]
      }
    })
    .state("playlists.create", {
      abstract: true,
      url: "/create/{intercut}",
      template:  require("./views/create_edit.html"),
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "ui.sortable",
                files: [
                  rootUrl + "assets/plugins/angular-ui-sortable/sortable.min.js"
                ]
              },
              {
                files: [
                  "Content/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css"
                ]
              },
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              },
              {
                name: "angular-cron-jobs",
                files: [
                  rootUrl +
                    "assets/plugins/angular-cron-jobs/angular-cron-jobs.min.css",
                  rootUrl +
                    "assets/plugins/angular-cron-jobs/angular-cron-jobs.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("playlists.create.step1", {
      url: "/step1",
      template:  require("./views/step1.html")
    })
    .state("playlists.create.step2", {
      url: "/step2/chapter/{chapterIndx:int}",
      template:  require("./views/step2.html"),
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "gridster",
                files: [
                  rootUrl +
                    "assets/plugins/javascript-detect-element-resize/jquery.resize.js",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.css",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.js"
                ]
              },
              {
                name: "com.2fdevs.videogular",
                files: [
                  rootUrl +
                    "assets/plugins/videogular/videogular/videogular.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("playlists.edit", {
      abstract: true,
      url: "/edit/{id:int}",
      template:  require("./views/create_edit.html"),
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "ui.sortable",
                files: [
                  rootUrl + "assets/plugins/angular-ui-sortable/sortable.min.js"
                ]
              },
              {
                files: [
                  "Content/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css"
                ]
              },
              {
                name: "moment-picker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                  rootUrl +
                    "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              },
              {
                name: "angular-cron-jobs",
                files: [
                  rootUrl +
                    "assets/plugins/angular-cron-jobs/angular-cron-jobs.min.css",
                  rootUrl +
                    "assets/plugins/angular-cron-jobs/angular-cron-jobs.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("playlists.edit.step1", {
      url: "/step1",
      template:  require("./views/step1.html")
    })
    .state("playlists.edit.step2", {
      url: "/step2/chapter/{chapterIndx:int}",
      template:  require("./views/step2.html"),
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "gridster",
                files: [
                  rootUrl +
                    "assets/plugins/javascript-detect-element-resize/jquery.resize.js",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.css",
                  rootUrl +
                    "assets/plugins/angular-gridster/angular-gridster.min.js"
                ]
              },
              {
                name: "com.2fdevs.videogular",
                files: [
                  rootUrl +
                    "assets/plugins/videogular/videogular/videogular.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    });
}
