import * as angular from "angular";
import { LOCALES } from "./constants";
import { TranslateConfig, DynamicLocaleConfig } from "./translations.config";
import { ngTranslateLanguageSelect } from "./translations.directive";
import { localeService } from "./translations.service";

// Angular modules
import "angular-sanitize";
import "angular-cookies";

// 3rd Party Modules
import 'angular-translate';
// import 'angular-translate/dist/angular-translate-storage-cookie';
// import 'angular-translate/dist/angular-translate-storage-local';
// import 'angular-translate/dist/angular-translate-loader-static-files';
// import 'angular-translate/dist/angular-translate-handler-log';
import 'angular-translate/dist/angular-translate-storage-cookie/angular-translate-storage-cookie.js';
import 'angular-translate/dist/angular-translate-storage-local/angular-translate-storage-local.js';
import 'angular-translate/dist/angular-translate-loader-static-files/angular-translate-loader-static-files.js';
import 'angular-translate/dist/angular-translate-handler-log/angular-translate-handler-log.js';

import 'angular-dynamic-locale';

export default angular
  .module("app.translations", [
    // Angular modules
    "ngSanitize",
    "ngCookies",

    // Custom modules

    // 3rd Party Modules
    "pascalprecht.translate", // Angular Translate
    "tmh.dynamicLocale" // Angular Dynamic Locale
  ])
  .constant("LOCALES", LOCALES)
  .config(TranslateConfig)
  .config(DynamicLocaleConfig)
  .directive("ngTranslateLanguageSelect", ngTranslateLanguageSelect)
  .service("LocaleService", localeService);
