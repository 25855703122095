import * as angular from "angular";
import { routeConfig } from "./config.route";
import { SurveyresultdefinitionListsCtrl, SurveyresultdefinitionEditCtrl } from "./surveyresultdefinition";

export default angular
  .module("app.smartshelves.surveyresultdefinition", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("surveyresultdefinitionList", {
    template: require("./views/lists.html"),
    controller: SurveyresultdefinitionListsCtrl,
    bindings: {
      surveyresultdefinitions: "<"
    }
  })
  .component("surveyresultdefinitionEdit", {
    template: require("./views/edit.html"),
    controller: SurveyresultdefinitionEditCtrl,
    bindings: {
      surveyresultdefinition: "<",
      surveys: "<",
      products: "<",
      skintypes: "<",
      authentication: "<"
    }
  });
