import { StateProvider } from "@uirouter/angularjs";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { Datacontext } from "../../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("smartshelves.treasurehunts", {
      abstract: true,
      url: "/treasurehunts",
      template: require("../../views/common/sub_content.html"),
      data: { pageTitle: "TreasureHunt", requiresLogin: true }
    })
    .state("smartshelves.treasurehunts.list", {
      url: "/lists",
      template: '<treasurehunt-list treasurehunts="$resolve.treasurehunts" authentication="authentication"></treasurehunt-list>',
      resolve: {
        treasurehunts: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.treasurehunts().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { serie: true, files: [rootUrl + "Scripts/plugins/dataTables/datatables.min.js", rootUrl + "Content/plugins/dataTables/datatables.min.css"] },
          { serie: true, name: "datatables", files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"] },
          { serie: true, name: "datatables.buttons", files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"] }
        ])]
      }
    })
    .state("smartshelves.treasurehunts.create", {
      url: "/create",
      template: '<treasurehunt-edit treasurehunt="" products="$resolve.products" agencies="$resolve.agencies" authentication="authentication"></treasurehunt-edit>',
      resolve: {
        products: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.products().query()],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { name: "moment-picker", files: [rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.css", rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"] },
          { name: "angularFileUpload", files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"] },
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    })
    .state("smartshelves.treasurehunts.edit", {
      url: "/{id:int}/edit",
      template: '<treasurehunt-edit treasurehunt="$resolve.treasurehunt" products="$resolve.products" agencies="$resolve.agencies" authentication="authentication"></treasurehunt-edit>',
      resolve: {
        treasurehunt: ["smartShelvesDatacontext", "$stateParams", (datacontext: SmartShelvesDatacontext, $stateParams) => datacontext.treasurehunts().get({ id: $stateParams.id })],
        products: ["smartShelvesDatacontext", (datacontext: SmartShelvesDatacontext) => datacontext.products().query()],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) => $ocLazyLoad.load([
          { name: "moment-picker", files: [rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.css", rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"] },
          { name: "angularFileUpload", files: [rootUrl + "assets/plugins/angular-file-upload/angular-file-upload.min.js"] },
          { name: "com.2fdevs.videogular", files: [rootUrl + "assets/plugins/videogular/videogular/videogular.min.js"] }
        ])
        ]
      }
    });
}
