import { StateProvider } from "@uirouter/angularjs";
routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider) {
  $stateProvider
    .state("tags", {
      abstract: true,
      url: "/tags",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Tags", requiresLogin: true }
    })
    .state("tags.list", {
      url: "/lists",
    template: require("./views/lists.html"),
      controller: "TagList",
      controllerAs: "vm",
      resolve: {
        tags: [
          "datacontext",
          function(datacontext) {
            return datacontext.tags.query();
          }
        ]
      }
    })
    .state("tags.edit", {
      url: "/createEdit/{id:int}",
    template: require("./views/create_edit.html"),
      resolve: {
        tags: [
          "datacontext",
          function(datacontext) {
            return datacontext.tags.query();
          }
        ]
      }
    })
    .state("tags.create", {
      url: "/createEdit",
    template: require("./views/create_edit.html")
    });
}
