import * as angular from "angular";
import { routeConfig } from "./config.route";
import {ProjectorListsCtrl, ProjectorViewCtrl, ProjectorSaveCtrl} from "./projector";

export default angular
  .module("app.developer.projector", [])
  .config(routeConfig)
  .controller("ProjectorList", ProjectorListsCtrl)
  .controller("ProjectorView", ProjectorViewCtrl)
  .controller("ProjectorSave", ProjectorSaveCtrl);
