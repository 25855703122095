import * as angular from "angular";
import { routeConfig } from "./config.route";
import { NaturalBeautyProductListsCtrl, NaturalBeautyProductEditCtrl } from "./product";

export default angular
  .module("app.smartshelves.naturalbeauty.product", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("naturalbeautyProductList", {
    template: require("./views/lists.html"),
    controller: NaturalBeautyProductListsCtrl,
    bindings: {
      products: "<",
      authentication: "<"
    }
  })
  .component("naturalbeautyProductEdit", {
    template: require("./views/edit.html"),
    controller: NaturalBeautyProductEditCtrl,
    bindings: {
      product: "<",
      series: "<",
      agencies: "<",
      authentication: "<"
    }
  });
