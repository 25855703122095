import { ExceptionHandlerProvider } from "../blocks/exception/exception-handler";

export var config = {
  appErrorPrefix: "[PCloud Error] ", // configure the exceptionHandler decorator
  appTitle: "PCloud Management System",
  version: "1.0.0"
};

export function toastrConfig(toastr: Toastr): void {
  toastr.options.timeOut = 4000;
  toastr.options.positionClass = "toast-bottom-right";
}
toastrConfig.$inject = ["toastr"];

export function configure(
  $logProvider: ng.ILogProvider,
  exceptionHandlerProvider: ExceptionHandlerProvider
): void {
  if ($logProvider.debugEnabled) {
    $logProvider.debugEnabled(true);
  }

  exceptionHandlerProvider.configure(config.appErrorPrefix);
}
configure.$inject = ["$logProvider", "exceptionHandlerProvider"];


