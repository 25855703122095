import * as moment from "moment-timezone";
import { Datacontext } from "../../data/datacontext";

PlaylogList.$inject = ["datacontext"];

export function PlaylogList(datacontext: Datacontext) {
  //, playlogs) {

  var vm = this;
  var playlogService = datacontext.playlogs();
  var deviceService = datacontext.devices();

  vm.devices = deviceService.query();
  //vm.deviceUid = '50d19929-e584-44f2-af8e-89984e523f85';
  vm.go = go;
  //go();

  function go() {
    vm.playlogs = playlogService.query({
      deviceUid: vm.deviceUid, //'50d19929-e584-44f2-af8e-89984e523f85',
      timeFrom: moment(vm.dateFrom).format("YYYY-MM-DD"), //'2016-02-01',
      timeTo: moment(vm.dateTo)
        .add(1, "days")
        .format("YYYY-MM-DD") // '2016-12-01'
    });
  }
}
