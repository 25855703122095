import * as angular from "angular";
import { routeConfig } from "./config.route";

import SmartshelvesData from "./data/data.module";
import SmartshelvesDrwu from "./drwu/drwu.module";
import SmartshelvesNaturalBeauty from "./naturalbeauty/naturalbeauty.module";

import Skintype from "./skintype/skintype.module";
import Series from "./series/series.module";
import Treasurehunt from "./treasurehunt/treasurehunt.module";
import Aiskindetect from "./aiskindetect/aiskindetect.module";
import Surveyresultdefinition from "./surveyresultdefinition/surveyresultdefinition.module";
import Product from "./product/product.module";

import Devicescenario from "./devicescenario/devicescenario.module";


export default angular
  .module("app.smartshelves", [
    SmartshelvesData.name,

    Skintype.name,
    Series.name,
    Treasurehunt.name,
    Aiskindetect.name,
    Surveyresultdefinition.name,
    Product.name,

    Devicescenario.name,

    SmartshelvesDrwu.name,
    SmartshelvesNaturalBeauty.name
  ])
  .config(routeConfig);
