import { StateProvider } from "@uirouter/angularjs";
routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("marts", {
      abstract: true,
      url: "/marts",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Mart", requiresLogin: true }
    })
    .state("marts.list", {
      url: "/list",
      template: require('./views/configuration/list.html'),
      controller: "ActivitiesListController",
      controllerAs: "vm"
    })
    .state("marts.view", {
      url: "/view/{id:int}",
      template: require('./views/configuration/view.html'),
      controller: "ActivityViewController",
      controllerAs: "vm"
    })
    .state("marts.create", {
      url: "/create",
      template: require('./views/configuration/create_edit.html'),
      controller: "ActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.edit", {
      url: "/edit/{id:int}",
      template: require('./views/configuration/create_edit.html'),
      controller: "ActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.purchasingList", {
      url: "/purchasingList",
      template: require('./views/purchasing/list.html'),
      controller: "PurchasingActivitiesListController",
      controllerAs: "vm"
    })
    .state("marts.purchasingView", {
      url: "/purchasingView/{id:int}",
      template: require('./views/purchasing/view.html'),
      controller: "PurchasingActivityViewController",
      controllerAs: "vm"
    })
    .state("marts.purchasingCreate", {
      url: "/purchasingCreate",
      template: require('./views/purchasing/create_edit.html'),
      controller: "PurchasingActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.purchasingEdit", {
      url: "/purchasingEdit/{id:int}",
      template: require('./views/purchasing/create_edit.html'),
      controller: "PurchasingActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.ticketList", {
      url: "/ticketList",
      template: require('./views/purchasing2/list.html'),
      controller: "TicketActivitiesListController",
      controllerAs: "vm"
    })
    .state("marts.ticketView", {
      url: "/ticketView/{id:int}",
      template: require('./views/purchasing2/view.html'),
      controller: "TicketActivityViewController",
      controllerAs: "vm"
    })
    .state("marts.ticketCreate", {
      url: "/ticketCreate",
      template: require('./views/purchasing2/create_edit.html'),
      controller: "TicketActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.ticketEdit", {
      url: "/ticketEdit/{id:int}",
      template: require('./views/purchasing2/create_edit.html'),
      controller: "TicketActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.gameList", {
      url: "/gameList",
      template: require('./views/game/list.html'),
      controller: "GameActivitiesListController",
      controllerAs: "vm"
    })
    .state("marts.gameView", {
      url: "/gameView/{id:int}",
      template: require('./views/game/view.html'),
      controller: "GameActivityViewController",
      controllerAs: "vm"
    })
    .state("marts.gameCreate", {
      url: "/gameCreate",
      template: require('./views/game/create_edit.html'),
      controller: "GameActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.gameEdit", {
      url: "/gameEdit/{id:int}",
      template: require('./views/game/create_edit.html'),
      controller: "GameActivityCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("marts.deviceList", {
      url: "/deviceList",
      template: require('./views/device/list.html'),
      controller: "MartDeviceListController",
      controllerAs: "vm"
    });
}
