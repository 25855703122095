import * as angular from "angular";
import { routeConfig } from "./config.route";
import { DrwuProductListsCtrl, DrwuProductEditCtrl, DrwuProductEditContentCtrl } from "./product";

export default angular
  .module("app.smartshelves.drwu.product", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("drwuProductList", {
    template: require("./views/lists.html"),
    controller: DrwuProductListsCtrl,
    bindings: {
      products: "<",
      authentication: "<"
    }
  })
  .component("drwuProductEditContent", {
    template: require("./views/edit-content.html"),
    controller: DrwuProductEditContentCtrl,
    bindings: {
      mainproduct: "<",
      product: "=",
      series: "<",
      culture: "<",
      ismainculture: "<",
      authentication: "<"
    }
  })
  .component("drwuProductEdit", {
    template: require("./views/edit.html"),
    controller: DrwuProductEditCtrl,
    bindings: {
      product: "<",
      series: "<",
      agencies: "<",
      authentication: "<"
    }
  });
