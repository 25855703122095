import * as angular from "angular";
import { scheduleFactory } from "./schedule.factory";
import { routeConfig } from "./config.route";
import { Schedule } from "./schedule";

export default angular.module("app.schedule", [])
  .factory("scheduleFactory", scheduleFactory)
  .config(routeConfig)
  .controller("Schedule", Schedule)
  .run([
    "$templateCache",
    function ($templateCache: angular.ITemplateCacheService) {
      $templateCache.put("app/schedule/views/frequencyTemplate.html", require("./views/frequencyTemplate.html"));
      $templateCache.put("app/schedule/views/weekFrequencyTemplate.html", require("./views/weekFrequencyTemplate.html"));
    }
  ]);
