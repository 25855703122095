import { StateProvider } from "@uirouter/angularjs";
import { IHeartbeatStatusService } from "./dashboard.factory";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("dashboards", {
      abstract: true,
      url: "/dashboards",
      template: require("../views/common/content.html"),
      data: { pageTitle: "Dashboard", requiresLogin: true }
    })
    .state("dashboards.dashboard_1", {
      url: "/dashboard_1",
      template: require("./views/dashboard_1.html"),
      controller: "DeviceHeartbeatDashboard",
      controllerAs: "vm",
      onEnter: [
        "heartbeatStatusService",
        (heartbeatStatusService: IHeartbeatStatusService) => {
          console.log('connecting...');
          heartbeatStatusService.connection.start().catch(console.error);
        }
      ],
      onExit: [
        "heartbeatStatusService",
        (heartbeatStatusService: IHeartbeatStatusService) => {
          heartbeatStatusService.connection.stop().catch(console.error);
        }
      ]
    });
}
