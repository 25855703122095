import * as angular from "angular";
import { routeConfig } from "./config.route";
import { ProductListsCtrl, ProductEditCtrl } from "./product";

export default angular
  .module("app.smartshelves.product", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("productList", {
    template: require("./views/lists.html"),
    controller: ProductListsCtrl,
    bindings: {
      products: "<",
      authentication: "<"
    }
  })
  .component("productEdit", {
    template: require("./views/edit.html"),
    controller: ProductEditCtrl,
    bindings: {
      product: "<",
      series: "<",
      agencies: "<",
      authentication: "<"
    }
  });
