import * as angular from "angular";
import { routeConfig } from "./config.route";
import { HeartbeatStatusService } from "./dashboard.factory";
import { DeviceHeartbeatDashboardCtrl } from "./dashboard";

export default angular
  .module("app.dashboard", [])
  .config(routeConfig)
  .service("heartbeatStatusService", HeartbeatStatusService)
  .controller("DeviceHeartbeatDashboard", DeviceHeartbeatDashboardCtrl);
