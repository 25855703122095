import { StateService } from "@uirouter/angularjs";
import { IAuthentication, authenticator } from "./authorization/authenticator";
import { Datacontext } from "./data/datacontext";
import { ILogger } from "./blocks/logger/logger";

import Swal from "sweetalert2";

interface IAppCtrlScope extends ng.IScope {
  authentication: IAuthentication;
  logOut(): void;
}

export class MainController implements angular.IComponentController {
  static $inject: Array<string> = ["$scope", "$state", "Authenticator"];

  constructor($scope: IAppCtrlScope, $state: StateService, authenticator) {
    $scope.authentication = authenticator.authentication;

    $scope.logOut = () => {
      authenticator.logOut();
      $state.go("login");
    };
  }
}

interface DeviceOpeningModel
  {
    guid: string;
    deviceId: string;
    serialNumber: string;
}

export class OpeningComponent implements angular.IComponentController {

  oneningModel: DeviceOpeningModel;
  textMaskConfig: any;
  licenseSevice: any;
  private logger: ILogger;

  static $inject: Array<string> = ["datacontext", "common"];
  constructor(datacontext: Datacontext, common: any) {
    this.licenseSevice = datacontext.licenses();
    this.logger = common.logger;

    this.textMaskConfig = {
      guid: { mask: [/[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /4/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/] },
      serialNumber: { mask: [/[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, '-', /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/, /[0-9A-Fa-f]/] }
    };
  }

  opening(): void {
    this.logger.log(this.oneningModel);
    this.licenseSevice.opening(null, this.oneningModel, () => {
      this.oneningModel = null;
      Swal.fire({ icon: 'success', title: "開通成功，可前往設定相關排程", showConfirmButton: false, timer: 1500 })
    },
    httpResponse => {
        this.logger.error("開通錯誤", httpResponse, "Opening Device and License Error");
        Swal.fire({ icon: 'error', title: '開通錯誤', text: httpResponse.data.message, footer: '請聯絡系統管理人員' })
      });
  }
}
