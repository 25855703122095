import { StateService, StateParams } from "@uirouter/core";
import { ILogger } from "../../blocks/logger/logger";
import { Datacontext } from "../../data/datacontext";

import * as R from "ramda";

export class LicenseListTable implements angular.IComponentController {
  licenses: any[];
  authentication: any;

  issued: boolean;
  opening: boolean;

  dtOptions: any;
  dtColumnDefs: any;

  private readonly logger: ILogger;

  static $inject = [ "common", "$filter", "DTOptionsBuilder", "DTColumnDefBuilder" ];

  $onInit() {
  }

  constructor(common: any, private readonly $filter, DTOptionsBuilder, DTColumnDefBuilder) {
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "LicensesFile" },
        { extend: "pdf", title: "LicensesFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

}

export class LicenseListsCtrl implements angular.IComponentController {
  licenses: any[];
  nonOpenLicenses: any[];
  issuedLicenses: any[];
  openedLicenses: any[];
  openedNonusedLicenses: any[];

  authentication: any;

  $onInit() {
    this.licenses.$promise.then(values => {
      this.nonOpenLicenses = R.filter(l => !l.openingUtc, values);
      this.issuedLicenses = R.filter(l => !l.openingUtc && l.issuedUtc, values);
      this.openedLicenses = R.filter(l => l.openingUtc && l.availableDays == 0, values);
      this.openedNonusedLicenses = R.filter(l => l.openingUtc && l.availableDays != 0, values);
    });
  }
}

export class LicenseCreateEditCtrl implements angular.IComponentController {
  isEdit: boolean;

  license: any;
  agencies: any[];

  private readonly logger: ILogger;
  private readonly licenseService;

  static $inject = ["datacontext", "common", "$stateParams", "$state"];
  constructor(private datacontext: Datacontext, common: any, private $stateParams: StateParams, private $state: StateService) {
    this.logger = common.logger;
    this.licenseService = datacontext.licenses();

    // 判斷為編輯還是新增播放清單
    this.isEdit = $stateParams.id !== undefined;

    if (this.isEdit) {
      this.licenseService.get({ id: $stateParams.id }).$promise.then(value => {
        this.license = value;
        this.logger.log(value);
      });
    }
  }

  save() {
    if (this.isEdit) {
      this.licenseService.update({ id: this.license.id }, this.license, (value, responseHeaders) => {
        this.logger.success("License has Update!", value, "Good job!");
        this.$state.go("admin.licenses.list");
      });
    }
    else {
      this.licenseService.save(null, this.license, (value, responseHeaders) => {
        this.logger.success("License has Create!", value, "Good job!");
        this.$state.go("admin.licenses.list");
      });
    }
  }
}
