import * as angular from "angular";
import { routeConfig, exportToCsv } from "./config.route";
import { MartPack1Ctrl } from "./analysis.martPack1";
import { MartPack2Ctrl } from "./analysis.martPack2";
import { MartRaw1Ctrl } from "./analysis.martRaw1";
import { MartRaw2Ctrl } from "./analysis.martRaw2";
import { MartRaw3Ctrl } from "./analysis.martRaw3";
import { MartRaw4Ctrl } from "./analysis.martRaw4";
import { SiteDNACtrl } from "./analysis";
import { SiteDNAByAgeCtrl } from "./analysisByAge";
import { ButtonLogsCtrl } from "./buttonLogs";
import { cameraEventsCtrl } from "./cameraEvents";
import { PeopleAttractCtrl } from "./peopleAttract";
import { PeopleAttractAllCtrl } from "./peopleAttractAll";
import { PeopleCountCtrl } from "./peopleCount";
import { PeopleCountAllCtrl } from "./peopleCountAll";
import { playLogsCtrl } from "./playLogs";
import { playLogsAllCtrl } from "./playLogsAll";
import { QRCodeLogsCtrl } from "./qrCodeLogs";

export default angular
  .module("app.analysis", [])
  .config(routeConfig)
  .directive("exportToCsv", exportToCsv)
  .controller("martPack1Ctrl", MartPack1Ctrl)
  .controller("martPack2Ctrl", MartPack2Ctrl)
  .controller("martRaw1Ctrl", MartRaw1Ctrl)
  .controller("martRaw2Ctrl", MartRaw2Ctrl)
  .controller("martRaw3Ctrl", MartRaw3Ctrl)
  .controller("martRaw4Ctrl", MartRaw4Ctrl)
  .controller("siteDNACtrl", SiteDNACtrl)
  .controller("siteDNAByAgeCtrl", SiteDNAByAgeCtrl)
  .controller("buttonLogsCtrl", ButtonLogsCtrl)
  .controller("cameraEventsCtrl", cameraEventsCtrl)
  .controller("peopleAttractCtrl", PeopleAttractCtrl)
  .controller("peopleAttractAllCtrl", PeopleAttractAllCtrl)
  .controller("peopleCountCtrl", PeopleCountCtrl)
  .controller("peopleCountAllCtrl", PeopleCountAllCtrl)
  .controller("playLogsCtrl", playLogsCtrl)
  .controller("playLogsAllCtrl", playLogsAllCtrl)
  .controller("qrCodeLogsCtrl", QRCodeLogsCtrl);
