import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("devices", {
      abstract: true,
      url: "/devices",
      template: require("../views/common/content.html"),
      data: { pageTitle: "Devices", requiresLogin: true }
    })
    .state("devices.list", {
      url: "/lists",
      template: '<device-list devices="$resolve.devices" nolicensedevices="$resolve.nolicensedevices" authentication="authentication"></device-list>',
      resolve: {
        devices: ["datacontext", (datacontext: Datacontext) => datacontext.devices().query()],
        nolicensedevices: ["datacontext", (datacontext: Datacontext) => datacontext.devices().getNoLicenseDevices()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              serie: true,
              files: [rootUrl + "Scripts/plugins/dataTables/datatables.min.js", rootUrl + "Content/plugins/dataTables/datatables.min.css"]
            },
            {
              serie: true,
              name: "datatables",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"]
            },
            {
              serie: true,
              name: "datatables.buttons",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"]
            }
          ])
        ]
      }
    })
    .state("devices.edit", {
      url: "/{id:int}/edit",
      template:
        '<device-edit device="$resolve.device" boards="$resolve.boards" projectors="$resolve.projectors" agencies="$resolve.agencies" licenseusedlogs="$resolve.licenseusedlogs" authentication="authentication"></device-edit>',
      resolve: {
        device: ["datacontext", "$stateParams", (datacontext: Datacontext, $stateParams) => datacontext.devices().get({ id: $stateParams.id })],
        boards: ["datacontext", (datacontext: Datacontext) => datacontext.boards().query()],
        projectors: ["datacontext", (datacontext: Datacontext) => datacontext.projectors().query()],
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()],
        licenseusedlogs: ["datacontext", "$stateParams", (datacontext: Datacontext, $stateParams)=> datacontext.licenses().getLicenseUsedLogByDevice({ deviceId: $stateParams.id })],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              name: "moment-picker",
              files: [
                rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                rootUrl + "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
              ]
            },
            {
              files: [
                rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
              ]
            }
          ])
        ]
      }
    });
}
