import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IDevice } from "../models/models";

interface IPlayLogsViewModel {
  devices: Array<any>;
  selectDevice: IDevice;

  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  loading: boolean;

  getPlayLogs();
}

export class playLogsCtrl implements IPlayLogsViewModel {
  devices: any[];
  selectDevice: IDevice;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dayKeyFrom: string;
  dayKeyTo: string;
  logSummary: any[];
  logs: any[];

  loading: boolean;

  static $inject = [
    "devices",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    devices,
    private $http,
    private apiUrl,
    private environment: number
  ) {
    this.loading = false;
    this.dateFrom = moment().subtract(8, "days");
    this.dateTo = moment().subtract(1, "days");

    devices.$promise.then(value => {
      this.devices = value.filter(device => device.functionalModule);
    });
    //this.devices = [{ name: '辦公室測試', guid: '80039b87-e206-4e15-aae4-000000000003' }];
    //console.log(this.devices);
  }

  //function declare
  getPlayLogs() {
    this.loading = true;
    this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
    this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

    this.$http
      .post(this.apiUrl + "api/Analysis/PlayLogs", {
        dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
        dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
        deviceGuid: this.selectDevice.guid,
        system: this.environment
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          //console.log(obj);
          this.logSummary = obj.playLogsByDevice;
          this.logs = obj.playLogs;
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }
}
