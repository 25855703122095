import { Datacontext } from "../../data/datacontext";
import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("admin.licenses", {
      abstract: true,
      url: "/licenses",
      template: require("../../views/common/sub_content.html"),
      data: { pageTitle: "Licenses", requiresLogin: true, roles: ["SystemAdmin"] }
    })
    .state("admin.licenses.list", {
      url: "/lists",
      template: '<license-list licenses="$resolve.licenses" authentication="authentication"></license-list>',
      resolve: {
        licenses: ["datacontext", (datacontext: Datacontext) => datacontext.licenses().query()],
        loadPlugin: ["$ocLazyLoad", ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              serie: true,
              files: [rootUrl + "Scripts/plugins/dataTables/datatables.min.js", rootUrl + "Content/plugins/dataTables/datatables.min.css"]
            },
            {
              serie: true,
              name: "datatables",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.min.js"]
            },
            {
              serie: true,
              name: "datatables.buttons",
              files: [rootUrl + "Scripts/plugins/dataTables/angular-datatables.buttons.min.js"]
            }
          ])
        ]
      }
    })
    .state("admin.licenses.edit", {
      url: "/createEdit/{id:int}",
      template: '<license-edit agencies="$resolve.agencies" authentication="authentication"></license-edit>',
      resolve: {
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()]
      }
    })
    .state("admin.licenses.create", {
      url: "/createEdit",
      template: '<license-create agencies="$resolve.agencies" authentication="authentication"></license-create>',
      resolve: {
        agencies: ["datacontext", (datacontext: Datacontext) => datacontext.agencies().query()]
      }
    });
}
