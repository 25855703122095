import { StateService } from "@uirouter/angularjs";
import Swal from "sweetalert2";
import { ILogger } from "../../blocks/logger/logger";
import { SmartShelvesDatacontext } from "../data/datacontext";
import { IAgency } from "../../models/models";
import { Datacontext } from "../../data/datacontext";
import { CultureService } from "../../services/culture.service";

interface ISeriesListViewModel {
  series: Array<any>;

  dtOptions: any;
  dtColumnDefs: any;

  del(id: number): void;
}

export class SeriesListsCtrl
  implements ISeriesListViewModel, angular.IController {
  series: any[];

  dtOptions: any;
  dtColumnDefs: any;

  private readonly seriesService: any;
  private readonly logger: ILogger;

  static $inject = [
    "smartShelvesDatacontext",
    "common",
    "$filter",
    "DTOptionsBuilder",
    "DTColumnDefBuilder"
  ];

  $onInit() {
    this.logger.log(this.series);
  }

  constructor(
    datacontext: SmartShelvesDatacontext,
    common: any,
    private readonly $filter,

    DTOptionsBuilder,
    DTColumnDefBuilder
  ) {
    this.seriesService = datacontext.series();
    this.logger = common.logger;

    this.dtOptions = DTOptionsBuilder.newOptions()
      .withDOM('<"html5buttons"B>lTfgitp')
      .withButtons([
        { extend: "copy" },
        { extend: "csv" },
        { extend: "excel", title: "SeriesFile" },
        { extend: "pdf", title: "SeriesFile" },
        {
          extend: "print",
          customize: win => {
            $(win.document.body).addClass("white-bg");
            $(win.document.body).css("font-size", "10px");

            $(win.document.body)
              .find("table")
              .addClass("compact")
              .css("font-size", "inherit");
          }
        }
      ]);

    this.dtColumnDefs = [
      //DTColumnDefBuilder.newColumnDef(7).notSortable()
    ];
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.seriesService.delete(
          { id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(
              this.series,
              "id",
              id
            );
            this.series.splice(indx, 1);
            Swal.fire("Deleted!", "Your Series has been deleted.", "success");
          },
          httpResponse => {
            this.logger.error(
              `Series can't delete, series ID is ${id}`,
              httpResponse,
              "Delete Series Error"
            );
            Swal.fire("Cancelled", "Series can't delete", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Series file is safe :)", "success");
      }
    });
  }
}

export class SeriesEditContentCtrl implements angular.IComponentController {
  series: any;
  ismainculture: boolean;
  culture: string;
  mainseries: any;
  authentication: any;
  private agencies: Array<IAgency>;
  private role: any;

  static $inject = ["apiUrl", "localStorageService", "datacontext", "Authenticator", "$sce"];
  constructor(private apiUrl, private localStorageService, private datacontext: Datacontext, private authenticator: any, private $sce) {
    this.role = authenticator.authentication.role;
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = datacontext.agencies().query();
    }
  }
}

export class SeriesEditCtrl implements angular.IComponentController {
  isEdit: boolean;
  series: any;

  private logger: ILogger;
  private seriesService: any;

  mainCulture: { [key: string]: string };
  otherCultures: { [key: string]: string };

  static $inject = [
    "CultureService",
    "smartShelvesDatacontext",
    "common",
    "$stateParams",
    "$state"
  ];
  constructor(
    private cultureService: CultureService,
    datacontext: SmartShelvesDatacontext,
    common: any,
    private $stateParams,
    private $state: StateService
  ) {
    this.logger = common.logger;
    this.seriesService = datacontext.series();
  }

  $onInit() {
    // 判斷為編輯還是新增
    this.isEdit = this.$stateParams.id !== undefined;

    if (this.isEdit) {
      this.series.$promise.then(value => {
        this.series = value;
        this.logger.log(this.series);
        this.initCulture();
      });
    } else {
      this.series = {
        id: 0,
        name: null,
        secondaryName: null,
        seriesTranslations: []
      };
      this.initCulture();
    }
  }

  save(): void {
    if (this.isEdit) {
      this.seriesService.update(
        { id: this.series.id },
        this.series,
        () => {
          this.$state.go("smartshelves.series.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    } else {
      this.seriesService.save(
        null,
        this.series,
        () => {
          this.$state.go("smartshelves.series.list");
        },
        httpResponse => {
          this.logger.log(httpResponse);
        }
      );
    }
  }

  initCulture() {
    this.mainCulture = this.cultureService.mainCulture;
    this.otherCultures = this.cultureService.otherCultures;
  }

  // 檢查是否有語系資料
  checkHasTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    if (this.series.seriesTranslations.length == 0) {
      this.pushNewTranslation(culture);
    }
    else {
      var found = this.getTranslation(culture);
      if (!found) {
        this.pushNewTranslation(culture);
      }
    }
  }

  // 新增語系資料
  pushNewTranslation(culture: string): void {
    culture = culture.replace(/_/g, '-');
    let newTranslation = {
      id: 0,
      name: null,
      culture: culture,
      seriesId: this.series.id
    };
    this.series.seriesTranslations.push(newTranslation);
  }

  // 取得已存在的語系資料
  getTranslation(culture: string): any {
    culture = culture.replace(/_/g, '-');
    return this.cultureService.getTranslation(culture, this.series.seriesTranslations);
  }
}
