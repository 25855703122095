import * as angular from "angular";
import * as R from "ramda";
import * as signalR from "@microsoft/signalr";

import { IStatusCounts, StatusCounts } from "./dashboard.model";
import { Datacontext } from "../data/datacontext";
import { ILogger } from "../blocks/logger/logger";

export interface IHeartbeatStatusService {
  devices: Array<any>;
  counts: IStatusCounts;
  boards: Array<any>;
  level2s: Array<any>;
  connection: signalR.HubConnection;
}

export class HeartbeatStatusService implements IHeartbeatStatusService {
  devices: Array<any>;
  counts: IStatusCounts;
  boards: Array<any>;
  level2s: Array<any>;
  connection: signalR.HubConnection;

  static $inject = ["functionUrl", "functionCode", "datacontext", "common", "$http", "$filter", "$rootScope"];

  constructor(functionUrl: string, functionCode: string, datacontext: Datacontext, common: any, $http, private $filter: any, $rootScope: ng.IRootScopeService) {
    const logger: ILogger = common.logger;
    const deviceService = datacontext.devices();

    this.devices = [];
    this.counts = new StatusCounts();
    this.boards = [];
    this.level2s = [];

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(functionUrl + "api/")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.on("updateDeviceStatus", heartbeatStatus => {
      R.map(heartbeat => {
        let found = R.find(R.propEq("guid", heartbeat.guid))(this.devices);
        if (found) {
          found.heartbeat = heartbeat.heartbeat;
          found.appVersion.mediaPlayer = heartbeat.appVersion;
          found.queueName = heartbeat.queueName;
          found.nowPlaying = heartbeat.chapterName;
          found.timing = heartbeat.timing;
          found.heartTimeSpan = heartbeat.heartTimeSpan;
          found.numOfProjectorOn = heartbeat.numOfProjectorOn;
          found.totalProjectors = heartbeat.totalProjectors;
          found.update = heartbeat.update;
          found.screenShot = heartbeat.screenShot;
          if (found.screenShot) {
            found.screenShot.image = `data:image/png;base64,${found.screenShot.image}`;
          }
        }
      }, heartbeatStatus);

      this.counts.calculate(this.devices, $filter);

      $rootScope.$apply();
    });

    this.connection.onclose(() => console.log('disconnected'));

    this.devices = deviceService.query(devices => {
      this.boards = R.reject(R.isNil, R.uniq(R.pluck("board")(devices)));

      R.map(x => this.level2s.push(x), R.reject(R.isEmpty, R.reject(R.isNil, R.uniq(R.pluck("level2")(this.boards)))));

      devices.map(device => {
        device.heartbeat = false;
        device.queueName = "";
        device.nowPlaying = "";
        device.timing = false;
        device.heartTimeSpan = "";
        device.numOfProjectorOn = 0;
        device.totalProjectors = 0;
      });

      $http.get(functionUrl + 'api/GetDeviceStatus?code=' + functionCode)
        .then(response => {
          let heartbeatStatus = response.data;

          R.map(heartbeat => {
            let found = R.find(R.propEq("guid", heartbeat.guid))(devices);
            if (found) {
              found.heartbeat = heartbeat.heartbeat;
              found.appVersion.mediaPlayer = heartbeat.appVersion;
              found.queueName = heartbeat.queueName;
              found.nowPlaying = heartbeat.chapterName;
              found.timing = heartbeat.timing;
              found.heartTimeSpan = heartbeat.heartTimeSpan;
              found.numOfProjectorOn = heartbeat.numOfProjectorOn;
              found.totalProjectors = heartbeat.totalProjectors;
              found.update = heartbeat.update;
              found.screenShot = heartbeat.screenShot;
              if (found.screenShot) {
                found.screenShot.image = `data:image/png;base64,${found.screenShot.image}`;
              }
            }
          }, heartbeatStatus);

          this.counts.calculate(devices, $filter);
        });

    });
  }
}
