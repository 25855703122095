import * as angular from "angular";
import { routeConfig } from "./config.route";
import { ActivityListCtrl, ActivityViewCtrl, ActivityCreateCtrl, PurchasingActivitiesListCtrl, PurchasingActivityViewCtrl, PurchasingActivityCreateCtrl, Purchasing2ActivitiesListCtrl, Purchasing2ActivityViewCtrl, Purchasing2ActivityCreateCtrl, GameActivitiesListCtrl, GameActivityViewCtrl, GameActivityCreateCtrl, DevicesListCtrl } from "./mart";

export default angular
.module("app.mart", [
  // Angular modules
  // Custom modules
  // 3rd Party Modules
])
.config(routeConfig)
.controller("ActivitiesListController", ActivityListCtrl)
.controller("ActivityViewController", ActivityViewCtrl)
.controller("ActivityCreateController", ActivityCreateCtrl)
.controller("PurchasingActivitiesListController", PurchasingActivitiesListCtrl)
.controller("PurchasingActivityViewController", PurchasingActivityViewCtrl)
.controller("PurchasingActivityCreateController", PurchasingActivityCreateCtrl)
.controller("TicketActivitiesListController", Purchasing2ActivitiesListCtrl)
.controller("TicketActivityViewController", Purchasing2ActivityViewCtrl)
.controller("TicketActivityCreateController", Purchasing2ActivityCreateCtrl)
.controller("GameActivitiesListController", GameActivitiesListCtrl)
.controller("GameActivityViewController", GameActivityViewCtrl)
.controller("GameActivityCreateController", GameActivityCreateCtrl)
.controller("MartDeviceListController", DevicesListCtrl);
