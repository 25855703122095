import { StateProvider } from "@uirouter/angularjs";

routeConfig.$inject = ["$stateProvider"];

export function routeConfig($stateProvider: StateProvider): void {
  $stateProvider.state("developer", {
    abstract: true,
    url: "/developer",
      template:  require("../views/common/content.html"),
    data: {
      pageTitle: "Developer",
      requiresLogin: true,
      roles: ["SystemAdmin", "Developer"]
    }
  });
}
