import * as angular from "angular";
import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IDevice } from "../models/models";
import { FunctionalModule } from "../models/enums";

declare var Highcharts: any;

interface ISiteDNAViewModel {
  devices: Array<any>;
  selectDevice: IDevice;

  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  loading: boolean;

  getSiteDNA();
}

export class SiteDNACtrl implements ISiteDNAViewModel {
  devices: any[];
  selectDevice: IDevice;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dayKeyFrom: string;
  dayKeyTo: string;

  loading: boolean;

  static $inject = [
    "devices",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    devices,
    private $http,
    private apiUrl,
    private environment: number
  ) {
    this.loading = false;
    this.dateFrom = moment().subtract(8, "days");
    this.dateTo = moment().subtract(1, "days");

    devices.$promise.then(value => {
      this.devices = value.filter(
        device => device.functionalModule & FunctionalModule.FaceDetection
      );
    });
    //this.devices = [{ name: '麗寶摩天輪TV01', guid: '7F906552-6890-4D0C-89A8-026792992FD9' }];
    //console.log(this.devices);
  }

  //function declare
  getSiteDNA() {
    this.loading = true;
    this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
    this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

    this.$http
      .post(this.apiUrl + "api/Analysis/SiteDNA", {
        dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
        dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
        deviceGuid: this.selectDevice.guid,
        system: this.environment
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          //console.log(obj);

          this.drawCountByGender(obj.countByGender);
          this.drawSmileByGender(obj.smileByGender);
          this.drawStayByGender(obj.stayByGender);
          this.drawCountByStay(obj.stayByGender);
          this.drawHourByGender(obj.hourByGender);
          this.drawWeekHourByGender(obj.weekHourByGender);
          this.drawStayByContent(obj.stayByContent);
          this.drawByContent(obj.byContent);
          this.drawCountPerSecByContent(obj.countPerSecByContent);
          this.drawCountByGenderPerSecByContent(obj.countPerSecByContent);
          this.drawStayPerSecByContent(obj.countPerSecByContent);
          this.drawStayByGenderPerSecByContent(obj.countPerSecByContent);
          this.drawSmilePerSecByContent(obj.countPerSecByContent);
          this.drawSmileByGenderPerSecByContent(obj.countPerSecByContent);
          this.drawDayTrend(obj.dayTrend);
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }

  drawCountByGender(objs: any) {
    var y0Array = [];
    var i = 0;
    var totalCount = 0;

    angular.forEach(objs, obj => {
      i++;
      totalCount += obj.count;
      y0Array.push({ name: obj.gender, y: obj.count });
    });

    const chartOptions = {
      title: {
        text: `${this.dayKeyFrom} ~ ${this.dayKeyTo} 累計 ${totalCount} 人數`
      },
      chart: {
        type: "pie",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format:
              "<b>{point.name}</b>: {point.percentage:.1f} % <br> {point.y} 人數"
          },
          showInLegend: true
        }
      },
      colors: ["#ff8080", "#4775d1", "#33ff33"],
      series: [
        {
          name: "Count",
          data: y0Array
        }
      ]
    };
    Highcharts.chart("containerCountByGender", chartOptions);
  }

  drawCountByStay(objs: any) {
    const y0Array = [];
    var aa = 0;
    var bb = 0;
    var i = 0;
    angular.forEach(objs, obj => {
      i++;
      if (obj.staySec >= 1) {
        aa += obj.maleCount + obj.femaleCount + obj.neutralCount;
      } else {
        bb += obj.maleCount + obj.femaleCount + obj.neutralCount;
      }
    });
    y0Array.push({ name: "注視停留 5 (含) 秒以上人數", y: aa } as any);
    y0Array.push({ name: "其他", y: bb } as any);
    const chartOptions = {
      title: { text: "" },
      chart: {
        type: "pie",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format:
              "<b>{point.name}</b>: {point.percentage:.1f} % <br> {point.y}"
          }
        }
      },
      colors: ["#ff8080", "#4775d1", "#33ff33"],
      series: [
        {
          name: "Count",
          data: y0Array
        }
      ]
    };
    Highcharts.chart("containerCountByStay", chartOptions);
  }

  drawSmileByGender(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.smile);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: { text: this.dayKeyFrom + "~" + this.dayKeyTo + " 微笑程度" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerSmileByGender", chartOptions);
  }

  drawStayByGender(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.stay);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 注視停留狀態累計人數"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerStayByGender", chartOptions);
  }

  drawHourByGender(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.hour);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每小時觀看人數"
      },
      chart: {
        stacking: "normal",
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerHourByGender", chartOptions);
  }

  drawWeekHourByGender(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.hour);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 周間每小時觀看人數"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: "normal",
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerWeekHourByGender", chartOptions);
  }

  drawStayByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.maleStay);
      y1Array.push(obj.femaleStay);
      y2Array.push(obj.neutralStay);
    });
    const chartOptions = {
      title: { text: this.dayKeyFrom + "~" + this.dayKeyTo + " 媒體吸睛比較" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "平均關注秒數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerStayByContent", chartOptions);
  }

  drawByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: { text: this.dayKeyFrom + "~" + this.dayKeyTo + " 媒體關注人數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          //stacking: 'normal'
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerCountByContent", chartOptions);
  }

  drawCountPerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(
        (obj.countFemalePerSec + obj.countMalePerSec + obj.countNeutralPerSec) *
          60
      );
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘人數分析"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘人數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "平均每分鐘人數",
          yAxis: 1,
          type: "line",
          data: y1Array
        }
      ]
    };
    Highcharts.chart("containerCountPerSecByContent", chartOptions);
  }

  drawCountByGenderPerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(obj.countFemalePerSec * 60);
      y2Array.push(obj.countMalePerSec * 60);
      y3Array.push(obj.countNeutralPerSec * 60);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘人數分析-區分性別"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘人數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "平均每分鐘女生人數",
          yAxis: 1,
          type: "line",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "平均每分鐘男生人數",
          yAxis: 1,
          type: "line",
          data: y2Array,
          color: "#4775d1"
        },
        {
          name: "平均每分鐘中性人數",
          yAxis: 1,
          type: "line",
          data: y3Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerCountByGenderPerSecByContent", chartOptions);
  }

  drawStayPerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(
        (obj.stayFemalePerSec + obj.stayMalePerSec + obj.stayNeutralPerSec) *
          60.0
      );
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘吸睛分析"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘吸睛秒數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "平均每分鐘吸睛秒數",
          yAxis: 1,
          type: "line",
          data: y1Array
        }
      ]
    };
    Highcharts.chart("containerStayPerSecByContent", chartOptions);
  }

  drawStayByGenderPerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(obj.stayFemalePerSec * 60.0);
      y2Array.push(obj.stayMalePerSec * 60.0);
      y3Array.push(obj.stayNeutralPerSec * 60.0);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘吸睛分析-區分性別"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘吸睛秒數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "女生",
          yAxis: 1,
          type: "line",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "男生",
          yAxis: 1,
          type: "line",
          data: y2Array,
          color: "#4775d1"
        },
        {
          name: "中性",
          yAxis: 1,
          type: "line",
          data: y3Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerStayByGenderPerSecByContent", chartOptions);
  }

  drawSmilePerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(
        (obj.smileFemalePerSec + obj.smileMalePerSec + obj.smileNeutralPerSec) *
          60.0
      );
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘創造歡笑指標"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘創造歡笑指標" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "創造歡笑指標",
          yAxis: 1,
          type: "line",
          data: y1Array
        }
      ]
    };
    Highcharts.chart("containerSmilePerSecByContent", chartOptions);
  }

  drawSmileByGenderPerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(obj.smileFemalePerSec * 60.0);
      y2Array.push(obj.smileMalePerSec * 60.0);
      y3Array.push(obj.smileNeutralPerSec * 60.0);
    });
    const chartOptions = {
      title: {
        text:
          this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘創造歡笑指標-區分性別"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘創造歡笑指標-區分性別" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "女性",
          yAxis: 1,
          type: "line",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "男性",
          yAxis: 1,
          type: "line",
          data: y2Array,
          color: "#4775d1"
        },
        {
          name: "中性",
          yAxis: 1,
          type: "line",
          data: y3Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerSmileByGenderPerSecByContent", chartOptions);
  }

  drawDayTrend(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.day);
      y0Array.push(obj.maleCount);
      y1Array.push(obj.femaleCount);
      y2Array.push(obj.neutralCount);
    });
    const chartOptions = {
      title: { text: `版位：${this.selectDevice.name}` },
      chart: {
        type: "spline",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: "normal"
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "男性",
          data: y0Array,
          color: "#4775d1"
        },
        {
          name: "女性",
          data: y1Array,
          color: "#ff8080"
        },
        {
          name: "中性",
          data: y2Array,
          color: "#33ff33"
        }
      ]
    };
    Highcharts.chart("containerDayTrend", chartOptions);
  }
}
