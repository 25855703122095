import * as angular from "angular";
import { routeConfig } from "./config.route";
import { SurveyListsCtrl, SurveyEditCtrl, SurveyEditContentCtrl } from "./survey";

export default angular
  .module("app.survey", [])
  .config(routeConfig)
  .component("surveyList", {
    template: require("./views/lists.html"),
    controller: SurveyListsCtrl,
    bindings: {
      surveys: "<",
      authentication: "<"
    }
  })
  .component("surveyEditContent", {
    template: require("./views/edit-content.html"),
    controller: SurveyEditContentCtrl,
    require: {　// Ref :https://stackoverflow.com/questions/9293423/can-one-angularjs-controller-call-another
      parent: '^surveyEdit'
    },
    bindings: {
      weightsDefinitions:"=",
      weightsDefinitionsGroupByOption:"=",
      survey: "=",
      skintypes: "<",
      culture: "<",
      ismainculture: "<",
      authentication: "<"
    }
  })
  .component("surveyEdit", {
    template: require("./views/edit.html"),
    controller: SurveyEditCtrl,
    bindings: {
      survey: "<",
      agencies: "<",
      skintypes: "<",
      authentication: "<"
    }
  });
