import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(
  rootUrl: string,
  $stateProvider: StateProvider
): void {
  $stateProvider
    .state("emergency", {
      abstract: true,
      url: "/emergency",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "Emergency", requiresLogin: true }
    })
    .state("emergency.alert", {
      url: "/alert",
    template: require("./views/alert.html"),
      controller: "EmergencyAlert",
      controllerAs: "vm",
      resolve: {
        boards: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.boards().query()
        ],
        devices: [
          "datacontext",
          (datacontext: Datacontext) => datacontext.devices().query()
        ]
      }
    });
}
