import * as angular from "angular";
import { routeConfig } from "./config.route";

import Application from "./application/application.module";
import Configuration from "./configuration/configuration.module";
import Projector from "./projector/projector.module";

export default angular
  .module("app.developer", [
    Application.name,
    Configuration.name,
    Projector.name
  ])
  .config(routeConfig);
