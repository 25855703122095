import * as angular from "angular";
import Swal from "sweetalert2";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { ICustomer, IDevice, IAgency } from "../models/models";
import { FunctionalModule } from "../models/enums";

class BaseCrtl {
  public getGameTypeList(): { [key: number]: string } {
    return {
      0: "拉霸",
      1: "刮刮樂",
      2: "省錢快拍",
      3: "招財進寶",
      4: "夾娃娃機"
    };
  }

  public getStoreNameList(): { [key: number]: string } {
    return {
      0: "---Please select---",
      1: "按鈕1",
      2: "按鈕2",
      3: "按鈕3(每日一物館)",
      4: "按鈕4(里仁館)",
      5: "按鈕5(天和鮮物館)",
      6: "按鈕6(達人推薦館)"
    };
  }

  public checkImage(imageIndex: number, imagesRelease: any, imagesUpdate: any): boolean {
    var sucess: boolean = false;
    for (var i = 0; i < imagesRelease.length; i++) {
      if (imagesRelease[i].no == imageIndex) {
        sucess = true;
      }
    }
    for (var i = 0; i < imagesUpdate.length; i++) {
      if (imagesUpdate[i].no == imageIndex) {
        sucess = true;
      }
    }
    return sucess;
  }

  public getDate(fromDateTime: Date): Date {
    var theDate: Date = fromDateTime || new Date();
    theDate.setHours(0);
    theDate.setMinutes(0);
    theDate.setSeconds(0);
    return theDate;
  }
}

export class ConfigurationListCtrl {
  private activities: Array<any>;
  private activityService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common", "$filter", "$state"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state,
    private swal
  ) {
    this.activities = datacontext.storeConfigurations().query();
    this.activityService = datacontext.storeConfigurations();
    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number, isEdit: boolean): void {
    this.activityService.deleteConfiguration(
      { id: id, isEdit: isEdit },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
      },
      httpResponse => {
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }
}

export class ConfigurationViewCtrl {
  private activity: any;
  private logger: ILogger;
  static $inject = ["datacontext", "common", "$stateParams", "$window"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window
  ) {
    this.logger = common.logger;
    datacontext.storeConfigurations().get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        var imagesRelease = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
          if (value.storeMultimedia[i].itemStatus == 1) {
            imagesRelease.push(value.storeMultimedia[i]);
          }
        }
        this.activity.storeMultimedia = imagesRelease;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }
  goBack(): void {
    this.$window.history.back();
  }
}

export class ConfigurationCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;

  private selectAllValue: string;

  activity: any;
  imageCategory: string;
  uploader: any;
  isEdit: boolean;
  isSaving: boolean;
  imagesRelease: any;
  imagesUpdate: any;
  titleIndex: string[];
  valueIndex: number[];
  running: boolean;
  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "localStorageService",
    "$window",
    "$rootScope"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private localStorageService,
    private $window,
    private $rootScope
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.storeConfigurations();

    // 初始化
    this.running = false;
    this.titleIndex = [
      "背景圖(Background)364x1080 px",
      "按鈕(第一功能)216x216 px",
      "按鈕(第二功能)216x216 px",
      "按鈕(第三功能/第四功能(VR))216x216 px",
      "MP3(第一功能)",
      "MP3(第二功能)",
      "MP3(第三功能/第四功能(VR))"
    ];
    this.valueIndex = [0, 1, 3, 2, 4, 6, 5];

    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.selectAllValue = "Select All";
    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      customerId: 0,
      agencyId: 0,
      isNormalAction: true,
      devices: [],
      storeMultimedia: []
    };
    this.isSaving = false;
    this.imageCategory = "";
    this.imagesRelease = [];
    this.imagesUpdate = [];

    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }

    this.customers = this.customerService.query();

    // Devices
	  this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		  );
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/StoreConfiguration",
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: (item, options) => {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType == "mp3" || mediaType == "mp4") {
          return true;
        } else if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onWhenAddingFileFailed = (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) => {
      this.logger.log("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      this.logger.log("onAfterAddingFile", fileItem.file.name);
    };

    this.uploader.onAfterAddingAll = addedFileItems => {
      this.logger.log("onAfterAddingAll", addedFileItems);
    };

    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      this.isSaving = true;
      var formData = [
        {
          activityId: this.activity.id,
          imageCategory: this.imageCategory
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (response != null) {
        this.activity.id = response.id;
        this.activity.storeMultimedia = response.storeMultimedia;
        this.imagesRelease = [];
        this.imagesUpdate = [];
        if (this.isEdit) {
          for (var i = 0; i < response.storeMultimedia.length; i++) {
            if (response.storeMultimedia[i].itemStatus == 1) {
              this.imagesRelease.push(response.storeMultimedia[i]);
            } else {
              this.imagesUpdate.push(response.storeMultimedia[i]);
            }
          }
        } else {
          this.imagesRelease = response.storeMultimedia;
        }
      }
      this.logger.log("onSuccessItem", response);
    };

    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };

    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.isSaving = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        var activity = value;
        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          if (activity.storeMultimedia[i].itemStatus == 1) {
            this.imagesRelease.push(activity.storeMultimedia[i]);
          } else {
            this.imagesUpdate.push(activity.storeMultimedia[i]);
          }
        }

        // 設定已經勾選的 Device
        var devicesSelected = activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }
        this.activity = activity;

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }
        this.logger.log(this.activity);
      }
    );
  }

  uploaderChange(imageCategory: string) {
    this.imageCategory = imageCategory;
  }

  submitCheck(): void {
    this.save();
  }
  save(): void {
    this.running = true;
    this.isSaving = true;
    this.activityService.createConfiguration(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.running = false;
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.configurationList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
      }
    );
  }
  cancel(): void {
    this.isSaving = true;
    var id = this.activity.id;
    if (id == 0) {
      this.$window.history.back();
    } else {
      this.activityService.deleteConfiguration(
        { id: id, isEdit: this.isEdit },
        (value, responseHeaders) => {
          this.$window.history.back();
        },
        httpResponse => {
          this.logger.error(
            `Activity can't delete, device ID is ${id}`,
            httpResponse,
            "Delete Activity Error"
          );
        }
      );
    }
  }
  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice == null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }
  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }
  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.storeMultimedia, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesRelease = [];
        this.imagesUpdate = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
          if (value.storeMultimedia[i].itemStatus == 1) {
            this.imagesRelease.push(value.storeMultimedia[i]);
          } else {
            this.imagesUpdate.push(value.storeMultimedia[i]);
          }
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

export class PopularProductListCtrl {
  public activities: Array<any>;
  private activityService: any;
  private logger: ILogger;
  private running: boolean;

  private searchText: string;
  private role: string;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$state"
  ];
  constructor(
    private authenticator: any,
    datacontext: Datacontext,
    common: any,
    private $state
  ) {
    this.running = true;
    this.role = authenticator.authentication.role;

    datacontext.storePopularProducts().query(
      {},
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Get Activity Error");
      }
    );
    this.activityService = datacontext.storePopularProducts();
    this.logger = common.logger;
  }

  deleteActivity(id: number, isEdit: boolean): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this activity!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.activityService.deletePopularProduct(
          { id: id },
          (value, responseHeaders) => {
            Swal.fire("Deleted!", "Your activity has been deleted.", "success");
            this.$state.reload();
          },
          httpResponse => {
            this.logger.error(
              `Activity can't delete, device ID is ${id}`,
              httpResponse,
              "Delete Activity Error"
            );
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Activity file is safe :)", "success");
      }
    });
  }

  docCopy(id: number): void {
    this.running = true;
    this.activityService.docCopy(
      { id: id },
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Copy Activity Error");
      }
    );
  }
}

export class PopularProductViewCtrl {
  private activity: any;
  private logger: ILogger;
  private activityService;

  private docSign: any;
  private running: boolean;

  static $inject = [
    "datacontext",
    "common",
    "$stateParams",
    "$window",
    "$state"
  ];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window,
    private $state
  ) {
    this.logger = common.logger;
    this.activityService = datacontext.storePopularProducts();
    // 初始值
    this.docSign = {
      id: 0,
      comment: "",
      decision: ""
    };
    this.running = true;
    this.activityService.get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.running = false;
        this.activity = value;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }

  goBack(): void {
    this.$window.history.back();
  }

  signAction(decision: string): void {
    this.running = true;
    this.docSign.id = this.activity.id;
    this.docSign.decision = decision;
    this.activityService.signDoc(
      null,
      this.docSign,
      (value, responseHeaders) => {
        this.running = false;
        this.$state.go("stores.popularProductList");
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Submit failed.", httpResponse, "簽核失敗");
      }
    );
  }
}

export class PopularProductCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;
  private imageStartDate;
  private selectAllValue: string;
  private imageCountTop;
  private _baseCrtl: BaseCrtl = new BaseCrtl();

  activity: any;
  uploader: any;
  isEdit: boolean;
  imagesRelease: any;
  imageNo: string;
  running: boolean;
  nextMonth: any;

  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "$window",
    "localStorageService"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private $window,
    private localStorageService
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    //this.deviceService = datacontext.devices();
    this.activityService = datacontext.storePopularProducts();

    // 初始化
    this.running = false;
    this.imageCountTop = 20;
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.imagesRelease = [];

    this.nextMonth = new Date();
    this.nextMonth.setMonth(this.nextMonth.getMonth() + 1);

    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      startDate: this._baseCrtl.getDate(new Date()),
      endDate: this._baseCrtl.getDate(this.nextMonth),
      agencyId: null,
      devices: [],
      storeMultimedia: []
    };

    for (var i = 0; i < this.imageCountTop; i++) {
      var image = {
        id: 0,
        no: i,
        customerId: null,
        startDate: this._baseCrtl.getDate(new Date()),
        endDate: this._baseCrtl.getDate(this.nextMonth),
        gotoUrl: ""
      };
      this.activity.storeMultimedia.push(image);
    }

    this.selectAllValue = "Select All";
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }
    this.customers = this.customerService.query();
    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/StorePopularProduct", // webapi url
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: function(item /*{File|FileLikeObject}*/, options) {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType === "mp4") {
          return true;
        } else if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      var formData = [
        {
          activityId: this.activity.id,
          imageNo: this.imageNo
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
      this.logger.log("onBeforeUploadItem", item);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (response != null) {
        this.activity.id = response.id;
        this.imagesRelease = [];
        for (var i = 0; i < response.storeMultimedia.length; i++) {
          this.imagesRelease.push(response.storeMultimedia[i]);
        }
      }
      this.logger.log("onSuccessItem", response);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };

    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.logger.log(value);

        var activity = value;

        this.activity.id = activity.id;
        this.activity.name = activity.name;
        this.activity.subject = activity.subject;
        this.activity.description = activity.description;
        this.activity.agencyId = activity.agencyId;

        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          this.imagesRelease.push(activity.storeMultimedia[i]);
          var itemNo = activity.storeMultimedia[i].no;

          this.activity.storeMultimedia[itemNo].id =
            activity.storeMultimedia[i].id;
          this.activity.storeMultimedia[itemNo].no =
            activity.storeMultimedia[i].no;
          this.activity.storeMultimedia[itemNo].customerId =
            activity.storeMultimedia[i].customerId;
          this.activity.storeMultimedia[itemNo].gotoUrl =
            activity.storeMultimedia[i].gotoUrl;
          // 換算為當地時間
          this.activity.storeMultimedia[
            itemNo
          ].startDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(activity.storeMultimedia[i].startDate)
          );
          this.activity.storeMultimedia[
            itemNo
          ].endDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(activity.storeMultimedia[i].endDate)
          );
        }

        // 設定已經勾選的 Device
        var devicesSelected = activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }
        this.activity.devices = devicesSelected;

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }

        //this.logger.log(this.activity);
      }
    );
  }

  uploaderChange(imageNo: string) {
    this.imageNo = imageNo;
  }

  submitCheck(): void {
    // 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
    this.running = true;
    var errMsg = new Array();
    if (this.imagesRelease.length === 0) {
      errMsg.push("。廣告 檔案上傳");
    }

    for (var i = 0; i < this.imageCountTop; i++) {
      var image = null;
      for (var k = 0; k < this.imagesRelease.length; k++) {
        if (this.imagesRelease[k].no == i) {
          image = this.imagesRelease[k];
          break;
        }
      }
      if (image != null) {
        if (
          this.activity.storeMultimedia[i].customerId == null ||
          this.activity.storeMultimedia[i].customerId == ""
        ) {
          errMsg.push("。廣告(" + (i + 1) + ") 客戶");
        }
        if (this.activity.storeMultimedia[i].startDate == "") {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期");
        }
        if (this.activity.storeMultimedia[i].endDate == "") {
          errMsg.push("。廣告(" + (i + 1) + ") 結束日期");
        }
        if (
          this.activity.storeMultimedia[i].gotoUrl == "" ||
          this.activity.storeMultimedia[i].gotoUrl == null
        ) {
          errMsg.push("。廣告(" + (i + 1) + ") QR Code URL");
        }
        var startDate = Date.parse(this.activity.storeMultimedia[i].startDate);
        var endDate = Date.parse(this.activity.storeMultimedia[i].endDate);
        if (startDate > endDate) {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期 > 結束日期");
        }
      }
    }

    if (errMsg.length > 0) {
      this.running = false;
      Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
    } else {
      this.docSubmit();
    }
  }

  // Submit function
  docSubmit(): void {
    this.activityService.createPopularProduct(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.popularProductList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Submit`,
          httpResponse,
          "Submit Activity Error"
        );
      }
    );
  }

  // Save function
  save(): void {
    this.activityService.save(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.popularProductList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Submit`,
          httpResponse,
          "Submit Activity Error"
        );
      }
    );
  }

  cancel(): void {
    this.$window.history.back();
  }

  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }

  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }

  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.storeMultimedia, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesRelease = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
          this.imagesRelease.push(value.storeMultimedia[i]);
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

export class PreOrderListCtrl {
  public activities: Array<any>;
  private activityService: any;
  private logger: ILogger;
  private running: boolean;
  private searchText: string;
  private role: string;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$filter",
    "$state"
  ];
  constructor(
    private authenticator: any,
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state
  ) {
    this.role = authenticator.authentication.role;

    this.activityService = datacontext.storePreOrder();
    this.running = true;
    this.activityService.query(
      {},
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Get Activity Error");
      }
    );

    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number): void {
    this.activityService.deletePreOrder(
      { id: id },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
      },
      httpResponse => {
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }

  docCopy(id: number): void {
    this.running = true;
    this.activityService.docCopy(
      { id: id },
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Copy Activity Error");
      }
    );
  }
}

export class PreOrderViewCtrl {
  private activity: any;
  private logger: ILogger;
  private activityService: any;
  private docSign: any;
  private running: boolean;

  static $inject = [
    "datacontext",
    "common",
    "$stateParams",
    "$window",
    "$state"
  ];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window,
    private $state
  ) {
    this.logger = common.logger;
    // 初始值
    this.docSign = {
      id: 0,
      comment: "",
      decision: ""
    };
    this.activityService = datacontext.storePreOrder();
    this.activityService.get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }

  goBack(): void {
    this.$window.history.back();
  }

  signAction(decision: string): void {
    this.running = true;
    this.docSign.id = this.activity.id;
    this.docSign.decision = decision;
    this.activityService.signDoc(
      null,
      this.docSign,
      (value, responseHeaders) => {
        this.running = false;
        this.$state.go("stores.preOrderList");
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Submit failed.", httpResponse, "簽核失敗");
      }
    );
  }
}

export class PreOrderCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;
  private imageStartDate;
  private selectAllValue: string;
  private imageCountTop;
  private _baseCrtl: BaseCrtl = new BaseCrtl();

  activity: any;
  uploader: any;
  isEdit: boolean;
  imagesRelease: any;
  imageNo: string;
  running: boolean;
  nextMonth: any;

  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "$window",
    "localStorageService"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private $window,
    private localStorageService
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.storePreOrder();

    this.nextMonth = new Date();
    this.nextMonth.setMonth(this.nextMonth.getMonth() + 1);

    // 初始化
    this.running = false;
    this.imageCountTop = 20;
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.imagesRelease = [];
    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      startDate: this._baseCrtl.getDate(new Date()),
      endDate: this._baseCrtl.getDate(this.nextMonth),
      agencyId: null,
      devices: [],
      storeMultimedia: []
    };

    for (var i = 0; i < this.imageCountTop; i++) {
      var image = {
        id: 0,
        no: i,
        name: "",
        customerId: null,
        startDate: this._baseCrtl.getDate(new Date()),
        endDate: this._baseCrtl.getDate(this.nextMonth),
        gotoUrl: ""
      };
      this.activity.storeMultimedia.push(image);
    }

    this.selectAllValue = "Select All";
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }
    this.customers = this.customerService.query();
    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/StorePreOrder", // webapi url
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: function(item /*{File|FileLikeObject}*/, options) {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType === "mp4") {
          return true;
        } else if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      var formData = [
        {
          activityId: this.activity.id,
          imageNo: this.imageNo
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
      this.logger.log("onBeforeUploadItem", item);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      this.logger.log("onSuccessItem", response);
      if (response != null) {
        this.activity.id = response.id;
        this.imagesRelease = [];
        for (var i = 0; i < response.storeMultimedia.length; i++) {
          var itemNo = response.storeMultimedia[i].no;
          this.activity.storeMultimedia[itemNo].id =
            response.storeMultimedia[i].id;
          this.activity.storeMultimedia[itemNo].no =
            response.storeMultimedia[i].no;
          this.imagesRelease.push(response.storeMultimedia[i]);
        }
      }
    };

    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };

    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.logger.log(value);

        var activity = value;

        this.activity.id = activity.id;
        this.activity.name = activity.name;
        this.activity.subject = activity.subject;
        this.activity.description = activity.description;
        this.activity.agencyId = activity.agencyId;

        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          this.imagesRelease.push(activity.storeMultimedia[i]);
          var itemNo = activity.storeMultimedia[i].no;

          this.activity.storeMultimedia[itemNo].id =
            activity.storeMultimedia[i].id;
          this.activity.storeMultimedia[itemNo].no =
            activity.storeMultimedia[i].no;
          this.activity.storeMultimedia[itemNo].customerId =
            activity.storeMultimedia[i].customerId;
          this.activity.storeMultimedia[itemNo].gotoUrl =
            activity.storeMultimedia[i].gotoUrl;
          // 換算為當地時間
          this.activity.storeMultimedia[
            itemNo
          ].startDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(activity.storeMultimedia[i].startDate)
          );
          this.activity.storeMultimedia[
            itemNo
          ].endDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(activity.storeMultimedia[i].endDate)
          );
        }

        // 設定已經勾選的 Device
        var devicesSelected = activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }
        this.activity.devices = devicesSelected;

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }

        this.logger.log(this.activity);
      }
    );
  }

  uploaderChange(imageNo: string) {
    this.imageNo = imageNo;
  }

  submitCheck(): void {
    // 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
    var errMsg = new Array();

    if (this.imagesRelease.length === 0) {
      errMsg.push("。廣告 檔案上傳");
    }
    for (var i = 0; i < this.imageCountTop; i++) {
      var image = null;
      for (var k = 0; k < this.imagesRelease.length; k++) {
        if (this.imagesRelease[k].no == i) {
          image = this.imagesRelease[k];
          break;
        }
      }
      if (image != null) {
        if (
          this.activity.storeMultimedia[i].customerId == null ||
          this.activity.storeMultimedia[i].customerId == ""
        ) {
          errMsg.push("。廣告(" + (i + 1) + ") 客戶");
        }
        if (this.activity.storeMultimedia[i].startDate == "") {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期");
        }
        if (this.activity.storeMultimedia[i].endDate == "") {
          errMsg.push("。廣告(" + (i + 1) + ") 結束日期");
        }
        if (this.activity.storeMultimedia[i].gotoUrl == "") {
          errMsg.push("。廣告(" + (i + 1) + ") QR Code URL");
        }
        var startDate = Date.parse(this.activity.storeMultimedia[i].startDate);
        var endDate = Date.parse(this.activity.storeMultimedia[i].endDate);
        if (startDate > endDate) {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期 > 結束日期");
        }
      }
    }

    if (errMsg.length > 0) {
      Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
    } else {
      this.docSubmit();
    }
  }

  // Submit function
  docSubmit(): void {
    this.running = true;
    // Save
    this.activityService.createPreOrder(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.preOrderList");
      },
      httpResponse => {
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
        this.running = false;
      }
    );
  }

  // Save function
  save(): void {
    this.activityService.save(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.preOrderList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Submit`,
          httpResponse,
          "Submit Activity Error"
        );
      }
    );
  }

  cancel(): void {
    this.running = true;
    var id = this.activity.id;
    if (id == 0) {
      this.$window.history.back();
    } else {
      this.activityService.deletePreOrder(
        { id: id, isEdit: this.isEdit },
        (value, responseHeaders) => {
          this.$window.history.back();
        },
        httpResponse => {
          this.logger.error(
            `Activity can't delete, device ID is ${id}`,
            httpResponse,
            "Delete Activity Error"
          );
        }
      );
    }
  }

  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }

  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }
  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.storeMultimedia, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesRelease = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
          this.imagesRelease.push(value.storeMultimedia[i]);
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

export class Game1ActivitiesListCtrl {
  private activities: Array<any>;
  private activityService: any;
  private logger: ILogger;
  private _baseCrtl = new BaseCrtl();

  running: boolean;
  gameTypeList: { [key: number]: string };

  static $inject = ["datacontext", "common", "$filter", "$state"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state,
    private swal
  ) {
    this.gameTypeList = this._baseCrtl.getGameTypeList();
    this.activities = datacontext.storeStudentArea().query();
    this.activityService = datacontext.storeStudentArea();
    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number, isEdit: boolean): void {
    if (confirm("確認刪除？")) {
      this.running = true;
      this.activityService.delete(
        { id: id, isEdit: isEdit },
        (value, responseHeaders) => {
          this.running = false;
          Swal.fire("Deleted!", "Your activity has been deleted.", "success");
          this.$state.reload();
        },
        httpResponse => {
          this.running = false;
          this.logger.error(
            `Activity can't delete, device ID is ${id}`,
            httpResponse,
            "Delete Activity Error"
          );
        }
      );
    }
  }
}

export class Game1ActivityViewCtrl {
  private activity: any;
  private logger: ILogger;
  private activityService: any;
  private _baseCrtl = new BaseCrtl();

  private reward1 = [0, 0];
  private reward2 = [0, 0];
  private reward3 = [0, 0];
  private reward4 = [0, 0];
  private reward5 = [0, 0];
  private rewardOther = [0, 0];
  rewardTotalCount: number;
  itemTitle: string[];
  itemCountTop: number;
  gameTypeList: { [key: number]: string };
  running: boolean;

  static $inject = ["datacontext", "common", "$stateParams", "$window"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window
  ) {
    this.logger = common.logger;
    this.itemTitle = [
      "直式拉霸背景圖",
      "拉霸背景圖",
      "轉輪圖",
      "1獎",
      "2獎",
      "3獎",
      "4獎",
      "5獎"
    ];
    this.gameTypeList = this._baseCrtl.getGameTypeList();
    this.itemCountTop = this.itemTitle.length;
    this.activityService = datacontext.storeStudentArea();
    this.showContent();
  }

  showContent(): void {
    this.running = true;
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.running = false;
        this.activity = value;
        this.countReward(this.activity.prizeCountList);
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }

  countReward(prizeCountList: any): void {
    this.reward1 = [0, 0];
    this.reward2 = [0, 0];
    this.reward3 = [0, 0];
    this.reward4 = [0, 0];
    this.reward5 = [0, 0];
    this.rewardOther = [0, 0];
    this.rewardTotalCount = 0;
    for (var i = 0; i < prizeCountList.length; i++) {
      this.rewardTotalCount =
        this.rewardTotalCount + prizeCountList[i].totalCount;
      switch (prizeCountList[i].no) {
        case 1:
          this.reward1[0] = prizeCountList[i].enableCount;
          this.reward1[1] = prizeCountList[i].disableCount;
          break;
        case 2:
          this.reward2[0] = prizeCountList[i].enableCount;
          this.reward2[1] = prizeCountList[i].disableCount;
          break;
        case 3:
          this.reward3[0] = prizeCountList[i].enableCount;
          this.reward3[1] = prizeCountList[i].disableCount;
          break;
        case 4:
          this.reward4[0] = prizeCountList[i].enableCount;
          this.reward4[1] = prizeCountList[i].disableCount;
          break;
        case 5:
          this.reward5[0] = prizeCountList[i].enableCount;
          this.reward5[1] = prizeCountList[i].disableCount;
          break;
        default:
          this.rewardOther[0] = prizeCountList[i].enableCount;
          this.rewardOther[1] = prizeCountList[i].disableCount;
          break;
      }
    }
  }

  goBack(): void {
    this.$window.history.back();
  }
}

export class Game1ActivityCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;

  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;
  private imageStartDate;

  private _baseCrtl = new BaseCrtl();

  images = [];
  activity: any;
  selectAllValue: string;
  isEdit: boolean;
  imagesUpdate: any;
  imageNo: number;
  imageCategory: string;
  uploader: any;
  webApiUrl: string;
  reward1 = [0, 0];
  reward2 = [0, 0];
  reward3 = [0, 0];
  reward4 = [0, 0];
  reward5 = [0, 0];
  rewardOther = [0, 0];
  rewardTotalCount: number;
  running: boolean;
  itemTitle: string[];
  itemCountTop: number;
  gameTypeList: { [key: number]: string };
  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "$window",
    "localStorageService"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private $window,
    private localStorageService
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.storeStudentArea();

    // 初始化
    this.running = false;
    this.itemTitle = [
      "直式裝置背景圖",
      "遊戲背景圖",
      "轉輪圖",
      "1獎",
      "2獎",
      "3獎",
      "4獎",
      "5獎"
    ];
    this.gameTypeList = this._baseCrtl.getGameTypeList();
    this.itemCountTop = this.itemTitle.length;

    this.images = [];
    for (var i = 0; i < this.itemCountTop; i++) {
      var image = {
        id: 0,
        no: i,
        reward: "",
        marketPrice: null,
        price: null,
        gotoUrl: "",
        fileUrl: ""
      };
      this.images.push(image);
    }

    var nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      isNormalAction: true,
      notifyMail: "",
      notifyMinimumQty: 10,
      agencyId: null,
      devices: [],
      customerId: null,
      startDate: this._baseCrtl.getDate(new Date()),
      endDate: this._baseCrtl.getDate(nextMonth),
      gotoUrl: "",
      storeMultimedia: this.images,
      prizeCountList: [],
      gameType: 0
    };

    this.selectAllValue = "Select All";
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.imagesUpdate = [];
    this.imageNo = 0;
    this.imageCategory = "";

    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }

    this.customers = this.customerService.query();
    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: this.webApiUrl,
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // Another user-defined filter
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: function(item) {
        var type = "|" + item.type.slice(item.type.lastIndexOf("/") + 1) + "|";
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    this.uploader.filters.push({
      name: "csvFilter",
      fn: function(item) {
        var fileName = item.name.toLowerCase();
        var charIndex = fileName.lastIndexOf(".");
        var key = fileName.substring(charIndex, fileName.length);
        return "|.csv|".indexOf(key) !== -1;
      }
    });

    // CALLBACKS
    this.uploader.onWhenAddingFileFailed = (
      item /*{File|FileLikeObject}*/,
      filter,
      options
    ) => {
      this.logger.log("onWhenAddingFileFailed", item, filter, options);
    };
    this.uploader.onAfterAddingFile = fileItem => {
      this.logger.log("onAfterAddingFile", fileItem.file.name);
    };
    this.uploader.onAfterAddingAll = addedFileItems => {
      this.logger.log("onAfterAddingAll", addedFileItems);
    };
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      item.url = this.webApiUrl;
      if (this.imageCategory == "PrizeNumbers") {
        this.activity.prizeCountList = [];
        this.countReward(this.activity.prizeCountList);
      }
      var formData = [
        {
          activityId: this.activity.id,
          imageCategory: this.imageCategory,
          imageNo: this.imageNo
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
      this.logger.log("onBeforeUploadItem", item);
    };
    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      this.activity.id = response.id;
      if (this.imageCategory == "PrizeNumbers") {
        this.activity.prizeCountList = response.prizeCountList;
        this.countReward(this.activity.prizeCountList);
      } else {
        //this.imagesUpdate = [];
        //if (this.isEdit) {
        //  for (var i = 0; i < response.storeMultimedia.length; i++) {
        //    if (response.storeMultimedia[i].itemStatus !== 1) {
        //      this.imagesUpdate.push(response.storeMultimedia[i]);
        //    }
        //  }
        //} else {
          for (var i = 0; i < response.storeMultimedia.length; i++) {
            var no = response.storeMultimedia[i].no;
            this.activity.storeMultimedia[no].id =
              response.storeMultimedia[i].id;
            this.activity.storeMultimedia[no].dispalyName =
              response.storeMultimedia[i].dispalyName;
            this.activity.storeMultimedia[no].fileUrl =
              response.storeMultimedia[i].fileUrl;
          }
        //}
      }
      this.logger.log("onSuccessItem", response);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };
    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.running = true;
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.running = false;

        var activity = value;
        activity.startDate = this._baseCrtl.getDate(
          this.$filter("localDateTime")(activity.startDate)
        );
        activity.endDate = this._baseCrtl.getDate(
          this.$filter("localDateTime")(activity.endDate)
        );
        this.countReward(activity.prizeCountList);

        this.activity = activity;
        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          var no = activity.storeMultimedia[i].no;
          if (activity.storeMultimedia[i].itemStatus === 1) {
            this.images[no] = activity.storeMultimedia[i];
          } else {
            this.imagesUpdate.push(activity.storeMultimedia[i]);
          }
        }
        this.activity.storeMultimedia = this.images;
        // 設定已經勾選的 Device
        var devicesSelected = activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }

        this.activity.devices = devicesSelected;

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }

        this.logger.log(this.activity);
      }
    );
  }

  submitCheck(): void {
    // 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
    var errMsg = new Array();
    errMsg = this.itemCheck();

    if (errMsg.length > 0) {
      Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
    } else {
      this.save();
    }
  }

  // 欄位檢查
  itemCheck(): string[] {
    var errMsg = new Array();
    if (this.activity.notifyMail == "") {
      errMsg.push("。序號結束的通知對象");
    }
    if (this.activity.customerId == null || this.activity.customerId == "") {
      errMsg.push("。廣告 客戶");
    }
    if (this.activity.startDate == "") {
      errMsg.push("。廣告 開始日期");
    }
    if (this.activity.endDate == "") {
      errMsg.push("。廣告 結束日期");
    }
    if (this.activity.gameType == null) {
      errMsg.push("。遊戲種類");
    }
    var startDate = Date.parse(this.activity.startDate);
    var endDate = Date.parse(this.activity.endDate);
    if (startDate > endDate) {
      {
        errMsg.push("。廣告 開始日期 > 結束日期");
      }
    }

    if (this.rewardTotalCount == 0) {
      errMsg.push("。序號(csv檔案) 上傳");
    }

    if (this.activity.gameType == 0 || this.activity.gameType == null) {
      // 拉 Bar
      for (var i = 0; i < this.itemCountTop; i++) {
        var reward = this.itemTitle[i];
        if (i >= 3) {
          if (this.activity.storeMultimedia[i].reward == null) {
            errMsg.push("。" + reward + " 獎項名稱");
          }
          if (this.activity.storeMultimedia[i].marketPrice == null) {
            errMsg.push("。" + reward + " 市價");
          }
          if (this.activity.storeMultimedia[i].price == null) {
            errMsg.push("。" + reward + " 特價");
          }
          if (
            this.activity.storeMultimedia[i].marketPrice != null &&
            this.activity.storeMultimedia[i].price != null
          ) {
            if (this.activity.storeMultimedia[i].marketPrice == 0) {
              errMsg.push("。" + reward + "市價 = 0 元");
            }
            if (this.activity.storeMultimedia[i].price == 0) {
              errMsg.push("。" + reward + "特價 = 0 元");
            }
            if (
              this.activity.storeMultimedia[i].price >
              this.activity.storeMultimedia[i].marketPrice
            ) {
              errMsg.push("。" + reward + "特價 > 市價");
            }
          }
          if (this.activity.storeMultimedia[i].gotoUrl == "") {
            errMsg.push("。" + reward + " QR Code URL");
          }
        }
        if (this.activity.storeMultimedia[i].fileUrl == "") {
          errMsg.push("。" + reward + " 檔案上傳");
        }
      }
    } else {
      if (this.activity.gotoUrl == "") {
        errMsg.push("。 QR Code URL");
      }
      for (var i = 0; i < this.itemCountTop; i++) {
        var reward = this.itemTitle[i];
        if (i >= 3) {
          if (this.activity.storeMultimedia[i].reward == null) {
            errMsg.push("。" + reward + " 獎項名稱");
          }
        }
        if (i < 2) {
          if (this.activity.storeMultimedia[i].fileUrl == "") {
            errMsg.push("。" + reward + " 檔案上傳");
          }
        }
      }
    }

    return errMsg;
  }

  save(): void {
    this.running = true;
    this.activityService.createActivity(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.running = false;
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$window.history.back();
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
      }
    );
  }

  cancel(): void {
    this.$window.history.back();
  }

  // Device 的 CRUD
  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }

  // Device Select All
  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }

  uploaderChange(imageCategory: string, imageNo: number) {
    if (imageCategory == "PrizeNumbers") {
      this.imageCategory = imageCategory;
      this.webApiUrl = this.apiUrl + "api/StorePrizeNumbers";
    } else {
      this.imageCategory =
        imageCategory == "0"
          ? "Background"
          : imageCategory == "1"
          ? "Reward"
          : "Product";
      this.imageNo = imageNo;
      this.webApiUrl = this.apiUrl + "api/StoreGame1";
    }
  }

  countReward(prizeCountList: any): void {
    this.reward1 = [0, 0];
    this.reward2 = [0, 0];
    this.reward3 = [0, 0];
    this.reward4 = [0, 0];
    this.reward5 = [0, 0];
    this.rewardOther = [0, 0];
    this.rewardTotalCount = 0;
    for (var i = 0; i < prizeCountList.length; i++) {
      this.rewardTotalCount =
        this.rewardTotalCount + prizeCountList[i].totalCount;
      switch (prizeCountList[i].no) {
        case 1:
          this.reward1[0] = prizeCountList[i].enableCount;
          this.reward1[1] = prizeCountList[i].disableCount;
          break;
        case 2:
          this.reward2[0] = prizeCountList[i].enableCount;
          this.reward2[1] = prizeCountList[i].disableCount;
          break;
        case 3:
          this.reward3[0] = prizeCountList[i].enableCount;
          this.reward3[1] = prizeCountList[i].disableCount;
          break;
        case 4:
          this.reward4[0] = prizeCountList[i].enableCount;
          this.reward4[1] = prizeCountList[i].disableCount;
          break;
        case 5:
          this.reward5[0] = prizeCountList[i].enableCount;
          this.reward5[1] = prizeCountList[i].disableCount;
          break;
        default:
          this.rewardOther[0] = prizeCountList[i].enableCount;
          this.rewardOther[1] = prizeCountList[i].disableCount;
          break;
      }
    }
  }

  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.storeMultimedia, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesUpdate = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
          if (value.storeMultimedia[i].itemStatus === 1) {
            var index = value.storeMultimedia[i].no;
            this.activity.storeMultimedia[index] = value.storeMultimedia[i];
          } else {
            this.imagesUpdate.push(value.storeMultimedia[i]);
          }
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

export class FourthAreaListCtrl {
  public activities: Array<any>;
  private activityService: any;
  private logger: ILogger;
  private running: boolean;

  private searchText: string;
  private role: string;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$filter",
    "$state"
  ];
  constructor(
    private authenticator: any,
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state
  ) {
    this.running = true;
    this.role = authenticator.authentication.role;

    this.activityService = datacontext.storeFourthArea();
    this.logger = common.logger;

    this.activityService.getActivityList(
      {},
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Get Activity Error");
      }
    );
  }

  deleteActivity(id: number, isEdit: boolean): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this activity!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.activityService.deleteActivity(
          { id: id },
          (value, responseHeaders) => {
            Swal.fire("Deleted!", "Your activity has been deleted.", "success");
            this.$state.reload();
          },
          httpResponse => {
            this.logger.error(
              `Activity can't delete, device ID is ${id}`,
              httpResponse,
              "Delete Activity Error"
            );
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Activity file is safe :)", "success");
      }
    });
  }

  docCopy(id: number): void {
    this.running = true;
    this.activityService.docCopy(
      { id: id },
      (value, responseHeaders) => {
        this.activities = value;
        this.running = false;
        this.logger.log("activities : ", this.activities);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Error", httpResponse, "Copy Activity Error");
      }
    );
  }
}

export class FourthAreaViewCtrl {
  private activity: any;
  private logger: ILogger;
  private activityService;
  private _baseCrtl: BaseCrtl = new BaseCrtl();

  private docSign: any;
  private running: boolean;
  private storeNameList: any;

  static $inject = [
    "datacontext",
    "common",
    "$stateParams",
    "$window",
    "$state"
  ];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window,
    private $state
  ) {
    this.logger = common.logger;
    this.activityService = datacontext.storeFourthArea();
    // 初始值
    this.storeNameList = this._baseCrtl.getStoreNameList();
    this.docSign = {
      id: 0,
      comment: "",
      decision: ""
    };
    this.running = true;
    this.activityService.getActivity(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.running = false;
        this.activity = value;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }

  goBack(): void {
    this.$window.history.back();
  }

  signAction(decision: string): void {
    this.running = true;
    this.docSign.id = this.activity.id;
    this.docSign.decision = decision;
    this.activityService.signDoc(
      null,
      this.docSign,
      (value, responseHeaders) => {
        this.running = false;
        this.$state.go("stores.fourthAreaList");
      },
      httpResponse => {
        this.running = false;
        this.logger.error("Submit failed.", httpResponse, "簽核失敗");
      }
    );
  }
}

export class FourthAreaCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;
  private imageStartDate;
  private selectAllValue: string;
  private imageCountTop;
  private _baseCrtl: BaseCrtl = new BaseCrtl();

  activity: any;
  uploader: any;
  isEdit: boolean;
  imagesRelease: any;
  imageNo: string;
  running: boolean;
  nextMonth: any;
  storeNameList: any;
  private role: any;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "$window",
    "localStorageService"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private $window,
    private localStorageService
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.storeFourthArea();

    // 初始化
    this.running = false;
    this.imageCountTop = 40;
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.imagesRelease = [];

    this.nextMonth = new Date();
    this.nextMonth.setMonth(this.nextMonth.getMonth() + 1);

    this.storeNameList = this._baseCrtl.getStoreNameList();

    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      startDate: this._baseCrtl.getDate(new Date()),
      endDate: this._baseCrtl.getDate(this.nextMonth),
      agencyId: null,
      devices: [],
      storeMultimedia: []
    };

    for (var i = 0; i < this.imageCountTop; i++) {
      var image = {
        id: 0,
        no: i,
        customerId: null,
        startDate: this._baseCrtl.getDate(new Date()),
        endDate: this._baseCrtl.getDate(this.nextMonth),
        gotoUrl: "",
        storeName: 0
      };
      this.activity.storeMultimedia.push(image);
    }

    this.selectAllValue = "Select All";
    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }
    this.customers = this.customerService.query();
    this.logger.log(this.customers);

    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/StoreFourthArea", // webapi url
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: function(item /*{File|FileLikeObject}*/, options) {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        const mediaType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (mediaType === "mp4") {
          return true;
        } else if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      var formData = [
        {
          activityId: this.activity.id,
          imageNo: this.imageNo
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
      this.logger.log("onBeforeUploadItem", item);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (response != null) {
        this.activity.id = response.id;
        this.imagesRelease = [];
        for (var i = 0; i < response.storeMultimedia.length; i++) {
          this.imagesRelease.push(response.storeMultimedia[i]);
        }
      }
      this.logger.log("onSuccessItem", response);
    };
    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };

    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.getActivity(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        this.logger.log(value);
        var activity = value;

        this.activity.id = activity.id;
        this.activity.name = activity.name;
        this.activity.subject = activity.subject;
        this.activity.description = activity.description;
        this.activity.agencyId = activity.agencyId;

        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          this.imagesRelease.push(activity.storeMultimedia[i]);
          var itemNo = activity.storeMultimedia[i].no;

          this.activity.storeMultimedia[itemNo].id =
            activity.storeMultimedia[i].id;
          this.activity.storeMultimedia[itemNo].no =
            activity.storeMultimedia[i].no;
          this.activity.storeMultimedia[itemNo].customerId =
            activity.storeMultimedia[i].customerId;
          this.activity.storeMultimedia[itemNo].gotoUrl =
            activity.storeMultimedia[i].gotoUrl;
          this.activity.storeMultimedia[itemNo].storeName =
            activity.storeMultimedia[i].storeName;
          // 換算為當地時間
          this.activity.storeMultimedia[
            itemNo
          ].startDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(activity.storeMultimedia[i].startDate)
          );
          this.activity.storeMultimedia[
            itemNo
          ].endDate = this._baseCrtl.getDate(
            this.$filter("localDateTime")(activity.storeMultimedia[i].endDate)
          );
        }

        // 設定已經勾選的 Device
        var devicesSelected = activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }
        this.activity.devices = devicesSelected;

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }

        //this.logger.log(this.activity);
      }
    );
  }

  uploaderChange(imageNo: string) {
    this.imageNo = imageNo;
  }

  submitCheck(): void {
    // 檢查 Device 是否重覆設定？對話框詢問是否使用新設定？確認後 Save()
    this.running = true;
    var errMsg = new Array();
    if (this.imagesRelease.length === 0) {
      errMsg.push("。廣告 檔案上傳");
    }

    for (var i = 0; i < this.imageCountTop; i++) {
      var image = null;
      for (var k = 0; k < this.imagesRelease.length; k++) {
        if (this.imagesRelease[k].no == i) {
          image = this.imagesRelease[k];
          break;
        }
      }
      if (image != null) {
        if (
          this.activity.storeMultimedia[i].customerId == null ||
          this.activity.storeMultimedia[i].customerId == ""
        ) {
          errMsg.push("。廣告(" + (i + 1) + ") 客戶");
        }
        if (
          this.activity.storeMultimedia[i].storeName == null ||
          this.activity.storeMultimedia[i].storeName == 0
        ) {
          errMsg.push("。廣告(" + (i + 1) + ") 廣告類別");
        }
        if (this.activity.storeMultimedia[i].startDate == "") {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期");
        }
        if (this.activity.storeMultimedia[i].endDate == "") {
          errMsg.push("。廣告(" + (i + 1) + ") 結束日期");
        }
        if (
          this.activity.storeMultimedia[i].gotoUrl == "" ||
          this.activity.storeMultimedia[i].gotoUrl == null
        ) {
          errMsg.push("。廣告(" + (i + 1) + ") QR Code URL");
        }
        var startDate = Date.parse(this.activity.storeMultimedia[i].startDate);
        var endDate = Date.parse(this.activity.storeMultimedia[i].endDate);
        if (startDate > endDate) {
          errMsg.push("。廣告(" + (i + 1) + ") 開始日期 > 結束日期");
        }
      }
    }

    if (errMsg.length > 0) {
      this.running = false;
      Swal.fire("欄位檢查錯誤：", errMsg.join("\n"));
    } else {
      this.docSubmit();
    }
  }

  // Submit function
  docSubmit(): void {
    this.activityService.createActivity(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.fourthAreaList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Submit`,
          httpResponse,
          "Submit Activity Error"
        );
      }
    );
  }

  // Save function
  save(): void {
    this.activityService.save(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.fourthAreaList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Submit`,
          httpResponse,
          "Submit Activity Error"
        );
      }
    );
  }

  cancel(): void {
    this.$window.history.back();
  }

  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice === null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }

  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }

  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.storeMultimedia, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        this.imagesRelease = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
          this.imagesRelease.push(value.storeMultimedia[i]);
        }
        this.logger.success("Action is successful.", value, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

export class FourthAreaConfigListCtrl {
  private activities: Array<any>;
  private activityService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common", "$filter", "$state"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state,
    private swal
  ) {
    this.activityService = datacontext.storeFourthAreaConfig();
    this.activities = this.activityService.query();
    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number, isEdit: boolean): void {
    this.activityService.deleteConfiguration(
      { id: id, isEdit: isEdit },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
      },
      httpResponse => {
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }
}

export class FourthAreaConfigViewCtrl {
  private activity: any;
  private titleIndex: string[];
  private logger: ILogger;
  static $inject = ["datacontext", "common", "$stateParams", "$window"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window
  ) {
    this.titleIndex = [
      "按鈕(1)",
      "按鈕(2)",
      "按鈕(3)",
      "按鈕(4)",
      "按鈕(5)",
      "按鈕(6)"
    ];
    this.logger = common.logger;
    datacontext.storeFourthAreaConfig().get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        var imagesRelease = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
          if (value.storeMultimedia[i].itemStatus === 1) {
            imagesRelease.push(value.storeMultimedia[i]);
          }
        }
        this.activity.storeMultimedia = imagesRelease;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }
  goBack(): void {
    this.$window.history.back();
  }
}

export class FourthAreaConfigCreateCtrl {
  private customers: Array<ICustomer>;
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private customerService;
  private agencyService;
  private activityService;

  private selectAllValue: string;

  activity: any;
  uploader: any;
  isEdit: boolean;
  isSaving: boolean;
  imagesRelease: any;
  imagesUpdate: any;
  private titleIndex: string[];
  valueIndex: number[];
  running: boolean;
  private role: any;
  private no: string;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "localStorageService",
    "$window",
    "$rootScope"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private localStorageService,
    private $window,
    private $rootScope
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.customerService = datacontext.customers();
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.storeFourthAreaConfig();

    // 初始化
    this.running = false;
    this.titleIndex = [
      "按鈕(1)",
      "按鈕(2)",
      "按鈕(3)",
      "按鈕(4)",
      "按鈕(5)",
      "按鈕(6)"
    ];
    this.valueIndex = [0, 1, 2, 3, 4, 5];
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.selectAllValue = "Select All";
    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      customerId: 0,
      agencyId: 0,
      devices: [],
      storeMultimedia: []
    };

    for (var i = 0; i < 6; i++) {
      var image = {
        id: 0,
        no: i,
        imageCategory: "",
        gotoUrl: "",
        fileUrl: ""
      };
      this.activity.storeMultimedia.push(image);
    }

    this.isSaving = false;
    this.imagesRelease = [];
    this.imagesUpdate = [];

    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }

    this.customers = this.customerService.query();

    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/StoreFourthAreaConfig",
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: (item, options) => {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      this.isSaving = true;
      var formData = [
        {
          activityId: this.activity.id,
          no: this.no
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (response != null) {
        var activity = response;
        this.activity.id = activity.id;
        this.imagesRelease = [];
        this.imagesUpdate = [];
        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          if (
            this.isEdit == false ||
            (this.isEdit == true && activity.storeMultimedia[i].itemStatus == 1)
          ) {
            this.imagesRelease.push(activity.storeMultimedia[i]);
            var itemNo = activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].id =
              activity.storeMultimedia[i].id;
            this.activity.storeMultimedia[itemNo].no =
              activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].fileUrl =
              activity.storeMultimedia[i].fileUrl;
          } else {
            this.imagesUpdate.push(activity.storeMultimedia[i]);
          }
        }
      }
      this.logger.log("onSuccessItem", response);
    };

    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };

    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.isSaving = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        var activity = value;
        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          if (
            this.isEdit == false ||
            (this.isEdit == true && activity.storeMultimedia[i].itemStatus == 1)
          ) {
            this.imagesRelease.push(activity.storeMultimedia[i]);

            var itemNo = activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].id =
              activity.storeMultimedia[i].id;
            this.activity.storeMultimedia[itemNo].no =
              activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].gotoUrl =
              activity.storeMultimedia[i].gotoUrl;
            this.activity.storeMultimedia[itemNo].imageCategory =
              activity.storeMultimedia[i].imageCategory;
            this.activity.storeMultimedia[itemNo].fileUrl =
              activity.storeMultimedia[i].fileUrl;
          } else {
            this.imagesUpdate.push(activity.storeMultimedia[i]);
          }
        }

        this.activity.id = activity.id;
        this.activity.name = activity.name;
        this.activity.subject = activity.subject;
        this.activity.description = activity.description;
        this.activity.agencyId = activity.agencyId;

        // 設定已經勾選的 Device
        var devicesSelected = activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }
        this.activity.devices = devicesSelected;

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }
        this.logger.log(this.activity);
      }
    );
  }

  uploaderChange(no: string) {
    this.no = no;
  }

  submitCheck(): void {
    this.running = true;
    var errMsg = new Array();
    for (var i = 0; i < 6; i++) {
      if (this.activity.storeMultimedia[i].imageCategory == "") {
        errMsg.push("。功能類別(" + (i + 1) + ")");
      }
      if (
        this.activity.storeMultimedia[i].imageCategory == 6 &&
        this.activity.storeMultimedia[i].gotoUrl == ""
      ) {
        errMsg.push("。VR網址(" + (i + 1) + ")");
      }
      if (this.activity.storeMultimedia[i].fileUrl == "") {
        errMsg.push("。按鈕圖案(" + (i + 1) + ")");
      }
    }

    if (errMsg.length > 0) {
      this.running = false;
      Swal.fire("欄位檢查錯誤：", errMsg.join("<br/>"));
    } else {
      this.save();
    }
  }
  save(): void {
    this.running = true;
    this.isSaving = true;
    this.activityService.createConfiguration(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.running = false;
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("stores.fourthAreaConfigList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
      }
    );
  }
  cancel(): void {
    this.isSaving = true;
    var id = this.activity.id;
    if (id == 0) {
      this.$window.history.back();
    } else {
      this.activityService.deleteConfiguration(
        { id: id, isEdit: this.isEdit },
        (value, responseHeaders) => {
          this.$window.history.back();
        },
        httpResponse => {
          this.logger.error(
            `Activity can't delete, device ID is ${id}`,
            httpResponse,
            "Delete Activity Error"
          );
        }
      );
    }
  }
  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice == null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }
  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }
  deleteImage(id: number): void {
    var image = this.$filter("getById")(this.activity.storeMultimedia, id);
    this.activityService.deleteImage(
      { id: id },
      null,
      (value, responseHeaders) => {
        var activity = value;
        this.imagesRelease = [];
        this.imagesUpdate = [];
        for (var i = 0; i < this.activity.storeMultimedia.length; i++) {
          this.activity.storeMultimedia[itemNo].fileUrl = "";
        }
        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          if (
            this.isEdit == false ||
            (this.isEdit == true && activity.storeMultimedia[i].itemStatus == 1)
          ) {
            this.imagesRelease.push(activity.storeMultimedia[i]);
            var itemNo = activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].id =
              activity.storeMultimedia[i].id;
            this.activity.storeMultimedia[itemNo].no =
              activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].fileUrl =
              activity.storeMultimedia[i].fileUrl;
          } else {
            this.imagesUpdate.push(activity.storeMultimedia[i]);
          }
        }
        this.logger.success("Action is successful.", activity, "Deleted!");
      },
      httpResponse => {
        this.logger.error("Action failed.", httpResponse, "Deleted!");
      }
    );
  }
}

export class HumancountboardConfigListCtrl {
  private activities: Array<any>;
  private activityService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common", "$filter", "$state"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $filter,
    private $state,
    private swal
  ) {
    this.activityService = datacontext.humancountboardConfig();
    this.activities = this.activityService.query();
    this.logger = common.logger;
    this.logger.log("activities : ", this.activities);
  }

  deleteActivity(id: number, isEdit: boolean): void {
    this.activityService.deleteConfiguration(
      { id: id, isEdit: isEdit },
      (value, responseHeaders) => {
        Swal.fire("Deleted!", "Your activity has been deleted.", "success");
        this.$state.reload();
      },
      httpResponse => {
        this.logger.error(
          `Activity can't delete, device ID is ${id}`,
          httpResponse,
          "Delete Activity Error"
        );
      }
    );
  }
}

export class HumancountboardConfigViewCtrl {
  private activity: any;
  private titleIndex: string[];
  private logger: ILogger;
  static $inject = ["datacontext", "common", "$stateParams", "$window"];
  constructor(
    datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $window
  ) {

    this.logger = common.logger;
    datacontext.humancountboardConfig().get(
      { id: $stateParams.id },
      (value, responseHeaders) => {
        this.activity = value;
        var imagesRelease = [];
        for (var i = 0; i < value.storeMultimedia.length; i++) {
            imagesRelease.push(value.storeMultimedia[i]);
        }
        this.activity.storeMultimedia = imagesRelease;
        this.logger.log(this.activity);
      },
      httpResponse => {
        this.logger.error("Process Error", httpResponse, "Get Activity Error");
      }
    );
  }
  goBack(): void {
    this.$window.history.back();
  }
}

export class HumancountboardConfigCreateCtrl {
  private devices: Array<IDevice>;
  private agencies: Array<IAgency>;
  private logger: ILogger;

  private agencyService;
  private activityService;

  private selectAllValue: string;

  activity: any;
  uploader: any;
  isEdit: boolean;
  isSaving: boolean;
  imagesRelease: any;
  running: boolean;
  private role: any;
  private no: string;

  static $inject = [
    "Authenticator",
    "datacontext",
    "common",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "$filter",
    "localStorageService",
    "$window",
    "$rootScope"
  ];
  constructor(
    private authenticator: any,
    private datacontext: Datacontext,
    common: any,
    private $stateParams,
    private $state,
    private apiUrl,
    private FileUploader,
    private $filter,

    private localStorageService,
    private $window,
    private $rootScope
  ) {
    this.logger = common.logger;
    this.role = authenticator.authentication.role;
    this.agencyService = datacontext.agencies();
    this.activityService = datacontext.humancountboardConfig();

    // 初始化
    this.running = false;
    this.isEdit = $stateParams.id !== undefined; // 判斷為編輯還是新增播放清單
    this.selectAllValue = "Select All";
    this.activity = {
      id: 0,
      name: "",
      subject: "",
      description: "",
      agencyId: 0,
      gotoUrl: "",
      devices: [],
      storeMultimedia: []
    };

    for (var i = 0; i < 1; i++) {
      var image = {
        id: 0,
        no: i,
        fileUrl: ""
      };
      this.activity.storeMultimedia.push(image);
    }

    this.isSaving = false;
    this.imagesRelease = [];

    if (this.role.includes("SystemAdmin") || this.role.includes("Developer")) {
      this.agencies = this.agencyService.query();
    }

    // Devices
    this.activityService.getDeviceList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		);
      if (this.isEdit) {
        this.getEditValue(value);
      }
    });

    // File Upload 相關
    this.uploader = new FileUploader({
      url: apiUrl + "api/HumancountboardConfig",
      headers: {
        Authorization: "Bearer " + localStorageService.get("jwt")
      },
      autoUpload: true
    });

    // FILTERS
    this.uploader.filters.push({
      name: "mediaFilter",
      fn: (item, options) => {
        const type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
        var fileSize = Math.ceil(item.size / 1024 / 1024);
        if (fileSize > 2) {
          Swal.fire('檔案 ' + fileSize.toString() + ' MB, 超過單檔上限!', '每次圖檔上傳，單檔上限 2MB', 'error');
          return false;
        } else {
          return "|jpg|png|jpeg|bmp|gif|".indexOf(type) !== -1;
        }
      }
    });

    // CALLBACKS
    this.uploader.onBeforeUploadItem = item => {
      this.running = true;
      this.isSaving = true;
      var formData = [
        {
          activityId: this.activity.id,
          no: this.no
        }
      ];
      Array.prototype.push.apply(item.formData, formData);
    };

    this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
      if (response != null) {
        var activity = response;
        this.activity.id = activity.id;
        this.imagesRelease = [];
        for (var i = 0; i < activity.storeMultimedia.length; i++) {
          if (this.isEdit == false) {
            this.imagesRelease.push(activity.storeMultimedia[i]);
            var itemNo = activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].id =
              activity.storeMultimedia[i].id;
            this.activity.storeMultimedia[itemNo].no =
              activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].fileUrl =
              activity.storeMultimedia[i].fileUrl;
          }
        }
      }
      this.logger.log("onSuccessItem", response);
    };

    this.uploader.onErrorItem = (fileItem, response, status, headers) => {
      this.logger.log("onErrorItem", fileItem, response, status, headers);
    };

    this.uploader.onCompleteAll = () => {
      this.running = false;
      this.isSaving = false;
      this.logger.log("onCompleteAll");
    };
  }

  getEditValue(devices: any): void {
    this.activityService.get(
      { id: this.$stateParams.id },
      (value, responseHeaders) => {
        var activity = value;
        for (var i = 0; i < activity.storeMultimedia.length; i++) {
            this.imagesRelease.push(activity.storeMultimedia[i]);
            var itemNo = activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].id =
              activity.storeMultimedia[i].id;
            this.activity.storeMultimedia[itemNo].no =
              activity.storeMultimedia[i].no;
            this.activity.storeMultimedia[itemNo].fileUrl =
              activity.storeMultimedia[i].fileUrl;
        }

        this.activity.id = activity.id;
        this.activity.name = activity.name;
        this.activity.subject = activity.subject;
        this.activity.description = activity.description;
        this.activity.agencyId = activity.agencyId;
        this.activity.gotoUrl = activity.gotoUrl;

        // 設定已經勾選的 Device
        var devicesSelected = activity.devices;
        for(var i=0; i < devicesSelected.length; i++){
          const found = this.$filter("getById")(devices, devicesSelected[i].id);
          if (found !== undefined && found !== null) {
            found.selected = true;
          }
        }
        this.activity.devices = devicesSelected;

        // 重新計算 Select All
        if (devicesSelected.length == devices.length) {
          this.selectAllValue = "Unselect All";
        }
        this.logger.log(this.activity);
      }
    );
  }

  uploaderChange(no: string) {
    this.no = no;
  }

  submitCheck(): void {
    this.running = true;
    var errMsg = new Array();
    if (this.activity.gotoUrl == "") {
       errMsg.push("。人流看板網址");
    }

    if (errMsg.length > 0) {
      this.running = false;
      Swal.fire("欄位檢查錯誤：", errMsg.join("<br/>"));
    } else {
      this.save();
    }
  }
  save(): void {
    this.running = true;
    this.isSaving = true;
    this.activityService.createConfiguration(
      null,
      this.activity,
      (value, responseHeaders) => {
        this.running = false;
        this.logger.success("Activity has Create!", value, "Good job!");
        this.$state.go("changjia.humancountboardConfigList");
      },
      httpResponse => {
        this.running = false;
        this.logger.log(httpResponse);
        this.logger.error(
          `Activity can't Create`,
          httpResponse,
          "Create Activity Error"
        );
      }
    );
  }
  cancel(): void {
    this.isSaving = true;
    var id = this.activity.id;
      this.$window.history.back();
  }
  
  deviceChange(device: any): void {
    var devices = this.activity.devices;
    if (device.selected) {
      const addDevice = this.$filter("getById")(devices, device.id);
      if (addDevice == null) {
        devices.push(device);
      }
    } else {
      const delDevice = this.$filter("getById")(devices, device.id);
      if (delDevice !== null) {
        const indx = devices.indexOf(delDevice);
        devices.splice(indx, 1);
      }
    }
    this.activity.devices = devices;
  }
  devicesSelectAll(): void {
    var selected = false;
    if (this.selectAllValue == "Select All") {
      selected = true;
      this.selectAllValue = "Unselect All";
    } else {
      selected = false;
      this.selectAllValue = "Select All";
    }
    for (var i = 0; i < this.devices.length; i++) {
      this.devices[i].selected = selected;
      this.deviceChange(this.devices[i]);
    }
  }
}

export class StoreDevicesListCtrl {
  private devices: any[];
  private activityService: any;
  private logger: ILogger;

  static $inject = ["datacontext", "common"];
  constructor(datacontext: Datacontext, common: any) {
    this.logger = common.logger;
    this.activityService = datacontext.storeConfigurations();
    this.activityService.getDeviceInfoList((value, responseHeaders) => {
		this.devices = value.filter(
			device => device.functionalModule & FunctionalModule.StoreApp
		);
      this.logger.log("devices :", this.devices);
    });
  }
}

export class DocSignListCtrl {
  docList: any[];

  private logger: ILogger;

  static $inject = ["datacontext", "common"];
  constructor(datacontext: Datacontext, common: any) {
    this.docList = datacontext.storeSign().getWaitSignDocList();
    this.logger = common.logger;
    this.logger.log("docList :", this.docList);
  }
}
