import * as angular from "angular";

import { toastrConfig, configure, config } from "./config";
import { apiUrl, functionUrl, functionCode, environment, region } from "./constants";
import { common } from "./common";

import BlocksLogger from "../blocks/logger/logger.module";
import BlocksException from "../blocks/exception/exception.module";

// Angular modules
require("angular-messages");
require("angular-touch");

// 3rd Party Modules
require('@uirouter/angularjs')
require('oclazyload');
require('angular-ui-bootstrap');
require('angular-moment');

// import 'sweetalert2/dist/sweetalert2.css';
// import 'sweetalert2';
// import { ngSweetAlert2 } from 'angular-h-sweetalert';

require('angular-filter');
require('ng-idle');

export default angular
  .module("app.core", [
    // Angular modules
    "ngMessages",
    "ngTouch", //'ngAnimate', 'ngRoute',

    // Custom modules
    /*
     * Our reusable cross app code modules
     */

    BlocksException.name,
    BlocksLogger.name, //'blocks.router',

    // 3rd Party Modules
    "ui.router", // Routing
    "oc.lazyLoad", // ocLazyLoad
    "ui.bootstrap", // Ui Bootstrap
    "angularMoment", // Moment
    // ngSweetAlert2,
    // "ngSweetAlert2", // sweetalert
    // "angular-sweetalert", // sweetalert
    "angular.filter",
    "ngIdle" // Idle timer
  ])
  .config(toastrConfig)
  .config(configure)
  .value("config", config)

  .constant("toastr", toastr)

  // system global var
  .constant("rootUrl", $("#linkRoot").attr("href"))
  .constant("apiUrl", apiUrl)
  .constant("functionUrl", functionUrl)
  .constant("functionCode", functionCode)
  .constant("environment", environment)
  .constant("region", region)

  // debug
  .constant("debugMode", true)

  .factory("common", common);
