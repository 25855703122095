import * as angular from "angular";
import { routeConfig } from "./config.route";
import { ApplicationCredentialListsCtrl, ApplicationCredentialCreateEditCtrl } from "./applicationCredential";

export default angular.module("app.applicationCredential", [])
  .config(routeConfig)
  .component("applicationCredentialList",
    {
      template: require("./views/lists.html"),
      controller: ApplicationCredentialListsCtrl,
      bindings: {
        applicationcredentials: "<",
        authentication: "<"
      }
    })
  .component("applicationCredentialCreateEdit",
    {
      template: require("./views/create_edit.html"),
      controller: ApplicationCredentialCreateEditCtrl,
      bindings: {
        applicationcredential: "<",
        agencies: "<",
        authentication: "<"
      }
    });
