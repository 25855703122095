import * as angular from "angular";
import { routeConfig } from "./config.route";
import { SkintypeListsCtrl, SkintypeEditCtrl, SkintypeEditContentCtrl } from "./skintype";

export default angular
  .module("app.smartshelves.skintype", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("skintypeList", {
    template: require("./views/lists.html"),
    controller: SkintypeListsCtrl,
    bindings: {
      skintypes: "<",
      authentication: "<"
    }
  })
  .component("skintypeEditContent", {
    template: require("./views/edit-content.html"),
    controller: SkintypeEditContentCtrl,
    bindings: {
      mainskintype: "<",
      skintype: "=",
      culture: "<",
      ismainculture:"<",
      authentication: "<"
    }
  })
  .component("skintypeEdit", {
    template: require("./views/edit.html"),
    controller: SkintypeEditCtrl,
    bindings: {
      skintype: "<",
      agencies: "<",
      authentication: "<"
    }
  });
