import * as angular from "angular";
import { StateParams, StateService } from "@uirouter/core";

import * as R from "ramda";
import Swal from "sweetalert2";
import * as moment from "moment-timezone";

import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";

import { FunctionalModule } from "../models/enums";
import { IDevice, IChapter } from "../models/models";

interface IPlaylistListViewModel {
  playlists: any;
  intercut: boolean;
  timeZone: string;

  del(id: number): void;
  publish(indx: number): void;
  copy(id: number): void;
}

export class PlaylistList implements IPlaylistListViewModel {
  playlists: any;
  intercut: boolean;
  timeZone: string;

  private readonly logger: ILogger;
  private readonly playlistService;

  static $inject = ["$stateParams", "$filter", "datacontext", "common", "playlists"];

  constructor($stateParams: any, private $filter, datacontext: Datacontext, common: any, playlists: any) {
    this.timeZone = moment.tz.guess();
    this.logger = common.logger;

    this.intercut = $stateParams.intercut === "true";

    playlists.$promise.then(value => {
      this.playlists = $filter("filter")(value, { interCut: { enable: this.intercut } }, true);
      this.logger.log(this.playlists);
    });

    this.playlistService = datacontext.playlists();
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.playlistService.delete(
          { id: id },
          () => {
            var indx = this.$filter("findIndexByKeyValue")(this.playlists, "id", id);
            this.playlists.splice(indx, 1);
            Swal.fire("Deleted!", "Your playlist has been deleted.", "success");
          },
          () => {
            Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your Playlist file is safe :)", "success");
      }
    });
  }

  publish(indx: number): void {
    const playlist = this.playlists[indx];
    playlist.$publish(
      { id: playlist.id },
      value => {
        this.playlists[indx] = this.playlistService.get({ id: value.id });
        if (value.hasSchedulers) {
          Swal.fire({
            title: "Is there a schedule that is related to this playlist?",
            text: "Do you want to publish all related schedules at the same time?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, publish it!",
            cancelButtonText: "No, cancel!"
          }).then(isConfirm => {
            if (isConfirm.value) {
              playlist.$publishMySchedulers(
                { id: playlist.id },
                () => {
                  this.logger.info("Scheduler had success published!", value, "publish");
                },
                httpResponse => {
                  Swal.fire("Oops...", httpResponse.data.message, "error");
                }
              );
            }
          });
        }

        this.logger.info("publish Success", value, "publish");
      },
      httpResponse => {
        Swal.fire("Oops...", httpResponse.data.message, "error");
      }
    );
  }

  copy(id: number): void {
    this.playlistService.copy({ id: id }, null, value => {
      this.playlists.push(value);
    });
  }
}

interface IPlaylistCreateditViewModel {
  isEdit: boolean;

  gridsterOpts: any;
  playlist: any;
  devices: any;
  rules: any;

  displayChapter: any;
  delChapters: any;

  isHourly(): boolean;

  save(playlist: any): void;

  cityChange(group): void;
  deviceChange(device): void;

  goStep1(): void;
  goStep2(chapterIndx: number): void;
  getTotal(): number;

  muteSetting(index: number): void;
}

export class PlaylistCreatedit implements IPlaylistCreateditViewModel {
  isEdit: boolean;

  gridsterOpts: any;
  cronConfig: any;

  playlist: any;
  devices: any;
  rules: any;

  displayChapter: any;
  delChapters: any;

  private readonly logger: ILogger;
  private timeZone: any;

  private readonly playlistService;
  private readonly chapterService;
  private readonly containerService;

  static $inject = ["datacontext", "common", "$stateParams", "$filter", "$uibModal", "$state", "$sce"];

  constructor(private datacontext: Datacontext,
    common: any, private $stateParams: StateParams, private $filter: angular.IFilterService,
    private $uibModal: angular.ui.bootstrap.IModalService, private $state: StateService, private $sce: ng.ISCEService
  ) {
    this.logger = common.logger;
    this.timeZone = moment.tz.guess();
    console.log("local time zone", this.timeZone);

    this.playlistService = datacontext.playlists();
    this.chapterService = datacontext.chapters();
    this.containerService = datacontext.containers();

    // 判斷為編輯還是新增播放清單
    this.isEdit = $stateParams.id !== undefined;

    ////////////
    this.gridsterOpts = {
      columns: 192, // the width of the grid, in columns
      pushing: false, // whether to push other items out of the way on move or resize
      floating: false, // whether to automatically float items up so they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
      swapping: false, // whether or not to have items of the same size switch places instead of pushing down if they are the same size
      width: "auto", // can be an integer or 'auto'. 'auto' scales gridster to be the full width of its containing element
        colWidth: "auto", // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
      rowHeight: "match", // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
      margins: [5, 5], // the pixel distance between each widget
      outerMargin: true, // whether margins apply to outer edges of the grid
      sparse: true, // "true" can increase performance of dragging and resizing for big grid (e.g. 20x50)
      minColumns: 10, // the minimum columns the grid must have
      minRows: 108, // the minimum height of the grid, in rows
      maxRows: 108,
      resizable: {
        enabled: false
      },
      draggable: {
        enabled: false
      }
    };

    // cron 下拉式選單設定
    this.cronConfig = {
      allowMultiple: false,
      options: {
        allowMinute: false,
        allowHour: true,
        allowDay: true,
        allowWeek: false,
        allowMonth: false,
        allowYear: false
      }
    };
    // PlayList 的初始化
    this.playlist = {
      id: 0,
      name: "",
      orientation: "0",
      chapters: [],
      devices: [],
      enable: false,
      globalMarquee: {
        text: "",
        color: "#FFFFFF",
        backgroundColor: "#000000",
        bold: false,
        twinkling: false,
        speed: "30"
      },
      interCut: {
        enable: $stateParams.intercut === "true",
        immediately: true,
        duration: {
          startDate: moment().startOf("day"),
          endDate: moment().startOf("day"),
          startTime: moment().format("HH:mm"),
          endTime: moment().format("HH:mm"),
          timeZone: this.timeZone,
          isRecurrence: "false",
          recurrenceRule: ""
        }
      }
    };

    // 取的所有的 Rules
    this.rules = datacontext.rules().query();

    this.displayChapter = undefined;
    this.delChapters = [];

    if (this.isEdit) {
      // 取得播放清單的全部資料
      common.$q.all([datacontext.devices().query().$promise, this.playlistService.get({ id: $stateParams.id }).$promise])
        .then((result: any[]) => {
          var data = result[1];
          // 設定已經設定在 PlayList 中的 Device
          if (data.devices.length > 0) {
            R.map((device: IDevice) => {
              var found = R.find(R.propEq("guid", device.guid))(result[0]);
              if (found) found.selected = true;
            }, data.devices);
          }
          this.devices = this.setupDevices(result[0]);

          // chapter 排序
          data.chapters = $filter("orderBy")(data.chapters, "sequence");

          R.map((chapter) => {
            chapter.ruleId = chapter.ruleId.toString();

            R.map((chapter) => {
              chapter.ruleId = chapter.ruleId.toString();

              R.map((container) => {

                switch (container.type) {
                  case "Video": {
                    container.sources = [
                      {
                        src: $sce.trustAsResourceUrl(container.contentUrl),
                        type: "video/mp4"
                      }
                    ];
                    break;
                  }
                  case "Audio": {
                    container.sources = [
                      {
                        src: $sce.trustAsResourceUrl(container.contentUrl),
                        type: "audio/mp3"
                      }
                    ];
                    break;
                  }
                  case "QRCode": {
                    container.qrCodeType = container.qrCodeType.toString();
                    break;
                  }
                }
                container.marquee.speed = container.marquee.speed.toString();
              }, chapter.containers);

            }, data.chapters);
          }, data.chapters);

          // 時區
          if (!data.interCut.duration.timeZone || data.interCut.duration.timeZone === "")
            data.interCut.duration.timeZone = moment.tz.guess();
          console.log(data.interCut.duration.timeZone);

          data.interCut.duration.startDate = moment.utc(data.interCut.duration.startDate).tz(data.interCut.duration.timeZone).local();
          data.interCut.duration.endDate = moment.utc(data.interCut.duration.endDate).tz(data.interCut.duration.timeZone).local();

          this.playlist = data;
          this.playlist.orientation = this.playlist.orientation.toString();
          this.playlist.interCut.duration.isRecurrence = this.playlist.interCut.duration.isRecurrence.toString();
          this.playlist.globalMarquee.speed = this.playlist.globalMarquee.speed.toString();

          this.logger.log("playlist", this.playlist);
        });
    } else {
      // 取的所有的 Devices
      datacontext
        .devices()
        .query()
        .$promise.then((value: IDevice[]) => { this.devices = this.setupDevices(value); });
    }
  }

  setupDevices(devices: IDevice[]) {
    const playerDevices: IDevice[] = devices.filter((device: IDevice) => device.functionalModule & FunctionalModule.MediaPlayer);
    const byCity = R.groupBy((device: IDevice) => device.messageQueue.city);
    const groupBy: any = byCity(playerDevices);

    R.mapObjIndexed((group: IDevice[], key: string) => {
      let sortby = this.$filter("orderBy")(group, ["agency.name", "board.level2", "board.level1", "name"]);
      groupBy[key] = R.splitEvery(3, sortby);
    }, groupBy);

    return groupBy;
  }

  isHourly(): boolean {
    if (
      this.playlist.interCut.enable &&
      !this.playlist.interCut.immediately &&
      this.playlist.interCut.duration.isRecurrence === "true"
    ) {
      const regex = /([1-5]?[0-9]) ([1-2]?[0-3]|\*) (\*) (\*) (\*)/g;
      const matchs = regex.exec(this.playlist.interCut.duration.recurrenceRule);
      if (matchs == null) return false;
      return matchs[2] === "*";
    }
    return false;
  }

  // PlayList 的 CRUD
  save(playlist: any): void {
    // 定時插播要把資料處理乾淨
    if (playlist.interCut.enable && !playlist.interCut.immediately) {
      if (playlist.interCut.duration.isRecurrence === "true") {
        const regex = /([1-5]?[0-9]) ([1-2]?[0-9]|\*) (\*) (\*) (\*)/g;
        const matchs = regex.exec(playlist.interCut.duration.recurrenceRule);
        if (matchs) {
          if (matchs[2] === "*") {
            playlist.interCut.duration.startTime = moment(
              playlist.interCut.duration.startTime,
              "HH:mm:ss"
            )
              .minutes(+matchs[1])
              .format("HH:mm");
            playlist.interCut.duration.endTime = moment(
              playlist.interCut.duration.endTime,
              "HH:mm:ss"
            )
              .minutes(+matchs[1])
              .format("HH:mm");
          } else {
            playlist.interCut.duration.startTime = moment(
              playlist.interCut.duration.startTime,
              "HH:mm:ss"
            )
              .minutes(+matchs[1])
              .hours(+matchs[2])
              .format("HH:mm");
          }
        }
      } else {
        playlist.interCut.duration.endDate =
          playlist.interCut.duration.startDate;
        playlist.interCut.duration.endTime =
          playlist.interCut.duration.startTime;
        playlist.interCut.duration.recurrenceRule = "";
      }
    }
    const isSelected = R.propEq("selected", true);
    const flattenDevices = [];
    R.forEachObjIndexed(
      (value, key) => flattenDevices.push(R.flatten(value)),
      this.devices
    );

    this.playlist.devices = R.filter(
      isSelected,
      R.flatten(flattenDevices)
    );

    this.logger.log("this.playlist", this.playlist);

    if (this.isEdit) {
      this.playlistService.update({ id: playlist.id }, playlist, value => {
        this.chapters.saveChapters(playlist.chapters, this.delChapters);
        this.logger.success("PlayList has Update!", value, "Good job!");
        this.$state.go("playlists.list", {
          intercut: this.playlist.interCut.enable
        });
      });
    } else {
      this.playlistService.save(null, playlist, value => {
        this.playlist = value;
        this.isEdit = true;
        this.logger.success("PlayList has Create!", value, "Good job!");
        this.$state.go("playlists.list", {
          intercut: this.playlist.interCut.enable
        });
      });
    }
    this.goStep1();
  }

  // Device 的 CRUD
  cityChange(group): void {
    R.map(chunks => {
      R.map(device => {
        device.selected = group.selected;
      }, chunks);
    }, group);
  }

  deviceChange(device): void {
    const devicesByCity = this.devices[device.messageQueue.city];
    const isSelected = R.propEq("selected", true);
    devicesByCity.selected = R.all(isSelected)(R.flatten(devicesByCity));
  }

  // Step 導覽設定
  goStep1(): void {
    if (!this.isEdit) {
      this.$state.go("playlists.create.step1");
    } else {
      this.$state.go("playlists.edit.step1", { id: this.playlist.id });
    }
  }

  goStep2(chapterIndx: number): void {
    if (!this.isEdit) {
      this.$state.go("playlists.create.step2", { chapterIndx: chapterIndx });
    } else {
      this.$state.go("playlists.edit.step2", {
        id: this.playlist.id,
        chapterIndx: chapterIndx
      });
    }
  }

  // 取得 chapter 的撥放時間
  getTotal(): number {
    let total = 0;
    for (let i = 0; i < this.playlist.chapters.length; i++) {
      const chapter = this.playlist.chapters[i];
      total += chapter.stayTime;
    }
    return total;
  }

  // Chapter 的 CRUD
  chapters = {
    new: size => {
      const modalInstance = this.$uibModal.open({
        template: require("./views/modal/templates_modal.html"),
        controller: layoutsModal,
        controllerAs: "vm",
        windowClass: "animated flipInY",
        size: size,
        resolve: {
          items: () => {
            return this.datacontext.layouts().query();
          },
          orientation: () => {
            return this.playlist.orientation;
          }
        }
      });

      modalInstance.result.then(
        selectedItem => {
          this.gridsterOpts.columns =
            this.playlist.orientation === "1" ? 108 : 192;
          this.gridsterOpts.minRows =
            this.playlist.orientation === "1" ? 192 : 108;
          this.gridsterOpts.maxRows =
            this.playlist.orientation === "1" ? 192 : 108;

          var selectedLayout = JSON.parse(selectedItem);

          var chapter = {
            id: 0,
            name: `Chapter ${this.playlist.chapters.length + 1}`,
            sequence: this.playlist.chapters.length + 1,
            layout: selectedLayout,
            layoutId: selectedLayout.id,
            stayTime: 10,
            ruleId: "1",
            playlistId: this.playlist.id,
            containers: [],
            qrCode: null,
            qrCodeType: "0",
            detectionRule: {
              gender: "0",
              age: "0",
              detectionTimePeriod: {
                startTime: null,
                endTime: null
              }
            }
          };

          for (let i = 0; i < selectedLayout.containerQuantity; i++) {
            chapter.containers.push({
              id: 0,
              no: i + 1,
              type: selectedLayout.containerSettings[i].type,
              layer: selectedLayout.containerSettings[i].layer,
              content: "",
              contentUrl: "",
              mute: true,
              marquee: {
                text: "",
                color: "#FFFFFF",
                backgroundColor: "#000000",
                bold: false,
                twinkling: false,
                speed: "30"
              },
              qrCodeType: "0",
              qrCode: null,
              hasDetectionRule: false,
              detectionRule: {
                gender: "0",
                age: "0",
                detectionTimePeriod: {
                  startTime: null,
                  endTime: null
                }
              },
              chapterId: chapter.id
            });
          }

          this.playlist.chapters.push(chapter);
          this.displayChapter = this.playlist.chapters[
            this.playlist.chapters.length - 1
          ];

          this.goStep2(this.playlist.chapters.length - 1);
        },
        () => {
          this.logger.log(`Modal dismissed at: ${new Date()}`);
        }
      );
    },
    edit: indx => {
      var chapter = this.playlist.chapters[indx];
      this.displayChapter = chapter;
      this.displayChapter.containers = this.$filter("orderBy")(this.displayChapter.containers, "no");

      if (this.displayChapter.hasDetectionRule) {
        this.displayChapter.detectionRule.gender = this.displayChapter.detectionRule.gender.toString();
        this.displayChapter.detectionRule.age = this.displayChapter.detectionRule.age.toString();
      }

      this.gridsterOpts.columns = this.playlist.orientation === "1" ? 108 : 192;
      this.gridsterOpts.minRows = this.playlist.orientation === "1" ? 192 : 108;
      this.gridsterOpts.maxRows = this.playlist.orientation === "1" ? 192 : 108;

      this.goStep2(indx);
    },
    clean: () => {
      R.map(chapter => {
        this.delChapters.push(chapter);
      }, this.playlist.chapters);

      this.playlist.chapters = [];
    },
    del: indx => {
      var delChapter = this.playlist.chapters.splice(indx, 1);
      if (delChapter !== 0) this.delChapters.push(delChapter[0]);
      for (let i = 0; i < this.playlist.chapters.length; i++) {
        this.playlist.chapters[i].sequence = i + 1;
      }
    },
    saveChapters: (updatechapters, delChapters) => {
      R.map(chapter => {
        if (!chapter.hasDetectionRule) {
          chapter.detectionRule = {
            gender: "0",
            age: "0",
            detectionTimePeriod: {
              startTime: null,
              endTime: null
            }
          };
        }

        if (chapter.id === 0) {
          this.chapterService.save({ id: chapter.id }, chapter);
        } else {
          this.chapterService.update({ id: chapter.id }, chapter, () => {
            R.map(container => {
              this.containerService.update({ id: container.id }, container);
            }, chapter.containers);
          });
        }
      }, updatechapters);

      R.map(chapter => {
        if (chapter.id !== 0) this.chapterService.delete({ id: chapter.id });
      }, delChapters);
    },

    sortableOptions: {
      stop: (e, ui) => {
        for (let i = 0; i < this.playlist.chapters.length; i++) {
          this.playlist.chapters[i].sequence = i + 1;
        }
      },
      connectWith: ".connectList"
    }
  };

  // 媒體檔案的 CRUD
  files = {
    select: (size, containerNo, orientation) => {
      var chapter = this.playlist.chapters[this.$stateParams.chapterIndx];

      var modalInstance = this.$uibModal.open({
        template: require("./views/modal/media_modal.html"),
        controller: filesModal,
        controllerAs: "vm",
        windowClass: "animated flipInY",
        size: size,
        resolve: {
          items: () => {
            return this.datacontext.medias().query();
          }
        }
      });

      modalInstance.result.then(
        selectedFile => {
          var found = chapter.containers[containerNo - 1];
          found.no = containerNo;
          found.mediaId = selectedFile.id;
          found.content = selectedFile.name;
          found.contentUrl = selectedFile.fileUrl;
          found.mute = true;

          switch (selectedFile.contentType) {
            case "video/mp4":
              found.type = "Video";
              if (chapter.stayTime < selectedFile.length)
                //chapter.stayTime = Math.round(selectedFile.length);
                chapter.stayTime = Math.ceil(selectedFile.length);
              found.sources = [
                {
                  src: this.$sce.trustAsResourceUrl(found.contentUrl),
                  type: "video/mp4"
                }
              ];
              break;
            case "video/mpeg":
              found.type = "Video";
              if (chapter.stayTime < selectedFile.length)
                chapter.stayTime = Math.ceil(selectedFile.length);
              found.sources = [
                {
                  src: this.$sce.trustAsResourceUrl(found.contentUrl),
                  type: "video/mpeg"
                }
              ];
              break;
            case "image/jpeg":
            case "image/png":
              found.type = "Image";
              break;
            case "audio/mp3":
              found.type = "Audio";
              if (chapter.stayTime < selectedFile.length)
                chapter.stayTime = Math.round(selectedFile.length);
              found.sources = [
                {
                  src: this.$sce.trustAsResourceUrl(found.contentUrl),
                  type: "audio/mp3"
                }
              ];
              break;
            default:
              found.type = "Text";
          }

          // 2019/7/12 Simpson 修改，chapter name 預設為檔案名稱
          try {
            var fileName = selectedFile.name.substr(0, selectedFile.name.lastIndexOf("-"));
            if (fileName.length > 45) {
              chapter.name = fileName.substr(0,45) + "...";
            } else if (fileName.length > 0) {
              chapter.name = fileName;
            };
          } catch (ex) {
            this.logger.error(ex);
          }

          this.displayChapter = chapter;
        },
        () => {
          this.logger.log(`Modal dismissed at: ${new Date()}`);
        }
      );
    },
    clean: containerNo => {
      var chapter = this.playlist.chapters[this.$stateParams.chapterIndx];
      var found = chapter.containers[containerNo - 1];
      found.mediaId = null;
      found.content = "Not Selected.";
      found.contentUrl = "";
      found.type = "Media";
      found.sources = null;
      found.mute = true;
    }
  };

  // 靜音設定
  muteSetting(index: number): void {
    // 如果有出聲音，其他的都要關閉
    if (this.displayChapter.containers[index].mute === false) {
      for (let i = 0; i < this.displayChapter.containers.length; i++) {
        if (i !== index) this.displayChapter.containers[i].mute = true;
      }
    }
  }
}

layoutsModal.$inject = [
  "$uibModalInstance",
  "items",
  "orientation",
  "rootUrl",
  "$filter"
];

function layoutsModal($uibModalInstance: angular.ui.bootstrap.IModalInstanceService, items, orientation, rootUrl, $filter): void {
  var vm = this;
  vm.rootUrl = rootUrl;

  vm.selected = {
    item: null
  };

  vm.ok = ok;
  vm.cancel = cancel;

  activate();

  ////////////
  function activate() {
    items.$promise.then(result => {
      vm.items = result;
      vm.items = $filter("filter")(
        vm.items,
        { orientation: orientation },
        false
      );
      vm.items = $filter("orderBy")(vm.items, "name", false);
    });
  }

  function ok() {
    $uibModalInstance.close(vm.selected.item);
  }

  function cancel() {
    $uibModalInstance.dismiss("cancel");
  }
}

filesModal.$inject = ["$uibModalInstance", "items", "rootUrl", "$filter"];

function filesModal($uibModalInstance: angular.ui.bootstrap.IModalInstanceService, items, rootUrl, $filter): void {
  const vm = this;
  vm.rootUrl = rootUrl;

  vm.ok = ok;
  vm.cancel = cancel;

  activate();

  ////////////
  function activate() {
    items.$promise.then(result => {
      vm.items = result;
      vm.items = $filter("orderBy")(vm.items, "updatedDate", true, false);
    });
  }

  function ok(file) {
    $uibModalInstance.close(file);
  }

  function cancel() {
    $uibModalInstance.dismiss("cancel");
  }
}
