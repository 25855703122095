import * as R from "ramda";
import Swal from "sweetalert2";

import { IAgency } from "../../models/models";
import { ILogger } from "../../blocks/logger/logger";
import { Datacontext } from "../../data/datacontext";

interface IAgencyListViewModel {
  agencies: Array<IAgency>;
  col_defs: any;

  del(indx: number): void;
}

export class AgencyListViewModel implements IAgencyListViewModel {
  agencies: any[];
  col_defs: any;

  agencyService: any;

  private logger: ILogger;

  static $inject = ["common", "agencies", "datacontext", "$filter"];
  constructor(
    common,
    agencies,
    private datacontext: Datacontext,
    private $filter
  ) {
    this.logger = common.logger;

    this.agencyService = datacontext.agencies();
    this.agencies = [];
    agencies.$promise.then((values: IAgency[]) => {
      this.agencies = R.filter(a => a.parentId == null, values);
      this.agencies.map(root => {
        root.level = 0;
        this.getChildren(root, values);
      });

      this.logger.log("agencies", this.agencies);
    });

    this.col_defs = [
      { field: "description", displayName: "Description" },
      {
        field: "expirationDate",
        displayName: "Expiration Date",
        cellTemplate:
          "<span>{{ row.branch[col.field] | date : 'yyyy/MM/dd'}}</span>"
      },
      {
        field: "isExpired",
        displayName: "Expired",
        cellTemplate:
          "<span class='label label-danger' ng-if='row.branch[col.field]'>Expired</span>"
      },
      {
        field: "features",
        displayName: "Features",
        cellTemplate:
          "<span class='label label-primary' ng-if='row.branch[col.field].dashboard'>Dashboard</span>" +
          "&nbsp;<span class='label label-info' ng-if='row.branch[col.field].interCut'>Inter Cut</span>" +
          "&nbsp;<span class='label label-success' ng-if='row.branch[col.field].detectionRule'>Detection Rule</span>" +
          "&nbsp;<span class='label label-warning' ng-if='row.branch[col.field].alert'>Alert</span>" +
          "<br/><br/>" +
          "<span class='label label-primary' ng-if='row.branch[col.field].applicationCredential'>Application Credential</span>" +
          "&nbsp;<span class='label label-info' ng-if='row.branch[col.field].report'>Report</span>" +
          "&nbsp;<span class='label label-success' ng-if='row.branch[col.field].mart'>Mart</span>" +
          "&nbsp;<span class='label label-warning' ng-if='row.branch[col.field].smartShelves'>Smart Shelves</span>" +
          "<br/><br/>" +
          "<span class='label label-primary' ng-if='row.branch[col.field].emergency'>Emergency</span>" +
          "&nbsp;<span class='label label-info' ng-if='row.branch[col.field].orderSetting'>Order Setting</span>"
      },
      { field: "culture", displayName: "Culture" },
      {
        field: "sales",
        displayName: "Sales",
        cellTemplate: "<span>{{row.branch[col.field].mainSales}}</span>"
      },
      {
        field: "id",
        displayName: "Action",
        cellTemplate:
          "<a ui-sref='admin.agencies.edit({ id: {{row.branch[col.field]}} })' class='btn btn-white btn-sm'><i class='fa fa-pencil'></i> Edit </a>" +
          "<a class='btn btn-white btn-sm' ng-click='cellTemplateScope.click(row.branch[col.field])'><i class='fa fa-trash text-danger'></i> Delete </a>",
        cellTemplateScope: {
          click: data => {
            // this works too: $scope.someMethod;
            this.del(data);
          }
        }
      }
    ];
  }

  getChildren(parent, pool: IAgency[]) {
    parent.children = R.filter(p => p.parentId === parent.id, pool);
    parent.children.map(c => {
      c.level = parent.level + 1;
      this.getChildren(c, pool);
    });
  }

  del(id: number): void {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!"
    }).then(isConfirm => {
      if (isConfirm.value) {
        this.agencyService.delete(
          { id: id },
          (value, responseHeaders) => {
            var indx = this.$filter("findIndexByKeyValue")(
              this.agencies,
              "id",
              id
            );
            this.agencies.splice(indx, 1);
            Swal.fire("Deleted!", "Your agency has been deleted.", "success");
          },
          httpResponse => {
            Swal.fire("Cancelled", "Your agency file is safe :)", "error");
          }
        );
      } else {
        Swal.fire("Cancelled!", "Your agency file is safe :)", "success");
      }
    });
  }
}

interface IAgencyListCreateEditModel {
  agencies: Array<IAgency>;
  agency: IAgency;
  contracts: any[];
  contract: any;

  uploader: any;
  isUploadContract: boolean;

  save(agency: any): void;
}

export class AgencyListCreateEditModel implements IAgencyListCreateEditModel {
  agencies: any[];
  agency: any;
  contracts: any[];
  contract: any;

  uploader: any;
  isUploadContract: boolean;

  agencyService: any;
  contractService: any;

  private logger: ILogger;

  static $inject = [
    "common",
    "agencies",
    "datacontext",
    "$scope",
    "$stateParams",
    "$state",
    "apiUrl",
    "FileUploader",
    "localStorageService"
  ];
  constructor(
    common,
    agencies,
    datacontext: Datacontext,
    private $scope,
    $stateParams,
    private $state,
    apiUrl,
    FileUploader,
    localStorageService
  ) {
    this.agencies = agencies;
    this.logger = common.logger;
    this.agencyService = datacontext.agencies();
    this.contractService = datacontext.contracts();

    // 判斷為編輯還是新增Agency
    $scope.isEdit = $stateParams.id !== undefined;

    // Agency 的初始化
    this.agency = {
      id: 0,
      name: "",
      fullName: "",
      description: "",
      features: { interCut: false, orderSetting: false },
      expirationDate: "",
      isExpired: false
    };

    if ($scope.isEdit) {
      // 取得播放清單的全部資料
      this.agencyService.get({ id: $stateParams.id }).$promise.then(value => {
        if (value.parentId) value.parentId = value.parentId.toString();
        this.agency = value;
      });

      this.contracts = this.contractService.getBy({
        agencyId: $stateParams.id
      });
      this.agencies.$promise.then(values => {
        const agencies = R.filter(a => a.id !== $stateParams.id, values);
        this.agencies = agencies;
      });

      // File Upload 相關
      this.uploader = new FileUploader({
        url: `${apiUrl}api/Contracts`, // webapi url
        headers: {
          Authorization: `Bearer ${localStorageService.get("jwt")}`
        },
        removeAfterUpload: true
      });

      // CALLBACKS
      this.uploader.onBeforeUploadItem = item => {
        var formData = [
          {
            agencyId: $stateParams.id,
            name: this.contract.name,
            startDate: this.contract.startDate,
            endDate: this.contract.endDate
          }
        ];

        Array.prototype.push.apply(item.formData, formData);

        console.info("onBeforeUploadItem", item);
      };
      this.uploader.onCompleteAll = () => {
        this.isUploadContract = false;
        this.contracts = this.contractService.getBy({
          agencyId: $stateParams.id
        });
      };
    }
  }

  save(agency: any) {
    if (this.$scope.isEdit) {
      this.agencyService.update({ id: agency.id }, agency, (value, responseHeaders) => {
          this.logger.success("Agency has Update!", value, "Good job!");
          this.$state.go("admin.agencies.list");
        }
      );
    } else {
      this.agencyService.save(null, agency, (value, responseHeaders) => {
        this.agency = value;
        this.$scope.isEdit = true;
        this.logger.success("Agency has Create!", value, "Good job!");
        this.$state.go("admin.agencies.list");
      });
    }
  }
}
