import * as angular from "angular";
import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IDevice } from "../models/models";
import { FunctionalModule } from "../models/enums";

declare var Highcharts: any;

interface IPeopleCountAllViewModel {
  devices: Array<any>;
  selectDevice: IDevice;

  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  loading: boolean;

  getPeopleCount();
}

export class PeopleCountAllCtrl implements IPeopleCountAllViewModel {
  devices: any[];
  selectDevice: IDevice;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dayKeyFrom: string;
  dayKeyTo: string;
  logs: any[];

  loading: boolean;

  static $inject = [
    "devices",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    devices,
    private $http,
    private apiUrl,
    private environment: number
  ) {
    this.loading = false;
    this.dateFrom = moment().subtract(8, "days");
    this.dateTo = moment().subtract(1, "days");

    devices.$promise.then(value => {
      this.devices = value.filter(
        device => device.functionalModule & FunctionalModule.FaceDetection
      );
    });
  }

  //function declare
  getPeopleCount() {
    this.loading = true;
    this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
    this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

    this.$http
      .post(this.apiUrl + "api/Analysis/PeopleCount", {
        dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
        dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
        system: this.environment
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          //console.log(obj);
          this.logs = obj.countByDay;
          this.drawChart1(obj.countByDevice);
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }
  drawChart1(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.deviceName);
      y0Array.push(obj.count);
      y1Array.push((obj.face / obj.count) * 100);
    });
    const chartOptions = {
      title: { text: this.dayKeyFrom + "~" + this.dayKeyTo + " 版位人流統計" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "人流" }
        },
        {
          title: { text: "回頭率(%)" },
          labels: {
            format: "{value:.0f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "人流",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "回頭率(%)",
          yAxis: 1,
          type: "line",
          data: y1Array //,
          //color: "#ff8080"
        }
      ]
    };
    Highcharts.chart("containerChart1", chartOptions);
  }
}
