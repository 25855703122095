export class TranslateConfig {
  static $inject = ["$translateProvider", "debugMode", "LOCALES"];

  constructor($translateProvider: angular.translate.ITranslateProvider, debugMode, locales) {
    if (debugMode) {
      $translateProvider.useMissingTranslationHandlerLog(); // warns about missing translates
    }

    $translateProvider.useStaticFilesLoader({
      prefix: "assets/resources/locale-", // path to translations files
      suffix: ".json" // suffix, currently- extension of the translations
    });

    // try to find out preferred language by yourself
    $translateProvider.determinePreferredLanguage();
    $translateProvider.preferredLanguage(locales.preferredLocale); // is applied on first load
    $translateProvider.useLocalStorage(); // saves selected language to localStorage

    // Enable escaping of HTML
    //$translateProvider.useSanitizeValueStrategy('sanitize');
    $translateProvider.useSanitizeValueStrategy("escape");
  }
}

export class DynamicLocaleConfig {
  static $inject = ["tmhDynamicLocaleProvider", "rootUrl"];

  constructor(tmhDynamicLocaleProvider, rootUrl) {
    tmhDynamicLocaleProvider.localeLocationPattern(
      rootUrl + "assets/plugins/angular/i18n/angular-locale_{{locale}}.js"
    );
  }
}
