import { Logger } from "../blocks/logger/logger";

common.$inject = ["$location", "$q", "$rootScope", "$timeout", "logger"];

export function common(
  $location: ng.ILocationService,
  $q: ng.IQService,
  $rootScope: ng.IRootScopeService,
  $timeout: ng.ITimeoutService,
  logger: Logger
) {
  var throttles = {};

  const service = {
    // common angular dependencies
    $broadcast: $broadcast,
    $q: $q,
    $timeout: $timeout,
    // generic
    createSearchThrottle: createSearchThrottle,
    debouncedThrottle: debouncedThrottle,
    isNumber: isNumber,
    logger: logger, // for accessibility
    replaceLocationUrlGuidWithId: replaceLocationUrlGuidWithId,
    textContains: textContains
  };

  return service;
  //////////////////////

  function $broadcast() {
    return $rootScope.$broadcast.apply($rootScope, arguments);
  }

  function createSearchThrottle(viewmodel, list, filteredList, filter, delay) {
    // After a delay, search a viewmodel's list using
    // a filter function, and return a filteredList.

    // custom delay or use default
    delay = +delay || 300;
    // if only vm and list parameters were passed, set others by naming convention
    if (!filteredList) {
      // assuming list is named sessions, filteredList is filteredSessions
      filteredList = `filtered${list[0].toUpperCase()}${list
        .substr(1)
        .toLowerCase()}`; // string
      // filter function is named sessionFilter
      filter = list + "Filter"; // function in string form
    }

    // create the filtering function we will call from here
    var filterFn = () => {
      // translates to ...
      // vm.filteredSessions
      //      = vm.sessions.filter(function(item( { returns vm.sessionFilter (item) } );
      viewmodel[filteredList] = viewmodel[list].filter(item =>
        viewmodel[filter](item)
      );
    };

    return (() => {
      // Wrapped in outer IIFE so we can use closure
      // over filterInputTimeout which references the timeout
      var filterInputTimeout;

      // return what becomes the 'applyFilter' function in the controller
      return searchNow => {
        if (filterInputTimeout) {
          $timeout.cancel(filterInputTimeout);
          filterInputTimeout = null;
        }
        if (searchNow || !delay) {
          filterFn();
        } else {
          filterInputTimeout = $timeout(filterFn, delay);
        }
      };
    })();
  }

  function debouncedThrottle(key, callback, delay, immediate) {
    // Perform some action (callback) after a delay.
    // Track the callback by key, so if the same callback
    // is issued again, restart the delay.

    const defaultDelay = 1000;
    delay = delay || defaultDelay;
    if (throttles[key]) {
      $timeout.cancel(throttles[key]);
      throttles[key] = undefined;
    }
    if (immediate) {
      callback();
    } else {
      throttles[key] = $timeout(callback, delay);
    }
  }

  function isNumber(val: any): boolean {
    // negative or positive
    return /^[-]?\d+$/.test(val);
  }

  function replaceLocationUrlGuidWithId(id): void {
    // If the current Url is a Guid, then we replace
    // it with the passed in id. Otherwise, we exit.
    const currentPath = $location.path();
    const slashPos = currentPath.lastIndexOf("/", currentPath.length - 2);
    const currentParameter = currentPath.substring(slashPos - 1);

    if (isNumber(currentParameter)) {
      return;
    }

    const newPath = currentPath.substring(0, slashPos + 1) + id;
    $location.path(newPath);
  }

  function textContains(text: string, searchText: string): boolean {
    return text && -1 !== text.toLowerCase().indexOf(searchText.toLowerCase());
  }
}
