import * as angular from "angular";
import * as moment from "moment-timezone";

declare var $: any;
declare var Highcharts: any;

PlaylogTrend.$inject = ["datacontext", "$filter"];

export function PlaylogTrend(datacontext, $filter) {
  var vm = this;
  var playlogService = datacontext.playlogs;
  var deviceService = datacontext.devices;
  vm.devices = deviceService.query();
  vm.dateFrom = moment().format("YYYY-MM-" + "01");
  vm.go = go;

  function go() {
    vm.playlogs = playlogService.query({
      deviceUid: vm.deviceUid, //'50d19929-e584-44f2-af8e-89984e523f85',
      timeFrom: moment(vm.dateFrom).format("YYYY-MM-DD"), //'2016-02-01',
      timeTo: moment(vm.dateTo)
        .add(1, "days")
        .format("YYYY-MM-DD") // '2016-12-01'
    });
    vm.playlogs.$promise.then(function(playlogs) {
      //console.log(playlogs);
      var playlogsSorted = $filter("orderBy")(vm.playlogs, "timeEnd");
      var chartData = [];
      chartData.push(["Time", "Second", "Amount"]);
      var accu = 0.0;
      var accuAmount = 0.0;
      var timeLabel = "";
      var timeLabelOld = "";
      angular.forEach(playlogsSorted, function(playlog) {
        accu = accu + (playlog.timeEndEpoch - playlog.timeStartEpoch) / 1000.0;
        accuAmount =
          accuAmount +
          (Math.random() *
            3 *
            (playlog.timeEndEpoch - playlog.timeStartEpoch)) /
            1000.0;
        timeLabel = moment(playlog.timeEnd).format("YYYY-MM-DD HH:00");
        if (timeLabel != timeLabelOld) {
          timeLabelOld = timeLabel;
          chartData.push([timeLabel, accu, accuAmount]);
        }
      });
      drawChart(chartData);
    });
  }

  var chartData = [];
}

export function drawChart(chartData) {
  $("#containerTrend").highcharts({
    chart: {
      type: "spline" // 'areaspline'
    },
    data: {
      //csv: csv
      rows: chartData
    },

    title: {
      text: "計費秒數統計圖",
      style: {
        "font-family": '"NotoSansHant-Regular","微軟正黑體"'
      }
    },

    subtitle: {
      text: "每小時累積金額",
      style: { "font-family": '"NotoSansHant-Regular","微軟正黑體"' }
    },

    xAxis: {
      tickInterval: 1 * 3600 * 1000,
      tickWidth: 0,
      gridLineWidth: 1,
      labels: {
        align: "left",
        x: 3,
        y: -3
      }
    },

    yAxis: [
      {
        // left y axis
        title: {
          text: null
        },
        labels: {
          align: "left",
          x: 3,
          y: 16,
          format: "{value:.,0f}"
        },
        showFirstLabel: false
      },
      {
        // right y axis
        linkedTo: 0,
        gridLineWidth: 0,
        opposite: true,
        title: {
          text: null
        },
        labels: {
          align: "right",
          x: -3,
          y: 16,
          format: "{value:.,0f}"
        },
        showFirstLabel: false
      }
    ],

    legend: {
      align: "left",
      verticalAlign: "top",
      y: 20,
      floating: true,
      borderWidth: 0
    },

    tooltip: {
      shared: true,
      crosshairs: true
    },

    plotOptions: {
      series: {
        cursor: "pointer",
        point: {},
        marker: {
          lineWidth: 1
        }
      }
    },

    series: [
      {
        name: "Seconds",
        lineWidth: 4,
        marker: {
          radius: 4
        }
      },
      {
        name: "Amount"
      }
    ],
    colors: [
      Highcharts.getOptions().colors[4],
      Highcharts.getOptions().colors[8]
    ]
  });
}
