import * as angular from "angular";
import { routeConfig } from "./config.route";
import { IdentityService } from "./identity.factory";
import { UserList, UserEdit, UserCreate, RoleList } from "./identity";

export default angular.module("app.admin.identity", [])
.service("IdentityService", IdentityService)
.config(routeConfig)
.controller("UserList", UserList)
.controller("UserEdit", UserEdit)
.controller("UserCreate", UserCreate)
.controller("RoleList", RoleList);
