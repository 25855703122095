import * as angular from "angular";
import { routeConfig } from "./config.route";
import { BulletinConfigurationListCtrl, BulletinConfigurationCreateCtrl, Bulletin1ListCtrl, Bulletin1CreateCtrl, Bulletin2ListCtrl, Bulletin2CreateCtrl, Bulletin3ListCtrl, Bulletin3CreateCtrl } from "./bulletin";

export default angular
  .module("app.bulletin", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .controller("BulletinConfigurationListController", BulletinConfigurationListCtrl)
  .controller("BulletinConfigurationCreateController", BulletinConfigurationCreateCtrl)
  .controller("Bulletin1ListController", Bulletin1ListCtrl)
  .controller("Bulletin1CreateController", Bulletin1CreateCtrl)
  .controller("Bulletin2ListController", Bulletin2ListCtrl)
  .controller("Bulletin2CreateController", Bulletin2CreateCtrl)
  .controller("Bulletin3ListController", Bulletin3ListCtrl)
  .controller("Bulletin3CreateController", Bulletin3CreateCtrl);

