import * as angular from "angular";
import * as R from "ramda";
import Swal from "sweetalert2";

import { ILogger } from "../blocks/logger/logger";

  interface IMediaListViewModel {
    medias: Array<any>;
    tagsFilter: Array<any>;

    del(id: number): void;
    //openLightboxModal(fileUrl, description, contentType): void;
  }

  export class MediaList implements IMediaListViewModel {
    medias: any[];
    tagsFilter: any[];

    private mediaService: any;
    private logger: ILogger;

    static $inject = ["common", "datacontext", "$filter"];

    constructor(common, datacontext, private $filter) {
      this.logger = common.logger;

      this.mediaService = datacontext.medias();

      this.medias = this.mediaService.query();

      // Filter
      this.tagsFilter = [];
    }

    del(id: number): void {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!"
      })
        .then(isConfirm => {
          if (isConfirm.value) {
            this.mediaService.delete({ id: id },
              (value) => {
                var indx = this.$filter("findIndexByKeyValue")(this.medias, "id", id);
                this.medias.splice(indx, 1);
                this.logger.success("Your media file has been deleted.", value, "Success");
              },
              (httpResponse) => {
                this.logger.error("Your media file can not delete.", httpResponse, "Delete Error");
              });
          };
        });
    }

    //開啟原檔
    //openLightboxModal(fileUrl, description, contentType) {
    //  if (contentType.includes("video"))
    //    this.lightbox.openModal([{ "url": fileUrl, "caption": description, "type": "video" }], 0);
    //  else if (contentType.includes("audio"))
    //    this.lightbox.openModal([{ "url": fileUrl, "caption": description, "type": "video" }], 0);
    //  else
    //    this.lightbox.openModal([{ "url": fileUrl, "caption": description }], 0);
    //};
  };

  interface IMediaCreatEditViewModel {
    media: any;
    customers: Array<any>;
    uploader: any;

    loadTags(query);
    save(media): void;
    //openLightboxModal(fileUrl, description, contentType): void;
  }

  export class MediaCreatEdit implements IMediaCreatEditViewModel {
    media: any;
    customers: any[];
    uploader: any;

    private tags = [];
    private availableTagName = [];

    private mediaService: any;
    private tagService: any;

    private logger: ILogger;

    static $inject = [
      "datacontext", "common", "$stateParams", "$state", "apiUrl", "FileUploader", "localStorageService"
    ];

    constructor(datacontext, common, $stateParams, private $state, apiUrl, FileUploader, localStorageService) {

      this.mediaService = datacontext.medias();
      this.tagService = datacontext.tags();
      this.customers = datacontext.customers().query();

      this.logger = common.logger;

      // File Upload 相關
      this.uploader = new FileUploader({
        url: `${apiUrl}api/Medias`, // webapi url
        headers: {
          'Authorization': `Bearer ${localStorageService.get("jwt")}`
        }
      });

      // FILTERS
      this.uploader.filters.push({
        name: "mediaFilter",
        fn: (item /*{File|FileLikeObject}*/, options) => {
          var type = `|${item.type.slice(item.type.lastIndexOf("/") + 1)}|`;
          return "|jpg|png|jpeg|pdf|mp4|mpg|mpeg|x-matroska|mkv|avi|mp3|".indexOf(type) !== -1;
        }
      });

      // CALLBACKS
      this.uploader.onBeforeUploadItem = (item) => {

        var formData = [
          {
            customerId: this.media.customerId,
            description: this.media.description,
            category: this.media.category,
            tags: JSON.stringify(this.tags)
          }
        ];

        Array.prototype.push.apply(item.formData, formData);

        console.info("onBeforeUploadItem", item);
      };
      this.uploader.onCompleteAll = () => {
        $state.go("medias.list");
      };

      // Tag 相關
      //availableTagName for Autocomplete
      this.tagService.query().$promise.then((availableTags) => {
        R.map(tag => this.availableTagName.push(tag.name), availableTags);
      });

      // 判斷為編輯還是新增Media
      const isEdit = $stateParams.id !== undefined;

      if (isEdit) {
        this.mediaService.get({ id: $stateParams.id },
          data => {
            this.media = data;
            console.log(data);
            R.map(tag => this.tags.push({ text: tag.name }), this.media.tags);
          });
      } else {
        // Media 的初始化
        this.media = {
          "id": 0,
          "customerId": null,
          "description": "",
          "category": "",
          "tags": []
        };
      }
    }

    loadTags(query) {
      var autocompleteTagName = [];
      this.availableTagName.map(tagName => {
        if (tagName.indexOf(query) !== -1) {
          autocompleteTagName.push(tagName);
        }
      });

      return autocompleteTagName;
    };

    //修改媒體檔
    save(media) {
      media.tags = this.tags.map((tag) => {
        return { name: tag.text };
      });

      switch (media.contentType.split("/")[0]) {
        case "image":
          this.mediaService.update({ id: media.id }, media,
            (value, responseHeaders) => {
              this.logger.success("Media has Update!", value, "Good job!");
              this.$state.go("medias.list");
            });
          break;
        case "video":
          this.mediaService.update({ id: media.id }, media,
            (value, responseHeaders) => {
              this.logger.success("Media has Update!", value, "Good job!");
              this.$state.go("medias.list");
            });
          break;
      }
    };

    //開啟原檔
    //openLightboxModal(fileUrl, description, contentType) {
    //  if (contentType.includes("video"))
    //    this.lightbox.openModal([{ "url": fileUrl, "caption": description, "type": "video" }], 0);
    //  else
    //    this.lightbox.openModal([{ "url": fileUrl, "caption": description }], 0);
    //};
}

export class MediaView {
  private logger: ILogger;
  private fileUrl: string;
  private dispalyName: string;
  private mediaType: string;

  static $inject = ["common", "$stateParams", "$window"];

  constructor(private common, private $stateParams, private $window) {
    this.logger = common.logger;
    this.fileUrl = $stateParams.fileUrl;
    this.dispalyName = $stateParams.dispalyName;
    this.mediaType = $stateParams.mediaType;
  };

  goBack() {
    console.log(22);
    this.$window.history.back();
  }
}
