import { Logger } from "../logger/logger";

interface IException {
  catcher: (message: string) => (reason: string) => void;
}

export class Exception implements IException {
  static $inject: Array<string> = ["logger"];

  constructor(private logger: Logger) {}

  catcher = (message: string) => (reason: string) =>
    this.logger.error(message, reason);
}
