import { StateProvider } from "@uirouter/angularjs";
import { Datacontext } from "../data/datacontext";

routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider.state("schedule", {
    url: "/schedule",
        template: require("./views/schedule.html"),
    controller: "Schedule",
    controllerAs: "vm",
    data: { pageTitle: "Schedule", requiresLogin: true },
    resolve: {
      devices: [
        "datacontext",
        (datacontext: Datacontext) => datacontext.devices().query()
      ],
      loadPlugin: [
        "$ocLazyLoad",
        $ocLazyLoad =>
          $ocLazyLoad.load([
            {
              insertBefore: "#linkRoot",
              serie: true,
              files: [
                rootUrl + "assets/plugins/fullcalendar/fullcalendar.min.css",
                rootUrl +
                  "assets/plugins/fullcalendar-scheduler/scheduler.min.css",
                rootUrl + "assets/plugins/fullcalendar/fullcalendar.min.js",
                rootUrl + "assets/plugins/fullcalendar/locale-all.js",
                rootUrl +
                  "assets/plugins/fullcalendar-scheduler/scheduler.min.js"
              ]
            },
            {
              name: "ui.calendar",
              files: [
                rootUrl + "assets/plugins/angular-ui-calendar/calendar.js"
              ]
            },
            {
              name: "moment-picker",
              files: [
                rootUrl +
                  "assets/plugins/angular-moment-picker/angular-moment-picker.min.css",
                rootUrl +
                  "assets/plugins/angular-moment-picker/angular-moment-picker.min.js"
              ]
            },
            {
              name: "datePicker",
              files: [
                rootUrl +
                  "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                rootUrl +
                  "assets/plugins/angular-datepicker/angular-datepicker.min.js"
              ]
            },
            {
              files: [
                rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
              ]
            },
            {
              name: "angular-cron-jobs",
              files: [
                rootUrl +
                  "assets/plugins/angular-cron-jobs/angular-cron-jobs.min.css",
                rootUrl +
                  "assets/plugins/angular-cron-jobs/angular-cron-jobs.min.js"
              ]
            },
            {
              name: "mgo-angular-wizard",
              files: [
                rootUrl +
                  "assets/plugins/angular-wizard/angular-wizard.min.css",
                rootUrl + "assets/plugins/angular-wizard/angular-wizard.min.js"
              ]
            }
          ])
      ]
    }
  });
}
