import { StateProvider } from "@uirouter/angularjs";
routeConfig.$inject = ["rootUrl", "$stateProvider"];

export function routeConfig(rootUrl: string, $stateProvider: StateProvider): void {
  $stateProvider
    .state("changjia", {
    abstract: true,
    url: "/changjia",
    template:  require("../views/common/content.html"),
    data: { pageTitle: "changjia", requiresLogin: true }
  })
    .state("changjia.humancountboardConfigList", {
    url: "/humancountboardConfigList",
    template: require('./views/humancountboardConfig/list.html'),
    controller: "HumancountboardConfigListController",
    controllerAs: "vm"
  })
    .state("changjia.humancountboardConfigCreate", {
    url: "/humancountboardConfigCreate",
    template: require('./views/humancountboardConfig/create_edit.html'),
    controller: "HumancountboardConfigCreateController",
    controllerAs: "vm",
    resolve: {
      loadPlugin: [
        "$ocLazyLoad",
        ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              name: "angularFileUpload",
              files: [
                rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
              ]
            }
          ])
      ]
    }
  })
    .state("changjia.humancountboardConfigEdit", {
    url: "/humancountboardConfigEdit/{id:int}",
    template: require('./views/humancountboardConfig/create_edit.html'),
    controller: "HumancountboardConfigCreateController",
    controllerAs: "vm",
    resolve: {
      loadPlugin: [
        "$ocLazyLoad",
        ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              name: "angularFileUpload",
              files: [
                rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
              ]
            }
          ])
      ]
    }
  })
    .state("changjia.humancountboardConfigView", {
    url: "/humancountboardConfigView/{id:int}",
    template: require('./views/humancountboardConfig/view.html'),
    controller: "HumancountboardConfigViewController",
    controllerAs: "vm",
    resolve: {
      loadPlugin: [
        "$ocLazyLoad",
        ($ocLazyLoad: oc.ILazyLoad) =>
          $ocLazyLoad.load([
            {
              name: "angularFileUpload",
              files: [
                rootUrl +
                  "assets/plugins/angular-file-upload/angular-file-upload.min.js"
              ]
            },
            {
              files: [
                rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
              ]
            }
          ])
      ]
    }
  })
    .state("stores", {
      abstract: true,
      url: "/stores",
      template:  require("../views/common/content.html"),
      data: { pageTitle: "AI智慧顯示屏", requiresLogin: true }
    })
    .state("stores.configurationList", {
      url: "/configurationList",
      template: require('./views/configuration/list.html'),
      controller: "ConfigurationListController",
      controllerAs: "vm"
    })
    .state("stores.configurationCreate", {
      url: "/configurationCreate",
      template: require('./views/configuration/create_edit.html'),
      controller: "ConfigurationCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.configurationEdit", {
      url: "/configurationEdit/{id:int}",
      template: require('./views/configuration/create_edit.html'),
      controller: "ConfigurationCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.configurationView", {
      url: "/configurationView/{id:int}",
      template: require('./views/configuration/view.html'),
      controller: "ConfigurationViewController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.popularProductList", {
      url: "/popularProductList",
      template: require('./views/popularProduct/list.html'),
      controller: "PopularProductListController",
      controllerAs: "vm"
    })
    .state("stores.popularProductCreate", {
      url: "/popularProductCreate",
      template: require('./views/popularProduct/create_edit.html'),
      controller: "PopularProductCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.popularProductEdit", {
      url: "/popularProductEdit/{id:int}",
      template: require('./views/popularProduct/create_edit.html'),
      controller: "PopularProductCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.popularProductView", {
      url: "/popularProductView/{id:int}",
      template: require('./views/popularProduct/view.html'),
      controller: "PopularProductViewController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.preOrderList", {
      url: "/preOrderList",
      template: require('./views/preOrder/list.html'),
      controller: "PreOrderListController",
      controllerAs: "vm"
    })
    .state("stores.preOrderCreate", {
      url: "/preOrderCreate",
      template: require('./views/preOrder/create_edit.html'),
      controller: "PreOrderCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.preOrderEdit", {
      url: "/preOrderEdit/{id:int}",
      template: require('./views/preOrder/create_edit.html'),
      controller: "PreOrderCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.preOrderView", {
      url: "/preOrderView/{id:int}",
      template: require('./views/preOrder/view.html'),
      controller: "PreOrderViewController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.studentAreaList", {
      url: "/studentAreaList",
      template: require('./views/game1/list.html'),
      controller: "StudentAreaListController",
      controllerAs: "vm"
    })
    .state("stores.studentAreaCreate", {
      url: "/studentAreaCreate",
      template: require('./views/game1/create_edit.html'),
      controller: "StudentAreaCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.studentAreaEdit", {
      url: "/studentAreaEdit/{id:int}",
      template: require('./views/game1/create_edit.html'),
      controller: "StudentAreaCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.studentAreaView", {
      url: "/studentAreaView/{id:int}",
      template: require('./views/game1/view.html'),
      controller: "StudentAreaViewController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.fourthAreaList", {
      url: "/fourthAreaList",
      template: require('./views/fourthArea/list.html'),
      controller: "FourthAreaListController",
      controllerAs: "vm"
    })
    .state("stores.fourthAreaCreate", {
      url: "/fourthAreaCreate",
      template: require('./views/fourthArea/create_edit.html'),
      controller: "FourthAreaCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.fourthAreaEdit", {
      url: "/fourthAreaEdit/{id:int}",
      template: require('./views/fourthArea/create_edit.html'),
      controller: "FourthAreaCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.fourthAreaView", {
      url: "/fourthAreaView/{id:int}",
      template: require('./views/fourthArea/view.html'),
      controller: "FourthAreaViewController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.fourthAreaConfigList", {
      url: "/fourthAreaConfigList",
      template: require('./views/fourthAreaConfig/list.html'),
      controller: "FourthAreaConfigListController",
      controllerAs: "vm"
    })
    .state("stores.fourthAreaConfigCreate", {
      url: "/fourthAreaConfigCreate",
      template: require('./views/fourthAreaConfig/create_edit.html'),
      controller: "FourthAreaConfigCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.fourthAreaConfigEdit", {
      url: "/fourthAreaConfigEdit/{id:int}",
      template: require('./views/fourthAreaConfig/create_edit.html'),
      controller: "FourthAreaConfigCreateController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                name: "datePicker",
                files: [
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.css",
                  rootUrl +
                    "assets/plugins/angular-datepicker/angular-datepicker.min.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.fourthAreaConfigView", {
      url: "/fourthAreaConfigView/{id:int}",
      template: require('./views/fourthAreaConfig/view.html'),
      controller: "FourthAreaConfigViewController",
      controllerAs: "vm",
      resolve: {
        loadPlugin: [
          "$ocLazyLoad",
          ($ocLazyLoad: oc.ILazyLoad) =>
            $ocLazyLoad.load([
              {
                name: "angularFileUpload",
                files: [
                  rootUrl +
                    "assets/plugins/angular-file-upload/angular-file-upload.min.js"
                ]
              },
              {
                files: [
                  rootUrl + "Content/plugins/clockpicker/clockpicker.css",
                  rootUrl + "Scripts/plugins/clockpicker/clockpicker.js"
                ]
              }
            ])
        ]
      }
    })
    .state("stores.deviceList", {
      url: "/deviceList",
      template: require('./views/device/list.html'),
      controller: "StoreDeviceListController",
      controllerAs: "vm"
    })
    .state("stores.docSignList", {
      url: "/docSignList",
      template: require('./views/docSign/list.html'),
      controller: "DocSignListController",
      controllerAs: "vm"
    });
}