import * as angular from "angular";
import { routeConfig } from "./config.route";
import { TreasurehuntListsCtrl, TreasurehuntEditCtrl, TreasurehuntEditContentCtrl } from "./treasurehunt";

export default angular
  .module("app.smartshelves.treasurehunt", [
    // Angular modules
    // Custom modules
    // 3rd Party Modules
  ])
  .config(routeConfig)
  .component("treasurehuntList", {
    template: require("./views/lists.html"),
    controller: TreasurehuntListsCtrl,
    bindings: {
      treasurehunts: "<",
      authentication: "<"
    }
  })
  .component("treasurehuntEditContent", {
    template: require("./views/edit-content.html"),
    controller: TreasurehuntEditContentCtrl,
    bindings: {
      maintreasurehunt: "<",
      treasurehunt: "=",
      products: "<",
      culture: "<",
      ismainculture: "<",
      authentication: "<"
    }
  })
  .component("treasurehuntEdit", {
    template: require("./views/edit.html"),
    controller: TreasurehuntEditCtrl,
    bindings: {
      treasurehunt: "<",
      products: "<",
      agencies: "<",
      authentication: "<"
    }
  });
