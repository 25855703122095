import { StateService } from "@uirouter/angularjs";
import { ILogger } from "../blocks/logger/logger";
import { Datacontext } from "../data/datacontext";
import { IBoard, IAgency } from "../models/models";

interface IBoardListViewModel {
  boards: Array<any>;
  authentication: any;

  del(id: number): void;
}

export class BoardListsCtrl
  implements IBoardListViewModel, angular.IController {
  boards: any[];
  authentication: any;

  private readonly boardService: any;
  private readonly logger: ILogger;

  static $inject = ["datacontext", "common", "$filter"];

  $onInit() {}

  constructor(datacontext: Datacontext, common: any, private readonly $filter) {
    this.boardService = datacontext.boards();
    this.boards = this.boardService.query();

    this.logger = common.logger;
  }

  del(id: number): void {
    this.boardService.delete(
      { id: id },
      (value, responseHeaders) => {
        var indx = this.$filter("findIndexByKeyValue")(this.boards, "id", id);
        this.boards.splice(indx, 1);
        this.logger.success(
          "Board has be deleted",
          value,
          "Delete Board Success"
        );
      },
      httpResponse => {
        this.logger.error(
          "Board can't delete, device ID is " + id,
          httpResponse,
          "Delete Board Error"
        );
      }
    );
  }
}

interface IBoardViewModel {
  board: any;
}

export class BoardViewCtrl implements IBoardViewModel {
  static $inject = ["common", "board"];
  constructor(common: any, public board: any) {
    var logger: ILogger = common.logger;
    logger.log(board);
  }
}

interface IBoardCreateEditViewModel {
  board: IBoard;
  agencies: IAgency;

  save(): void;
  codeAddress(): void;
}

export class BoardCreateEditCtrl implements IBoardCreateEditViewModel {
  board: any;
  agencies: IAgency;

  private logger: ILogger;
  private boardService: any;

  static $inject = [
    "datacontext",
    "common",
    "agencies",
    "$scope",
    "$stateParams",
    "$state",
    "Geocoder"
  ];
  constructor(
    datacontext: Datacontext,
    common,
    agencies: IAgency,
    private $scope,
    $stateParams,
    private $state: StateService,
    private geocoder
  ) {
    this.agencies = agencies;

    this.logger = common.logger;
    this.boardService = datacontext.boards();

    // 判斷為編輯還是新增Agency
    $scope.isEdit = $stateParams.id !== undefined;

    // Board 的初始化
    this.board = {
      id: 0,
      name: "",
      description: "",
      category: "",
      location: {
        address: "",
        latitude: 0,
        longitude: 0
      },
      screen: {
        orientation: 0,
        resolution: {
          height: 0,
          width: 0
        }
      },
      agencyId: null
    };

    if ($scope.isEdit) {
      this.board = this.boardService.get({ id: $stateParams.id });
    }
  }

  save(): void {
    if (this.$scope.isEdit) {
      this.boardService.update(
        { id: this.board.id },
        this.board,
        (value, responseHeaders) => {
          this.logger.success("Board has Update!", value, "Good job!");
          this.$state.go("boards.list");
        }
      );
    } else {
      this.boardService.save(null, this.board, (value, responseHeaders) => {
        this.board = value;
        this.$scope.isEdit = true;
        this.logger.success("Board has Create!", value, "Good job!");
        this.$state.go("boards.list");
      });
    }
  }

  codeAddress(): void {
    this.geocoder.geocodeAddress(this.board.location.address).then(latlng => {
      this.board.location.latitude = latlng.lat;
      this.board.location.longitude = latlng.lng;
      this.logger.log(latlng);
    });
  }
}
