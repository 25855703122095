import * as angular from "angular";
import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IDevice } from "../models/models";
import { FunctionalModule } from "../models/enums";

declare var Highcharts: any;

interface ISiteDNAByAgeViewModel {
  devices: Array<any>;
  selectDevice: IDevice;

  dateFrom: moment.Moment;
  dateTo: moment.Moment;

  loading: boolean;

  getSiteDNA();
}

export class SiteDNAByAgeCtrl implements ISiteDNAByAgeViewModel {
  devices: any[];
  selectDevice: IDevice;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  dayKeyFrom: string;
  dayKeyTo: string;

  loading: boolean;

  static $inject = [
    "devices",
    "$http",
    "apiUrl",
    "environment"
  ];

  constructor(
    devices,
    private $http,
    private apiUrl,
    private environment: number
  ) {
    this.loading = false;
    this.dateFrom = moment().subtract(8, "days");
    this.dateTo = moment().subtract(1, "days");

    devices.$promise.then(value => {
      this.devices = value.filter(
        device => device.functionalModule & FunctionalModule.FaceDetection
      );
      //this.devices = value;
      //this.devices = [{
      //    name: 'BJXM01',
      //    guid: '0AE0518B-B151-4EEA-8285-6045CB82186A'
      //}];
    });
  }

  //function declare
  getSiteDNA() {
    this.loading = true;
    this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
    this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

    this.$http
      .post(this.apiUrl + "api/Analysis/SiteDNAByAge", {
        dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
        dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
        deviceGuid: this.selectDevice.guid,
        system: this.environment
      })
      .then(
        response => {
          this.loading = false;

          var obj = response.data;
          //console.log(obj);

          this.drawCountByAge(obj.countByAge);
          this.drawSmileByAge(obj.smileByAge);
          this.drawCountByStay(obj.stayByAge);
          this.drawStayByAge(obj.stayByAge);
          this.drawHourByAge(obj.hourByAge);
          this.drawWeekHourByAge(obj.weekHourByAge);
          this.drawStayByContent(obj.stayByContent);
          this.drawByContent(obj.byContent);
          this.drawCountPerSecByContent(obj.countPerSecByContent);
          this.drawCountByAgePerSecByContent(obj.countPerSecByContent);
          this.drawStayPerSecByContent(obj.countPerSecByContent);
          this.drawStayByAgePerSecByContent(obj.countPerSecByContent);
          this.drawSmilePerSecByContent(obj.countPerSecByContent);
          this.drawSmileByAgePerSecByContent(obj.countPerSecByContent);
          this.drawDayTrend(obj.dayTrend);
        },
        errorResponse => {
          this.loading = false;
          Swal.fire(
            "錯誤",
            errorResponse.data.message +
              "\n" +
              errorResponse.data.exceptionMessage,
            "error"
          );
          console.log(errorResponse.data.message);
        }
      );
  }

  drawCountByAge(objs: any) {
    var y0Array = [];
    var i = 0;
    var totalCount = 0;

    angular.forEach(objs, obj => {
      i++;
      totalCount += obj.count;
      y0Array.push({ name: obj.ageGradeName, y: obj.count });
    });

    const chartOptions = {
      title: {
        text: `${this.dayKeyFrom} ~ ${this.dayKeyTo} 累計 ${totalCount} 人數`
      },
      chart: {
        type: "pie",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format:
              "<b>{point.name}</b>: {point.percentage:.1f} % <br> {point.y} 人數"
          },
          showInLegend: true
        }
      },
      colors: ["#FFBE5F", "#32C887", "#D25F5F", "#2387C3", "#A0A0A0"],
      series: [
        {
          name: "Count",
          data: y0Array
        }
      ]
    };
    Highcharts.chart("containerCountByAge", chartOptions);
  }

  drawSmileByAge(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.smile);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: { text: this.dayKeyFrom + "~" + this.dayKeyTo + " 微笑程度" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerSmileByAge", chartOptions);
  }

  drawCountByStay(objs: any) {
    const y0Array = [];
    var aa = 0;
    var bb = 0;
    var i = 0;
    angular.forEach(objs, obj => {
      i++;
      if (obj.staySec >= 1) {
        aa += obj.tCount + obj.yCount + obj.sCount + obj.mCount + obj.oCount;
      } else {
        bb += obj.tCount + obj.yCount + obj.sCount + obj.mCount + obj.oCount;
      }
    });
    y0Array.push({ name: "注視停留 5 (含) 秒以上", y: aa } as any);
    y0Array.push({ name: "其他", y: bb } as any);
    const chartOptions = {
      title: { text: "" },
      chart: {
        type: "pie",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format:
              "<b>{point.name}</b>: {point.percentage:.1f} % <br> {point.y}"
          }
        }
      },
      colors: ["#ff8080", "#4775d1", "#33ff33"],
      series: [
        {
          name: "Count",
          data: y0Array
        }
      ]
    };
    Highcharts.chart("containerCountByStay", chartOptions);
  }

  drawStayByAge(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.stay);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 注視停留狀態累計人數"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerStayByAge", chartOptions);
  }

  drawHourByAge(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.hour);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每小時觀看人數"
      },
      chart: {
        stacking: "normal",
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerHourByAge", chartOptions);
  }

  drawWeekHourByAge(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.hour);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 周間每小時觀看人數"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: "normal",
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerWeekHourByAge", chartOptions);
  }

  drawStayByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.tStay);
      y1Array.push(obj.yStay);
      y2Array.push(obj.sStay);
      y3Array.push(obj.mStay);
      y4Array.push(obj.oStay);
    });
    const chartOptions = {
      title: { text: this.dayKeyFrom + "~" + this.dayKeyTo + " 媒體吸睛比較" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "平均關注秒數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerStayByContent", chartOptions);
  }

  drawByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: { text: this.dayKeyFrom + "~" + this.dayKeyTo + " 媒體關注人數" },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          //stacking: 'normal'
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerCountByContent", chartOptions);
  }

  drawCountPerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(
        (obj.countTPerSec +
          obj.countYPerSec +
          obj.countSPerSec +
          obj.countMPerSec +
          obj.countOPerSec) *
          60
      );
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘人數分析"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘人數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "平均每分鐘人數",
          yAxis: 1,
          type: "line",
          data: y1Array
        }
      ]
    };
    Highcharts.chart("containerCountPerSecByContent", chartOptions);
  }

  drawCountByAgePerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [],
      y5Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(obj.countTPerSec * 60);
      y2Array.push(obj.countYPerSec * 60);
      y3Array.push(obj.countSPerSec * 60);
      y4Array.push(obj.countMPerSec * 60);
      y5Array.push(obj.countOPerSec * 60);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘人數分析-區分年齡"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘人數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "少年",
          yAxis: 1,
          type: "line",
          data: y1Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          yAxis: 1,
          type: "line",
          data: y2Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          yAxis: 1,
          type: "line",
          data: y3Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          yAxis: 1,
          type: "line",
          data: y4Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          yAxis: 1,
          type: "line",
          data: y5Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerCountByAgePerSecByContent", chartOptions);
  }

  drawStayPerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(
        (obj.stayTPerSec +
          obj.stayYPerSec +
          obj.staySPerSec +
          obj.stayMPerSec +
          obj.stayOPerSec) *
          60.0
      );
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘吸睛分析"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘吸睛秒數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "平均每分鐘吸睛秒數",
          yAxis: 1,
          type: "line",
          data: y1Array
        }
      ]
    };
    Highcharts.chart("containerStayPerSecByContent", chartOptions);
  }

  drawStayByAgePerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [],
      y5Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(obj.stayTPerSec * 60);
      y2Array.push(obj.stayYPerSec * 60);
      y3Array.push(obj.staySPerSec * 60);
      y4Array.push(obj.stayMPerSec * 60);
      y5Array.push(obj.stayOPerSec * 60);
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘吸睛分析-區分年齡"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘吸睛秒數" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "少年",
          yAxis: 1,
          type: "line",
          data: y1Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          yAxis: 1,
          type: "line",
          data: y2Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          yAxis: 1,
          type: "line",
          data: y3Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          yAxis: 1,
          type: "line",
          data: y4Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          yAxis: 1,
          type: "line",
          data: y5Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerStayByAgePerSecByContent", chartOptions);
  }

  drawSmilePerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(
        (obj.smileTPerSec +
          obj.smileYPerSec +
          obj.smileSPerSec +
          obj.smileMPerSec +
          obj.smileOPerSec) *
          60.0
      );
    });
    const chartOptions = {
      title: {
        text: this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘創造歡笑指標"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘創造歡笑指標" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "創造歡笑指標",
          yAxis: 1,
          type: "line",
          data: y1Array
        }
      ]
    };
    Highcharts.chart("containerSmilePerSecByContent", chartOptions);
  }

  drawSmileByAgePerSecByContent(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [],
      y5Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.content);
      y0Array.push(obj.playSecs / 60.0);
      y1Array.push(obj.smileTPerSec * 60);
      y2Array.push(obj.smileYPerSec * 60);
      y3Array.push(obj.smileSPerSec * 60);
      y4Array.push(obj.smileMPerSec * 60);
      y5Array.push(obj.smileOPerSec * 60);
    });
    const chartOptions = {
      title: {
        text:
          this.dayKeyFrom + "~" + this.dayKeyTo + " 每分鐘創造歡笑指標-區分年齡"
      },
      chart: {
        type: "column",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: [
        {
          title: { text: "累積播放分鐘" }
        },
        {
          title: { text: "平均每分鐘創造歡笑指標-區分年齡" },
          labels: {
            format: "{value:.2f}"
          },
          opposite: true
        }
      ],
      series: [
        {
          name: "累積播放分鐘",
          yAxis: 0,
          data: y0Array
        },
        {
          name: "少年",
          yAxis: 1,
          type: "line",
          data: y1Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          yAxis: 1,
          type: "line",
          data: y2Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          yAxis: 1,
          type: "line",
          data: y3Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          yAxis: 1,
          type: "line",
          data: y4Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          yAxis: 1,
          type: "line",
          data: y5Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerSmileByAgePerSecByContent", chartOptions);
  }

  drawDayTrend(objs: any) {
    var i = 0;
    var xArray = [],
      y0Array = [],
      y1Array = [],
      y2Array = [],
      y3Array = [],
      y4Array = [];
    angular.forEach(objs, obj => {
      i++;
      xArray.push(obj.day);
      y0Array.push(obj.tCount);
      y1Array.push(obj.yCount);
      y2Array.push(obj.sCount);
      y3Array.push(obj.mCount);
      y4Array.push(obj.oCount);
    });
    const chartOptions = {
      title: { text: `版位：${this.selectDevice.name}` },
      chart: {
        type: "spline",
        style: {
          fontFamily: "NotoSansHant-Regular"
        }
      },
      legend: { align: "right", verticalAlign: "center", layout: "vertical" },
      plotOptions: {
        column: {
          stacking: "normal"
        }
      },
      xAxis: {
        title: { text: "" },
        categories: xArray
      },
      yAxis: {
        title: { text: "人數" }
      },
      series: [
        {
          name: "少年",
          data: y0Array,
          color: "#FFBE5F"
        },
        {
          name: "青年",
          data: y1Array,
          color: "#32C887"
        },
        {
          name: "壯年",
          data: y2Array,
          color: "#D25F5F"
        },
        {
          name: "中年",
          data: y3Array,
          color: "#2387C3"
        },
        {
          name: "老年",
          data: y4Array,
          color: "#A0A0A0"
        }
      ]
    };
    Highcharts.chart("containerDayTrend", chartOptions);
  }
}
