import * as moment from "moment-timezone";
import Swal from "sweetalert2";

import { IDevice } from "../models/models";
import { FunctionalModule } from "../models/enums";

  interface IButtonLogsViewModel {
    devices: Array<any>;
    selectDevice: IDevice;

    dateFrom: moment.Moment;
    dateTo: moment.Moment;

    loading: boolean;

    getButtonLogs();
  }

  export class ButtonLogsCtrl implements IButtonLogsViewModel {
    devices: any[];
    selectDevice: IDevice;
    dateFrom: moment.Moment;
    dateTo: moment.Moment;
    dayKeyFrom: string;
    dayKeyTo: string;

    loading: boolean;
    logs: any[];


    static $inject = ["common", "devices", "$http", "apiUrl", "environment"];

    constructor(common, devices, private $http, private apiUrl, private environment: number) {
      this.loading = false;
      this.dateFrom = moment().subtract(8, "days");
      this.dateTo = moment().subtract(1, "days");

      devices.$promise.then(value => {
        this.devices = value.filter(device => device.functionalModule & FunctionalModule.FaceDetection);
      });
      //this.devices = [{ name: '辦公室測試', guid: '80039b87-e206-4e15-aae4-000000000003' }];
      //console.log(this.devices);
    }

    //function declare
    getButtonLogs() {
      this.loading = true;
      this.dayKeyFrom = moment(this.dateFrom).format("YYYYMMDD");
      this.dayKeyTo = moment(this.dateTo).format("YYYYMMDD");

      this.$http.post(this.apiUrl + "api/Analysis/ButtonLogs",
        {
          dayKeyFrom: moment(this.dateFrom).format("YYYYMMDD"),
          dayKeyTo: moment(this.dateTo).format("YYYYMMDD"),
          deviceGuid: this.selectDevice.guid,
        })
        .then(response => {

          this.loading = false;

          var obj = response.data;
          //console.log(obj);
          this.logs = obj.buttonLogs;
        }, errorResponse => {
          this.loading = false;
          Swal.fire("錯誤", errorResponse.data.message + "\n" + errorResponse.data.exceptionMessage,
            "error");
          console.log(errorResponse.data.message);
        });
    }
  }
